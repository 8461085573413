export function quoteToObject(quote) {
    return {
        readOnly: quote.readOnly,
        address: quote.address,
        city: quote.city,
        company: quote.company,
        createdAt: quote.createdAt,
        customer_subtotal: quote.customer_subtotal,
        customer_totalPrice: quote.customer_totalPrice,
        tagName: quote.tag_name,
        deal_name: quote.deal_name,
        discount: {
            lineItemsDiscount: quote.discount_lineItemsDiscount,
            additionalDiscount: quote.discount_additionalDiscount,
            totalDiscount: quote.discount_totalDiscount,
            description: quote.discount_description,
        },
        email: quote.email,
        internal_notes: quote.internal_notes,
        firstname: quote.firstname,
        freight: {
            suggestedCost: quote.freight_suggestedCost,
            cost: quote.freight_cost,
            customerPrice: quote.freight_customerPrice,
            crates: quote.freight_crates,
            palettes: quote.freight_palettes,
            pricePerCrate: quote.freight_pp_crate,
            pricePerMile: quote.freight_pp_mile,
            pricePerPalette: quote.freight_pp_palette,
            markup: quote.freight_markup,
            mileage: quote.freight_mileage,
            suggestedPrice: quote.freight_suggestedPrice,
            description: quote.freight_description,
        },
        hubspot_contact_id: quote.hubspot_contact_id,
        id: quote.id,
        includeTaxes: quote.include_tax,
        installation: {
            includeInstallation: quote.installation_includeInstallation,
            suggestedCost: quote.installation_suggestedCost,
            cost: quote.installation_cost,
            customerPrice: quote.installation_customerPrice,
            description: quote.installation_description,
            includeInstallation: quote.installation_includeInstallation,
            installerCost: quote.installation_installerCost,
            tripCharge: quote.installation_tripCharge,
            suggestedPrice: quote.installation_suggestedPrice,
        },
        is_primary_quote: quote.is_primary_quote,
        deal_closed: quote.deal_closed,
        lastname: quote.lastname,
        phone: quote.phone,
        mobile: quote.mobile,
        prepared_by_hubspot_id: quote.prepared_by_hubspot_id,
        prepared_by_email: quote.prepared_by_email,
        prepared_by_name: quote.prepared_by_name,
        prepared_by_phone: quote.prepared_by_phone,
        products: JSON.parse(quote.products),
        quote_number: quote.quote_number,
        shipping_firstname: quote.shipping_firstname,
        shipping_lastname: quote.shipping_lastname,
        shipping_email: quote.shipping_email,
        shipping_phone: quote.shipping_phone,
        shipping_mobile: quote.shipping_mobile,
        shipping_company: quote.shipping_company,
        shipping_contactid: quote.shipping_contactid,
        shipping_address: quote.shipping_address,
        shipping_city: quote.shipping_city,
        shipping_state: quote.shipping_state,
        shipping_zip: quote.shipping_zip,
        state: quote.state,
        total_cost: quote.total_cost,
        total_margin: quote.total_margin,
        total_profit: quote.total_profit,
        total_taxes: quote.total_taxes,
        tax_rate: quote.tax_rate,
        zip: quote.zip,
    };
}
