import { ACTIONS } from "./Actions"
import { compareArrays, compareArraysOfObjects, compareObjects } from "./helpers"

export const storeReducer = (state, action) => {
    switch(action.type) {
        case ACTIONS.APP_CONTAINER_STYLE:
            if (compareObjects(state.appContainerStyle, action.payload)) {
                return {
                    ...state,
                    appContainerStyle: action.payload
                }
            }
            return state;
        case ACTIONS.AUTH:
            if (compareObjects(state.auth, action.payload)) {
                return {
                    ...state,
                    auth: action.payload
                }
            }
            return state;
        case ACTIONS.BACKGROUND_COLOR:
            if (state.backgroundColor === action.payload) return state

            return {
                ...state,
                backgroundColor: action.payload
            }
        case ACTIONS.CONTACT_INFO:            
            if (compareObjects(state.contactInfo, action.payload)) {
                return {
                    ...state,
                    contactInfo: action.payload
                }
            }
            return state;
        case ACTIONS.CONTACT_SELECTED:
            if (state.contactSelected === action.payload) return state

            return {
                ...state,
                contactSelected: action.payload
            }
        case ACTIONS.CONTACT_SELECTED_ARR:
            if (compareArrays(state.contactsSelectedArr, action.payload)) {
                return {
                    ...state,
                    contactsSelectedArr: action.payload
                }
            }
            return state;
        case ACTIONS.ENABLE_CACHE_BUSTER:
            if (state.enableCacheBuster === action.payload) return state;

            return {
                ...state,
                enableCacheBuster: action.payload,
            }
        case ACTIONS.FILTERED_LIST:
            if (compareArrays(state.filteredList, action.payload)) {
                return {
                    ...state,
                    filteredList: action.payload
                }
            }
            return state;
        case ACTIONS.INCLUDE_SHEETS:
            if (JSON.stringify(state.includeSheets) !== JSON.stringify(action.payload)) {
                return {
                    ...state,
                    includeSheets: action.payload
                }
            }
            return state
        case ACTIONS.IS_NEW_CONTACT:
            if (state.isNewContact === action.payload) return state

            return {
                ...state,
                isNewContact: action.payload
            }
        case ACTIONS.GLOBAL_ERROR_MESSAGE:
            if (state.globalErrorMessage === action.payload) return state;

            return {
                ...state,
                globalErrorMessage: action.payload
            }
        case ACTIONS.LOADING_PO:
            if (state.loadingPO === action.payload) return {...state, loadedPO: false};

            return {
                ...state,
                loadedPO: false,
                loadingPO: action.payload
            }
        case ACTIONS.MANUFACTURERS:
            if (compareObjects(state.manufacturers, action.payload)) {
                return {
                    ...state,
                    manufacturers: action.payload
                }
            }
            return state
        case ACTIONS.NAVBAR_QUOTE_ID:
            if (state.navbarQuoteID === action.payload) return state;

            return {
                ...state,
                navbarQuoteID: action.payload
            }
        case ACTIONS.PREPARED_BY:
            if (compareObjects(state.preparedBy, action.payload)) {
                return {
                    ...state,
                    preparedBy: action.payload
                }
            }
            return state;
        case ACTIONS.PRODUCT_TABLE_ITEMS:             
            if (JSON.stringify(state.productTableItems) !== JSON.stringify(action.payload)) {
                return {
                    ...state,
                    productTableItems: action.payload
                }
            }
            return state;
        case ACTIONS.PRODUCT_VARIANTS:
            if (compareObjects(state.productVariants, action.payload)) {
                return {
                    ...state,
                    productVariants: action.payload
                }
            }
            return state;
        case ACTIONS.PROJECT:
            if (compareObjects(state.project, action.payload)) {
                return {
                    ...state,
                    project: action.payload
                }
            }
            return state;
        case ACTIONS.PURCHASE_ORDER:
            return {
                ...state,
                loadedPO: true,
                loadingPO: false,
                purchaseOrders: action.payload,
            }
        case ACTIONS.QB_PURCHASE_ORDER:
            if (compareObjects(state.qbPurchaseOrder, action.payload)) {
                return {
                    ...state,
                    qbPurchaseOrders: action.payload
                }
            }
            return state;
        case ACTIONS.QUOTE:
            if (JSON.stringify(state.quote) !== JSON.stringify(action.payload)) {
                return {
                    ...state,
                    quote: action.payload
                }
            }
            return state;
        case ACTIONS.SAVED_QUOTE_LIST:            
            if (compareArraysOfObjects(state.savedQuotesList, action.payload)) {
                return {
                    ...state,
                    savedQuotesList: action.payload
                }
            }
            return state
        case ACTIONS.TOAST:
            if (state.toast === action.payload) return state
            if (compareObjects(state.toast, action.payload)) {
                return {
                    ...state,
                    toast: action.payload
                }
            }
            return state;
        case ACTIONS.UPDATE_CONTACT_INFO:            
            if (compareObjects(state.updateContactInfo, action.payload)) {
                return {
                    ...state,
                    updateContactInfo: action.payload
                }
            }
            return state;
        case ACTIONS.UPDATE_PREFERED_COMPLETION_DATE:
            if (state.updatedPreferredCompletionDate === action.payload) return state;

            return {
                ...state,
                updatedPreferredCompletionDate: action.payload
            }
        case ACTIONS.VERSION_NUMBER:
            if (state.versionNumber === action.payload) return state;

            return {
                ...state,
                versionNumber: action.payload
            }
        default:
            return state;
    }
}
