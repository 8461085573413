import React, {useEffect, useState} from 'react';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const DealName = ({
    dealNameRequired,
    setDealNameRequired,
    uniqueName,
    setUniqueName,
    dealNameList,
}) => {
    const { state, dispatch } = useStore();

    // New deal input value
    const [newDeal, setNewDeal] = useState();

    useEffect(() => {
        if (newDeal) {
            setDealNameRequired(false);
        }
    }, []);

    useEffect(() => {
        setNewDeal(state?.quote?.deal_name);
    }, [state?.quote?.deal_name]);

    useEffect(() => {
        if (uniqueName) {
            dispatch({type: ACTIONS.QUOTE, payload: {...state?.quote, deal_name: newDeal.trim()}});
        }
        
    }, [newDeal]);

    return (
        <div className="deal_name width-100">
            <label className = 'text-bold text-smedium width-100'>Deal Name:</label>
            <br />
            {/* Text input for adding new deal name */}
            <input
                type="text"
                className="width-100 pad-xs border-radius-xs border-thin"
                defaultValue={newDeal}
                placeholder="New deal name"
                onChange={(e) => {
                    //Check if only whitespace
                    const chars = e.target.value.match(/\S/);
                    //create array of dealNames
                    let dealNames = dealNameList?.map((a) => a.deal_name);
                    //check array for deal name
                    var unique = dealNames?.includes(e.target.value);

                    if (!chars) {
                        setDealNameRequired(true);
                    } else {
                        setNewDeal(e.target.value);
                        setDealNameRequired(false);
                    }

                    if (!unique) {
                        setUniqueName(false);
                    } else {
                        setUniqueName(true);
                    }
                }}
                onBlur={(e) => {
                    dispatch({type: ACTIONS.QUOTE, payload: { ...state?.quote, deal_name: newDeal.trim()}});
                }}
                disabled={state?.quote?.readOnly}
            />
            <p className="deal_name_or">
                {dealNameRequired ? (
                    <b className="deal_name error">Deal Name Required</b>
                ) : (
                    <>{uniqueName ? <b className="deal_name error">Deal name must be unique</b> : <div></div>}</>
                )}
            </p>
        </div>
    );
};

export default DealName;
