import React from 'react';
import { useNavigate, useHistory } from 'react-router-dom';
import { ACTIONS } from '../../../src/Actions';
import { useStore } from '../../../src/StoreProvider';
import Btn from '../Btn';
import notFound from '../../../src/assets/not-found.svg';
import pgLogo from '../../assets/PGLogo.png';

const InvalidQuote = () => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();

    const backToQuotes = () => {
        const newPath = "";
        dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: '' });
        navigate(newPath);
    }

    return (
        <>
        <div className = "full-orange-background"></div>
        <div className = {"error-modal border-radius-xs b-none justify-center"}>
            <img src = {pgLogo} alt = "Playground Boss Logo" className = "modal-logo"></img>
            <div className = "flex-column height-100 width-60 justify-center">
                <div className = 'flex justify-center align-center width-100 column-gap-lg'>
                <img src = {notFound} alt = "Not Found" className = "width-20"></img>
                <div className = "text-green text-bolder row-gap-lg">
                    <p className = "text-xxl margin-b-md">Invalid Quote</p>
                    <p className = "text-xxxl">Number</p>
                </div>
                </div>
                <p className = "margin-t-lg width-75 align-self-center text-smedium line-height-150">
                This quote does not exist, please verify the quote number in the URL is a valid quote number.
                </p>
                <div className = "margin-t-xxlg flex justify-center">
                <Btn onClick={backToQuotes}
                btnClass='bg-green2 text-white text-lrg b-none box-shadow pad-md pad-l-xxlg pad-r-xxlg' 
                title='Back to Quotes'/>
                </div>
            </div>
        </div>
        </>
    )

}

export default InvalidQuote;
