import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import loadingGif from '../assets/loading.gif'
import DownloadPDF from '../components/cutSheets/DownloadPDF';
import SendEmailBtn from '../components/cutSheets/SendEmailBtn';
import SheetsToAdd from '../components/cutSheets/SheetsToAdd'
import AvailableSheets from '../components/cutSheets/AvailableSheets'
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import {quoteToObject} from '../utilities/formatQuote';

const CutSheets = () => {
  const { state, dispatch } = useStore();
  const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
  const navigate = useNavigate();

  const quoteNumber = window.location.pathname?.split('/cutSheets/')[1];
  const quoteID = quoteNumber?.substring(7);

  const [cutSheets, setCutSheets] = useState([]);

  // Loading cut sheets from hubspot
  const [loading, setLoading] = useState()
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    // When component loads, make call to get all cut sheets from our API
    (async () => {
      setLoading(true)
      try {
        const res = await fetch(`${config.base_api}/hubspot/cut-sheets`, { headers })
        const data = await convertJSON(res)
        setCutSheets(data.results)
        
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    })()

    if (quoteID) {
      dispatch({type: ACTIONS.QUOTE, payload: emptyQuoteObj});

      (async () => {
          try {
              let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, {headers});
              if (!quoteData.ok) throw 'Could not get quote';
              quoteData = await convertJSON(quoteData);
              const formattedQuote = quoteToObject(quoteData);
              dispatch({ type: ACTIONS.QUOTE, payload: {...state?.quote, ...formattedQuote}});
          } catch (error) {
              console.log(error);
          }
      })();
    }
  },[])

  return (
    <div className="cutSheets">
      <div className="cutSheets_headline_grid">
			  <h1>Include Cut Sheets To Deal</h1>
        <div className="cutSheets_headline_buttons">
          <DownloadPDF setDownloading={setDownloading}/>
          <SendEmailBtn quoteNumber = {quoteNumber} />
        </div>
      </div>
      <div className="cutSheets_grid">
        <SheetsToAdd />
        <AvailableSheets cutSheets={cutSheets} loading={loading} />
      </div>
      {/* Downloading modal that pops up when creating pdf */}
      {downloading && 
        <div className='cutSheets_downloading'>
          <h3>Download will start shortly</h3> 
          <img src={loadingGif}/>
        </div>
      }
		</div>
  )
}

export default CutSheets
