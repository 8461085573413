import React from 'react';
import formatDate from '../../utilities/formatDate';
import DealName from './DealName';
import TagName from './TagName';
import Btn from '../../components/Btn'
import { useStore } from '../../StoreProvider';
import { useNavigate } from 'react-router-dom';


const QuoteTitle = (
    { dealNameRequired, setDealNameRequired, uniqueName, setUniqueName }) => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();

    const backToQuotes = () => {
        navigate('/');
    };

    return (
        <div className="margin-b-xxs">
            <Btn
                title='Back to Quotes'
                btnClass='darken-background box-shadow text-bold margin-b-xlg pad-t-md pad-b-md pad-l-xlg pad-r-xlg bg-white text-blue b2-blue' 
                onClick={backToQuotes}/>
            <div className="flex align-start gap-lg">
                <p className='width-fit-content no-wrap text-smedium'>
                    <b>Created on: </b><br />
                    <span className='margin-t-lg text-med'>{formatDate(state?.quote?.createdAt, 'slash')}</span>
                </p>
                <DealName
                    dealNameRequired={dealNameRequired}
                    setDealNameRequired={setDealNameRequired}
                    uniqueName={uniqueName}
                    setUniqueName={setUniqueName}
                />
                <TagName
                />
            </div>
            <h1 className='quote_contactDetails_quoteNumber flex align-center margin-t-xlg'>
                Quote <span className="margin-r-md">#{state?.quote?.quote_number}</span>
                {state?.quote?.is_primary_quote &&
                    <span className="flex justify-center bg-orange text-white text-smedium width-fit-content min-width-85px border-radius-md text-normal margin-r-md pad-t-xs pad-b-xs">
                        Primary
                    </span>}
                {state?.quote?.deal_closed &&
                    <span className="flex justify-center bg-blue text-white text-smedium min-width-85px border-radius-md text-normal pad-t-xs pad-b-xs">
                        WON
                    </span>}
            </h1>

        </div>
    )
}

export default QuoteTitle;