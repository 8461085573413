import React, {useEffect} from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import Modal from '../Modal';
import Btn from '../Btn';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const UpdatePreparedBy = ({preparedBy, quote}) => {
  const { state, dispatch } = useStore();
  const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

  const updatePreparedByInfo = async (e) => {

    let updateInfo = {
      prepared_by_email: preparedBy.prepared_by_email,
      prepared_by_hubspot_id: preparedBy.prepared_by_hubspot_id,
      prepared_by_name: preparedBy.prepared_by_name,
      prepared_by_email: preparedBy.prepared_by_email,
    }

      try {
                    // If current quote for contact exists in our data table
          // Update contact information in Quote data table
          if (quote.id) {
              const quoteRes = await fetch(`${config.base_api}/quote/updateContactInfo/${quote.id}`, {
                  method: 'PATCH',
                  headers,
                  body: JSON.stringify(updateInfo),
              });

              const data = await convertJSON(quoteRes);

              // Set quote state to post request response
              // Get key values;
              const {
                  id,
                  hubspot_contact_id,
                  quote_number,
                  firstname,
                  lastname,
                  phone,
                  mobile,
                  email,
                  company,
                  address,
                  city,
                  state,
                  zip,
                  shipping_firstname,
                  shipping_lastname,
                  shipping_email,
                  shipping_phone,
                  shipping_mobile,
                  shipping_address,
                  shipping_city,
                  shipping_state,
                  shipping_zip,
                  prepared_by_hubspot_id,
                  prepared_by_name,
                  prepared_by_email,
                  prepared_by_phone,
                  is_primary_quote,
                  createdAt,
              } = data;

              // Update our state to track quote creation
              dispatch({ 
                type: ACTIONS.QUOTE, 
                payload: {
                  ...quote,
                  id,
                  hubspot_contact_id,
                  quote_number,
                  firstname,
                  lastname,
                  phone,
                  mobile,
                  email,
                  company,
                  address,
                  city,
                  state,
                  zip,
                  shipping_firstname,
                  shipping_lastname,
                  shipping_email,
                  shipping_phone,
                  shipping_mobile,
                  shipping_address,
                  shipping_city,
                  shipping_state,
                  shipping_zip,
                  prepared_by_hubspot_id,
                  prepared_by_name,
                  prepared_by_email,
                  prepared_by_phone,
                  is_primary_quote,
                  createdAt,
                }
              });
          }
          dispatch({ type: ACTIONS.TOAST, payload: {message: 'Prepared By Updated'}});
          
      } catch (error) {
          console.log(error);
          dispatch({ type: ACTIONS.TOAST, payload: {message: 'Could Not Update', isError: true}});
      }
  };


  return (
    <Btn title="Update Prepared By" onClick={updatePreparedByInfo}
    btnClass = 'b2-blue text-white bg-blue text-bold box-shadow blue-button'/>
  )

}
export default UpdatePreparedBy;