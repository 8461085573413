import React, { useEffect, useState } from 'react';
import PurchaseOrderExclusions from '../components/configuration/PurchaseOrderExclusions';
import LastActivityDateOptions from '../components/configuration/LastActivityDateOptions';
import WidgetPrioritization from '../components/configuration/WidgetPrioritization';
import ReportAutomation from '../components/configuration/ReportAutomation';

const Configuration = () => {
    return (
        <div className='config-container'>
            <h1 className='text-center margin-t-xxlg'>Configuration</h1>
            
            <div className='config-items height-100'>
                <PurchaseOrderExclusions />
                <LastActivityDateOptions />
                <WidgetPrioritization />
                <ReportAutomation />
            </div>
        </div>
    )
}

export default Configuration;