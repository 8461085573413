import React, {useEffect} from 'react';
import {useStore} from '../../StoreProvider';

const SameBillingContact = ({
    shippingContact,
    setShippingContact,
    billingContact,
    billingContactID,
    setShippingContactID,
    handleContactDetailsChange,
}) => {
    const {state} = useStore();
    // set state for billing contact if box is set
    useEffect(() => {
        // check if same billing contact box is checked
        if (billingContact.sameBillingContact) {
            if (billingContactID != 'create' || billingContactID != null) {
                setShippingContactID(billingContactID);
            }
            // set shipping info to contain whatever is in billing contact
            setShippingContact({
                ...shippingContact,
                shipping_firstname: billingContact.firstname,
                shipping_lastname: billingContact.lastname,
                shipping_email: billingContact.email,
                shipping_phone: billingContact.phone,
                shipping_mobile: billingContact.mobile || '',
                shipping_company: billingContact.company,
            });
        } else {
            // if box is unchecked then set state to empty
            setShippingContact({
                ...shippingContact,
                shipping_contactid: '',
                shipping_firstname: '',
                shipping_lastname: '',
                shipping_email: '',
                shipping_phone: '',
                shipping_mobile: '',
                shipping_company: '',
            });
            setShippingContactID('create');
        }
    }, [billingContact.sameBillingContact]);

    return (
        <div className="hubspotContact_details_sameBillingAddress">
            <label htmlFor="hubspot_street">Same as Billing Contact:</label>
            <input
                name="sameBillingContact"
                type="checkbox"
                checked={billingContact.sameBillingContact}
                onChange={handleContactDetailsChange}
                disabled={state?.quote.readOnly}
            />
        </div>
    );
};
export default SameBillingContact;
