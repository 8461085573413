import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import config from '../../config';
import logo from '../../assets/PGLogo.png';
import LoadingInline from '../LoadingInline';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';
import Btn from '../Btn';
import authHeaders from '../../utilities/authHeaders';

const Login = ({setBackgroundColor, appContainerStyle, setAppContainerStyle, getProductVariants, getManufacturers}) => {
    const navigate = useNavigate();
    const { state, dispatch } = useStore();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [timedOut, setTimedOut] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [resetPasswordCheck, setResetPasswordCheck] = useState(0);
    const [userNotFound, setUserNotFound] = useState(false);
    const [userNotVerified, setUserNotVerified] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [incorrectCode, setIncorrectCode] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(false);

    const headers = authHeaders(state?.auth.accessToken)

    const onSubmit = async (e) => {
        e.preventDefault();
        dispatch({type: ACTIONS.AUTH, payload: {...state?.auth, loading: true}});
        try {
            const res = await fetch(`${config.base_api}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: email, password}),
            });

            const data = await convertJSON(res);

            // If incorrect username or password is entered perform following actions
            if (data.error === 'Invalid username or password') {
                setInvalidLogin(true);
                dispatch({type: ACTIONS.AUTH, payload: {...state?.auth, loading: false}});
                return;
            }

            // If playground boss account needs to be verified
            if (data.error === 'Must verify account') {
                setInvalidLogin(false);
                // Pass in auth data to make loggin in easier
                dispatch({type: ACTIONS.AUTH, payload: {...state?.auth, loading: false, username: data.username, tempPass: data.password}});
                navigate('/auth/verifyEmail');
                return;
            }

            //
            // Handle succesful login
            //
            setInvalidLogin(false);

            const idToken = data.idToken?.jwtToken;
            const accessToken = data.accessToken?.jwtToken;
            const refreshToken = data.refreshToken?.token;
            const username = data.idToken.payload.email;
            const isAdmin = +data.idToken.payload['custom:adminPGB'];
            const isSales = +data.idToken.payload['custom:salesPGB'];
            const isOps = data.idToken.payload.zoneinfo;

            // Set tokens in local storage
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('username', username);
            localStorage.setItem('isAdmin', isAdmin);
            localStorage.setItem('rememberMe', rememberMe);
            localStorage.setItem('isSales', isSales);
            localStorage.setItem('isOps', isOps);

            // Store auth items in state
            dispatch({type: ACTIONS.AUTH, payload: {
                loggedIn: true,
                loading: false,
                accessToken,
                refreshToken,
                username,
                isAdmin,
                rememberMe,
                isOps,
                isSales,
            }});
            dispatch({ type: ACTIONS.TOAST, payload: {message: `Succesfully logged in ${data.idToken?.payload?.email}`}});

            try {
                getProductVariants();
                getManufacturers();
            } catch (error) {
                console.log(error);
            }

            try {
                getProductVariants();
                getManufacturers();
            } catch (error) {
                console.log(error);
            }

            // Send back to homepage
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (window.location.pathname?.includes('timedOut')) {
            setTimedOut(true);
        }
      
        if (state?.auth.loggedIn) {
            try {
                getProductVariants();
                getManufacturers();
            } catch (error) {
                console.log(error);
            }
            navigate('/');
        }
        //Set Login Page Styling
        setBackgroundColor('#C6793D');
        setAppContainerStyle({
            ...appContainerStyle,
            position: 'absolute',
        });
    }, []);

    const handleForgotPassword = async () => {
    setForgotPassword(!forgotPassword)
    }

    const handleSendCode = async () => {
        let username = resetPasswordEmail.toLowerCase();
        try {
            const res = await fetch(`${config.base_api}/auth/resetPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: username}),
            })
            const data = await res.json();

            if (data.error) {
                console.log(data)
                if (data.error === "User not found") {
                    setUserNotFound(true);
                    setUserNotVerified(false);
                } else if (data.error === "User not verified") {
                    setUserNotFound(false);
                    setUserNotVerified(true);
                }
            } else {
                console.log(data)
                setResetPasswordCheck(1);
                setUserNotFound(false);
                setUserNotVerified(false);
            }
        } catch (error) {
            console.log(error);
            setUserNotFound(true);
        }
    }

    const handleGoBack = async () => {
        setResetPasswordCheck(0)
    }

    const handleConfirmPassword = async () => {
        let username = resetPasswordEmail.toLowerCase();
        try {
            const res = await fetch(`${config.base_api}/auth/confirmPassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username, 
                    confirmationCode: confirmationCode, 
                    newPassword: newPassword}),
            });
            const data = await res.json();
            if (data.error) {
                console.log(data)
                if (data.error.name === "CodeMismatchException") {
                    setIncorrectCode(true);
                    setInvalidPassword(false);
                    setLimitExceeded(false);
                } else if (data.error.name === "InvalidPasswordException") {
                    setInvalidPassword(true);
                    setIncorrectCode(false);
                    setLimitExceeded(false);
                } else if (data.error.name === "LimitExceededException") {
                    setLimitExceeded(true);
                    setIncorrectCode(false);
                    setInvalidPassword(false);
                }
            } else {
                setResetPasswordCheck(2);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const backToLogin = () => {
        setForgotPassword(false);
        setResetPasswordCheck(0);
        setUserNotFound(false);
        setIncorrectCode(false);
        setInvalidPassword(false);
        setLimitExceeded(false);
    }

    return (
        <div className="app--login auth auth-login">
            <div className={`top-toast ${timedOut ? "" : "hide"}`}>
                <p >Session has expired.  Please log in again.</p>
            </div>
            <div className="login_logo">
                <img src={logo} />
            </div>
            {/* <p>Do you need to <Link to="/auth/verifyEmail">verify your email address?</Link></p> */}
            {forgotPassword ? (
                <div className = "flex-column align-center">
                    {resetPasswordCheck === 2 ? (
                    <>
                        <p className = "text-lrg margin-b-lg">Your password has been successfully updated! You may now log in with your new password.</p>
                        <Btn title="Back to Login" 
                        btnClass = "text-smedium pad-md pad-l-60 pad-r-60 bg-white text-blue b2-blue darken-background text-bold box-shadow margin-b-lg"
                        onClick = {backToLogin} />
                    </>
                    ) : resetPasswordCheck === 1 ? (
                    <>
                        <p className = "text-smedium">Confirm your email with the code sent to your email to change your password:</p>
                        <div className="margin-b-sm login_singular margin-t-sm width-75">
                            <label className={confirmationCode && `filled`} htmlFor="confirmationCode">
                                confirmation code{' '}
                            </label>
                            <input type="text" name="confirmationCode" value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} />
                        </div>
                        {incorrectCode && <p className = "text-red margin-b-md">This code is incorrect or no longer valid. Resend the code by going back and re-entering your email.</p>}
                        <p className = "text-smedium">Change your password:</p>
                        <div className="login_singular margin-t-sm width-75">
                            <label className={newPassword && `filled`} htmlFor="newPassword">
                                password{' '}
                            </label>
                            <input type="text" name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        {invalidPassword && <p className = "text-red margin-b-md">This password is invalid. Please make sure your new password meets all of the requirements.</p>}
                        {limitExceeded && <p className = "text-red margin-b-md">You have exceeded the limit for attempts. Please try again in 20 minutes.</p>}
                        <p className = "text-smaller line-height-sm margin-b-lg">
                            Requirements: <br/>
                            - 8-character minimum length <br/>
                            - Contains at least 1 number <br/>
                            - Contains at least 1 uppercase letter  <br/>
                            - Contains at least 1 special character
                        </p>
                        <Btn title="Change Password" 
                        btnClass = {`text-smedium pad-md pad-l-60 pad-r-60 ${confirmationCode.length && newPassword.length > 0 ? "bg-blue text-white b2-blue blue-button bolder box-shadow" : "btn-disabled"}`}
                        disabled = {confirmationCode.length > 0 && newPassword.length > 0 ? false : true}
                        onClick = {handleConfirmPassword} />
                        <p className = "text-blue underline text-small pointer margin-t-sm" onClick={handleGoBack}>Go back</p>
                    </>
                    ) : (
                    <>
                        <p className = "text-smedium">Which email should we send the confirmation code to change your password to?</p>
                        <div className="login_singular margin-t-lg width-75">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                                <path
                                    d="M30,6H6A3,3,0,0,0,3.015,9L3,27a3.009,3.009,0,0,0,3,3H30a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,30,6Zm0,6L18,19.5,6,12V9l12,7.5L30,9Z"
                                    transform="translate(-3 -6)"
                                    fill="#6e6e6e"
                                />
                            </svg>
                            <label className={email && `filled`} htmlFor="email">
                                email{' '}
                            </label>
                            <input type="text" name="email" value={resetPasswordEmail} onChange={(e) => setResetPasswordEmail(e.target.value)} />
                        </div>
                        {userNotFound && <p className = "text-red margin-b-md">We don't have this email. Re-enter your email or contact Playground Boss to create an account.</p>}
                          {userNotVerified && <p className = "text-red margin-b-md">This email is not yet verified.  Please contact your administrator to verify your account.</p>}
                        <Btn title="Send Code" 
                        btnClass = {`text-smedium pad-md pad-l-60 pad-r-60 ${resetPasswordEmail.length > 0 ? "bg-light-green green-button text-white b-none bolder box-shadow" : "btn-disabled"}`}
                        disabled = {resetPasswordEmail.length > 0 ? false : true}
                        onClick = {handleSendCode} />
                        <p className = "text-blue underline text-small pointer margin-t-sm" onClick={handleForgotPassword}>Go back</p>
                    </>
                    )
                }            
                </div>
                ) : (
                <>
                    {invalidLogin && <p className="error">Invalid email or password</p>}
                    <form onSubmit={onSubmit}>
                        <div className="login_details">
                            <div className="login_singular">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
                                    <path
                                        d="M30,6H6A3,3,0,0,0,3.015,9L3,27a3.009,3.009,0,0,0,3,3H30a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,30,6Zm0,6L18,19.5,6,12V9l12,7.5L30,9Z"
                                        transform="translate(-3 -6)"
                                        fill="#6e6e6e"
                                    />
                                </svg>
                                <label className={email && `filled`} htmlFor="email">
                                    email{' '}
                                </label>
                                <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="login_singular margin-b-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                    <path
                                        d="M36,12.375A12.382,12.382,0,0,1,21.317,24.534l-1.688,1.9A1.687,1.687,0,0,1,18.367,27H15.75v2.813A1.687,1.687,0,0,1,14.063,31.5H11.25v2.813A1.687,1.687,0,0,1,9.563,36H1.688A1.687,1.687,0,0,1,0,34.313V28.824a1.688,1.688,0,0,1,.494-1.193L11.871,16.254A12.376,12.376,0,1,1,36,12.375ZM23.625,9A3.375,3.375,0,1,0,27,5.625,3.375,3.375,0,0,0,23.625,9Z"
                                        fill="#6e6e6e"
                                    />
                                </svg>
                                <label className={password && `filled`} htmlFor="password">
                                    password{' '}
                                </label>
                
                                {viewPassword ? (
                                    <input
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                ) : (
                                    <input name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                )}
                                <svg
                                    className={`login_eye active_${viewPassword} hover`}
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    enable-background="new 0 0 512 512"
                                    onClick={() => setViewPassword(!viewPassword)}>
                                    <g>
                                        <g>
                                            <path d="m494.6,241.1l-50.1-47c-50.5-47.3-117.4-73.3-188.5-73.3-71.1,0-138,26-188.4,73.3l-50.1,47c-12.1,12.9-4.3,26.5 0,29.8l50.1,47c50.4,47.3 117.3,73.3 188.4,73.3 71.1,0 138-26 188.4-73.3l50.1-47c4.7-3.9 12.2-17.6 0.1-29.8zm-238.6,74.9c-33.1,0-60-26.9-60-60 0-33.1 26.9-60 60-60 33.1,0 60,26.9 60,60 0,33.1-26.9,60-60,60zm-194.7-60l34.3-32.1c32-30 72-49.9 115.5-58.1-33.1,16.6-55.8,50.8-55.8,90.2 0,39.4 22.8,73.7 55.8,90.2-43.5-8.1-83.5-28.1-115.5-58.1l-34.3-32.1zm355.2,32.1c-32,30-72,50-115.5,58.1 33.1-16.6 55.8-50.8 55.8-90.2 0-39.4-22.8-73.6-55.8-90.2 43.5,8.1 83.5,28.1 115.5,58.1l34.3,32.1-34.3,32.1z" />
                                            <path d="m256,235.2c-11.3,0-20.8,9.5-20.8,20.8 0,11.3 9.5,20.8 20.8,20.8 11.3,0 20.8-9.5 20.8-20.8 0-11.3-9.5-20.8-20.8-20.8z" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className = "flex justify-end">
                            <p className = "text-blue underline text-smedium pointer" onClick={handleForgotPassword}>Forgot Password?</p>
                        </div>
                        <div
                            className="rememberMe hover"
                            onClick={() => {
                                console.log(rememberMe);
                                setRememberMe(!rememberMe);
                                console.log(rememberMe);
                            }}>
                            <input type="checkbox" id="rememberMeCheckbox" />
                            <label for="rememberMeCheckbox">Remember me</label>
                        </div>
                        {state?.auth.loading ? (
                            <button className="login_btn disabled_btn" disabled="true">
                                <LoadingInline />
                            </button>
                        ) : (
                            <button className="login_btn green-button bg-light-green b-none hover" type="submit">
                                Login
                            </button>
                        )}
                    </form>  
                </>    
                )}
        </div>
    );
};

export default Login;
