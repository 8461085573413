import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Btn from '../../Btn';
import config from '../../../config';
import authHeaders from '../../../utilities/authHeaders';
import Freight from '../../quote/Freight';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';
import convertJSON from '../../../utilities/convertJSON';

const HeavyGroup = ({freightRules, markup}) => {
    const { state, dispatch } = useStore();
    console.log(freightRules);
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [hg_price_per_crate, set_hg_price_per_crate] = useState(freightRules.heavy_group_price_per_crate);
    const [hg_price_per_palette, set_hg_price_per_palette] = useState(freightRules.heavy_group_price_per_palette);
    const [hg_price_per_mile, set_hg_price_per_mile] = useState(freightRules.heavy_group_price_per_mile);

    const heavyGroup = {
        title: 'Heavy Group',
        names: ['ID', 'Price Per Crate', 'Price Per Pallet', 'Price Per Mile(decimal)', 'Freight Markup'],
    };
    const colSpanLength = heavyGroup.names.length;

    let idArray = [
        'address_kidsTale_playgrounds',
        'address_kidsTale_inc',
        'address_barks_and_rec',
        'address_sports_play',
        'heavy_group_price_per_crate',
        'heavy_group_price_per_palette',
        'heavy_group_price_per_mile',
    ];
    let putBody = {};

    // API call for posting quote into our database
    const putInput = async () => {
        for (let e = 0; e < idArray.length; e++) {
            var inputID = idArray[e];
            var inputValue = document.getElementById(inputID).value;

            putBody[inputID] = inputValue;
        }

        try {
            const res = await fetch(`${config.base_api}/freightRules`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(putBody),
            });

            await convertJSON(res);

            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Heavy Group Updated'}});
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
        }
    };

    return (
        <form className="freightGroup">
            <div className="quotesTable">
                <table>
                    <thead>
                        <tr>
                            {heavyGroup.names.map((name) => (
                                <th>{name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{heavyGroup.title}</td>
                            <td>
                                <input
                                    id="heavy_group_price_per_crate"
                                    defaultValue={hg_price_per_crate}
                                    onChange={(e) => set_hg_price_per_crate(e.target.value)}></input>
                            </td>
                            <td>
                                <input
                                    id="heavy_group_price_per_palette"
                                    defaultValue={hg_price_per_palette}
                                    onChange={(e) => set_hg_price_per_palette(e.target.value)}></input>
                            </td>
                            <td>
                                <input
                                    id="heavy_group_price_per_mile"
                                    defaultValue={hg_price_per_mile}
                                    onChange={(e) => set_hg_price_per_mile(e.target.value)}></input>
                            </td>
                            <td>
                                <input value={markup} disabled="true"></input>
                            </td>
                        </tr>
                        <tr className="equation">
                            <td colspan={colSpanLength}>
                                Calculation: <br />
                                ((Crates X ${hg_price_per_crate}) + (IS_PALLETE x ${hg_price_per_palette}) + (Miles x $
                                {hg_price_per_mile})) / {markup}
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="1">Manufacturer</th>
                            <th colSpan={colSpanLength - 1}>Address</th>
                        </tr>
                    </thead>
                    <tbody className="blueBorder">
                        <tr>
                            <td colspan="1">kidsTale Playground</td>
                            <td colspan={colSpanLength - 1}>
                                <input
                                    className="addressInput"
                                    id="address_kidsTale_playgrounds"
                                    defaultValue={freightRules.address_kidsTale_playgrounds}></input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1">kidsTale Inc</td>
                            <td colspan={colSpanLength - 1}>
                                <input
                                    className="addressInput"
                                    id="address_kidsTale_inc"
                                    defaultValue={freightRules.address_kidsTale_inc}></input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1">Barks and Rec</td>
                            <td colspan={colSpanLength - 1}>
                                <input
                                    className="addressInput"
                                    id="address_barks_and_rec"
                                    defaultValue={freightRules.address_barks_and_rec}></input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1">Sports Play</td>
                            <td colspan={colSpanLength - 1}>
                                <input
                                    className="addressInput"
                                    id="address_sports_play"
                                    defaultValue={freightRules.address_sports_play}></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Btn title="Update" onClick={putInput} 
                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
            </div>
        </form>
    );
};

export default HeavyGroup;
