import React, { useEffect, useState } from 'react';
import SyncSection from '../components/dashboard/SyncSection'


const Sync = () => {

  return (
      <div>
          <h1 className="centerText">Sync Section</h1>
          <SyncSection />
    </div>
  )
}

export default Sync
