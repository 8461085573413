import React, { useEffect, useState } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import Btn from '../../components/Btn';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';
import checkmark from '../../../src/assets/checkmark.svg';
import xmark from '../../../src/assets/xmark.svg';
import LoadingInline from '../../components/LoadingInline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

const PurchaseOrderExclusions = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const [loading, setLoading] = useState(true);
    const [addExclusion, setAddExclusion] = useState(false);
    const [editExclusion, setEditExclusion] = useState(0);
    const [exclusions, setExclusions] = useState([]);
    const [name, setName] = useState('');
    const [exclusionType, setExclusionType] = useState({ product: false, freight: false, installation: false });
    const [editExclusionType, setEditExclusionType] = useState({ product: false, freight: false, installation: false });
    const [filter, setFilter] = useState({ product: false, freight: false, installation: false });
    const [filteredExclusions, setFilteredExclusions] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(`${config.base_api}/configuration/exclusions`, { headers });
                const data = await convertJSON(res);
                setExclusions(data);
                setFilteredExclusions(data);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error loading exclusions' } });
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            filterAllExclusions();
        }
        fetchData();
    }, [filter.product, filter.freight, filter.installation]);

    const saveExclusion = async () => {
        const newExclusion = {
            type: 'exclusion',
            name,
            product: exclusionType.product,
            freight: exclusionType.freight,
            installation: exclusionType.installation,
        };

        try {
            const res = await fetch(`${config.base_api}/configuration/exclusions/create`,
                {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(newExclusion)
                });
            const data = await convertJSON(res);
            const newExclusions = [...exclusions, data];
            setExclusions(newExclusions);
            filterAllExclusions(newExclusions);
            setName('');
            setExclusionType({ product: false, freight: false, installation: false });
        } catch (error) {
            console.log(error);
            setName('');
            setExclusionType({ product: false, freight: false, installation: false });
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error saving exclusion' } });
        }
    };

    const deleteExclusion = async (id) => {
        try {
            const res = await fetch(`${config.base_api}/configuration/exclusions/delete/${id}`,
                {
                    method: 'DELETE',
                    headers,
                    body: JSON.stringify({}),
                });
            const newExclusions = exclusions.filter((exclusion) => {
                if (exclusion.id !== id) {
                    return exclusion;
                }
            });
            setExclusions(newExclusions);
            filterAllExclusions(newExclusions);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error deleting exclusion' } });
        }
    }

    const changeExclusion = async (id) => {
        setEditExclusion(0);

        const updatedProject = {
            product: editExclusionType.product,
            freight: editExclusionType.freight,
            installation: editExclusionType.installation,
        }
        try {
            const res = await fetch(`${config.base_api}/configuration/exclusions/update/${id}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(updatedProject),
            });
            const data = await convertJSON(res);

            const newExclusions = exclusions.map((exclusion) => {
                if (exclusion.id === id) {
                    return data[0];
                } else {
                    return exclusion;
                }
            });
            setExclusions(newExclusions);
            filterAllExclusions(newExclusions);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error editing exclusion' } });
        }
    }

    const checkboxFalse = (id, type) => {
        if (editExclusion !== id) {
            return <img src={xmark} className={'pad-xs size-25px' + (editExclusion === id ? ' b1 border-radius-xs pointer' : ' b1-white')} alt="xmark icon" />
        }
        if (type === 'product') {
            return <div className='margin-b-xxs pad-xs size-25px b1 border-radius-xs pointer horizontal-middle'
                onClick={() => setEditExclusionType((prevState) => ({ ...prevState, product: true }))}></div>
        } else if (type === 'freight') {
            return <div className='margin-b-xxs pad-xs size-25px b1 border-radius-xs pointer horizontal-middle'
                onClick={() => setEditExclusionType((prevState) => ({ ...prevState, freight: true }))}></div>
        } else if (type === 'installation') {
            return <div className='margin-b-xxs pad-xs size-25px b1 border-radius-xs pointer horizontal-middle'
                onClick={() => setEditExclusionType((prevState) => ({ ...prevState, installation: true }))}></div>
        }
    }

    const checkboxTrue = (id, type) => {
        if (editExclusion !== id) {
            return <img src={checkmark} className={'pad-xxs size-25px' + (editExclusion === id ? ' b1 border-radius-xs pointer' : ' b1-white')} alt="checkmark icon" />
        }
        if (type === 'product') {
            return <img src={checkmark} className='pad-xxs size-25px b1 border-radius-xs pointer'
                alt="checkmark icon" onClick={() => setEditExclusionType((prevState) => ({ ...prevState, product: false }))} />
        } else if (type === 'freight') {
            return <img src={checkmark} className='pad-xxs size-25px b1 border-radius-xs pointer'
                alt="checkmark icon" onClick={() => setEditExclusionType((prevState) => ({ ...prevState, freight: false }))} />
        } else if (type === 'installation') {
            return <img src={checkmark} className='pad-xxs size-25px b1 border-radius-xs pointer'
                alt="checkmark icon" onClick={() => setEditExclusionType((prevState) => ({ ...prevState, installation: false }))} />
        }
    }

    const editClicked = (id, product, freight, installation) => {
        setEditExclusion(id);
        setEditExclusionType({ product, freight, installation });
    }

    const valueDisplay = (value, id, type) => {
        if (editExclusion !== id) return value;
        if (type === 'product') return editExclusionType.product;
        else if (type === 'freight') return editExclusionType.freight;
        else if (type === 'installation') return editExclusionType.installation;
    }

    const invisible = (id) => {
        if (editExclusion !== id && editExclusion > 0) return ' invisible ';
    }

    const hideTrash = (id) => {
        if (editExclusion > 0) return ' hide ';
    }

    const hideCancel = (id) => {
        if (editExclusion === 0) return ' hide ';
        if (editExclusion !== id && editExclusion > 0) return ' hide ';
    }

    const existsInFilter = (filterList, exclusion) => {
        let exists = false;
        filterList.forEach((filter) => {
            if (filter.id === exclusion.id) exists = true;
        });
        return exists;
    }

    const filterAllExclusions = (allExclusions = exclusions) => {
        let filtered = [];

        if (filter.product) {
            let filterResults = allExclusions.filter((exclusion) => {
                if (exclusion.product) {
                    if (!existsInFilter(filtered, exclusion)) return exclusion;
                    return exclusion;
                }
            });
            if (filterResults.length > 0) filtered.push(...filterResults);
        }
        if (filter.freight) {
            let filterResults = allExclusions.filter((exclusion) => {
                if (exclusion.freight) {
                    if (!existsInFilter(filtered, exclusion)) return exclusion;
                    return exclusion;
                }
            });
            if (filterResults.length > 0) filtered.push(...filterResults);
        }
        if (filter.installation) {
            let filterResults = allExclusions.filter((exclusion) => {
                if (exclusion.installation) {
                    if (!existsInFilter(filtered, exclusion)) return exclusion;
                    return exclusion;
                }
            });
            if (filterResults.length > 0) filtered.push(...filterResults);
        }
        if (!filter.product && !filter.freight && !filter.installation) {
            filtered = allExclusions;
        }

        setFilteredExclusions(filtered);
    }

    const tableView = () => {
        if (loading) return <tr><td></td><td></td><td><div className='loading-table'><LoadingInline /></div></td></tr>;

        return filteredExclusions.map((exclusion, index) => (
            <tr className=''>
                <td className={`${hideTrash(exclusion?.id)} text-left pad-r-md pad-t-sm pad-b-sm text-center`}>
                    <Btn icon='trash' btnClass=' margin-l-lg sm-box'
                        onClick={() => deleteExclusion(exclusion?.id)} />
                </td>
                <td className='text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>{exclusion?.name}</td>
                <td className='text-center pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>
                    {valueDisplay(exclusion?.product, exclusion?.id, 'product')
                        ? checkboxTrue(exclusion?.id, 'product')
                        : checkboxFalse(exclusion?.id, 'product')
                    }
                </td>
                <td className='text-center pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>
                    {valueDisplay(exclusion?.freight, exclusion?.id, 'freight')
                        ? checkboxTrue(exclusion?.id, 'freight')
                        : checkboxFalse(exclusion?.id, 'freight')
                    }
                </td>
                <td className='text-center pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>
                    {valueDisplay(exclusion?.installation, exclusion?.id, 'installation')
                        ? checkboxTrue(exclusion?.id, 'installation')
                        : checkboxFalse(exclusion?.id, 'installation')
                    }
                </td>
                <td className={`${hideCancel(exclusion?.id)} text-left pad-r-md pad-t-sm pad-b-sm text-center`}>
                    <Btn title='Cancel'
                        btnClass='bg-light-red delete-button text-white pad-lr-lg text-small b-none'
                        onClick={() => {
                            if (editExclusion === exclusion?.id) {
                                setEditExclusion(0);
                                setEditExclusionType({ product: false, freight: false, installation: false });
                            }
                        }} />
                </td>
                <td className='text-left pad-t-sm pad-b-sm text-center'>
                    <Btn title={editExclusion !== exclusion?.id ? 'Edit' : 'Save'}
                        btnClass={`${editExclusion > 0 ? '' : 'margin-r-48px '} ${editExclusion !== exclusion?.id ? 'bg-blue small-blue-button' : 'bg-light-green green-button margin-none'} ' text-white pad-lr-lg text-small b-none ' + ${invisible(exclusion?.id)}`}
                        onClick={() => {
                            if (editExclusion > 0) changeExclusion(exclusion?.id)
                            else editClicked(exclusion?.id, exclusion?.product, exclusion?.freight, exclusion?.installation)
                        }} />
                </td>
            </tr>
        ))
    }

    return (
        <div className='flex flex-column project-box widget-width margin-t-xxlg'>
            <div className='flex justify-between pad-sm pad-b-sm pad-l-lg'>
                <p className='text-lrg text-bold margin-r-xxlg'>PO Exclusions</p>
                {addExclusion
                    ? null
                    : <Btn title='Add' icon='add' btnClass='text-bold bg-white text-green b2-green darken-background box-shadow pad-lr-xlg text-small' onClick={() => { setAddExclusion(true) }}
                    />
                }
            </div>

            {addExclusion
                ? <div className='pad-sm project-box border-radius-md pad-lg margin-b-sm'>
                    <div className='flex align-center margin-b-lg'>
                        <label className='margin-r-md'>Name:</label>
                        <input className='pad-xs width-100' type='text' placeholder='Service' value={name}
                            onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className='flex align-center margin-b-sm'>
                        <p className='margin-r-md'>Type:</p>

                        <input className='pad-xs margin-l-xlg pointer' type='checkbox' placeholder='Search' value={exclusionType.product}
                            onChange={(e) => setExclusionType((prevState) => ({ ...prevState, product: e.target.checked }))} />
                        <label className='pad-xs pad-r-xlg' htmlFor='type'>Product</label>

                        <input className='pad-xs pointer' type='checkbox' placeholder='Search' value={exclusionType.freight}
                            onChange={(e) => setExclusionType((prevState) => ({ ...prevState, freight: e.target.checked }))} />
                        <label className='pad-xs pad-r-xlg' htmlFor='type'>Freight</label>

                        <input className='pad-xs pointer' type='checkbox' placeholder='Search' value={exclusionType.installation}
                            onChange={(e) => setExclusionType((prevState) => ({ ...prevState, installation: e.target.checked }))} />
                        <label className='pad-xs pad-r-xlg' htmlFor='type' >Installation</label>
                    </div>

                    <div className='flex justify-end'>
                        <Btn title='Cancel'
                            btnClass='bg-light-red delete-button text-white pad-lr-xlg text-small margin-r-sm b-none'
                            onClick={() => {
                                setName('');
                                setExclusionType({ product: false, freight: false, installation: false });
                                setAddExclusion(false)
                            }} />
                        <Btn title='Save'
                            btnClass='bg-light-green green-button text-white pad-lr-xlg text-small b-none'
                            onClick={() => {
                                saveExclusion();
                                setAddExclusion(false);
                            }} />
                    </div>
                </div>
                : null
            }

            <div className='underline-light-gray'></div>
            <div className='width-100 '>
                {exclusions || exclusions?.length > 0
                    ? <table className='width-100 border-collapse'>
                        <thead>
                            <tr className='underline-light-gray'>
                                {editExclusion > 0 ? null : <td></td>}
                                <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>Name</td>
                                <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>
                                    <span onClick={() => { setFilter((prevState) => ({ ...prevState, product: !filter.product })) }}
                                        className={`${filter.product ? 'text-orange' : ''} pointer filter-btn`}><FontAwesomeIcon icon={faFilter} /> Product</span>
                                </td>
                                <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>
                                    <span onClick={() => { setFilter((prevState) => ({ ...prevState, freight: !filter.freight })) }}
                                        className={`${filter.freight ? 'text-orange' : ''} pointer filter-btn`}><FontAwesomeIcon icon={faFilter} /> Freight</span>
                                </td>
                                <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>
                                    <span onClick={() => { setFilter((prevState) => ({ ...prevState, installation: !filter.installation })) }}
                                        className={`${filter.installation ? 'text-orange' : ''} pointer filter-btn`}><FontAwesomeIcon icon={faFilter} /> Installation</span>
                                </td>
                                <td></td>
                                {editExclusion > 0 ? <td></td> : null}
                            </tr>
                        </thead>
                        <tbody className='height-100 '>
                            {tableView()}
                        </tbody>
                    </table>
                    : <div className='flex justify-center font-med pad-md margin-t-xlg bold'>
                        No POs Recorded Yet
                    </div>
                }
            </div>
        </div>
    )
}

export default PurchaseOrderExclusions;
