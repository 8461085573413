import React from 'react';
import { useState, useEffect } from 'react';
import Btn from '../Btn';
import { addAccessLogEvent } from '../../utilities/accessLog';
import authHeaders from '../../utilities/authHeaders';
import config from '../../config';
import formatNumber from '../../utilities/formatNumber'
import LoadingInline from '../../components/LoadingInline';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const Issues = ({loading, setLastAccess, saveClicked, issueDetails, setIssueDetails, issueDate, setIssueDate, issuesFormChanged, setIssuesFormChanged}) => {
  const { state, dispatch } = useStore();
  const [resolvedIssue, setResolvedIssue] = useState(false);
  const [projectIssues, setProjectIssues] = useState([]);
  const [resolutionCost, setResolutionCost] = useState('');
  const [dateResolved, setDateResolved] = useState('');
  const [issueID, setIssueID] = useState('');
  const [loadingIssues, setLoadingIssues] = useState(true);

  useEffect(() => {
    if (saveClicked &&  (issuesFormChanged && (issueDetails.length > 0 && issueDate.length > 0))) {
      submitIssue();
    }
  }, [saveClicked])

  // function to create accesslog Event
  async function createAccessLogEvent(eventValue) {
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken)
    const accessLogEvent = {
      project_id: state?.project.project_id,
      update_message: eventValue,
      user: localStorage.getItem('username') || '',
    }
    try {
      const res = await addAccessLogEvent(accessLogEvent, headers);
      if (res) {
        dispatch({ type: ACTIONS.TOAST, payload: { message: 'Updated Access Log', isError: false } });
        setLastAccess(accessLogEvent.update_message)
      }
    } catch (e) {
      console.log(e)
      dispatch({ type: ACTIONS.TOAST, payload: { message: 'Could not update Access Log', isError: true } });
    }
  }

  async function submitIssue(e) {
    if (e) e.preventDefault();

    let issueDetailsText = issueDetails.trim();

    if (issueDate?.length < 1 || issueDetailsText?.length < 1) {
      dispatch({ type: ACTIONS.TOAST, payload: { message: 'Please provide an Issue Date and Details', isError: true } });

      return;
    }

    let accessLogEvent = '';
    if (!issueID) {
      accessLogEvent = `Created new Project Issue.`
    } else {
      if (!resolvedIssue) {
        accessLogEvent = `Updated Project Issue.`
      } else {
        accessLogEvent = `Updated Resolved Issue.`
      }
    }
    // if(dateResolved){
    //   accessLogEvent += ` with a resolution date of ${dateResolved}`
    // }
    // accessLogEvent += '.';
    try {
      await saveProjectIssue();
      await createAccessLogEvent(accessLogEvent)
    } catch (err) {
      console.log(err)
    }
  }

  const saveProjectIssue = async () => {
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken)
    const newIssue = {
      date_resolved: dateResolved,
      issue_date: issueDate,
      issue_details: issueDetails,
      project_id: state?.project.project_id,
      resolution_cost: resolutionCost || null,
    };
    if (!issueID) {
      try {
        const res = await fetch(`${config.base_api}/projects/issues`,
          {
            method: 'POST',
            headers,
            body: JSON.stringify(newIssue)
          });

        if (!res.ok) throw 'Error creating project issue';
        const savedIssue = await convertJSON(res);
        setProjectIssues(prevState => [...prevState, savedIssue]);
        setIssuesFormChanged(false);
        clearProjectIssues();
      } catch (error) {
        console.log(error);
        dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
      }
    } else {
      try {
        let res = await fetch(`${config.base_api}/projects/issues/update/${issueID}`,
          {
            method: 'PATCH',
            headers,
            body: JSON.stringify(newIssue)
          });
        if (!res.ok) throw 'Error updating project issue'
        const updatedIssue = await convertJSON(res);
        setProjectIssues((prevState) => {
          return prevState.map((issue) => {
            if (issue.id === updatedIssue[0].id) {
              return {
                ...issue,
                date_resolved: updatedIssue[0]?.date_resolved || '',
                issue_date: updatedIssue[0]?.issue_date || '',
                issue_details: updatedIssue[0]?.issue_details || '',
                resolution_cost: updatedIssue[0]?.resolution_cost,
                updated_at: updatedIssue[0]?.updated_at || '',
              }
            } else {
              return issue;
            }
          });
        });
        setIssuesFormChanged(false);
      } catch (error) {
        console.log(error);
        dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
      }
    }
  }

  function clearProjectIssues() {
    setIssueID('');
    setIssueDetails('');
    setDateResolved('');
    setIssueDate('');
    setResolutionCost('');
    setResolvedIssue(false)
  }

  function updateIssue(index) {
    setIssueID(projectIssues[index].id || '');
    setIssueDetails(projectIssues[index].issue_details || '');
    setDateResolved(projectIssues[index].date_resolved || '');
    setIssueDate(projectIssues[index].issue_date || '');
    setResolutionCost(projectIssues[index].resolution_cost || '');
    setIssuesFormChanged(false);
    if (dateResolved) {
      setResolvedIssue(true)
    }
  }

  async function deleteIssue() {
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken)
    // send action to access log
    const accessLogEvent = "Deleted Project Issue."
    try {
      let res = await fetch(`${config.base_api}/projects/issues/delete/${issueID}`, 
      {
        method: 'DELETE',
        headers,
        body: JSON.stringify({project_id: state.project.project_id}),
      });
      await convertJSON(res)

      if (!res.ok) {
        throw 'Error deleting Project Issue'
      }
      setProjectIssues((prevState) => {
        return prevState.filter((issue) => {
          if (issue.id !== issueID) {
            return issue;
          }
        });
      });
      clearProjectIssues();
      await createAccessLogEvent(accessLogEvent);
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
    }
  }

  useEffect(() => {
    (async () => {
      const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
      if (state?.project?.project_id) {
        try {
          let currentIssues = await fetch(`${config.base_api}/projects/issues/get/${state?.project.project_id}`, { headers });
          currentIssues = await convertJSON(currentIssues);
          setProjectIssues(currentIssues);
          setLoadingIssues(false);
        } catch (error) {
          console.log(error);
          setLoadingIssues(false);
          console.log(error);
        }
      }
    })();

    return () => {
      setProjectIssues([]);
      setLoadingIssues(true);
    }
  }, [state?.project]);

  function formatIssueDate(issueDate) {
    if (!issueDate || issueDate.toLowerCase() == 'invalid date'.toLowerCase()) {
      return ''
    }
    const year = issueDate.slice(0, 4)
    const month = issueDate.slice(5, 7)
    const day = issueDate.slice(8, 10)
    return `${month}/${day}/${year}`
  }

  if (loading || loadingIssues) {
    return (
      <div className='flex-column align-center project-box width-fit-content'>
        <p className='text-lrg text-bold pad-b-xxxlg'>4. (Optional) Project Issues</p>
        <LoadingInline />
      </div>
    );
  }

  return (
    <>
      <div className='issuesCont flex project-box overflow-y-hidden width-fit-content'>
        <div className='leftIssueCol  overflow-y-hidden'>
          <div className='flex justify-between'>
            <p className='text-lrg text-bold'>4. (Optional) Project Issues</p>
            <span className='margin-l-md'>
              {projectIssues?.length > 0
                ? <Btn btnClass='pad-tb-sm text-green bg-white b2-green text-bold darken-background box-shadow'
                  title='New Issue' onClick={clearProjectIssues} />
                : <></>
              }
            </span>
          </div>
          <div className='width-100 height-100'>
            {
              projectIssues?.length > 0
                ?
                <table className=' width-100 border-collapse layout-fixed'>
                  <div className=' width-100 border-collapse layout-fixed'>
                    <thead>
                      <tr className='underline-light-gray'>
                        <td className='text-small text-bold text-left pad-l-xs pad-t-xs td-100'>Date</td>
                        <td className='text-small text-bold text-left pad-l-xs pad-t-xs td-100'>Resolution<br />Cost</td>
                        <td className='text-small text-bold text-left pad-l-xs pad-t-xs td-100'>Date<br />Resolved</td>
                        <td className='text-small text-bold text-left pad-l-xs pad-t-xs td-110'>Issue<br />Details</td>
                        <td className='text-small text-bold text-left pad-l-xs pad-t-xs td-150'></td>

                      </tr>
                    </thead>
                    <div className='overflow-scroll height-210px'>
                      <tbody className='height-100 '>
                        {
                          projectIssues.map((issue, index, array) => (
                            <tr key={index} className={index === array?.length - 1 ? 'issue_row' : 'issue_row underline-light-gray'} >
                              <td className='text-smaller pad-t-lg pad-b-lg td-100'>{formatIssueDate(issue?.issue_date)}</td>
                              <td className='text-smaller pad-t-lg pad-b-lg td-100'>{issue.resolution_cost ? '$' + formatNumber(issue.resolution_cost) : ''}</td>
                              <td className='text-smaller pad-t-lg pad-b-lg td-100'>{formatIssueDate(issue?.date_resolved)}</td>
                              <td className='text-smaller pad-t-lg pad-b-lg td-110 issue-details-box'><div className='max-h-35px issue-details'>{issue.issue_details}</div></td>
                              <td className='pad-t-lg pad-b-lg pad-l-md width-fit-content'>
                                <Btn btnClass='btn-update-issue' title='Update Issue' onClick={() => (updateIssue(index))} />
                              </td>
                            </tr>
                          ))}
                      </tbody></div>
                  </div>
                </table>
                :
                <div className='flex justify-center align-center margin-b-xxlg height-100 font-med pad-md '>
                  No Issues Recorded Yet
                </div>
            }
          </div>
        </div>
        <div className='rightIssuesCol margin-l-md pad-l-md border-left-gray'>
          <p className='text-lrg text-bold'>{!issueID ? 'New Issue' : 'Edit Issue'}</p>
          <div>

            <form onSubmit={submitIssue} id='issueForm'>
              <div className='flex pad-xs'>
                <div className='pad-xs'>
                  <label htmlFor='issue_date' className='form-label'>Date of Issue:</label>
                  <input
                    className='form-input'
                    id='issue_date'
                    value={issueDate?.slice(0, 10)}
                    type='date'
                    onChange={(e) => {
                      setIssueDate(e.target.value)
                      setIssuesFormChanged(true)
                    }}
                    required
                  />
                </div>
                <div className='pad-xs'>
                  <label htmlFor='resolution_cost' className='form-label'>Resolution Cost</label>
                  <input
                    className='form-input'
                    id='resolution_cost'
                    value={resolutionCost}
                    type='number'
                    required
                    onChange={(e) => {
                      setResolutionCost(+e.target.value)
                      setIssuesFormChanged(true)}}
                  />
                </div>
                <div className='pad-xs'>
                  <label htmlFor='date_resolved' className='form-label'>Date Resolved</label>
                  <input
                    className='form-input'
                    id='date_resolved'
                    value={dateResolved?.slice(0, 10)}
                    type='date'
                    onChange={(e) => {
                      setDateResolved(e.target.value)
                      setIssuesFormChanged(true)}}
                  />
                </div>
              </div>
              <div className='pad-md pad-t-none'>
                <label htmlFor='issue_details' className='form-label'>Issue Details:</label>
                <textarea
                  className='form-input text-input-large'
                  id='issue_details'
                  value={issueDetails}
                  required
                  onChange={(e) => {
                    setIssueDetails(e.target.value)
                    setIssuesFormChanged(true)}}
                />
              </div>
              <div className={!issueID ? 'flex width-100 justify-end pad-md' : 'flex width-100 justify-between pad-md'}>
                {
                  issueID ?
                    <Btn title='Delete' btnClass='bg-light-red delete-button text-white box-shadow text-bold b-none min-width-85px justify-center' onClick={deleteIssue} icon={true} />
                    : ''
                }

                <Btn
                  title='Save'
                  btnClass={((issuesFormChanged && issueDetails.length > 0 && issueDate.length > 0) ? 'text-white bg-light-green box-shadow green-button b-none' : 'btn-disabled') + ' min-width-85px pad-tb-md justify-center text-bold'}
                  disabled={!issuesFormChanged || issueDetails.length === 0 || issueDate.length === 0}
                  onClick={submitIssue}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Issues;