import React from 'react';
import {useNavigate} from 'react-router';
import authHeaders from '../../utilities/authHeaders';
import config from '../../config';
import { useStore } from '../../StoreProvider';
import Modal from '../Modal';
import Btn from '../Btn';

const UserDeleteModal = ({user, deleteUser, setShowUserDeleteModal}) => {
    const { state } = useStore();
    const navigate = useNavigate();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    return (
        <Modal>
            <div className="modal_deleteUser">
                <h4>Delete User</h4>
                <p style={{fontWeight: 'bold'}}>Are you sure you want to delete User: {user.email}?</p>
                <div className="modal_deleteQuote_buttonContainer">
                    <Btn title="Delete" onClick={(e) => deleteUser(user.id, user.email)} btnClass="btn-red" />
                    <Btn title="Cancel" onClick={(e) => setShowUserDeleteModal(false)} />
                </div>
            </div>
        </Modal>
    );
};

export default UserDeleteModal;
