import React, { useEffect, useState } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import convertJSON from '../../utilities/convertJSON';
import Btn from '../../components/Btn';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from './StrictModeDroppable';

const WidgetPrioritization = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [originalItems, setOriginalItems] = useState({
        firstList: [],
        secondList: []
    });
    const [items, setItems] = useState({
        firstList: [],
        secondList: []
    });
    const prodEnv = window.location.href.includes("https://pgbquote.com/", "https://www.pgbquote.com/") ? true : false;

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(`${config.base_api}/configuration/organizer`, { headers });
                const data = await convertJSON(res);
                setItems({
                    firstList: [...data[0]],
                    secondList: [...data[1]]
                });
                setOriginalItems({
                    firstList: [...data[0]],
                    secondList: [...data[1]]
                });                
            } catch (error) {
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error loading exclusions', isError: true } });
            }
        }

        fetchData();
    }, []);

    const onSave = async () => {
        try {
            const res = await fetch(`${config.base_api}/configuration/organizer/update`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify({
                    firstList: items.firstList,
                    secondList: items.secondList
                })
            });
            const data = await convertJSON(res);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'success', message: 'Configuration saved' } });
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error saving configuration', isError: true } });
        }
    };

    const onDragEnd = result => {
        const { source, destination } = result;

        // Dropped outside the list
        if (!destination) {
            return;
        }

        const sourceList = items[source.droppableId];
        const destList = items[destination.droppableId];
        const [removed] = sourceList.splice(source.index, 1);

        // Check for restricted items and the destination list
        if (destination.droppableId === 'firstList' && (removed.name_id === 'pos_status' || removed.name_id === 'project_issues')) {
            // Add back the item to the source list and do not proceed further
            sourceList.splice(source.index, 0, removed);
            console.log(`Cannot add ${removed.name_id} to the first list`);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: `Cannot add ${removed.name} to the first list`, isWarning: true } });
            return;
        }


        // Moving within the same list
        if (source.droppableId === destination.droppableId) {
            sourceList.splice(destination.index, 0, removed);
            setItems({
                ...items,
                [source.droppableId]: sourceList
            });
        } 
        // Moving to a different list
        else {
            destList.splice(destination.index, 0, removed);
            setItems({
                ...items,
                [source.droppableId]: sourceList,
                [destination.droppableId]: destList
            });
        }
    };

    const openPreview = (projectID) => {
        window.open(`/operations/projects/${projectID}?column1=${btoa(items.firstList.map(item => item.name_id).join(','))}&column2=${btoa(items.secondList.map(item => item.name_id).join(','))}`);
    }

  return (
    <div className='flex flex-column project-box widget-width margin-t-xxlg'>
        <div className='flex justify-between pad-sm pad-b-sm pad-l-lg'>
            <p className='text-lrg text-bold margin-r-xxlg'>Widget Organizer</p>
            <div className='flex gap-3'>
                <Btn title="Reset" 
                btnClass='bg-light-red delete-button text-white pad-lr-lg text-small b-none'
                onClick={() => setItems({
                    firstList: [...originalItems.firstList],
                    secondList: [...originalItems.secondList]
                })}/>
                <Btn title="Save"
                    btnClass='bg-green green-button text-white pad-lr-lg text-small b-none'
                    onClick={() => onSave()}
                />
                <button 
                    className = "pad-l-md pad-r-md pad-t-xs pad-b-xs bg-white border-radius-xs text-blue b2-blue text-bold pointer darken-background box-shadow"
                    onClick={() => openPreview(prodEnv ? '091423-13155' : '121123-1477')}>Preview</button>

            </div>
        </div>
        <div className='underline-light-gray'></div>
        <div className='width-100 '>

            <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex">
                {Object.entries(items).map(([listId, listItems]) => (
                    <Droppable key={listId} droppableId={listId}>
                    {(provided, snapshot) => (
                        <div 
                        ref={provided?.innerRef}
                        {...provided?.droppableProps}
                        style={(
                            { 
                                padding: '10px', 
                                ...provided?.droppableProps?.style
                            }
                        )}
                        >
                        {listItems.map((item, index) => (
                            <Draggable key={item?.name} draggableId={item?.name} index={index}>
                            {(provided, snapshot) => (
                                <div 
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ 
                                    userSelect: 'none', 
                                    padding: '10px', 
                                    margin: '0 0 8px 0', 
                                    minHeight: '50px', 
                                    width: '250px',
                                    backgroundColor: snapshot?.isDragging ? '#FE7C26' : '#f0f0f0',
                                    color: snapshot?.isDragging ? 'white' : 'black', 
                                    border: '1px solid lightgrey',
                                    borderRadius: '5px',
                                    ...provided?.draggableProps?.style
                                }}
                                >
                                {item?.name}
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                ))}
            </div>
            </DragDropContext>
        </div>
    </div>
  );
};

export default WidgetPrioritization;