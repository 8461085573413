import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStore } from '../../StoreProvider';

const DashboardNavBar = ({ dashboards }) => {
    const { state } = useStore();
    const location = useLocation();

    return (
        <nav className="dashboardNavbar">
            <ul>
                {dashboards.map((dashboard, index) => {
                    if (dashboard?.restricted) {
                        let allowed = false;
                        if (dashboard?.access?.includes('admin') && +state?.auth?.isAdmin) {
                            allowed = true;
                        } else if (dashboard?.access?.includes('ops') && +state?.auth?.isOps) {
                            allowed = true;
                        } else if (dashboard?.access?.includes('sales') && +state?.auth?.isSales) {
                            allowed = true;
                        }

                        if (!allowed) return null;
                    }
                    return (<li key={index} className={location.pathname.split('/operations/zoho/') === dashboard.path ? 'active' : ''}>
                        <Link onClick={dashboard?.onClick ? (e) => dashboard?.onClick() : null} to={dashboard.path} className="navLink">{dashboard.name}</Link>
                    </li>);
                })}
            </ul>
        </nav>
    );
};

export default DashboardNavBar;
