import React from 'react';
import person from '../../../src/assets/person.svg';
import phone from '../../../src/assets/phone.svg';
import formatPhoneNumber from '../../utilities/formatPhoneNumber';
import LoadingInline from '../../components/LoadingInline';
import { useStore } from '../../StoreProvider';

const PointofContact = (
    { customerContacted, setCustomerContacted, loading, loadingQuote, leftCol, customerContactDate, setCustomerContactDate }) => {
    const { state } = useStore();

    function handleCustomerContacted() {
        setCustomerContacted(!customerContacted);
        if (customerContacted === false) {
            setCustomerContactDate(new Date().toISOString().slice(0, 10))
        }
    };

    let contactName = state?.quote?.firstname + ' ' + state?.quote?.lastname || " "
    let contactPhoneNumber = state?.quote?.phone || " "
    let billingAddress = state?.quote?.address + ', ' + state?.quote?.city + ', ' + state?.quote?.state + ' ' + state?.quote?.zip || "Not Available"
    let shippingAddress = state?.quote?.shipping_address + ', ' + state?.quote?.shipping_city + ', ' + state?.quote?.shipping_state + ' ' + state?.quote?.shipping_zip || "Not Available"

    const handleCustomerDate = (e) => {
        setCustomerContactDate(e.target.value)
    }

    if (loading || loadingQuote) {
        return (
            <div className='component-card  pad-b-lg'>
                <p className='text-lrg text-bold pad-b-xs pad-l-lg pad-t-lg pad-b-md'>1. Point of Contact</p>
                <LoadingInline />
            </div>
        );
    }

    return (
        <>
            <div className = {`component-card pad-b-xlg ${leftCol ? 'max-width-550px' : 'max-width-500px'}`}>
                <p className = 'text-lrg text-bold pad-b-xs pad-l-lg pad-t-lg'>1. Point of Contact</p>
                <p className = 'text-light-gray line-height-sm margin-l-lg pad-l-xlg width-85'>
                    Manually call the customer to confirm shipping address, billing 
                    address, and purchase order. Ensure everything synced properly.
                </p>
                <div className='flex pad-t-sm pad-l-lg'>
                    <div className='width-40 pad-l-lg margin-r-xxlg'>
                        <p className='text-bold pad-b-xs'>Contact Information</p>
                        <div className='pad-b-xs flex align-center'>
                            <img src={person} className='margin-r-xs width-5' alt="person icon" />
                            <input
                                disabled
                                className='text-input-sm width-100 text-black bg-light-gray border-radius-xs b1 pad-l-xs'
                                value={contactName} name="contactName"
                            />
                        </div>
                        <div className='pad-t-xs flex align-center'>
                            <img src={phone} className='margin-r-xs width-5' alt="phone icon" />
                            <input
                                disabled
                                className='text-input-sm width-100 text-black bg-light-gray border-radius-xs b1 pad-l-xs'
                                value={formatPhoneNumber(contactPhoneNumber)} name="phoneNumber"
                            />
                        </div>
                    </div>
                    <div className='width-50'>
                        <div>
                            <p className='text-bold'>Billing Address</p>
                            <p className='line-height-sm'>{billingAddress}</p>
                        </div>
                        <div className='pad-t-xs'>
                            <p className='text-bold'>Shipping Address</p>
                            <p className='line-height-sm' >{shippingAddress}</p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end align-center column-gap-md margin-l-xs pad-t-xlg pad-r-xlg margin-b-xlg pad-l-xlg">
                    {customerContacted &&
                        <div>
                            <input type="date" className='form-input' id="contactDate" name="contactDate"
                            onChange={handleCustomerDate} value={customerContactDate}></input>
                        </div>}
                    <div className={`flex align-center justify-center pad-l-md pad-r-md pad-t-xs pad-b-xs b1-black border-radius-xs column-gap-sm pointer
                    ${customerContacted ? "border-green" : ""}`}
                        id="checkboxes" onClick={handleCustomerContacted}>
                        <input type="checkbox" id="contacted" name="contacted"
                            className="sm-box pointer" checked={customerContacted} onChange={handleCustomerContacted} />
                        <label className="pointer">Customer Contacted?</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PointofContact