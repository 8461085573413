import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Btn from '../../Btn';
import config from '../../../config';
import authHeaders from '../../../utilities/authHeaders';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';
import convertJSON from '../../../utilities/convertJSON';

const AthleticConnection = ({freightRules}) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [ac_markup, set_ac_markup] = useState(freightRules.athleticConnection_markup);

    const athleticConnection = {
        title: 'Athletic Connection',
        names: ['ID', 'Athletic Connection Markup'],
    };
    const colSpanLength = athleticConnection.names.length;

    // API call for posting quote into our database
    const putInput = async () => {
        var inputValue = document.getElementById(athleticConnection).value;
        let putBody = {
            athleticConnection: inputValue,
        };

        try {
            const res = await fetch(`${config.base_api}/freightRules`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(putBody),
            });

            await convertJSON(res);

            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Athletic Connection Updated'}});
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
        }
    };
    return (
        <form className="freightGroup">
            <div className="quotesTable">
                <table>
                    <thead>
                        <tr>
                            {athleticConnection.names.map((name) => (
                                <th>{name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{athleticConnection.title}</td>
                            <td>
                                <input
                                    id="athleticConnection_markup"
                                    defaultValue={ac_markup}
                                    onChange={(e) => set_ac_markup(e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr className="equation">
                            <td colspan={colSpanLength}>
                                Calculation: <br />
                                MSRP * {ac_markup}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Btn title="Update" onClick={putInput} 
                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
            </div>
        </form>
    );
};

export default AthleticConnection;
