import React from 'react';
import {isNote} from './productTypeCheck';
import formatNumber from '../../../utilities/formatNumber';
import { roundUp } from '../../../utilities/rounding';
import {useStore} from '../../../StoreProvider';
import {ACTIONS} from '../../../Actions';

const CellSalePrice = ({product, calculateRow, productChange, setProductChange}) => {
    const {state, dispatch} = useStore();
    /**
     * If product is 'note' return blank td - else
     * If sale price has a value from database display value
     * else if no price let user input value
     */

    if (isNote(product) || !product.web_listed_price || product.web_listed_price_altered) {
        return <td key="sale_price" className="center col-sale_price"></td>;
    }    

    if (product.sale_price && !product.sale_price_altered) {
        return (
            <td key="list_price" className="center col-list_price">
                {`${roundUp(product.sale_price)}`}
            </td>
        );
    }

    return (
        <td key="sale_price" className="center col-sale_price">
            <input
                onWheel={(e) => e.target.blur()}
                type="number"
                className={`pad-t-xs pad-b-xs text-center ${product.sale_price_altered ? 'altered ' : ''}`}
                value={roundUp(product.sale_price)}
                name="sale_price"
                onChange={(e) => {
                    // Update product description in state productTableItems
                    product.sale_price = roundUp(e.target.value);
                    product.sale_price_altered = true;

                    // Update state with user input changes
                    // Map through previous state, replace product with matching id, return updated state
                    dispatch({
                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                        payload: state?.productTableItems.map((prod) =>
                            prod.playground_boss_sku === product.playground_boss_sku ? product : prod
                        )
                    });
                    calculateRow(product.playground_boss_sku);
                    setProductChange(true);
                }}
                // When user leaves form field recalculate valu{debugToggle && es in row
                onBlur={(e) => calculateRow(product.id)}
                disabled={state?.quote.readOnly}
            />
        </td>
    );
};

export default CellSalePrice;
