import React from 'react';
import Modal from '../Modal';
import Btn from '../Btn';

const NoteModal = ({setShowNoteModal}) => {
    return (
        <Modal>
            <div className="modal_deleteQuote">
                <h4>Cannot Edit Notes</h4>
                <p style={{fontWeight: 'bold'}}>Add a note in the quote tool to edit description.</p>
                <div className="modal_deleteQuote_buttonContainer">
                    <Btn title="Cancel" onClick={(e) => setShowNoteModal(false)} />
                </div>
            </div>
        </Modal>
    );
};

export default NoteModal;
