import React, { useState, useEffect } from 'react';

const FloatingSidebar = ({ sections }) => {
  const [activeSection, setActiveSection] = useState('');

  const handleScroll = () => {
    const currentSection = sections.find((section) => {
      const element = document.getElementById(section.id);
      const scrollPosition = window.scrollY + window.innerHeight / 2; // Middle of the viewport
      return element.offsetTop <= scrollPosition && element.offsetTop + element.offsetHeight > scrollPosition;
    });
    setActiveSection(currentSection ? currentSection.id : '');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [sections]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToSectionWithOffset = (sectionId, offset = 0) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: sectionTop - offset, behavior: 'smooth' });
    }
  };

  return (
    <div className="sidebar">
      {sections.map((section, index) => (
        <div key={index} className="sidebar-item" onClick={() => scrollToSectionWithOffset(section.id, 100)}>
          <div className={`sidebar-dot ${activeSection === section.id ? 'active' : ''}`}></div>
          <div className="sidebar-label">{section.label}</div>
        </div>
      ))}
    </div>
  );
};

export default FloatingSidebar;
