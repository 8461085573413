import './main.scss';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import emptyQuoteObj from './utilities/emptyQuoteObj';
import authHeaders from './utilities/authHeaders';
import config from './config';
import PrivateRoute from './components/PrivateRoute';
import PrivateRouteAdmin from './components/PrivateRouteAdmin';
import PrivateRouteOps from './components/PrivateRouteOps';
import Login from './components/auth/Login';
import VerifyEmail from './components/auth/VerifyEmail';
import SavedQuotes from './views/SavedQuotes';
import ContactDetails from './views/ContactDetails';
import Quote from './views/Quote';
import Navbar from './components/Navbar';
import NavbarAdmin from './components/NavbarAdmin';
import NavbarOps from './components/NavbarOps';
import NavbarSales from './components/NavbarSales';
import Toast from './components/contactDetails/Toast';
import CutSheets from './views/CutSheets';
import FreightRules from './views/FreightRules';
import Reports from './views/Reports';
import Users from './views/Users';
import EditEmail from './views/EditEmail';
import Sync from './views/Sync';
import Products from './views/Products';
import ProductVariants from './views/ProductVariants';
import Manufacturers from './views/Manufacturers';
import SendEmail from './views/SendEmail';
import CostSheet from './views/CostSheet';
import ProjectAccessLog from './views/ProjectAccessLog';
import Projects from './views/Projects';
import ViewProject from './views/ViewProject';
import Configuration from './views/Configuration';
import Zoho from './views/Zoho';
import ApiErrorRetry from './views/ApiErrorRetry';
import packageInfo from "../package.json";
import CallAnalysis from './views/CallAnalysis';
import { useStore } from './StoreProvider';
import { ACTIONS } from './Actions';
import convertJSON from './utilities/convertJSON';
import pgLogo from './assets/PGLogo.png';

const version = packageInfo.version;

const App = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useStore();

    /**
     * App level state
     */

    // Authentication
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const page = window.location.pathname;

    //Multiples Background Color States
    const [backgroundColor, setBackgroundColor] = useState('#f5f8fa');

    //App Container Multiple Style States
    const [appContainerStyle, setAppContainerStyle] = useState({
        display: 'flex',
        position: '',
        alignItems: 'center',
        justifyContent: 'center',
    });

    /**
     * State for quote view
     */

    // Quote with attributes formated for putting into our quote data table in db
    // savedQuotes will set this, for creating a duplicate quote

    /**
     * State for ContactDetails view and hubspot integration
     */
    // Array of contacts for select box
    const [contactsSelectedArr, setContactsSelectedArr] = useState([
        // { value: 'create', name: 'Add New Contact' },
    ]);

    // Hubspot Shipping Contact select
    const [shippingContactSelected, setShippingContactSelected] = useState('create');

    // Array of contacts for shipping contact select box
    const [shippingContactSelectedArr, setShippingContactSelectedArr] = useState([]);

    // Form control
    const [isNewContact, setIsNewContact] = useState(true);
    const [isNewShippingContact, setIsNewShippingContact] = useState(true);

    // Prepared by state
    const [preparedBy, setPreparedBy] = useState({
        prepared_by_hubspot_id: '',
        prepared_by_name: '',
        prepared_by_phone: '',
        prepared_by_email: '',
    });

    /**
     * State for "saved quotes" view
     */

    const [filteredList, setFilteredList] = useState([]);

    //State for Product Variants
    const [productVariants, setProductVariants] = useState([]);

    //State for Manufacturers
    const [manufacturers, setManufacturers] = useState([]);
    const [pathname, setPathname] = useState(window.location.pathname);
    const [quoteSearchTerm, setQuoteSearchTerm] = useState('');
    const [incorrectVersion, setIncorrectVersion] = useState(false);

    useEffect(() => {
        const fetchVersion = async () => {
            try {
                const res = await fetch(`${config.base_api}/version`, { headers });
                const data = await res.json();

                if (data?.version?.find(vs => vs.version_number == version)) {
                    setIncorrectVersion(false);
                } else {
                    setIncorrectVersion(true);
                    dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: "" });
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchVersion();
    }, []);

    useEffect(() => {
        const urlPath = window.location.pathname;
        setPathname(window.location.pathname);
        if (window.location.pathname === '/') {
            setBackgroundColor('#f5f8fa');
            setAppContainerStyle({
                ...appContainerStyle,
                position: 'relative',
            });
        }

        let saveSearchPaths = ["viewQuote", "costsheet", "contactDetails/", "cutSheets", "sendEmail"]

        if (urlPath != '/' && !saveSearchPaths.some(path => urlPath.includes(path))) {
            setQuoteSearchTerm('');
        }
    }, [window.location.pathname]);

    const getProductVariants = async () => {
        try {
            const res = await fetch(`${config.base_api}/productVariants`, { headers });
            const data = await res.json();
            setProductVariants(data);
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
            console.log(error);
        }
    };

    const getManufacturers = async () => {
        try {
            const res = await fetch(`${config.base_api}/manufacturers`, { headers });
            const data = await res.json();
            setManufacturers(data);
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
            console.log(error);
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.VERSION_NUMBER, payload: version });
        // On app load, check if there is auth tokens stored in local storage to log client in. Check that it is valid. If so login
        // Check for token
        const rememberMeCheck = localStorage.getItem('rememberMe');

        const checkToken = async () => {
            let accessToken = localStorage.getItem('accessToken');
            const headers = authHeaders(accessToken);
            const res = await fetch(`${config.base_api}/auth/validate`, { headers });
            const data = await res.json();

            // If res.ok, then token has validated succesfully, update auth state
            if (res.ok) {
                console.log('successful validation');
                dispatch({
                    type: ACTIONS.AUTH, payload: {
                        loggedIn: true,
                        loading: false,
                        accessToken,
                        refreshToken: localStorage.getItem('refreshToken'),
                        username: localStorage.getItem('username'),
                        isAdmin: localStorage.getItem('isAdmin'),
                        rememberMe: localStorage.getItem('rememberMe'),
                        isOps: localStorage.getItem('isOps'),
                        isSales: localStorage.getItem('isSales'),
                    }
                });

                //GET Product Variants & Manufacturers on successful authentication
                getProductVariants();
                getManufacturers();
            } else {
                if (rememberMeCheck == 'true') {
                    const refreshResponse = await fetch(`${config.base_api}/auth/refresh`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            refreshToken: localStorage.getItem('refreshToken'),
                        }),
                    });

                    const tokens = await convertJSON(res);

                    if (tokens.message == 'Missing required parameter REFRESH_TOKEN') {
                        //No valid token, set invalid state
                        dispatch({
                            type: ACTIONS.AUTH, payload: {
                                loggedIn: false,
                                loading: false,
                                accessToken: '',
                                refreshToken: '',
                                username: '',
                                isAdmin: '',
                                rememberMe: false,
                                isOps: '',
                                isSales: '',
                            }
                        });
                    }

                    if (tokens.AuthenticationResult) {
                        localStorage.setItem('idToken', tokens.AuthenticationResult.IdToken);
                        localStorage.setItem('accessToken', tokens.AuthenticationResult.AccessToken);

                        dispatch({
                            type: ACTIONS.AUTH, payload: {
                                loggedIn: true,
                                loading: false,
                                accessToken: tokens.AuthenticationResult.AccessToken,
                                refreshToken: localStorage.getItem('refreshToken'),
                                username: localStorage.getItem('username'),
                                isAdmin: localStorage.getItem('isAdmin'),
                                rememberMe: localStorage.getItem('rememberMe'),
                                isOps: localStorage.getItem('isOps'),
                                isSales: localStorage.getItem('isSales'),
                            }
                        });

                        //GET Product Variants & Manufacturers on successful authentication
                        getProductVariants();
                        getManufacturers();
                    }
                } else {
                    // No token in local storage, set invalid state
                    dispatch({
                        type: ACTIONS.AUTH, payload: {
                            loggedIn: false,
                            loading: false,
                            accessToken: '',
                            refreshToken: '',
                            username: '',
                            isAdmin: '',
                            rememberMe: false,
                            isOps: '',
                            isSales: '',
                        }
                    });
                }
            }
        };
        checkToken();
    }, []);

    useEffect(() => {
        if (!Array.isArray(productVariants)) {
            getProductVariants();
        }
    }, [productVariants]);

    useEffect(() => {
        if (!Array.isArray(manufacturers)) {
            getManufacturers();
        }
    }, [manufacturers]);

    return (
        <div className={`${pathname === "/operations/projects" ? "app_responsive" : ''} ${pathname.includes("/operations/projects/") ? "app_project_responsive" : ''} 
            ${pathname.includes("viewQuote") ? "appQuote_responsive" : ''} app`}
            style={{ backgroundColor: backgroundColor }}>
            <div className={`${pathname === "/operations/projects" ? "navbar_responsive" : ''} ${pathname.includes("/operations/projects/") ? "navbar_project_responsive" : ''} 
                ${pathname.includes("viewQuote") ? "quoteNav_responsive" : ''} position-sticky-top`}>
                <Navbar
                    pathname={pathname} />
                {/* Display toast if there is a toast message */}
                {state?.toast.message && <Toast />}
                {+state?.auth.isAdmin ?
                    <NavbarAdmin
                        pathname={pathname}
                        setPathname={setPathname} /> : <></>}
                {+state?.auth.isOps && !+state?.auth.isAdmin ?
                    <NavbarOps
                        pathname={pathname}
                        setPathname={setPathname} /> : <></>}
                {!+state?.auth.isAdmin && !+state?.auth.isOps && state?.auth.loggedIn ?
                    <NavbarSales
                        pathname={pathname}
                        setPathname={setPathname} /> : <></>}
            </div>
            <div
                className={`${pathname.includes("/operations/projects") ? "app_container_responsive " : ''} ${pathname.includes("viewQuote") ? "quote_container_responsive" : ''} app_container`}
                style={{
                    display: appContainerStyle.display,
                    position: appContainerStyle.position,
                    alignItems: appContainerStyle.alignItems,
                    justifyContent: appContainerStyle.justifyContent,
                }}>
                {/* Routes for routing */}
                <Routes>

                    {/* Auth routes (non-private) */}
                    <Route
                        path="/auth/login/*"
                        element={
                            <>
                                {state?.auth.loggedIn ? (
                                    <Navigate to="/" replace />
                                ) : (
                                    <Login
                                        setBackgroundColor={setBackgroundColor}
                                        appContainerStyle={appContainerStyle}
                                        setAppContainerStyle={setAppContainerStyle}
                                        getProductVariants={getProductVariants}
                                        getManufacturers={getManufacturers}
                                    />
                                )}
                            </>
                        }
                    />
                    <Route path="/auth/verifyEmail" element={<VerifyEmail />} />

                    {/* View with all saved quotes */}
                    <Route path="/" element={
                        <PrivateRoute>
                            <SavedQuotes
                                filteredList={filteredList}
                                setFilteredList={setFilteredList}
                                appContainerStyle={appContainerStyle}
                                setAppContainerStyle={setAppContainerStyle}
                                quoteSearchTerm={quoteSearchTerm}
                                setQuoteSearchTerm={setQuoteSearchTerm}
                            />
                        </PrivateRoute>
                    } />

                    <Route path="/contactDetails/*" element={
                        <PrivateRoute>
                            <ContactDetails
                                preparedBy={preparedBy}
                                setPreparedBy={setPreparedBy}
                                isNewContact={isNewContact}
                                setIsNewContact={setIsNewContact}
                                isNewShippingContact={isNewShippingContact}
                                setIsNewShippingContact={setIsNewShippingContact}
                                contactsSelectedArr={contactsSelectedArr}
                                setContactsSelectedArr={setContactsSelectedArr}
                                shippingContactSelected={shippingContactSelected}
                                setShippingContactSelected={setShippingContactSelected}
                                shippingContactSelectedArr={shippingContactSelectedArr}
                                setShippingContactSelectedArr={setShippingContactSelectedArr}
                            />
                        </PrivateRoute>
                    } />

                    <Route path="/viewQuote/*" element={
                        <PrivateRoute>
                            <Quote productVariants={productVariants} />
                        </PrivateRoute>
                    } />

                    <Route path="/cutSheets/*" element={<PrivateRoute><CutSheets /></PrivateRoute>} />
                    <Route path="/sendEmail/*" element={<PrivateRoute><SendEmail /></PrivateRoute>} />

                    <Route path="/admin/products" element={
                        <PrivateRouteAdmin>
                            <Products productVariants={productVariants} manufacturers={manufacturers} />
                        </PrivateRouteAdmin>
                    } />

                    <Route path="/operations/zoho/*" element={
                        <PrivateRouteOps isSalesOverride={true} allUsersOverride={true}>
                            <Zoho />
                        </PrivateRouteOps>
                    } />

                    <Route path="/operations/productVariants" element={
                        <PrivateRouteOps>
                            <ProductVariants
                                productVariants={productVariants}
                                setProductVariants={setProductVariants}
                                getProductVariants={getProductVariants}
                                manufacturers={manufacturers}
                            />
                        </PrivateRouteOps>
                    } />

                    <Route path="/operations/manufacturers" element={
                        <PrivateRouteOps>
                            <Manufacturers
                                manufacturers={manufacturers}
                                setManufacturers={setManufacturers}
                                getManufacturers={getManufacturers}
                            />
                        </PrivateRouteOps>
                    } />

                    <Route path="/operations/projectAccessLog/*" element={<PrivateRouteOps><ProjectAccessLog /></PrivateRouteOps>} />
                    <Route path="/operations/projects" element={<PrivateRouteOps><Projects /></PrivateRouteOps>} />
                    <Route path="/operations/costSheet/*" element={<PrivateRouteOps><CostSheet /></PrivateRouteOps>} />
                    <Route path="/admin/users" element={<PrivateRouteAdmin><Users /></PrivateRouteAdmin>} />
                    <Route path="/admin/reports" element={<PrivateRouteAdmin><Reports /></PrivateRouteAdmin>} />
                    <Route path="/operations/sync" element={<PrivateRouteOps><Sync /></PrivateRouteOps>} />
                    <Route path="/operations/projects/:id" element={<PrivateRouteOps><ViewProject /></PrivateRouteOps>} />
                    <Route path="/admin/email" element={<PrivateRouteAdmin><EditEmail /></PrivateRouteAdmin>} />
                    <Route path="/admin/freightRules" element={<PrivateRouteAdmin><FreightRules /></PrivateRouteAdmin>} />
                    <Route path="/admin/configuration" element={<PrivateRouteOps><Configuration /></PrivateRouteOps>} />
                    <Route path="/admin/callAnalysis" element={<PrivateRouteOps><CallAnalysis /></PrivateRouteOps>} />
                    <Route path="/admin/apiErrorRetry" element={<PrivateRouteOps><ApiErrorRetry /></PrivateRouteOps>} />
                    {/* End app container */}
                </Routes>
            </div>{' '}


            {/* Footer */}
            {!page.includes('auth/login')
                ? <footer className='text-gray text-center'>Version {state?.versionNumber}</footer>
                : null
            }
            <div className={`${incorrectVersion ? "error-modal border-radius-xs b-none justify-center" : "hidden"}`}>
                <img src={pgLogo} alt="Playground Boss Logo" className="modal-logo"></img>
                <div className="flex-column height-100 width-60 justify-center">
                    <div className="text-green text-bolder row-gap-lg flex justify-center">
                        <p className="text-xxl width-75 text-center line-height-120">Your version of this application is not up to date.</p>
                    </div>
                    <p className="margin-t-lg width-80 align-self-center text-smedium line-height-150">
                        Please follow these instructions to reset your cache and refresh the page:
                        <div className='margin-t-lg'>
                            <b> For Windows, press and hold CTRL+SHIFT+R
                            <br></br>For MAC, press and hold CMD+SHIFT+R</b>
                        </div>
                    </p>
                </div>
            </div>
            <div id="page-mask" className={`${incorrectVersion ? "" : "hidden"}`}></div>
        </div>
    );
};

export default App;
