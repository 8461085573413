export default {
    loaded: false,
    id: '',
    hubspot_contact_id: '',
    products: [],
    total_profit: 0,
    total_margin: 0,
    total_cost: 0,
    total_taxes: 0,
    // Set tax rate to Texas's tax for a default
    tax_rate: 8.25,
    customer_subtotal: 0,
    customer_totalPrice: 0,
    includeTaxes: false,
    tagName: '',
    deal_name: 'default',
    readOnly: false,
    internal_notes: '',
    freight: {
        suggestedCost: 0,
        suggestedPrice: 0,
        cost: 0,
        customerPrice: 0,
        description: '',
    },
    installation: {
        includeInstallation: null,
        suggestedCost: 0,
        suggestedPrice: 0,
        cost: 0,
        customerPrice: 0,
        description: '',
    },
    discount: {
        lineItemsDiscount: 0,
        additionalDiscount: 0,
        totalDiscount: 0,
        description: '',
    },
};
