import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {SmallBtn} from '../Btn';
import {useStore} from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const SheetsToAdd = () => {
    const {state, dispatch} = useStore();
    // All cut sheets to add are passed in from include sheets prop

    // Sets updated order where item is dragged to in array
    const handleOnDragEnd = (result) => {
        const items = Array.from(state?.includeSheets);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        dispatch({type: ACTIONS.INCLUDE_SHEETS, payload: items});
    };

    useEffect(() => {
        console.log(state?.includeSheets);
    }, [state?.includeSheets]);

    // Cut sheets to include with drag and drop capabilities.
    // If no cut sheets to include, return an ul with a p with text of "none"
    // If there are cut sheets return a list of them, that is drag and droppable for ordering

    return (
        <div className="cutSheets_sheetsToAdd">
            <h3>Cut Sheets to include</h3>
            <p>Drag to rearrange cut sheets.</p>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="cutSheets">
                    {(provided) => (
                        <ul {...provided?.droppableProps} ref={provided?.innerRef}>
                            {state?.includeSheets?.length === 0 ? (
                                <p>None</p>
                            ) : (
                                state?.includeSheets?.map((sheet, index) => (
                                    <Draggable key={sheet?.id} draggableId={sheet?.id?.toString()} index={index}>
                                        {(provided) => (
                                            <li
                                                {...provided?.dragHandleProps}
                                                {...provided?.draggableProps}
                                                ref={provided?.innerRef}>
                                                <SmallBtn
                                                    btnClass= "inline-block margin-r-lg bg-light-red text-bold text-white box-shadow delete-button light-red-border"
                                                    title="Remove"
                                                    onClick={(e) =>
                                                        dispatch({
                                                            type: ACTIONS.INCLUDE_SHEETS, 
                                                            payload: state?.includeSheets?.filter((s) => s.name !== sheet?.name)
                                                        })
                                                    }
                                                />
                                                <SmallBtn
                                                    btnClass="inline-block margin-r-xlg bg-white text-blue b2-blue box-shadow text-bold darken-background"
                                                    title="View"
                                                    onClick={(e) => window.open(sheet?.url)}
                                                />
                                                <span className="cutSheets_sheetsToAdd_listNumber">{index + 1}.</span>{' '}
                                                {sheet?.name}

                                            </li>
                                        )}
                                    </Draggable>
                                ))
                            )}
                            {provided?.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default SheetsToAdd;
