import React from 'react';
import formatNumber from '../../utilities/formatNumber';
import { useStore } from '../../StoreProvider';

const TotalProfit = () => {
    const { state } = useStore();

    return (
        <div className="productsTotal">
            <p className="text-med text-bold margin-r-sm">Profit Margin</p>
            <p className="text-profit-margin text-bold text-green margin-r-sm"> {state?.quote.total_margin ? formatNumber(state?.quote.total_margin) + '%' : '0%'}</p>
            <p className="text-med text-bold margin-r-sm">Total Profit</p>
            <p className="text-profit-margin text-bold text-green margin-r-sm">{state?.quote.total_profit ? '$' + formatNumber(state?.quote.total_profit) : '$0'}</p>
        </div>
    );
};

export default TotalProfit;
