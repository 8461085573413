import React from 'react';
import Modal from '../Modal';
import Btn from '../Btn';

const NoDataModal = ({setShowNoDataModal}) => {
    return (
        <Modal>
            <div className="modal_deleteQuote">
                <h4>Cannot Create PDF</h4>
                <p style={{fontWeight: 'bold'}}>You cannot create a PDF with no data!</p>
                <div className="modal_deleteQuote_buttonContainer">
                    <Btn title="Cancel" onClick={(e) => setShowNoDataModal(false)} 
                    btnClass = 'bg-white text-blue text-bold b2-blue darken-background box-shadow pad-lr-lg pad-tb-md'/>
                </div>
            </div>
        </Modal>
    );
};

export default NoDataModal;
