const devRoute = 'https://api.pgbquote.com/v1';
const localRoute = 'http://localhost:3001/v1';

// All environment variables must be prefixed with REACT_APP_ to be recognized 
// Documentation: https://create-react-app.dev/docs/adding-custom-environment-variables/

export default {
    base_api: process.env.REACT_APP_API_BASE,
    qb_base_api: process.env.REACT_APP_QUICKBOOK_ENVIRONMENT,
};
