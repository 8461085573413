import React, { useEffect, useState } from 'react';
import UsersSection from '../components/dashboard/UsersSection'


const Users = () => {

    return (
        <div>
            <UsersSection />
        </div>
    )
}

export default Users
