import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import config from '../config';
import authHeaders from '../utilities/authHeaders';

import { calculateFreightCost, calculateFreightPrice } from '../utilities/calculateFreight';
import { calculateInstallationCost, calculateInstallationPrice } from '../utilities/calculateInstallation';
import QuoteTitle from '../components/quote/QuoteTitle';
import BillingContact from '../components/quote/BillingContact';
import ShippingContact from '../components/quote/ShippingContact';
import QuoteButtons from '../components/quote/QuoteButtons';
import FindProducts from '../components/quote/FindProducts';
import ProductsTable from '../components/quote/productsTable/ProductsTable';
import QuoteSummary from '../components/quote/QuoteSummary';
import Discount from '../components/quote/Discount';
import Freight from '../components/quote/Freight';
import Installation from '../components/quote/Installation';
import { saveQuote } from '../components/quote/SaveQuote';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import roundDollar from '../utilities/roundDollar';
import { quoteToObject } from '../utilities/formatQuote';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import LoadingInline from '../components/LoadingInline';
import InvalidQuote from '../components/invalidDisplays/InvalidQuote';


const Quote = ({ productVariants }) => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const [uniqueName, setUniqueName] = useState(false);
    const [dealNameRequired, setDealNameRequired] = useState(false);
    // Store freight rules - values from our database
    const [freightRules, setFreightRules] = useState([]);
    const [recalculateTotals, setRecalculateTotals] = useState(false);
    // new state to check for product change
    const [productChange, setProductChange] = useState(false);
    const [autoSave, setAutoSave] = useState(false)
    const [loading, setLoading] = useState(true);
    const [invalidQuote, setInvalidQuote] = useState(false);
    const [shippingContactMissing, setShippingContactMissing] = useState(false);
    const [billingContactMissing, setBillingContactMissing] = useState(false);

    const quoteNumber = window.location.pathname?.split('/viewQuote/')[1];
    const quoteID = quoteNumber?.substring(7);

    /**
     * Quote calculations
     * 1. Add product to state productTableItems[] and set initial product values - then calculateRow() to fill in remaining values
     * 2. Calculate product row values on changes from quantity change, and user input changes - at a single product level
     * 3. Calculate quote totals on by analyzing individual product totals
     */

    // Calculate Quote totals when productTableItems state changes
    // Total calculations by looping through products

    const calculateTotals = () => {
        setProductChange(false);
        if (!freightRules || freightRules.length <= 0) {
            getFreightRules();
            return;
        }
        //
        // Profit totals for Playground Boss
        let totalProfit = 0,
            totalMargin = 0,
            totalSale = 0,
            totalCost = 0,
            totalTaxes = 0;

        // Customer totals to pay
        let customer_subtotal = 0,
            customer_totalPrice = 0;

        // Total line items discount for state?.quote?.discount
        let lineItemsDiscount = 0;

        // Freight and installation
        let suggFreightCost = 0,
            suggInstallationCost = 0;
        let suggFreightPrice = 0,
            suggInstallationPrice = 0;
        let numberOfPalettes = 0;
        let numberOfCrates = 0;
        let palettesNoCrate = 0;
        // Timber's freight calculation (4 products)
        let timberCount = 0;
        const timbersPerPalette = 42;
        
        if (state?.productTableItems?.length > 0) {
            /**
             * Calculations per row
             */
            // Loop through all products in state and calculate figures for playground boss and customer
            for (const product of state?.productTableItems) {
                // console.log('product', product);
                // Only include products with both a total_price and cost into total profit and margin calculations
                // totalSale and totalCost used for aforementioned calculations
                if (product.total_price) {
                    totalSale += product.total_price;
                }

                if (product.total_cost) {
                    totalCost += product.total_cost;
                }

                // Customer totals
                customer_subtotal += +product.total_price;

                //
                // totalTaxes = parseFloat(totalTaxes) + parseFloat(product.tax);
                //

                if (product.web_listed_price && product.sale_price) {
                    lineItemsDiscount += (product.web_listed_price - product.sale_price) * +product.qty;
                }
                if (!product.has_free_freight) {
                    if (product.manufacturer === 'KidsTale Playgrounds' || product.manufacturer === 'KidsTale Inc' || product.manufacturer === 'Barks and Rec' || product.manufacturer === 'Sports Play') {
                        if (product.is_palette) {
                            if (product?.no_crate === true && product?.per_palette > 0) {
                                palettesNoCrate += (Number(product?.qty) / product?.per_palette);
                            } else if (product?.no_crate === true && product?.per_palette === 0) {
                                palettesNoCrate += Number(product?.qty);
                            } else if (product?.no_crate === false && product?.per_palette > 0) {
                                numberOfPalettes += (Number(product?.qty) / product?.per_palette);
                            } else {
                                numberOfPalettes += Number(product?.qty);
                            }
                        } else {
                            let crates = product?.crates ? product?.crates : 0;
                            numberOfCrates += (crates * product?.qty);
                        }
                    }
                }

                suggFreightCost += +product.suggFreight;
                suggInstallationCost += product.suggInstallation;

                // Get total timber quantity
                if (
                    product.playground_boss_sku === 'PGBTB-5208-KT' ||
                    product.playground_boss_sku === 'PGBTB-5212-KT' ||
                    product.playground_boss_sku === 'PGBTB-5208-APS' ||
                    product.playground_boss_sku === 'PGBTB-5212-APS'
                ) {
                    timberCount += +product.qty;
                    numberOfPalettes = 0;
                    palettesNoCrate = 0;
                    numberOfCrates = 0;
                }

                if (product.manufacturer?.toLowerCase() === 'mytcoat') {
                    if (product?.is_palette === true && product?.per_palette > 0) {
                        let productCount = parseInt(product.qty)/product?.per_palette;
                        let myTCoatFreight = 
                            (Math.floor(productCount) * freightRules?.myTCoat_price_per_palette) 
                            + (Math.ceil(productCount % 1) * freightRules?.myTCoat_price_per_partial_palette);
                        suggFreightCost += myTCoatFreight;
                    } else {
                        let productCount = parseInt(product.qty);
                        let myTCoatFreight = 
                            (Math.floor(productCount / 2) * freightRules?.myTCoat_price_per_palette) 
                            + ((productCount % 2) * freightRules?.myTCoat_price_per_partial_palette);
                        suggFreightCost += myTCoatFreight;
                    }
                }
            }
        }

        let totalPalettes = Math.ceil(numberOfPalettes);

        while (totalPalettes > 1) {
            numberOfCrates += 1;
            totalPalettes -= 4;
            if (totalPalettes < 0) totalPalettes = 0;
        }

        if (numberOfCrates > 6) numberOfCrates = 6;
        palettesNoCrate = Math.ceil(palettesNoCrate);

        const heavyGroupFreight = (totalPalettes * freightRules?.heavy_group_price_per_palette) + (palettesNoCrate * freightRules?.heavy_group_price_per_palette) + (numberOfCrates * freightRules?.heavy_group_price_per_crate);
        suggFreightCost += heavyGroupFreight;
 
        /**
         * Total Calculations
         */

        // Calculate install profit - If install cost and install price exist
        if (
            state?.quote?.installation?.includeInstallation &&
            state?.quote?.installation?.cost >= 0 &&
            state?.quote?.installation?.customerPrice >= 0
        ) {
            totalSale += +state?.quote?.installation.customerPrice;
            totalCost += +state?.quote?.installation.cost;
        }

        // Calculate freight profit - If freight cost and freight price exist
        if (state?.quote?.freight?.cost >= 0 && state?.quote?.freight?.customerPrice >= 0) {
            totalSale += +state?.quote?.freight?.customerPrice;
            totalCost += +state?.quote?.freight?.cost;
        }

        // Totals
        totalProfit = totalSale - totalCost - +state?.quote?.discount?.additionalDiscount;
        // additionalProfit;

        // Subract additional profit - do not calculate this profit for totalMargin at this time
        totalMargin = totalProfit / (totalSale - +state?.quote?.discount?.additionalDiscount);

        // Make sure total Margin is not divided by zero resulting in NaN
        totalMargin = typeof totalMargin === 'number' ? totalMargin : 0;

        // Change margin to percentage
        totalMargin = totalMargin * 100;

        // Deduct discounts additionalDiscount (should be deducted from subtotal and not totalPrice)
        customer_subtotal -= +state?.quote?.discount?.additionalDiscount;

        // Calculate total values
        customer_totalPrice += +customer_subtotal;

        // Add in freight if products exist in table
        customer_totalPrice += +state?.quote?.freight?.customerPrice;

        // Add in Installation if includeInstallation is true
        customer_totalPrice += state?.quote?.installation?.includeInstallation ? +state?.quote?.installation?.customerPrice : 0;

        // Timber freight calculation
        if (timberCount && freightRules?.actionPlaySystems_price_per_pallete) {
            let timberFreight =
                Math.ceil(timberCount / timbersPerPalette) * freightRules?.actionPlaySystems_price_per_pallete;
            suggFreightCost += timberFreight;
        }

        suggFreightCost = isNaN(suggFreightCost) ? 0 : suggFreightCost;

        // Add trip charge to installation
        suggInstallationCost += freightRules?.installer_trip_charge;
        // Check to make sure suggInstallationCost is a number, if not return 0
        suggInstallationCost = isNaN(suggInstallationCost) ? 0 : suggInstallationCost;

        // Set minimum install cost
        if (suggInstallationCost < 3500) suggInstallationCost = 3500;

        // Calculate sugg freight and install totals - (Price to customer, not cost for pgb)

        suggFreightPrice = calculateFreightPrice(freightRules, suggFreightCost);

        suggInstallationPrice = calculateInstallationPrice(freightRules, suggInstallationCost);

        // If taxes - calculate all taxes
        if (state?.quote?.includeTaxes) {
            totalTaxes = customer_totalPrice * (+state?.quote?.tax_rate * 0.01);
            customer_totalPrice += totalTaxes;
        }

        if (typeof customer_totalPrice !== 'number') {
            customer_totalPrice = 0;
        }

        // set the newly calculated values into the quote object
        dispatch({
            type: ACTIONS.QUOTE,
            payload: {
                ...state?.quote,
                // Trigger auto save by setting flag
                autoSave: !state?.quote?.autoSave,
                total_profit: totalProfit,
                total_margin: totalMargin,
                total_cost: totalCost,
                customer_subtotal,
                customer_totalPrice,
                total_taxes: totalTaxes,
                products: [...(state?.productTableItems || [])],
                discount: {
                    ...state?.quote['discount'],
                    lineItemsDiscount,
                },
                freight: {
                    ...state?.quote['freight'],
                    suggestedCost: suggFreightCost,
                    suggestedPrice: suggFreightPrice,
                },
                installation: {
                    ...state?.quote['installation'],
                    suggestedCost: suggInstallationCost,
                    suggestedPrice: suggInstallationPrice,
                },
            }
        });
    };

    // debounce to remove the onBlur
    // UseEffect is triggered when one of the values in quote or the product sale price is changed
    useEffect(() => {
        // debounce input will set a delay when the function is called
        const debounceInput = setTimeout(() => {
            calculateTotals();
        }, 350);
        // the return will clear previous instances of the function until 500ms has passed and only run the function once
        return () => clearTimeout(debounceInput);
    }, [
        // this array will only trigger when the primitive values in the quote change or the state is set for a product price change
        state?.quote?.freight?.customerPrice,
        state?.quote?.freight?.cost,
        state?.quote?.installation?.customerPrice,
        state?.quote?.installation?.cost,
        state?.quote?.discount?.additionalDiscount,
        state?.quote?.includeInstallation,
        productChange,
    ]);

    // Calculate all remaining properties needed for table row
    // Takes in product
    // Find total price, profit, margin, qty change
    // Called on qty change and blur when user exiting text input field
    // Calculate quote totals after row totals
    // Does not update state

    const calculateSingleRow = (prod) => {
        let total_price, total_cost, profit, margin;
        let suggFreight = 0,
            suggInstallation = 0;

        // If there is a sale price, have that be the total price
        total_price = prod.sale_price ? prod.sale_price : prod.web_listed_price;

        total_price *= prod.qty;

        // Handle Cost
        total_cost = prod.cost * prod.qty;

        profit = total_price - total_cost;

        if (total_price) {
            margin = (total_price - total_cost) / total_price;
            margin *= 100;
        }

        // Calculate freight rules if values are present in state from our database
        if (freightRules) {
            // Pass in freightRules and product to calculate
            suggFreight = calculateFreightCost(freightRules, prod);
            suggInstallation = calculateInstallationCost(freightRules, prod);
        }

        return {
            ...prod,
            total_price,
            tax: total_price * (state?.quote?.tax_rate / 100),
            total_cost,
            margin,
            profit,
            suggFreight,
            suggInstallation,
        };
    };

    // Update state with calculated row
    // Only runs recalculation on needed row
    function calculateRow(playground_boss_sku) {
        dispatch({
            type: ACTIONS.PRODUCT_TABLE_ITEMS,
            payload: state?.productTableItems.map((prod) =>
                prod.playground_boss_sku === playground_boss_sku ? calculateSingleRow(prod) : prod
            )
        });

        setRecalculateTotals(true);
    }

    // Recalculate all rows in state
    // For instance - when freight shipping address changes
    function recalculateAllRows() {
        let updated = state?.productTableItems?.map((prod) => calculateSingleRow(prod));
        dispatch({ type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: updated });
        setRecalculateTotals(true);
    }

    // When user clicks product to add to data table
    // Get product from api by passing in id
    // Configure values product name, description, qty, list price and sale price
    // Add product to state?.productTableItems[] state
    const addProduct = async (pgbSKU) => {
        // If product is already in array, do not add product to state
        for (const product of state?.productTableItems) {
            if (product?.playground_boss_sku === pgbSKU) {
                console.log('Product already in productTableItems');
                return;
            }
        }

        const response = await fetch(`${config.base_api}/products/quoteTool/sku/${pgbSKU}`, { headers });

        let product = await convertJSON(response);

        // Configure addtional product fields (other fields already exist in product object)

        product.qty = 1;

        // Product description if available, or empty string
        product.product_description = product.product_description ? product.product_description : '';

        if (product.has_free_freight) product.product_description = 'FREE SHIPPING - ' + product.product_description;

        // If no amount is set for price or cost, set empty string
        product.web_listed_price = product.web_listed_price ? Math.ceil(product.web_listed_price) : '';
        product.sale_price = product.sale_price ? product.sale_price : '';

        if (product.cost) {
            if (product.has_free_freight) product.cost += product.freight_cost;
        } else {
            product.cost = '';
        }
        if (!product.ops_cost) {
            product.ops_cost = null;
        }
        // After initial productTableItem properties are set, calculate the rest of the properties in the row
        const calculatedRow = calculateSingleRow(product);

        dispatch({ type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: [...state?.productTableItems, calculatedRow] })
        // checking to see if it's in state
        setRecalculateTotals(true);
    };

    // Get and set freight calculations from backend
    const getFreightRules = async () => {
        if (!state?.quote?.shipping_address) return;

        // Use shipping address saved in quote, encode it for api call
        let shippingAddress = `${state?.quote?.shipping_address && state?.quote?.shipping_address.trim()} ${state?.quote?.shipping_city && state?.quote?.shipping_city.trim()
            } ${state?.quote?.shipping_state && state?.quote?.shipping_state.trim()} ${state?.quote?.shipping_zip && state?.quote?.shipping_zip.trim()}`;

        shippingAddress = encodeURI(shippingAddress);

        try {
            console.log('Maps api called');
            const res = await fetch(`${config.base_api}/freightRules/getCalculations/${shippingAddress}`, { headers });

            if (!res.ok) {
                throw 'Could not get freight rules.';
            }

            const rules = await convertJSON(res);

            if (rules.error || !res.ok) {
                throw 'Could not get freight rules.';
            }

            setFreightRules(rules);
        } catch (error) {
            console.log(error);
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: error,
                    isError: true,
                }
            });
            dispatch({
                type: ACTIONS.GLOBAL_ERROR_MESSAGE,
                payload: 'Invalid shipping address. The freight calculation will be incorrect until a valid address is entered.',
            });
        }
    };

    useEffect(() => {
        dispatch({ type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: [] });
        // clear all data from state
        if (quoteID) {
            dispatch({ type: ACTIONS.QUOTE, payload: emptyQuoteObj });

            (async () => {
                try {
                    let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, { headers });
                    if (!quoteData.ok) throw 'Could not get quote';
                    quoteData = await convertJSON(quoteData);
                    const formattedQuote = quoteToObject(quoteData);
                    formattedQuote['loaded'] = true
                    dispatch({ type: ACTIONS.QUOTE, payload: { ...state?.quote, ...formattedQuote } });
                    dispatch({ type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: formattedQuote?.products || [] });
                    dispatch({ type: ACTIONS.TOAST, payload: { message: `Viewing quote ${quoteNumber}` } });
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                    dispatch({ type: ACTIONS.TOAST, payload: { message: "Error loading the quote", isError: true } });
                    setInvalidQuote(true);
                    setLoading(false);
                }
            })();
        }

        // If no quote, send user to contact details / new quote view
        if (!quoteID) navigate('/contactDetails');
        // Do quote calcuations if read only is not set to true
        // No need to, and harmful to do, if set to read only
        getFreightRules();

        // Cleanup function to reset global error message
        return () => {
            dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: "" });
        };
    }, []);

    // When user changes shipping address, recalculate all rows to figure out individual product shipping prices
    // recalculateAllRows updates totals with calculateTotals()
    useEffect(() => {
        if (freightRules) {
            recalculateAllRows();
        }
    }, [freightRules]);

    // Recaluate totals when a component sets recalculateTotals to true
    // Autosave after recalculation
    useEffect(() => {
        if (recalculateTotals) {
            calculateTotals();
            setRecalculateTotals(false);
            setAutoSave(true);
        }
    }, [recalculateTotals]);

    useEffect(() => {
        setAutoSave(true);
    }, [state?.quote?.deal_name])

    useEffect(() => {
        if (state?.quote?.loaded) {
            const debounce = setTimeout(() => {
                dispatch({ type: ACTIONS.QUOTE, payload: { ...state?.quote, autoSave: !state?.quote?.autoSave } })
            }, 100);
            return () => clearTimeout(debounce);
        }
    }, [autoSave])


    // Auto save quote - on state?.quote?.autoSave flag
    // Calculate total function triggers state?.quote?.autoSave
    // After save set flag back to false
    useEffect(() => {
        if (!state?.quote?.readOnly && autoSave && state?.quote?.id) {
            (async () => {
                try {
                    await saveQuote(state?.auth, state?.quote, null, ACTIONS, dispatch);
                } catch (error) {
                    console.log('Quote failed to save');
                } finally {
                    console.log('autoSave', state?.quote);
                    setAutoSave(false)
                }
            })();
        }
    }, [state?.quote?.autoSave]);

    if (loading) {
        <LoadingInline title=""></LoadingInline>
    }

    if (invalidQuote) {
        return <InvalidQuote></InvalidQuote>
    }

    return (
        <>
        <div className="quote_view">
            <div className="quote_view-topHalf column-gap-lg">
                {/* Top section / Contact Details and Buttons */}
                <section className="quote_view-leftSection">
                    <QuoteTitle
                        uniqueName={uniqueName}
                        setUniqueName={setUniqueName}
                        dealNameRequired={dealNameRequired}
                        setDealNameRequired={setDealNameRequired}
                    />
                    <FindProducts
                        addProduct={addProduct}

                    />
                </section>
                {/* Second Row with, grid 2 wide */}

                <section className="quote_view-rightSection">
                    {/* First column */}
                    <Installation
                        setProductChange={setProductChange}
                        setRecalculateTotals={setRecalculateTotals}
                        setAutoSave={setAutoSave}
                    />

                    {/* Second column */}
                    <section className="quote_view-secondColumn">
                        <BillingContact
                            billingContactMissing={billingContactMissing}
                            setBillingContactMissing={setBillingContactMissing}
                        />
                        <Freight
                            setRecalculateTotals={setRecalculateTotals} setAutoSave={setAutoSave}
                        />
                        <Discount setRecalculateTotals={setRecalculateTotals} setAutoSave={setAutoSave} />
                    </section>

                    {/* Third column */}
                    <section className="quote_view-summary">
                        <ShippingContact
                            shippingContactMissing={shippingContactMissing}
                            setShippingContactMissing={setShippingContactMissing}
                        />
                        <QuoteSummary setRecalculateTotals={setRecalculateTotals} setAutoSave={setAutoSave} />
                    </section>
                    {/* Fourth Column */}
                    <section>
                        <QuoteButtons
                            uniqueName={uniqueName}
                            dealNameRequired={dealNameRequired}
                            shippingContactMissing={shippingContactMissing}
                            billingContactMissing={billingContactMissing}
                        />
                    </section>
                </section>
            </div>
            <section className="quote_view-productsTable">
                <ProductsTable
                    productChange={productChange}
                    setProductChange={setProductChange}
                    setRecalculateTotals={setRecalculateTotals}
                    calculateRow={calculateRow}
                    productVariants={productVariants}
                    setAutoSave={setAutoSave}
                />
            </section>
        </div>
        </>
    );
};

export default Quote;
