import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const LineChart = ({ apiData, xLabel, yLabel, seriesLabel = "", seriesQuantifier = 0, seriesColorSet = {} }) => {
  const labels = apiData.reduce((acc, curr) => {
    if (curr.length > acc.length) {
        return curr.map(item => item[0]);
    }
    return acc;
  }, []);

  const datasets = apiData.map((series, index) => {
 
    const color = seriesColorSet[index] || `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`;

    return {
        label: `${seriesLabel}${index + seriesQuantifier}`,
        data: series.map(item => item[1]),
        borderColor: color,
        backgroundColor: color,
    }
  });

  const data = { labels, datasets };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel, // Customize this value
        }
      },
      y: {
        title: {
          display: true,
          text: yLabel, // Customize this value
        }
      }
    }
  };

  return (
    <div style={{ width: 'auto', height: 'auto' }}>
        <Line data={data} options={options} />
    </div>);
};

export default LineChart;