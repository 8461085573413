import React, {useEffect, useState} from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import convertJSON from '../../utilities/convertJSON';
import { ACTIONS } from '../../Actions';

const PreparedBy = ({preparedBy, setPreparedBy}) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const [owners, setOwners] = useState([]);
    const [ownerSelect, setOwnerSelect] = useState('');

    // When component loads get owners and set to state
    useEffect(() => {
        const getOwners = async () => {
            try {
                const res = await fetch(`${config.base_api}/hubspot/owners`, {headers});
                if (!res.ok) {
                    throw new Error('Error getting owners');
                } 
                else {
                    const data = await convertJSON(res);
                    setOwners(data);
                    dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: '' });
                }
            } catch (error) {
               dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: 'Couldn\'t getting owners from HubSpot.' })
                console.error('Error getting owners', error);
            }
        };
        getOwners();

        return () => {
            dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: '' });  
        }
    }, []);

    // When sales person is selected from drop down set prepared by in app level state
    useEffect(() => {
        let [firstName, lastName] = ownerSelect.split(' ');

        let ownerObj = owners.filter((person) => person.firstName === firstName && person.lastName === lastName);

        // If there is a match for owner fill in state - or else set state blank
        if (ownerObj.length > 0) {
            ownerObj = ownerObj[0];

            console.log('Owner selected: ');
            console.log(ownerObj);

            // Get phone EXT - from our users api - matches email from hubspot with email in our api
            (async () => {
                let phoneEXT = '';
                try {
                    const res = await fetch(`${config.base_api}/users`, {headers});
                    const users = await convertJSON(res);

                    // Loop through users
                    for (const user of users) {
                        // Loop through all attributes of use and match email with email attribute
                        for (const att of user.Attributes) {
                            // Matches email, then loop through again and get phone number
                            if (att.Name?.toLowerCase() === 'email' && att.Value?.toLowerCase() === ownerObj.email?.toLowerCase()) {
                                for (const att1 of user.Attributes) {
                                    if (att1.Name === 'custom:phoneEXT') {
                                        phoneEXT = att1.Value;
                                        return;
                                    }
                                }
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                    console.log("Could not get salesperson's phone extension");
                } finally {
                    console.log('phone');
                    console.log(phoneEXT);
                    setPreparedBy({
                        prepared_by_hubspot_id: ownerObj.id,
                        prepared_by_name: ownerObj.firstName + ' ' + ownerObj.lastName,
                        prepared_by_email: ownerObj.email,
                        prepared_by_phone: phoneEXT,
                    });
                }
            })();
        } else {
            setPreparedBy({
                prepared_by_hubspot_id: '',
                prepared_by_name: '',
                prepared_by_email: '',
                prepared_by_phone: '',
            });
        }
    }, [ownerSelect]);

    return (
        <div className="hubspotContact">
            <h4>Prepared By</h4>
            <div className="hubspotContact_details hubspotContact_details-preparedBy">
                {/* Blank label for grid layout */}                
                        <label>
                            Select: <span className="hubspotContact_details_required">*</span>
                        </label>
                        <input
                            list="owners"
                            value={ownerSelect}
                            onChange={(e) => {
                                setOwnerSelect(e.target.value);
                            }}
                            required = {!state?.quote.quote_number}
                            disabled={state?.quote.readOnly}
                        />
                <datalist id="owners">
                    {owners?.length > 0 
                    ? owners?.map((person) => (
                        <option key={person.userId} value={`${person.firstName} ${person.lastName}`} />
                    ))
                : null}
                </datalist>

                <label htmlFor="prepared_by_name">
                    Name: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="prepared_by_name"
                    value={preparedBy.prepared_by_name}
                    type="text" 
                    name="prepared_by_name"
                    disabled
                />

                <label htmlFor="prepared_by_email">
                    Email: <span className="hubspotContact_details_required">*</span>
                </label>
                <input id="prepared_by_email" value={preparedBy.prepared_by_email} name="" type="email" disabled />

                <label htmlFor="prepared_by_phone">Phone EXT:</label>
                <input
                    id="prepared_by_phone"
                    value={preparedBy.prepared_by_phone}
                    type="tel"
                    name="prepared_by_phone"
                    onChange={(e) =>
                        setPreparedBy({
                            ...preparedBy,
                            prepared_by_phone: e.target.value,
                        })
                    }
                    disabled={state?.quote.readOnly || state?.quote.quote_number}
                />
            </div>
        </div>
    );
};

export default PreparedBy;
