// Calculate Installation Charges
// Sample 'rules' object, to pass in to all freight calculations
/*
const sampleObj = {
  distance: {
    description: 'Shipping distances (in miles) from manufacturer address to customers shipping address - 1502 old ranch rd camarillo ca',
    address_kidsTale_playgrounds: 1597,
    address_kidsTale_inc: 384,
    address_barks_and_rec: 2229,
    address_sports_play: 1871
  },
  freight_markup: 0.65,
  heavy_group_price_per_crate: 300,
  heavy_group_price_per_palette: 100,
  heavy_group_price_per_mile: 1,
  myTCoat_price_per_palette: 400,
  myTCoat_price_per_partial_palette: 315,
  zaeger_ewf_price_per_cubic_yard: 55,
  zaeger_filter_price_per_cubic_yard: 150,
  libertyTire_price_per_tons: 375,
  libertyTire_fixed_cost: 1950,
  athleticConnection_markup: 0.2,
  actionPlaySystems_price_per_pallete: 700,
  installer_cost: 0.3,
  installer_trip_charge: 500,
  installer_markup: 0.65
}
*/

// Calculate installation cost for each product
export function calculateInstallationCost(rules, product) {
    // Calculation (COST_OF_ALL_PRODUCTS * installer_cost)
    // Add installer_trip_charge to total calculation, not for individual row
    let installationCost = 0;

    // Do not include following products in calculating install cost
    if (
        // All safety surface products
        product.manufacturer === 'Safety Surface' ||
        // Turf - make sure by manufacturer and sku
        product.manufacturer === 'Turf' ||
        product.playground_boss_sku === 'TURF' ||
        // ADA Engineered wood Fiber
        product.playground_boss_sku === 'EWF' ||
        // Rubber Mulch
        product.playground_boss_sku === 'RUBBERMULCH' ||
        //Removal
        product.playground_boss_sku === 'REMOVAL'
    ) {
        return 0;
    } else if (product.exclude_from_installation === true) {
        return 0;
    }

    installationCost = product.cost * rules.installer_cost * product.qty;

    return installationCost;
}

export function calculateInstallationPrice(rules, cost) {
    // Installation Cost / installer_markup
    if (!rules || cost === undefined) {
        return 0;
    }

    var installationPrice = cost / rules.installer_markup;
    return installationPrice;
}
