export default function formatInput(num) {
    if (num) {
        return num;
    }
    return '';
}

export function phoneFormat(input) { // ###-###-#### free text - no special characters (excl. hyphens) and limit of 30 characters
    if (!input) return '';
    input = input?.replace(/[^A-Za-z-0-9 -]/g, "");
    let finalInput = '';
    let extraInput = input.substring(12);
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, '');

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    let size = input.length;
    if (size === 0 || size <= 3) {
        input = input;
    } else if (size > 3 && size <= 6) {
        console.log('dbg ', input, ' ', input.length)
        input = input.substring(0, 3) + '-' + input.substring(3, 6);
    } else if (size > 6) {
        console.log('dbg ', input, ' ', input.length)
        input = input.substring(0, 3) + '-' + input.substring(3, 6) + '-' + input.substring(6, 10);
    } 

    if ((input?.length) <= 12 && extraInput?.trim()?.length === 0) finalInput = input;
    else if ((input?.length) === 12 && extraInput?.length === 1) finalInput = input + ' ' + extraInput?.substring(0, 18);
    else if ((input?.length) === 12 && extraInput?.length > 1) finalInput = input + extraInput?.substring(0, 18);
    else if ((input?.length + extraInput?.length) > 12) finalInput = input + extraInput?.substring(0, 18);
    
    return finalInput;
}