import React from 'react';
import SameBillingContact from './SameBillingContact';
import { useStore } from '../../StoreProvider';

const ShippingContact = ({
    handleContactDetailsChange,
    billingContact,
    shippingContact,
    setShippingContact,
    setShippingContactID,
    isNewShippingContact,
    quote
}) => {
    const { state } = useStore();

    return (
        <div className="hubspotContact">
            <h4>Shipping Contact</h4>
            <SameBillingContact
                setShippingContactID={setShippingContactID}
                isNewShippingContact={isNewShippingContact}
                shippingContact={shippingContact}
                setShippingContact={setShippingContact}
                billingContact={billingContact}
                handleContactDetailsChange={handleContactDetailsChange}
            />
            <div className="hubspotContact_details hubspotContact_details-general">
                <label htmlFor="hubspot_fname">
                    First Name: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    value={shippingContact.shipping_firstname}
                    id="hubspot_fname"
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="shipping_firstname"
                    required
                    disabled={billingContact.sameBillingContact || quote.readOnly}
                />
                <label htmlFor="hubspot_lname">
                    Last Name: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_lname"
                    value={shippingContact.shipping_lastname}
                    name="shipping_lastname"
                    onChange={handleContactDetailsChange}
                    type="text"
                    required
                    disabled={billingContact.sameBillingContact || quote.readOnly}
                />

                <label htmlFor="shipping_email">
                    Email: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="shipping_email"
                    value={shippingContact.shipping_email}
                    type="email"
                    onChange={handleContactDetailsChange}
                    name="shipping_email"
                    required
                    disabled={billingContact.sameBillingContact || quote.readOnly}
                />

                <label htmlFor="hubspot_phone">
                    Phone: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_phone"
                    value={shippingContact.shipping_phone}
                    type="tel"
                    onChange={handleContactDetailsChange}
                    name="shipping_phone"
                    required
                    disabled={billingContact.sameBillingContact || quote.readOnly}
                />

                <label htmlFor="shipping_mobile">Mobile Phone (optional):</label>
                <input
                    id="shipping_mobile"
                    type="tel"
                    value={shippingContact.shipping_mobile}
                    onChange={handleContactDetailsChange}
                    name="shipping_mobile"
                    disabled={billingContact.sameBillingContact || quote.readOnly}
                />

                <label htmlFor="hubspot_company">Company:</label>
                <input
                    id="hubspot_company"
                    value={shippingContact.shipping_company}
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="shipping_company"
                    disabled={billingContact.sameBillingContact || quote.readOnly}
                />
            </div>
        </div>
    );
};

export default ShippingContact;
