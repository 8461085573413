export default function formatPhoneNumber(num) {
    if (!num) return;

    let n = num?.match(/[0-9]/g);

    // If phone number has '1' representing country code remove it
    if (num[0] === '1') n?.shift();

    // If phone number is not 10 digits, return and don't modify it
    if (num?.length !== 10) return num;

    n?.splice(3, 0, '-');
    n?.splice(7, 0, '-');

    return n.join('');
}
