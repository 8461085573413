import React from 'react';
import SubmitBtn from '../SubmitBtn';
import {useStore} from '../../StoreProvider';
import {ACTIONS} from '../../Actions';

const EmailForm = ({emailState, setEmailState, sendOutEmail}) => {
    const {state, dispatch} = useStore();
    const onChange = (e) => {
        setEmailState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <form className="sendEmail_form" onSubmit={sendOutEmail}>
            <div className="sendEmail_form_grid">
                {/* grid is two items */}

                <label htmlFor="email-to">Send to:</label>
                <div>
                    <input type="text" id="email-to" name="to" value={emailState.to} onChange={onChange} />
                    <p className="sendEmail_grid_note">Only send to one person</p>
                </div>

                <label htmlFor="email-cc">CC:</label>
                <div>
                    <input type="text" id="email-cc" name="cc" value={emailState.cc} onChange={onChange} />
                    <p className="sendEmail_grid_note">Separate email addresses by a comma</p>
                </div>

                <label htmlFor="email-bcc">BCC:</label>
                <div>
                    <input type="text" id="email-bcc" name="bcc" value={emailState.bcc} onChange={onChange} />
                    <p className="sendEmail_grid_note">Separate email addresses by a comma</p>
                </div>

                <p>Email sent from:</p>
                <p>{state?.quote.prepared_by_email}</p>
            </div>{' '}
            {/* End grid */}
            <label htmlFor="email-body">Subject:</label>
            <input type="text" id="email-body" name="subject" value={emailState.subject} onChange={onChange} />
            <label htmlFor="email-body">Email Body:</label>
            <textarea id="email-body" name="body" value={emailState.body} onChange={onChange} />
            <label htmlFor="email-signature">Signature:</label>
            <textarea id="email-signature" disabled name="signature" value={emailState.signature} onChange={onChange} />
            <SubmitBtn title="Send Email" />
        </form>
    );
};

export default EmailForm;
