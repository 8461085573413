import React from 'react';
import Btn from '../Btn';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import {saveQuote} from '../quote/SaveQuote';
import convertJSON from '../../utilities/convertJSON';

const DownloadPDF = ({setDownloading}) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    // When user clicks create pdf button
    const onClick = async (e) => {
        try {
            await saveQuote(state?.auth, {...state?.quote, readOnly: true}, null, ACTIONS, dispatch);

            setDownloading(true);
            // Get array of urls of cut sheets to include in quote from includeSheets
            const sheets = state?.includeSheets.map((sheet) => sheet.url);

            // Generates and create pdf on our server
            // Takes in quote and cut sheets to include in quote
            // Returns pdf file name to use with the download pdf api route
            const res = await fetch(`${config.base_api}/quote/createPDF`, {
                method: 'POST',
                headers,
                body: JSON.stringify({quote: state?.quote, sheets}),
            });

            const data = await convertJSON(res);
            if (!res.ok) throw data.error;

            dispatch({type: ACTIONS.QUOTE, payload :{...state?.quote, readOnly: true}});

            const pdfName = data.fileName;
            console.log(`Succesful pdfs: ${data.succesfulPDFs}\n`);
            console.log(`Failed pdfs:  ${data.failedPDFs}\n`);

            // dispatch({type: ACTIONS.TOAST, payload: {
            // 	message: 'Downloaded PDF - Quote is now read only'
            // }});
            // Open window with api route of viewPDF (starts download)
            window.open(`${config.base_api}/downloadPDF/${pdfName}`, '_blank');
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: {
                message: `Failed to download quote`,
                isError: true,
            }});
        } finally {
            setDownloading(false);
        }
    };

    return (
        <div className="saveQuote">
            <Btn title="Download PDF" onClick={onClick} 
            btnClass="pad-tb-md bg-light-green text-white text-bold b-none green-button box-shadow min-width-150px justify-center" />
        </div>
    );
};

export default DownloadPDF;
