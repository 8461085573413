import React from 'react';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';

const CellExcludeInstallation = ({ product, calculateRow }) => {
    const { state, dispatch } = useStore();

    return (
        <td key="excludeInstallation" className="center col-margin">
            <input type="checkbox"
                defaultChecked={product?.exclude_from_installation}
                onChange={(e) => {
                    product.exclude_from_installation = e.target.checked;
                    
                    dispatch({
                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                        payload: state?.productTableItems.map((prod) =>
                            prod.playground_boss_sku === product.playground_boss_sku ? product : prod
                        )
                    });
                    calculateRow(product.playground_boss_sku);
                }}
                onBlur={(e) => calculateRow(product.playground_boss_sku)}
                disabled={state?.quote.readOnly} />
        </td>
    );
}

export default CellExcludeInstallation;