import React, {useEffect, useState} from 'react';

const ExpenseAcc = ({put, setPut, product, populateAccounts, vendorPurchase}) => {
    //Filter Income Accounts
    const [expenseAccounts, setExpenseAccounts] = useState();

    //Loading States
    const [loadingExpense, setLoadingExpense] = useState(true);

    //Display for account input
    const [expenseDisplay, setExpenseDisplay] = useState();

    //Set Income and Expense Change Default
    //Populate Accounts On Mount
    useEffect(() => {
        const filteredAccounts = populateAccounts.filter((account) => account.expense === true);
        setExpenseAccounts(filteredAccounts);

        if (product.qb_expense_acc) {
            let expenseAcc = filteredAccounts.filter((account) => account.id === product.qb_expense_acc);

            if (expenseAcc.length > 0) {
                setExpenseDisplay(expenseAcc[0].reference);
                setLoadingExpense(false);
            }
        } else {
            setExpenseDisplay('');
            setLoadingExpense(false);
        }
    }, []);

    if (loadingExpense === true) {
        return <td colSpan="6">Loading...</td>;
    }

    return (
        <td colSpan="2">
            {vendorPurchase ? (
                <div>
                    <input
                        className="inputWidth"
                        list="expense-results-list"
                        id="expense-results-input"
                        name="expense-results-input"
                        placeholder="Select Expense Account"
                        defaultValue={expenseDisplay}
                        onChange={(e) => {
                            let expenseAcc = expenseAccounts.filter((account) => account.reference === e.target.value);

                            if (expenseAcc.length > 0) {
                                setExpenseDisplay(expenseAcc[0].reference);
                                setPut({...put, qb_expense_acc: expenseAcc[0].id});
                            }
                        }}
                    />
                    <datalist id="expense-results-list">
                        {expenseAccounts.map((account, i) => (
                            <option value={account.reference} key={i} />
                        ))}
                    </datalist>
                </div>
            ) : (
                <div>
                    <input
                        className="inputWidth"
                        list="expense-results-list"
                        id="expense-results-input"
                        name="expense-results-input"
                        placeholder="Select Expense Account"
                        disabled="true"
                    />
                </div>
            )}
        </td>
    );
};

export default ExpenseAcc;
