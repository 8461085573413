export const compareArraysOfObjects = (oldReference, newReference) => {
    // if the lengths aren't the same then these are different values
    if (oldReference?.length !== newReference?.length) return true;
    const result = oldReference?.find((item, i) => {
        return Object.keys(item)?.find((key) => {
            // Convert to string for side effects caused by rare values (e.g. NaN)
            if (String(item[key]) === String(newReference[i][key])) {
                // matching
                return false;
            } else {
                // not matching
                return true;
            }
        });
    });
    // result being undefined would mean both references had matching values
    return result !== undefined
}

export const compareArrays = (oldReference, newReference) => {
    // if the lengths aren't the same then these are different values
    if (oldReference?.length !== newReference?.length) return true;
    const result = oldReference?.find((item, i) => {
        if (item === newReference[i]) {
            // matching
            return false;
        } else {
            // not matching
            return true;
        }
    });
    // result being undefined would mean both references had matching values
    return result !== undefined
}

export const compareObjects = (oldReference, newReference) => {
    if (newReference === undefined) return false;
    if (oldReference === undefined) return true;

    // if the lengths aren't the same then these are different values
    if (Object.keys(oldReference)?.length !== Object.keys(newReference)?.length) return true;
    const result = Object.keys(oldReference)?.find((key) => {
            if (String(oldReference[key]) === String(newReference[key])) {
                // matching
                return false;
            } else {
                // not matching
                return true;
            }
    });

    // result being undefined would mean both references had matching values
    return result !== undefined
}