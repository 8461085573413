import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import SubmitBtn from '../SubmitBtn';
import config from '../../config';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useStore();

    const [email, setEmail] = useState(state?.auth.username);
    const [password, setPassword] = useState(state?.auth.tempPass);
    const [newPassword, setNewPassword] = useState('');
    const [invalidLogin, setInvalidLogin] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        // Check that new password is at least 8 characters and includes 1 number
        const re = /[0-9]/;
        const containsNum = re.test(newPassword);

        if (newPassword.length < 8 || !containsNum) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Password must be at least 8 characters and contain a number', isError: true}});
            console.log('called');
            return;
        }

        // Log in
        dispatch({type: ACTIONS.AUTH, payload: {...state?.auth, loading: true}});
        try {
            const res = await fetch(`${config.base_api}/auth/verifyEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username: email, password, newPassword}),
            });

            const data = await convertJSON(res);
            
            // If incorrect username or password is entered perform following actions
            if (data.error === 'Invalid username or password') {
                setInvalidLogin(true);
                dispatch({type: ACTIONS.AUTH, payload: {...state?.auth, loading: false}});
                dispatch({ type: ACTIONS.TOAST, payload: {message: 'Invalid username or password', isError: true}});
                return;
            }

            //
            // Handle succesful login
            //

            setInvalidLogin(false);

            const accessToken = data.accessToken?.jwtToken;
            const refreshToken = data.refreshToken?.token;
            const username = data.idToken.payload.email;
            const isAdmin = null;

            // Set tokens in local storage
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('username', username);

            // Store auth items in state
            dispatch({type: ACTIONS.AUTH, payload: {
                loggedIn: true,
                loading: false,
                accessToken,
                refreshToken,
                username,
                isAdmin,
            }});
            dispatch({ type: ACTIONS.TOAST, payload: {message: `Succesfully logged in ${data.idToken?.payload?.email}`}});

            // Send back to homepage
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="auth auth-verifyEmail">
            <h1>Verify Account</h1>
            <p>Please create a new password to verify your account.</p>
            {invalidLogin && <p>Invalid email or password</p>}
            <form onSubmit={onSubmit}>
                <label htmlFor="email">Email Address: </label>
                <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <br />
                <label htmlFor="password">Old Password: </label>
                <input type="text" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <br />
                <label htmlFor="newPassword">New Password: </label>
                <input
                    type="text"
                    name="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <p>New password must be at least 8 characters and include a number.</p>
                <SubmitBtn title="Login" />
            </form>
        </div>
    );
};

export default VerifyEmail;
