import React from 'react';
import formatDate from '../../utilities/formatDate';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import searchIcon from '../../assets/search.svg';
import trashIcon from '../../assets/trashIcon.svg';
import uploadIcon from '../../assets/upload.svg';
import xIcon from '../../assets/xicon.svg';
import pdfIcon from '../../assets/pdf-icon.png'
import LoadingInline from '../../components/LoadingInline';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const Images = ({ projectID, showImage, setShowImage, imageArray, logImagesUpdate, connectImageData, loading, headers, loadingImage, leftCol, taxExemption = false }) => {
    const { state, dispatch } = useStore();

    const handleDeleteImage = async (event) => {
        let eventID = Number(event.target.id);
        let attachableID = event.target.dataset?.attachbleId;

        let taxExemptionOption = taxExemption ? { taxExemption } : {};

        let deletedImage = {
            projectID: projectID,
            imageName: event.target.name,
            ...taxExemptionOption,
        }

        try {
            let qbRes = '';
            if (taxExemption) {
                qbRes = await fetch(`${config.base_api}/quickbooks/syncProducts/authURI/detatchTaxExemption_|_${attachableID}`, { headers });
            }

            if (qbRes.ok) {
                const res = await fetch(`${config.base_api}/images/delete/${eventID}`, {
                    method: 'DELETE',
                    headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                    body: JSON.stringify(deletedImage)
                })

                await convertJSON(res);

                if (res.ok) {
                    console.log("image deleted");
                    document.getElementById(`file-input-${taxExemption ? 'tax-exemption' : 'image'}`).value = ''; // Resetting the input field value after successful upload


                    dispatch({
                        type: ACTIONS.TOAST, payload: {
                            message: 'Image deleted successfully',
                            isError: false,
                        }
                    });
                    logImagesUpdate();
                    connectImageData();
                }
            } else {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Could not detatch image from customer in QuickBooks.',
                        isError: true,
                    }
                });
            }
        } catch (error) {
            console.log(error)
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not delete image',
                    isError: true,
                }
            });
        }
    }

    function handleViewImage(event) {
        let imageLink = event.target.id

        let imageType = ((imageLink).substring(imageLink.length - 3))
        if (imageType === "pdf") {
            setShowImage({ status: true, url: imageLink, type: "pdf" });
        } else {
            setShowImage({ status: true, url: imageLink, type: "image" });
        }
    }

    function handleModal() {
        setShowImage({ status: false, url: "" })
    }

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectID", projectID);

        try {
            const res = await fetch(`${config.base_api}/images`,
                {
                    method: 'POST',
                    headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken, "formData"),
                    body: formData
                })
            if (res.ok) {
                let response = await convertJSON(res);

                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Image uploaded successfully',
                        isError: false,
                    }
                });

                console.log("image uploaded")
                logImagesUpdate();
                connectImageData();
            } else if (res.status === 500) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Could not upload image, internal server error',
                        isError: true,
                    }
                });
            } else if (res.status === 400) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Image with the same name already exists.',
                        isError: true,
                    }
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not upload image, please check file size and type',
                    isError: true,
                }
            });
            console.log(error)
        }
    }

    const handleTaxExemptionUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectID", projectID);
        formData.append("taxExemption", true);
        let contactName = state?.quote?.firstname + ' ' + state?.quote?.lastname;
        formData.append("contactName", contactName);

        try {
            const res = await fetch(`${config.base_api}/images`,
                {
                    method: 'POST',
                    headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken, "formData"),
                    body: formData
                })
            if (res.ok) {
                const response = await convertJSON(res);

                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Tax exemption uploaded successfully',
                        isError: false,
                    }
                });

                let qbRes = await fetch(`${config.base_api}/quickbooks/syncProducts/authURI/taxExemption_|_${state?.quote?.firstname}_|_${state?.quote?.lastname}_|_${response?.id}_|_${response?.image_name}`, { headers });

                logImagesUpdate();
                connectImageData();
                console.log(qbRes);
                const newRes = await convertJSON(qbRes);
                console.log(newRes)

            } else if (res.status === 500) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Could not upload tax exemption, internal server error',
                        isError: true,
                    }
                });
            } else if (res.status === 400) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Tax exemption with the same name already exists.',
                        isError: true,
                    }
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not upload tax exemption, please check file size and type',
                    isError: true,
                }
            });
            console.log(error)
        }
    }

    if (loading || loadingImage) {
        return (<div className='component-card width-500px pad-b-xxxlg min-height-265px'>
            <p className='text-lrg text-bold pad-l-xxxlg pad-t-lg margin-l-sm margin-b-xxlg'>{taxExemption ? "Tax Exemption" : "Images"}</p>
            <LoadingInline />
        </div>);
    }

    return (
        <>
            <div className={`component-card min-height-265px ${leftCol ? 'max-width-550px' : 'max-width-500px'}`}>
                <div className='flex justify-between'>
                    <p className='text-lrg text-bold pad-l-xlg pad-t-lg margin-l-sm margin-b-md'>
                        {taxExemption ? "Tax Exemption" : "Images"}
                    </p>
                    <div
                        className="darken-background b2-green border-radius-xs box-shadow text-green text-bold margin-t-lg margin-r-xxlg pad-xs flex align-center width-30 pointer">
                        <input type="file" id={`file-input-${taxExemption ? 'tax-exemption' : 'image'}`} onChange={async (e) => {
                                if (taxExemption) await handleTaxExemptionUpload(e);
                                else await handleUpload(e)
                            }} accept=".png,.jpg,.jpeg ,.pdf" hidden></input>
                        <label htmlFor={`file-input-${taxExemption ? 'tax-exemption' : 'image'}`} className="width-100 flex align-center justify-even pointer">
                            Upload
                            <img src={uploadIcon} alt="upload icon" />
                        </label>
                    </div>
                </div>

                <div className='overflow-scroll height-200px'>
                    <table className='width-100 border-collapse'>
                        <thead className='text-bold underline-black width-100'>
                            <tr>
                                <td className='td-60'></td>
                                <td className='td-100'>Date</td>
                                <td className='td-150'>File</td>
                                <td className='td-150'></td>
                            </tr>
                        </thead>
                        {imageArray?.length > 0 ? imageArray?.map((image, index, array) => (
                            <tbody className={index === array.length - 1 ? '' : 'underline-black'} key={image.id}>
                                <tr>
                                    <td className='pad-l-md td-60'>
                                        <img src={trashIcon} className='sm-box pointer' alt="trash icon nccaanb" key={`${image?.id}-delete-${taxExemption ? 'tax-exemption' : 'image'}`} id={image.id} data-attachble-id={image?.qb_attachable_id} name={image.image_name} onClick={async (e) => {
                                            await handleDeleteImage(e);
                                        }} />
                                    </td>
                                    <td className='pad-t-lg pad-b-lg text-small text-start td-100'>{formatDate(image.date || '').replace(/-/g, "/")}</td>
                                    <td className='text-small td-150 flex align-center pad-t-sm'>
                                        {image.type ?
                                            <img src={image?.image_link}
                                                alt="thumbnail"
                                                className="thumbnail pad-r-xs"
                                            />
                                            :
                                            <img
                                                src={pdfIcon}
                                                alt="thumbnail"
                                                className="thumbnail pad-r-xs"
                                            />
                                        }
                                        {image.image_name}
                                    </td>
                                    <td align='right' className='pad-r-xxlg td-150 justify-end height-100 align-center pad-t-sm pad-b-sm'>
                                        <button onClick={handleViewImage} id={image?.image_link} key={`${image?.id}-view-${taxExemption ? 'tax-exemption' : 'image'}`}
                                            className="flex align-center justify-center bg-white border-radius-xs border-green text-green pad-xs pointer">
                                            <img id={image?.image_link} src={searchIcon} className='width-40 pad-r-xs pointer' alt="search icon" />
                                            View
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        ))
                            :
                            <tbody className='text-center'>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td className='pad-lg'>{taxExemption ? "No Tax Exemptions" : "No images"}</td>
                                    <td></td>
                                </tr>
                            </tbody>}
                    </table>
                </div>
            </div>
            <div className={`${showImage.status ? "modal b-none border-radius-xs" : "hide"}`}>
                <button onClick={handleModal} className='bg-white b-none close-button'>
                    <img src={xIcon} alt="close button" />
                </button>
                {showImage.type === "image" ?
                    <img src={showImage.url}
                        alt="placeholder"
                        className="resize-image" />
                 :
                    (showImage.url?.length > 0)
                        ? <iframe
                            src={`https://docs.google.com/gview?url=${showImage.url}&embedded=true`}
                            className="width-100 height-100"
                            title="file">
                        </iframe>
                        : null
                }
            </div>
            <div id="page-mask"
                className={`${showImage.status ? "" : "hidden"}`}></div>
        </>
    )
}

export default Images