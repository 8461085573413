import React from 'react';
import loadingGif from '../assets/loading.gif';

const LoadingInline = ({title = 'Loading', id = '', dataTestId = '', size = 'h5', iconSize = '', bold = false, cacheBusting = false}) => {
    return (
        <div id={id} data-testid={dataTestId || id} className={"loading_inline" + (cacheBusting ? ' height-100vh width-100vw flex justify-center align-center' : '')}>
            {size === 'h5' ? <h5 className={"loading_inline_title" + (bold ? 'bold' : '')}>{title}</h5> : null}
            {size === 'h4' ? <h4 className={"loading_inline_title" + (bold ? 'bold' : '')}>{title}</h4> : null}
            {size === 'h3' ? <h3 className={"loading_inline_title" + (bold ? 'bold' : '')}>{title}</h3> : null}
            {size === 'h2' ? <h2 className={"loading_inline_title" + (bold ? 'bold' : '')}>{title}</h2> : null}
            {size === 'h1' ? <h1 className={"loading_inline_title" + (bold ? 'bold' : '')}>{title}</h1> : null}
            {size === 'text-sm' ? <p className={"loading_inline_title text-sm" + (bold ? 'bold' : '')}>{title}</p> : null}
            {size === 'text-xs' ? <p className={"loading_inline_title text-xs" + (bold ? 'bold' : '')}>{title}</p> : null}
            <img className={iconSize} src={loadingGif} />
        </div>
    );
};

export default LoadingInline;
