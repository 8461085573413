import React, { useEffect } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import convertJSON from '../../utilities/convertJSON';

const ShippingContactSelect = ({
    setShippingContact,
    shippingContactID,
    setShippingContactID,
    shippingContactsSelectedArr,
    setShippingContactsSelectedArr,
    setIsNewShippingContact,
    billingContact,
    setBillingContact,
    getContactDetails,
    quote,
    shippingContactMissing
}) => {
    // Populate state with search contact query
    // Populates data list "contact-results-list"
    const { state } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const populateShippingContactsSelect = async (e) => {
        console.log('selected shipping contact');
        setBillingContact({
            ...billingContact,
            sameShippingAddress: false,
            sameBillingContact: false,
        });
        try {
            // Send query to our API which gets data from hubspot
            const res = await fetch(`${config.base_api}/hubspot/contacts/selectOptionValues/${e.target.value}`, {
                headers,
            });

            if (!res.ok) {
                return;
            }

            let data = await convertJSON(res);
            // Build object with id and name field
            data = data.map((contact) => ({
                id: contact.id,
                name: `${contact.firstname} ${contact.lastname}`,
            }));

            // data list "contact-results-list" maps through this state value
            setShippingContactsSelectedArr(data);

            // Need to set state contactSelected with id
            // However datalist only has data.name in option value
            // Get id by matching name
            let contactId = data.filter((contact) => contact.name === e.target.value);

            // If contactId is a match set as state - which will get contact information and fill in updateContactInfo with values
            if (contactId.length !== 0) {
                contactId = contactId[0].id;
                setShippingContactID(contactId);
            } else {
                // If blank or no match create new contact
                setShippingContactID('create');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // When contact select is changed
    // Populate updateContactInfo with selected contact values
    useEffect(() => {
        try {
            if (shippingContactID === 'create' && quote.quote_number) {
                if (quote.shipping_contactid == null) {
                    return
                }
                setShippingContactID(quote.shipping_contactid);
            }

            if (shippingContactID === 'create' && !quote.quote_number) {
                setIsNewShippingContact(true);
                // Clear drop down state for better UI
                setShippingContact({
                    shipping_firstname: '',
                    shipping_lastname: '',
                    shipping_email: '',
                    shipping_phone: '',
                    shipping_mobile: '',
                    shipping_company: '',
                    shipping_address: '',
                    shipping_city: '',
                    shipping_state: '',
                    shipping_zip: '',
                });
            } else {
                setIsNewShippingContact(false);
                console.log('UseEffect Shipping Contact Select. getting contact details for:', shippingContactID);
                // Sets state with contact details
                getContactDetails('shipping');
            }
        } catch (error) {
            console.log(error);
        }
    }, [shippingContactID]);

    return (
        <div className="hubspotContact_select flex-column">
            <div className="flex-row align-center margin-b-xs justify-center">
                <h3 className="margin-b-none">Select Shipping Contact</h3>
                <div>
                    {/* Drop down hubspot contact select */}
                    <input
                        list="shipping-contact-results-list"
                        id="contact-results-input"
                        name="contact-results-input"
                        className="icon-rtl width-fit-content pad-xs border-radius-sm"
                        placeholder="Search for a contact"
                        onChange={populateShippingContactsSelect}
                        disabled={quote.readOnly}
                    />
                    <datalist id="shipping-contact-results-list">
                        {shippingContactsSelectedArr.map((contact, i) => (
                            <option value={contact.name} key={i} />
                        ))}
                    </datalist>
                </div>
            </div>
            {shippingContactMissing && (
                <div className="contact-missing flex justify-center">
                    <p className="text-red">
                        <strong>Warning:</strong> This contact is missing required fields. Please update the contact
                        before proceeding.
                    </p>
                </div>
            )}
        </div>
    );
};

export default ShippingContactSelect;
