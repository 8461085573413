import React, { useState, useEffect } from 'react';

const CellSku = ({ sku, product }) => {
    const [freightWarning, setFreightWarning] = useState(false);

    useEffect(() => {
        if (product?.has_free_freight) {
            setFreightWarning(false);
        } else if (product?.manufacturer === 'KidsTale Playgrounds' || product?.manufacturer === 'KidsTale Inc' || product?.manufacturer === 'Barks and Rec' || product?.manufacturer === 'Sports Play') {
            if (product?.is_palette != true && product?.crates < 1) {
                setFreightWarning(true);
            }
        }
    }, [product]);

    return (
        <td key="sku" className="col-sku">
            <p className='text-center'>{sku}
                {freightWarning &&
                    <span className="tooltip tooltip-icon vertical-align-middle">
                        <span className="tooltiptext double-orange-border line-height-sm product-tooltip product-tooltiptext">
                            This product is missing palette or crate information that may affect freight estimations.
                        </span>
                    </span>
                }
            </p>
        </td>
    );
};

export default CellSku;
