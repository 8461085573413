import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import EmailForm from '../components/sendEmail/EmailForm';
import Attachments from '../components/sendEmail/Attachments';
import SalesPersonInfo from '../components/sendEmail/SalesPersonInfo';
import HubspotDeal from '../components/sendEmail/HubspotDeal';
import LoadingInline from '../components/LoadingInline';
import {saveQuote} from '../components/quote/SaveQuote';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import {quoteToObject} from '../utilities/formatQuote';

const SendEmail = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const navigate = useNavigate();

    const quoteNumber = window.location.pathname?.split('/sendEmail/')[1];
    const quoteID = quoteNumber?.substring(7);

    const [emailState, setEmailState] = useState({});
    const [loading, setLoading] = useState(false);
    const [createHubspotDealBool, setCreateHubspotDealBool] = useState(true);

    // Send out email function
    // Pass as prob to EmailForm - will be onsubmit function
    const sendOutEmail = async (e) => {
        e.preventDefault();
        setLoading(true);
        // If createhubspotDealBool is set to true - create hubspot deal before sending out email.
        if (state?.quote?.is_primary_quote) {
            await createHubspotDeal();
        }

        try {
            const res = await fetch(`${config.base_api}/quote/sendQuote`, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    emailValues: emailState,
                    quote: state?.quote,
                    cutSheets: state?.includeSheets,
                }),
            });

            await convertJSON(res);

            if (!res.ok) {
                dispatch({type: ACTIONS.TOAST, payload: {message: 'Email failed to send', isError: true }});
            } else {
                dispatch({type: ACTIONS.TOAST, payload: {
                    message: 'Email sent',
                    isError: false,
                }});
            }

            dispatch({type: ACTIONS.QUOTE, payload: {...state?.quote, readOnly: true, autoSave: !state?.quote?.autoSave}});
        } catch (error) {
            console.log(error);
            dispatch({type: ACTIONS.TOAST, payload: {
                message: 'Could not send email - Check email addresses are correct',
                isError: true,
            }});
        } finally {
            setLoading(false);
        }
    };

    async function createHubspotDeal() {
        try {
            const res = await fetch(`${config.base_api}/quote/createDeal`, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    cutSheets: state?.includeSheets,
                    quote: state?.quote,
                }),
            });
            // if (!res.ok) throw data.error;
            const data = await convertJSON(res);
            // Succesfully created deal
            console.log('Created Hubspot deal');
            dispatch({type: ACTIONS.TOAST, payload: {
                message: 'Created deal in hubspot',
                isError: false,
            }});
        } catch (error) {
            dispatch({type: ACTIONS.TOAST, payload: {
                message: 'Could not create hubspot deal',
                isError: true,
            }});
            console.log(error);
        }
    }

    // Populate form email state with values from quote data and values from email data table
    // Immediately populate email state with quote data because its immediately available
    // When waiting for email data from db api call, set those values to loading, then update when api call is complete
    const populateValues = async () => {
        setEmailState({
            to: state?.quote?.email,
            bcc: state?.quote?.prepared_by_email,
            subject: 'loading...',
            body: 'loading...',
            signature: 'loading...',
        });

        let subject, body, signature;

        try {
            const res = await fetch(`${config.base_api}/email`, {method: 'GET', headers});
            const values = await convertJSON(res);

            subject = values.subject;
            body = parseBodyTemplate(values.body, state?.quote?.firstname);
            signature = parseSignatureTemplate(values.signature, state?.quote?.prepared_by_name, state?.quote?.prepared_by_phone);
        } catch (error) {
            console.log(error);
            subject = '';
            body = '';
            signature = '';
        }

        setEmailState((prevState) => ({
            ...prevState,
            subject,
            body,
            signature,
        }));
    };

    useEffect(() => {
        if (quoteID) {
            dispatch({type: ACTIONS.QUOTE, payload: emptyQuoteObj});
        
            (async () => {
                try {
                    let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, {headers});
                    if (!quoteData.ok) throw 'Could not get quote';
                    quoteData = await convertJSON(quoteData);
                    const formattedQuote = quoteToObject(quoteData);
                    dispatch({ type: ACTIONS.QUOTE, payload: {...state?.quote, ...formattedQuote}});
                    if (state?.quote?.email && state?.quote?.firstname) populateValues();

                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, []);

    useEffect(() => {
        if (state?.quote?.email && state?.quote?.firstname) populateValues();
    }, [state?.quote?.email, state?.quote?.firstname]);

    // Save quote after it's finished being marked as read only
    useEffect(() => {
        const fetchData = async () => {
            if (state?.quote?.id > 0 && state?.quote?.products?.length > 0) {
                await saveQuote(state?.auth, state?.quote, quoteID, ACTIONS, dispatch);
            }
        }

        fetchData();
    }, [state?.quote?.readOnly]);

    return (
        <div className="sendEmail">
            <h1>Send email</h1>
            <div className="sendEmail_grid">
                <EmailForm
                    sendOutEmail={sendOutEmail}
                    emailState={emailState}
                    setEmailState={setEmailState}
                    createHubspotDealBool={createHubspotDealBool}
                    setCreateHubspotDealBool={setCreateHubspotDealBool}
                />
                <section className="sendEmail_rightColumn">
                    <Attachments />
                    <SalesPersonInfo />
                    <HubspotDeal />
                    {loading && <LoadingInline />}
                    {/*Used for testing purposes*/}
                    {/*<Btn title ="Create Hubspot Deal" onClick={e => createHubspotDeal()}/>*/}
                </section>
            </div>{' '}
            {/* End grid*/}
        </div>
    );
};

// Replaces FIRST_NAME in email body value (default message store in our database), with first name of customer from quote
function parseBodyTemplate(text, firstname) {
    return text.replace(/FIRST_NAME/, firstname);
}

// Replaces FIRST_NAME and LAST_NAME in email signature value (default message store in our database), with salesperson information from quote
function parseSignatureTemplate(text, name, phone) {
    text = text.replace(/SALES_REP_NAME/, name);
    text = text.replace(/SALES_REP_EXTENSION/, phone);
    return text;
}

export default SendEmail;
