import React, { useEffect } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import Modal from '../Modal';
import Btn from '../Btn';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const UpdateModal = ({
    preparedBy,
    setShowUpdateModal,
    billingContact,
    shippingContact,
    updateModalType,
    shippingContactID,
    billingContactID,
    quote,
    setBillingContactMissing,
    setShippingContactMissing
}) => {
    const { state, dispatch } = useStore();

    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    // Update Contact - hubspot
    // Update contact information in quote if quote already exists

    const updateContact = async (e) => {
        let updateInfo = createUpdateRequest();
        let id;
        if (updateModalType == 'Shipping Contact') {
            if (billingContact.sameBillingContact) {
                if (quote.shipping_contactid == null) {
                    dispatch({
                        type: ACTIONS.QUOTE,
                        payload: {
                            ...quote,
                            shipping_contactid: billingContactID,
                            shipping_firstname: shippingContact.shipping_firstname,
                            shipping_lastname: shippingContact.shipping_lastname,
                            shipping_email: shippingContact.shipping_email,
                            shipping_company: shippingContact.shipping_company,
                            shipping_phone: shippingContact.shipping_phone,
                            shipping_mobile: shippingContact.shipping_mobile,
                            shipping_address: shippingContact.shipping_address,
                            shipping_city: shippingContact.shipping_city,
                            shipping_state: shippingContact.shipping_state,
                            shipping_zip: shippingContact.shipping_zip,
                        },
                    });
                }
                id = billingContactID;
            } else {
                if (quote.shipping_contactid == null) {
                    dispatch({
                        type: ACTIONS.QUOTE,
                        payload: {
                            ...quote,
                            shipping_contactid: shippingContactID,
                            shipping_firstname: shippingContact.shipping_firstname,
                            shipping_lastname: shippingContact.shipping_lastname,
                            shipping_email: shippingContact.shipping_email,
                            shipping_company: shippingContact.shipping_company,
                            shipping_phone: shippingContact.shipping_phone,
                            shipping_mobile: shippingContact.shipping_mobile,
                            shipping_address: shippingContact.shipping_address,
                            shipping_city: shippingContact.shipping_city,
                            shipping_state: shippingContact.shipping_state,
                            shipping_zip: shippingContact.shipping_zip,
                        },
                    });
                }
                id = shippingContactID;
            }
        }
        if (updateModalType == 'Billing Contact') {
            id = billingContactID;
            dispatch({
                type: ACTIONS.QUOTE,
                payload: {
                    ...quote,
                    firstname: billingContact.firstname,
                    lastname: billingContact.lastname,
                    email: billingContact.email,
                    phone: billingContact.phone,
                    mobile: billingContact.mobile,
                    company: billingContact.company,
                    address: billingContact.address,
                    city: billingContact.city,
                    state: billingContact.state,
                    zip: billingContact.zip,
                },
            });
        }

        try {
            // Update contact on hubspot
            const hubRes = await fetch(`${config.base_api}/hubspot/contacts/id/${id}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(updateInfo),
            });
            const hubData = await convertJSON(hubRes);

            if (!hubRes.ok) throw hubData.error;

            // If shipping contact was updated, change updateInfo params
            if (updateModalType == 'Shipping Contact') {
                updateInfo = setUpdateBodyShipping(updateInfo);
                setShippingContactMissing(false);
            } else {
                updateInfo.mobile = billingContact.mobile;
                setBillingContactMissing(false);
            }

            // If current quote for contact exists in our data table
            // Update contact information in quote data table
            if (quote.id) {
                const quoteRes = await fetch(`${config.base_api}/quote/updateContactInfo/${quote.id}`, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify(updateInfo),
                });

                const data = await convertJSON(quoteRes);

                // Set quote state to post request response
                // Get key values;
                const {
                    id,
                    hubspot_contact_id,
                    quote_number,
                    firstname,
                    lastname,
                    phone,
                    mobile,
                    email,
                    company,
                    address,
                    city,
                    state,
                    zip,
                    shipping_firstname,
                    shipping_lastname,
                    shipping_email,
                    shipping_phone,
                    shipping_mobile,
                    shipping_address,
                    shipping_city,
                    shipping_state,
                    shipping_zip,
                    prepared_by_hubspot_id,
                    prepared_by_name,
                    prepared_by_email,
                    prepared_by_phone,
                    is_primary_quote,
                    createdAt,
                    shipping_contactid,
                } = data;

                // Update our state to track quote creation
                dispatch({
                    type: ACTIONS.QUOTE,
                    payload: {
                        ...quote,
                        id,
                        hubspot_contact_id,
                        quote_number,
                        firstname,
                        lastname,
                        phone,
                        mobile,
                        email,
                        company,
                        address,
                        city,
                        state,
                        zip,
                        shipping_firstname,
                        shipping_lastname,
                        shipping_email,
                        shipping_phone,
                        shipping_mobile,
                        shipping_address,
                        shipping_city,
                        shipping_state,
                        shipping_zip,
                        prepared_by_hubspot_id,
                        prepared_by_name,
                        prepared_by_email,
                        prepared_by_phone,
                        is_primary_quote,
                        createdAt,
                        shipping_contactid,
                    },
                });
            }
            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Contact updated' } });
            setShowUpdateModal(false);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Contact could not be updated', isError: true } });
            // throw 'Could not update contact';
        }
    };


    // function to update shipping information on quote update request
    function setUpdateBodyShipping(updateInfo) {
        let shippingInfo = {
            shipping_firstname: updateInfo.firstname,
            shipping_lastname: updateInfo.lastname,
            shipping_email: updateInfo.email,
            shipping_phone: updateInfo.phone,
            shipping_mobile: updateInfo.mobilephone,
            shipping_company: updateInfo.company,
            shipping_address: updateInfo.shipping_address,
            shipping_city: updateInfo.shipping_city,
            shipping_state: updateInfo.shipping_state,
            shipping_zip: updateInfo.shipping_zip,
            prepared_by_hubspot_id: preparedBy.prepared_by_hubspot_id,
            prepared_by_name: preparedBy.prepared_by_name,
            prepared_by_email: preparedBy.prepared_by_email,
            prepared_by_phone: preparedBy.prepared_by_phone,
            shipping_contactid: updateInfo.shipping_contactid
        };
        return shippingInfo;
    }



    // Sort through all edge cases to make sure we don't lose any data on our updates
    function createUpdateRequest() {
        let updateInfo;
        if (updateModalType == 'Billing Contact') {
            // if update billing contact selected, set updateinfo for the billing contact only
            updateInfo = {
                firstname: billingContact.firstname,
                lastname: billingContact.lastname,
                email: billingContact.email,
                phone: billingContact.phone,
                mobilephone: billingContact.mobile,
                company: billingContact.company,
                address: billingContact.address,
                city: billingContact.city,
                state: billingContact.state,
                zip: billingContact.zip,
                prepared_by_hubspot_id: preparedBy.prepared_by_hubspot_id,
                prepared_by_name: preparedBy.prepared_by_name,
                prepared_by_email: preparedBy.prepared_by_email,
                prepared_by_phone: preparedBy.prepared_by_phone,
                hubspot_contact_id: billingContactID,
            };
        }
        // if shipping contact is same as billing with unique shipping address
        // add to contact
        if (billingContact.sameBillingContact && !billingContact.sameShippingAddress) {
            updateInfo = {
                ...updateInfo,
                shipping_address: shippingContact.shipping_address,
                shipping_city: shippingContact.shipping_city,
                shipping_state: shippingContact.shipping_state,
                shipping_zip: shippingContact.shipping_zip,
            };
        } else if (updateModalType == 'Shipping Contact') {
            // if it's update shipping contact, set the update info for the contact info
            updateInfo = {
                firstname: shippingContact.shipping_firstname,
                lastname: shippingContact.shipping_lastname,
                email: shippingContact.shipping_email,
                phone: shippingContact.shipping_phone,
                mobilephone: shippingContact.shipping_mobile,
                company: shippingContact.shipping_company,
                shipping_address: shippingContact.shipping_address,
                shipping_city: shippingContact.shipping_city,
                shipping_state: shippingContact.shipping_state,
                shipping_zip: shippingContact.shipping_zip,
                prepared_by_hubspot_id: preparedBy.prepared_by_hubspot_id,
                prepared_by_name: preparedBy.prepared_by_name,
                prepared_by_email: preparedBy.prepared_by_email,
                prepared_by_phone: preparedBy.prepared_by_phone,
                shipping_contactid: shippingContactID,
            };
        }

        return updateInfo;
    }
    return (
        <Modal>
            <div className="modal_deleteQuote">
                <h4>This will overwrite HubSpot Contact Information</h4>
                {updateModalType == 'Billing Contact' ? (
                    <p style={{ fontWeight: 'bold' }}>
                        Are you sure you want to update{' '}
                        <span className="text-blue"> {billingContact.firstname + ' ' + billingContact.lastname} </span>
                        in hubspot?
                    </p>
                ) : (
                    <p style={{ fontWeight: 'bold' }}>
                        Are you sure you want to update{' '}
                        <span className="text-blue">
                            {shippingContact.shipping_firstname + ' ' + shippingContact.shipping_lastname}
                        </span>{' '}
                        in hubspot?
                    </p>
                )}
                <div className="modal_deleteQuote_buttonContainer">
                    <Btn title="Continue" onClick={updateContact} btnClass="btn-green" />
                    <Btn title="Cancel" onClick={(e) => setShowUpdateModal(false)} />
                </div>
            </div>
        </Modal>
    );
};

export default UpdateModal;
