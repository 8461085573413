import React from 'react';
import config from '../config';
import authHeaders from './authHeaders';
import convertJSON from './convertJSON';


export async function addAccessLogEvent(accessLogEvent, headers) {
  
  try {
    const res = await fetch(`${config.base_api}/projects/updateAccessLog`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify(accessLogEvent),
    })
    await convertJSON(res)

    if(!res.ok){
      throw 'Could Not Update Access Log'
    }
  } catch (error){
    console.log(error)
    return error
  }
  return true
}