import emptyQuoteObj from './utilities/emptyQuoteObj';

export const store = {
    appContainerStyle: {
        display: 'flex',
        position: '',
        alignItems: 'center',
        justifyContent: 'center',
    },
    auth: {
        loggedIn: false,
        loading: true,
        isAdmin: localStorage.getItem('isAdmin'),
        isOps: localStorage.getItem('isOps'),
        isSales: localStorage.getItem('isSales'),
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        username: localStorage.getItem('username'),
        tempPass: '',
    },
    backgroundColor: '#f5f8fa',
    contactInfo: {
        sameShippingAddress: true,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        shipping_address: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
    },
    contactSelected: 'create',
    contactsSelectedArr: [
    //  { value: 'create', name: 'Add New Contact' },
    ],
    enableCacheBuster: false,
    filteredList: [],
    includeSheets: [],
    isNewContact: true,
    globalErrorMessage: '',
    loadedPO: false,
    loadingPO: true,
    manufacturers: [],
    navbarQuoteID: '',
    preparedBy: {
        prepared_by_hubspot_id: '',
        prepared_by_name: '',
        prepared_by_phone: '',
        prepared_by_email: '',
    },
    productTableItems: [],
    productVariants: [],
    project: {},
    purchaseOrder: {},
    qbPurchaseOrders: {},
    quote: emptyQuoteObj,
    savedQuotesList: [],
    toast: {
        message: '',
        isError: false,
    },
    updateContactInfo: {
        // id is hubspot_contact_id
        id: '',
        // sameShippingAddress: false,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        shipping_address: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
    },
    updatedPreferredCompletionDate: false,
    versionNumber: '',
}