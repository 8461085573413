import React from 'react';
import {useState, useEffect} from 'react';
import Btn from '../Btn';
import authHeaders from '../../utilities/authHeaders';
import checkmark from '../../../src/assets/checkmark.svg';
import xmark from '../../../src/assets/xmark.svg';
import trashIcon from '../../assets/trashIcon.svg';
import config from '../../config';
import LoadingInline from '../../components/LoadingInline';
import { addAccessLogEvent } from '../../utilities/accessLog';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const PurchaseOrders = ({
  issueOutstanding,
  setIssueOutstanding,
  unitInStock,
  setUnitInStock,
  factoryOrder,
  setFactoryOrder,
  poSubmitted,
  signedPerforma,
  setSignedPerforma,
  arrivalDate,
  setArrivalDate,
  setPoSync,
  loadingQB,
  setFetchData,
})=> {
  const { state, dispatch } = useStore();
  const [searchPoNumber, setSearchPoNumber] = useState('');
  const [changeSync, setChangeSync] = useState(false);

  useEffect(() => {
    setSearchPoNumber(state?.project?.po_project_number);
  }, [state?.project?.po_project_number]);

  useEffect(() => {
    setIssueOutstanding(false)
  }, [state?.project?.preferred_completion]);

  const daysLeft = () => {
    if (!state?.project?.preferred_completion) return 10;
    let prefferedCompletion = state?.project?.preferred_completion;
    prefferedCompletion = prefferedCompletion.split('T')[0];
    prefferedCompletion += 'T06:00:00.000Z'
    prefferedCompletion = new Date(prefferedCompletion)
    prefferedCompletion = prefferedCompletion.setHours(0,0,0,0)
    let today = new Date();
    today = today.setDate(today.getDate() + 3);
    today = new Date(today);
    today = today.setHours(0,0,0,0);
    const diffTime = prefferedCompletion - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const issuesOutstanding = (issue) => {
    if (!issue && daysLeft() <= 0) {
      if (!issueOutstanding) {
        setIssueOutstanding(true);
      }
      return 'b2-red border-radius-sm pad-t-xs pad-l-sm pad-r-xs margin-tb-xxs-negative'
    }
    return 'pad-l-smd pad-t-xs';
  }

  const deletePO = async (poId) => {
    const res = await fetch(`${config.base_api}/projects/purchaseOrder/delete/${poId}`, {
      headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
      method: 'DELETE',
    });
    await convertJSON(res);
    
    if (res.ok) {
      dispatch({ type: ACTIONS.TOAST, payload: {
        message: 'Deleted PO',
        isError: false,
      }});
      const accessLogEvent = {
        project_id: state?.project?.project_id,
        update_message: 'Deleted PO.',
        user: localStorage.getItem('username') || '',
      };
      const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
      await addAccessLogEvent(accessLogEvent, headers);
      setFetchData(true);
    }
  }

  const purchaseOrderTableView = () => {
    if (state?.loadingPO || loadingQB) return (<tr><td></td><td></td><td></td><td><div className='loading-table'><LoadingInline /></div></td></tr>);
    return (
      state?.qbPurchaseOrders.allPos.map((po, index, array) => (
        <tr key={index} className={index === array.length - 1 ? 'issue_row'  : 'issue_row underline-light-gray'} >

            <td className='text-small pad-t-lg pad-b-lg pad-l-lg pad-r-xlg text-center pointer' onClick={() => deletePO(po.refID)}>{<img src = {trashIcon} className = 'sm-box' alt = "trash icon" />}</td>
            <td className='text-small pad-t-lg pad-b-lg pad-l-lg pad-r-xlg '>{po.manufacturer_name}<br/><a target="_blank" href={`${config.qb_base_api}/app/purchaseorder?txnId=${po.qb_po_id}`}>{`PO #${po.project_number}`}</a></td>
            <td className='text-small pad-t-lg pad-b-lg pad-l-md pad-r-xlg text-center'>{(po?.type === 'product' && po.manufacturer_name.toLowerCase().includes('kidstale'))
              ? <select className='pad-xs pad-r-lg border-radius-xs ' onChange={(e) => setUnitInStock(prevState => ({...prevState, [po.refID]: e.target.value}))}>
                <option selected={unitInStock[po.refID] === null} value=''>Select</option>
                <option selected={unitInStock[po.refID] === 'yesNo'} value='yesNo'>Yes/No</option>
                <option selected={unitInStock[po.refID] === true} value={true}>Yes</option>
                <option selected={unitInStock[po.refID] === false} value={false}>No</option>
              </select>
              : <span className='height-1em pad-r-xs letter-spacing-1px text-med-gray'>N/A</span>

            }</td> 
            <td className='text-small pad-t-lg pad-b-lg pad-l-md text-center'>{(po?.type === 'product' && po.manufacturer_name.toLowerCase().includes('kidstale'))
              ? <select className='pad-xs pad-r-lg border-radius-xs' onChange={(e) => setFactoryOrder(prevState => ({...prevState, [po.refID]: e.target.value}))}>
                <option selected={factoryOrder[po.refID] === null} value=''>Select</option>
                <option selected={factoryOrder[po.refID] === 'yesNo'} value='yesNo'>Yes/No</option>
                <option selected={factoryOrder[po.refID] === true} value={true}>Yes</option>
                <option selected={factoryOrder[po.refID] === false} value={false}>No</option>
              </select>
              : <span className='height-1em pad-r-xs letter-spacing-1px text-med-gray'>N/A</span>
            }</td>
            <td className='text-small pad-t-lg pad-b-lg pad-r-md'>{
              <div className={`${issuesOutstanding(poSubmitted[po.refID])} flex align-center justify-center`}>
                {poSubmitted[po.refID]
                ? <span className='table-cell text-end pad-r-xs '><img src={checkmark} className='text-xs height-1dot3em' /></span>
                : <span className='table-cell text-end pad-r-xs'><img src={xmark} className=' text-xs height-1dot5em' /></span>

              }
              </div>
            }</td>                      
            <td className='text-small pad-t-lg pad-b-lg pad-l-md '>{ po.manufacturer_name.toLowerCase().includes('kidstale')
              ? <div className={`${issuesOutstanding(signedPerforma[po.refID])}`} 
                onClick={() => setSignedPerforma(prevState => ({...prevState, [po.refID]: !signedPerforma[po.refID]}))} 
                >
                <label htmlFor={"signedPerforma-" + index} className='checkbox-container margin-b-xs'
                >Done?
                  <input type="checkbox" id={"signedPerforma-" + index} className="custom-checkbox" disabled
                    value={signedPerforma[po.refID]}/>
                  <span className='checkmark'><img src={checkmark} className={signedPerforma[po.refID] ? null : 'hide'} /></span>
                </label>
              </div>
              : <div className='text-center'><span className='height-1em pad-r-xs letter-spacing-1px text-med-gray text-center'>N/A</span></div>
            }</td>
            <td className='text-small pad-t-lg pad-b-lg pad-l-md '>{(po?.type === 'installation' || po?.manufacturer_name?.toLowerCase()?.includes('kidstale'))
              ? <div className='text-center'><span className='height-1em pad-r-xs letter-spacing-1px text-med-gray'>N/A</span></div>
              : <input id={"arrivalDate-" + index} className="pad-xs border-radius-sm" type="date" value={arrivalDate[po.refID]?.slice(0,10)} onChange={(e) => setArrivalDate(prevState => ({...prevState, [po.refID]: e.target.value}))} />
            }</td>
            <td className='text-small pad-t-lg pad-b-lg pad-l-md '><input className="pad-xs border-radius-sm min-width-450px" type="text" value={po?.note} disabled /></td>
        </tr>
      ))
    )
  }

  const syncPO = () => {
    if (loadingQB || state?.loadingPO) {
      return (<LoadingInline title='' />);
    } else if (state?.project?.po_project_number && !changeSync) {
      return (
        <>
          <p className='bold margin-r-sm margin-l-xxlg vertical-middle'>{state?.project?.po_project_number}</p>
          <Btn btnClass='btn-blue text-white b-none bolder box-shadow pad-md pad-l-xxlg pad-r-xxlg margin-r-md' 
          title='Change' onClick = {() => setChangeSync(true)}/>
        </>
      )
    } 
    return (
      <>
        <input className='pad-xs pad-r-lg margin-r-sm margin-l-xxlg border-radius-xs' type="text" placeholder="Search Project Number" value={searchPoNumber} onChange={(e) => setSearchPoNumber(e.target.value)}/>
        <Btn btnClass={(searchPoNumber?.length > 7 ? 'bg-white text-green b2-green text-bold darken-background box-shadow' : 'btn-disabled') + ' pad-sm pad-l-xxlg pad-r-xxlg margin-r-md' }
          disabled={searchPoNumber?.length > 7 ? false : true}
          title='Sync' 
          onClick = {async () => {
            await setPoSync(searchPoNumber);
            setChangeSync(false);
          }}/>
      </>
    );
  }

  return (
    <div className='flex flex-column project-box width-100'>
        <div className='flex justify-between underline-light-gray pad-b-sm pad-l-lg'>
            <p className='text-lrg text-bold'>2. POs Status </p>
            <div className='flex'>
              {syncPO()}
            </div>
        </div>
        <div className='width-100 '>
            {
            state?.qbPurchaseOrders?.allPos?.length > 0 || state?.loadingPO || loadingQB
            ?
                <table className='width-100 border-collapse'>
                <thead>
                    <tr className='underline-light-gray'>
                    <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'></td>
                    <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>Manufacturer<br/>Name (PO #)</td>
                    <td className='text-bold text-left pad-l-md pad-r-xlg pad-t-sm pad-b-sm'>Unit in Stock?</td>
                    <td className='text-bold text-left pad-l-md pad-r-xlg pad-t-sm pad-b-sm'>Factory Order?</td>
                    <td className='text-bold text-left pad-l-md pad-r-xlg pad-t-sm pad-b-sm '>PO<br/>Submitted?</td>
                    <td className='text-bold text-left pad-l-md pad-r-xlg pad-t-sm pad-b-sm '>Signed<br/>Performa?</td>
                    <td className='text-bold pad-l-md pad-r-xlg pad-t-sm pad-b-sm text-center'>Arrival Date</td>
                    <td className='text-bold text-left pad-l-md pad-r-xlg pad-t-sm pad-b-sm '>Notes</td>
                    </tr>
                </thead>
                <tbody className='height-100 '>
                  {purchaseOrderTableView()}
                        
                </tbody>
                </table>
            :
                <div className='flex justify-center font-med pad-md '>
                No POs Recorded Yet
                </div>  
            }
        </div>
      </div>
    );
}

export default PurchaseOrders;