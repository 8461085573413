import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Btn from '../../Btn';
import config from '../../../config';
import authHeaders from '../../../utilities/authHeaders';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';
import convertJSON from '../../../utilities/convertJSON';

const Installer = ({freightRules}) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const installer = {
        title: 'Installer Cost',
        names: ['ID', 'Percentage of Cost', 'Trip Charge', 'Installer Markup'],
        values: [
            {
                id: 'installer_cost',
                value: freightRules.installer_cost,
            },
            {
                id: 'trip_charge',
                value: freightRules.installer_trip_charge,
            },
            {
                id: 'installer_markup',
                value: freightRules.installer_markup,
            },
        ],
    };
    const colSpanLength = installer.names.length;

    let idArray = [];

    for (let i = 0; i < installer.values.length; i++) {
        let ID = installer.values[i].id;
        idArray.push(ID);
    }
    let putBody = {};

    const putInput = async () => {
        for (let e = 0; e < idArray.length; e++) {
            var inputID = idArray[e];
            var inputValue = document.getElementById(inputID).value;

            putBody[inputID] = inputValue;
        }

        try {
            const res = await fetch(`${config.base_api}/freightRules`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(putBody),
            });

            await convertJSON(res);

            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Installer Updated'}});
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
        }
    };

    return (
        <form className="freightGroup">
            <div className="quotesTable">
                <table>
                    <thead>
                        <tr>
                            {installer.names.map((name) => (
                                <th>{name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{installer.title}</td>
                            {installer.values.map((value) => (
                                <td>
                                    <input id={value.id} defaultValue={value.value}></input>
                                </td>
                            ))}
                        </tr>
                        <tr className="equation">
                            <td colspan={colSpanLength}>
                                Calculation: <br />
                                (COST_OF_ALL_PRODUCTS * {freightRules.installer_cost}) +{' '}
                                {freightRules.installer_trip_charge}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Btn title="Update" onClick={putInput} 
                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
            </div>
        </form>
    );
};

export default Installer;
