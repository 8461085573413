import React, { useState, useEffect, useRef } from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import { useStore } from '../StoreProvider';
import Btn from '../components/Btn';
import { ACTIONS } from '../Actions';
import xIcon from '../assets/xicon.svg';
import checkmark from '../assets/checkmark.svg';
import GenericTable from '../components/table/GenericTable';

const CallAnalysis = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const promptDropdownMenuRef = useRef(null);
    const [allCalls, setAllCalls] = useState([]);
    const [displayedPrompts, setDisplayedPrompts] = useState([]);
    const [allPrompts, setAllPrompts] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [createPromptModal, setCreatePromptModal] = useState(false);
    const [editPromptModal, setEditPromptModal] = useState(false);
    const [promptTitle, setPromptTitle] = useState('');
    const [promptText, setPromptText] = useState('');
    const [selectedPromptToEdit, setSelectedPromptToEdit] = useState({
        id: '',
        title: '',
        text: '',
    });
    const [openPromptMenu, setOpenPromptMenu] = useState(false);

    const closeOpenPromptMenu = (e) => {
        if (promptDropdownMenuRef.current && openPromptMenu && !promptDropdownMenuRef.current.contains(e.target) && !e.target?.className?.includes('prompt-dropdown-menu')) {
            setOpenPromptMenu(false);
        }
    };

    document.addEventListener('mouseup', closeOpenPromptMenu);

    const promptDropdownClicked = () => {
        if (openPromptMenu) {
            setOpenPromptMenu(false);
        } else {
            setOpenPromptMenu(true);
        }
    }

    const fetchCalls = async () => {
        try {
            const res = await fetch(`${config.base_api}/callAnalysis`, { headers });
            const data = await res.json();
            const transformedCalls = data.map(call => {
                const { date, time } = convertToCST(call.start_time);
                const formattedDuration = formatDuration(call.duration);
                const location = `${call.customer_city}, ${call.customer_state}`;
                const criteriaScore = `${call.criteria_score}/6`
                return {
                    ...call,
                    start_date: date,
                    start_time: time,
                    duration: formattedDuration,
                    customer_city: location,
                    criteria_score: criteriaScore,
                    age_group_inquiry: call.age_group_inquiry ? "Yes" : "No",
                    facility_inquiry: call.facility_inquiry ? "Yes" : "No",
                    budget_inquiry: call.budget_inquiry ? "Yes" : "No",
                    installation_setup: call.installation_setup ? "Yes" : "No",
                    quote_discussion: call.quote_discussion ? "Yes" : "No",
                    follow_up: call.follow_up ? "Yes" : "No",
                };
            });
            setAllCalls(transformedCalls);
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchPrompts = async () => {
        try {
            const res = await fetch(`${config.base_api}/callAnalysis/prompt`, { headers });
            const data = await res.json();
            console.log(data);
            setAllPrompts(data);
            const activePrompts = data.filter(prompt => prompt.active === true);
            setDisplayedPrompts(activePrompts);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCalls();
        fetchPrompts();

        return () => {
            document.removeEventListener('mouseup', closeOpenPromptMenu);
        }
    }, []);

    const convertToCST = (isoDateString) => {
        const date = new Date(isoDateString);
        const formattedDate = date.toLocaleDateString('en-US', {
            timeZone: 'America/Chicago',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('en-US', {
            timeZone: 'America/Chicago', hour12: true,
            hour: 'numeric',
            minute: '2-digit',
        });
        return { date: formattedDate, time: formattedTime };
    };

    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min ${remainingSeconds} sec`;
    };

    const toggleRow = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const openCreateModal = () => {
        setCreatePromptModal(true);
    }

    const closeCreateModal = () => {
        setCreatePromptModal(false);
    }

    const openEditModal = () => {
        setEditPromptModal(true);
    }

    const closeEditModal = () => {
        setEditPromptModal(false);
    }

    const handleSubmit = async () => {
        const body = {
            prompt_title: promptTitle,
            prompt_text: promptText,
        };

        if (!promptTitle.trim() || !promptText.trim()) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: `Please enter a prompt title and text` } });
            return;
        }

        try {
            const res = await fetch(`${config.base_api}/callAnalysis/prompt`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body),
            });

            if (!res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Failed to submit prompt` } });
                return;
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Prompt submitted successfully` } });
                fetchPrompts();
                setPromptTitle('');
                setPromptText('');
                setCreatePromptModal(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handlePromptSelection = async (selectedPromptId) => {
        const isAlreadyDisplayed = displayedPrompts.some(prompt => prompt.id == selectedPromptId);

        if (isAlreadyDisplayed) {
            removePrompt(selectedPromptId);
            return;
        }

        if (displayedPrompts.length >= 2) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: "Please remove a custom prompt from the table to select a new one." } });
            return;
        }

        const promptToUpdate = allPrompts.find(prompt => prompt.id == selectedPromptId);
        if (!promptToUpdate) return;

        try {
            const res = await fetch(`${config.base_api}/callAnalysis/prompt/${promptToUpdate.id}/status`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify({ active: true }),
            });

            if (res.ok) {
                fetchPrompts();
            } else {
                console.error('Failed to update prompt status');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const removePrompt = async (promptId) => {
        try {
            const res = await fetch(`${config.base_api}/callAnalysis/prompt/${promptId}/status`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify({ active: false }),
            });

            if (res.ok) {
                const updatedPrompts = displayedPrompts.filter(p => p.id !== promptId);
                setDisplayedPrompts(updatedPrompts);
                fetchPrompts();
            } else {
                console.error('Failed to update prompt status');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handlePromptEditOrDelete = (selectedPromptId) => {
        const prompt = allPrompts.find(prompt => prompt.id == selectedPromptId);
        console.log(prompt);
        if (prompt) {
            setSelectedPromptToEdit({
                id: prompt.id,
                title: prompt.prompt_title,
                text: prompt.prompt_text,
            });
        } else {
            setSelectedPromptToEdit({
                id: '',
                title: '',
                text: '',
            });
        }
    }

    const handleEditPrompt = async () => {
        if (!selectedPromptToEdit.id) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: "Please select a prompt to edit" } });
            return;
        }

        const body = {
            prompt_text: selectedPromptToEdit.text,
        };

        try {
            const res = await fetch(`${config.base_api}/callAnalysis/prompt/${selectedPromptToEdit.id}/content`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: "Prompt updated successfully" } });
                fetchPrompts();
                setSelectedPromptToEdit({ id: '', title: '', text: '' });
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: { message: "Failed to update prompt" } });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDeletePrompt = async () => {
        if (!selectedPromptToEdit.id) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: "Please select a prompt to delete" } });
            return;
        }

        try {
            const res = await fetch(`${config.base_api}/callAnalysis/prompt/${selectedPromptToEdit.id}`, {
                method: 'DELETE',
                headers,
            });

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: "Prompt deleted successfully" } });
                fetchPrompts();
                setSelectedPromptToEdit({ id: '', title: '', text: '' });
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: { message: "Failed to delete prompt" } });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const columns = [
        { key: 'customer_name', label: 'Caller Name', headerClass: '' },
        { key: 'customer_phone_number', label: 'Caller Number', headerClass: '' },
        { key: 'customer_city', label: 'Caller Location', headerClass: '' },
        { key: 'sales_rep', label: 'Sales Rep.', headerClass: '' },
        { key: 'duration', label: 'Call Duration', headerClass: '' },
        { key: 'start_date', label: 'Call Date', headerClass: '' },
        { key: 'start_time', label: 'Call Time', headerClass: '' },
        { key: 'sentiment_score', label: 'Sentiment Score', headerClass: '' },
        { key: 'criteria_score', label: 'Criteria Score', headerClass: '' },
        ...displayedPrompts.map(prompt => ({ key: `custom_prompt_responses`, tooltip: true, tooltip_text: prompt.prompt_text, nestedKey: prompt.id, placeholder: 'N/A', label: prompt.prompt_title, headerClass: '' })),
    ]

    const expandableColumns = [
        { key: 'age_group_inquiry', label: 'Age Inquiry*', headerClass: '' },
        { key: 'facility_inquiry', label: 'Facility Inquiry*', headerClass: '' },
        { key: 'budget_inquiry', label: 'Budget Inquiry*', headerClass: '' },
        { key: 'installation_setup', label: 'Installation Inquiry*', headerClass: '' },
        { key: 'quote_discussion', label: 'Quote Mentioned*', headerClass: '' },
        { key: 'follow_up', label: 'Follow-up Set*', headerClass: '' },
        { key: 'age_group', label: 'Age Group', headerClass: '' },
        { key: 'facility_type', label: 'Facility Type', headerClass: '' },
        { key: 'budget_range', label: 'Budget Range', headerClass: '' },
        { key: 'installation_deadline', label: 'Installation Deadline', headerClass: '' },
    ]

    return (
        <div>
            <h1 className='text-center margin-tb-xlg'>Call Analysis</h1>
            <div className='component-card flex margin-b-xlg width-fit-content'>
                <p className="margin-tb-lg pad-tb-xlg pad-r-lg margin-l-xxxlg text-bold">Prompt to Display:</p>
                <div>
                    <div className='margin-t-lg pad-t-xlg pad-r-lg project-dropdowns prompt-dropdown-menu width-fit-content' onClick={() => promptDropdownClicked()}>
                        <select ref={promptDropdownMenuRef} id="promptDropdown" className="border-radius-sm pad-xs" disabled>
                            <option>Choose up to 2 Prompts to Display:</option>
                        </select>
                    </div>
                    <div ref={promptDropdownMenuRef} className={(openPromptMenu ? 'prompt-dropdown-menu' : 'hide') + ' position-absolute border-radius-xs pad-sm flex-col box-shadow bg-white z-index-1'}>
                        {allPrompts.map((prompt, index) => (
                            <label htmlFor={`promptOption-${prompt.id}`} className={`checkbox-container margin-b-xs ${(displayedPrompts.length === 2 && prompt.active != true) ? "text-med-gray not-allowed" : ""}`}>{prompt.prompt_title}
                                <input type="checkbox" id={`promptOption-${prompt.id}`} className="custom-checkbox"
                                    onChange={() => handlePromptSelection(prompt.id)} value={prompt.id} disabled={displayedPrompts.length === 2 && prompt.active != true} />
                                <span className="checkmark"><img src={checkmark} className={prompt.active ? null : 'hide'} /></span>
                            </label>
                        ))}
                    </div>
                </div>
                <Btn title='Create Prompt' btnClass='bg-blue blue-button text-white b-none bolder box-shadow margin-xxlg'
                    onClick={openCreateModal} />
                <Btn title='Edit a Prompt' btnClass='bg-white border-radius-xs text-blue b2-blue text-bold pointer darken-background box-shadow margin-xxlg'
                    onClick={openEditModal} />
            </div>

            <GenericTable columns={columns} data={allCalls} expandableKey='criteria_score' expandableColumns={expandableColumns} expandableData={allCalls} noAction={true} />

            <div id="page-mask" className={`${(createPromptModal || editPromptModal) ? "" : "hidden"}`}></div>
            <div className={`${createPromptModal ? "prompt-modal border-radius-xs b-none" : "hide"}`}>
                <button onClick={closeCreateModal} className='bg-white b-none close-button pointer'>
                    <img src={xIcon} alt="close button" />
                </button>
                <div className="margin-xxlg width-100">
                    <h4 className='margin-t-lg margin-b-xxlg'>Create Prompt</h4>
                    <p className='margin-b-md text-bold'>Prompt Title</p>
                    <input className='width-60 margin-b-lg border-radius-xs pad-xs' type="text" placeholder="Enter Prompt Title" value={promptTitle}
                        onChange={(e) => setPromptTitle(e.target.value)} />
                    <p className='margin-b-md text-bold'>Prompt Description</p>
                    <textarea className='width-100 border-radius-xs pad-xs no-resize' rows="5" placeholder="Enter a new prompt for analysis"
                        value={promptText} onChange={(e) => setPromptText(e.target.value)}></textarea>
                    <Btn btnClass={`${(!promptTitle || !promptText) ? "btn-disabled" : "text-white box-shadow green-button bg-light-green b-none"} height-40px align-self-end bolder absolute bottom-right`}
                        onClick={handleSubmit} title="Save Prompt" />
                </div>
            </div>
            <div className={`${editPromptModal ? "prompt-modal border-radius-xs b-none" : "hide"}`}>
                <button onClick={closeEditModal} className='bg-white b-none close-button pointer'>
                    <img src={xIcon} alt="close button" />
                </button>
                <div className='margin-xxlg width-100'>
                    <h4 className='margin-t-lg margin-b-xxlg'>Edit a Prompt</h4>
                    <div className='flex-column'>
                        <label htmlFor="promptDropdown" className='margin-b-md text-bold'>Choose a Prompt to Edit: </label>
                        <select id="promptDropdown" className='width-60 margin-b-lg border-radius-xs pad-xs'
                            onChange={(e) => handlePromptEditOrDelete(e.target.value)}>
                            <option value='' disabled selected >Select a prompt</option>
                            {allPrompts.map((prompt, index) => (
                                <option key={prompt.id} value={prompt.id}>{prompt.prompt_title}</option>
                            ))}
                        </select>
                    </div>
                    <p className='margin-b-md text-bold'>Prompt Description</p>
                    <textarea className='width-100 border-radius-xs pad-xs no-resize' rows="5" disabled={!selectedPromptToEdit.id}
                        value={selectedPromptToEdit.text} onChange={(e) => setSelectedPromptToEdit({ ...selectedPromptToEdit, text: e.target.value })}></textarea>
                    <div className='flex margin-t-xxlg justify-between'>
                        <Btn title="Delete Prompt" btnClass={`${!selectedPromptToEdit.id ? "btn-disabled" : "bg-light-red delete-button text-white b-none"} height-40px align-self-end  bolder`} onClick={handleDeletePrompt} />
                        <Btn title="Save Changes" btnClass={`${!selectedPromptToEdit.id ? "btn-disabled" : "bg-light-green text-white b-none box-shadow green-button"} height-40px align-self-end bolder`} onClick={handleEditPrompt} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallAnalysis;