import React from 'react';
import {isNote} from './productTypeCheck';
import {useStore} from '../../../StoreProvider';
import {ACTIONS} from '../../../Actions';

const CellQty = ({product, calculateRow}) => {
    const {state, dispatch} = useStore();
    {
        /* If product is a 'note' return blank td for product table display */
    }
    if (isNote(product)) {
        return <td key="qty" className="col-qty"></td>;
    }

    return (
        <td key="qty" className="col-qty">
            <input
                onWheel={(e) => e.target.blur()}
                type="number"
                className="productsTable_qty pad-xs"
                value={product.qty}
                // On qty change - update product state
                // Calculate product row after changes
                onChange={(e) => {
                    product.qty = e.target.value;
                    dispatch({
                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                        payload: state?.productTableItems.map((prod) =>
                            prod.playground_boss_sku === product.playground_boss_sku ? product : prod
                        )
                    });
                    calculateRow(product.playground_boss_sku);
                }}
                onBlur={(e) => calculateRow(product.playground_boss_sku)}
                disabled={state?.quote.readOnly}
            />
        </td>
    );
};

export default CellQty;
