import React, {useCallback, useEffect, useState} from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import Btn from '../../components/Btn';
import UserDeleteModal from './UserDeleteModal';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

const UsersSection = () => {
    const { state, dispatch } = useStore();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const forceUpdate = useCallback(() => setUsers({}));
    const [modal, setModal] = useState('');
    const [message, setMessage] = useState('');
    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState('');
    const [searchTermFilter, setSearchTermFilter] = useState('');
    
    var userArray = [];

    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const getUsers = async () => {
        try {
            const res = await fetch(`${config.base_api}/users`, {headers});
            const data = await convertJSON(res);
            configUsers(data);
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
            console.log(error);
        }
    };

    const giveSalesAccess = async (id, email) => {
        let body = {
            username: id,
        };

        try {
            const response = await fetch(`${config.base_api}/users/sales/make`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Granted`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Could Not Be Granted`, isError: true}});
        }
    };

    const revokeSalesAccess = async (id, email) => {
        let body = {
            username: id,
        };

        try {
            const response = await fetch(`${config.base_api}/users/sales/revoke`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Revoked`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Could Not Be Revoked`, isError: true}});
        }
    };

    const giveAccess = async (id, email) => {
        let body = {
            username: id,
        };

        try {
            const response = await fetch(`${config.base_api}/users/admin/make`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Granted`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Could Not Be Granted`, isError: true}});
        }
    };

    const revokeAccess = async (id, email) => {
        let body = {
            username: id,
        };

        try {
            const response = await fetch(`${config.base_api}/users/admin/revoke`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Revoked`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Access Could Not Be Revoked`, isError: true}});
        }
    };

    const giveOpsAccess = async (id, email) => {
        let body = {
            username: id,
        };

        try {
            const response = await fetch(`${config.base_api}/users/operations/make`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Operations access granted`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {
                message: `${email} Operations access could not be granted`,
                isError: true,
            }});
        }
    };

    const removeOpsAccess = async (id, email) => {
        let body = {
            username: id,
        };

        try {
            const response = await fetch(`${config.base_api}/users/operations/revoke`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Operations access revoked`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {
                message: `${email} operations access could not be revoked`,
                isError: true,
            }});
        }
    };

    const updateTelephone = async (id, tel, email) => {
        let body = {
            username: id,
            phoneEXT: tel,
        };

        try {
            const response = await fetch(`${config.base_api}/users/admin/updatePhone`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Telephone Extension Updated`}});
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Could Not Update Telphone Extension`, isError: true}});
        }
    };

    const userUpdate = async (user) => {
        var id = user.id;
        var email = user.email;

        var admin = document.getElementById(id).querySelector('.admin').checked;
        var tel = document.getElementById(id).querySelector('.telephone').value || null;
        var ops = document.getElementById(id).querySelector('.ops').checked;
        var sales = document.getElementById(id).querySelector('.sales').checked;

        try {
            let isOps = null;
            let isAdmin = null;
            let isSales = null;

            if (admin == 1) {
                isAdmin = true;
                giveAccess(id, email);
            } else if (admin == 0) {
                isAdmin = false;
                revokeAccess(id, email);
            }

            if (tel) {
                updateTelephone(id, tel, email);
            }

            if (ops == 1) {
                isOps = true;
                giveOpsAccess(id, email);
            } else if (ops == 0) {
                isOps = false;
                removeOpsAccess(id, email);
            }

            if (sales == 1) {
                isSales = true;
                giveSalesAccess(id, email);
            } else if (sales == 0) {
                isSales = false;
                revokeSalesAccess(id, email);
            }

            let request = {
                ext: tel,
                operations: isOps,
                admin: isAdmin,
                sales: isSales
            }

            return updateUserDB(email, request);
        } catch (error) {   
            console.log(error);
        }
    }

    const updateUserDB = async (email, request) => {
        console.log(request)
        try {
            const response = await fetch(`${config.base_api}/users/update/db/${email}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(request),
            });

            await convertJSON(response);
        } catch (error) {
            console.log(error)
        }
    }

    function confirmDeleteUser(user) {
        // modal here with 2 buttons
        setUserToDelete(user);
        setShowUserDeleteModal(true);
    }

    const deleteUser = async (id, email) => {
        if (email !== state?.auth.username) {
            let body = {
                username: id,
                email: email
            };

            try {
                const response = await fetch(`${config.base_api}/users`, {
                    method: 'DELETE',
                    headers,
                    body: JSON.stringify(body),
                });

                await convertJSON(response);

                dispatch({ type: ACTIONS.TOAST, payload: {message: `${email} Deleted`}});

                setLoading(true);
                getUsers();
            } catch (error) {
                console.error(error);
                dispatch({ type: ACTIONS.TOAST, payload: {message: `Could Not Delete ${email}`, isError: true}});
            }
        } else {
            dispatch({ type: ACTIONS.TOAST, payload: {message: `You can't delete yourself!`, isError: true}});
        }
        setShowUserDeleteModal(false);
    };

    function configUsers(users) {
        for (let i = 0; i < users.length; i++) {
            var theEmail = '';
            var theTel = '';
            var theAdmin = '';
            var theOps = '';
            for (let e = 0; e < users[i].Attributes.length; e++) {
                var comparison = users[i].Attributes[e].Name;
                switch (comparison) {
                    case 'email':
                        theEmail = users[i].Attributes[e].Value.toLowerCase();
                        break;
                    case 'custom:adminPGB':
                        theAdmin = users[i].Attributes[e].Value;
                        break;
                    case 'custom:phoneEXT':
                        theTel = users[i].Attributes[e].Value;
                        break;
                    case 'zoneinfo':
                        theOps = users[i].Attributes[e].Value;
                    default:
                        console.log('No Matches');
                }
            }

            if (theAdmin == 0) {
                theAdmin = false;
            } else if (theAdmin == 1) {
                theAdmin = true;
            } else {
                theAdmin = false;
            }

            if (theOps == 0) {
                theOps = false;
            } else if (theOps == 1) {
                theOps = true;
            } else {
                theOps = false;
            }

            var userObject = {
                id: users[i].Username,
                email: theEmail,
                telephone: theTel,
                admin: theAdmin,
                ops: theOps,
            };

            if (i == 0) {
                userArray = [];
            }

            userArray.push(userObject);
        }
    
        connectDBEmployees(userArray);
    }

    const connectDBEmployees = async(userArray) => {
        
        let newUserArray = [];
        try {
            const res = await fetch(`${config.base_api}/users/db`, {headers});
            const data = await convertJSON(res);

            for (let i = 0; i < userArray.length; i++) {
                let userEmail = userArray[i].email;
                let matchFound = false

                for (let j = 0; j < data.length; j++) {
                    let dbEmail = data[j].email;
                    if (dbEmail == userEmail) {
                        matchFound = true;
                        let dbName = data[j].name;
                        let sales = data[j]?.sales || false;
                        
                        let newUserObject = {...userArray[i], name: dbName, sales: sales};
                        
                        newUserArray.push(newUserObject)
                    }
                }

                if (matchFound == false) {
                    let newUserObject = {...userArray[i], name: 'N/A'};
                    newUserArray.push(newUserObject)
                }
                
            }

            setUsers(newUserArray);
        } catch (error) {
            console.log(error);
        }
    }

    const createUser = async () => {
        let body = {
            email: document.getElementById('email').value.toLowerCase(),
            name: document.getElementById('name').value,
            phoneEXT: document.getElementById('phone').value,
        };

    
        try {
            const response = await fetch(`${config.base_api}/users`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            if (response.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: {message: `${document.getElementById('email').value} Created`}});
                setLoading(true);  
                getUsers();
            } 
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `Could not create user}`, isError: true}});
        }

        document.getElementById('email').value = '';
        document.getElementById('name').value = '';
        document.getElementById('phone').value = '';
    };

    const matchesSearch = (field) => {
        if (searchTermFilter?.trim()) {
          return field?.toLowerCase()?.includes(searchTermFilter?.toLowerCase()?.trim());
        }
        return false;
      }

    useEffect(() => {
        let listedUsers = users;
        if (searchTermFilter?.trim()) {
          listedUsers = users.filter(user => {
            if (matchesSearch(user.email) || matchesSearch(user.name) || matchesSearch(user.telephone)) {
              return true;
            }
            return false;
          });
        if (searchTermFilter?.trim() == 'true') {
            listedUsers = users.filter(user => {  
                if (user.admin == true) {
                    return true;
                }
                return false;
            })
        }
        } 
        console.log(listedUsers)

        setFilteredUsers(listedUsers);
      }, [searchTermFilter]);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (users) {
            setLoading(false);
        } else {
            getUsers();
        }
    }, [users]);

    if (loading) {
        return (
            <div>
                <form id="addUser">
                    <div>
                        <h1 className="centerText">Add User</h1>

                        <div>
                            <label className="block">Name</label>

                            <input id="name" type="text"></input>
                        </div>

                        <div>
                            <label className="block">Email</label>

                            <input id="email" type="text"></input>
                        </div>

                        <div>
                            <label className="block">Phone Extension</label>

                            <input id="phone" type="text"></input>
                        </div>

                        <div className="centerButton">
                            <Btn title="Create User" onClick={() => createUser()} 
                            btnClass = 'pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background'/>
                        </div>
                    </div>
                </form>
                <div id="adminUsers">
                    <div className="userHeader">
                        <h1>Users</h1>
                        <div className="usersSearch">
                            <label htmlFor="searchProducts">Search Users:</label>
                            <input id="searchProducts" type="text" value={searchTermFilter} onChange={(event) => setSearchTermFilter(event.target.value)} />
                            <br></br>
                            <p>Search 'true' to filter by Admin</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <form id="addUser">
                <div>
                    <h1 className="centerText">Add User</h1>

                    <div>
                        <label className="block">Name</label>

                        <input id="name" type="text"></input>
                    </div>

                    <div>
                        <label className="block">Email</label>

                        <input id="email" type="text"></input>
                    </div>

                    <div>
                        <label className="block">Phone Extension</label>

                        <input id="phone" type="text"></input>
                    </div>

                    <div className="centerButton">
                        <Btn title="Create User" onClick={() => createUser()} 
                        btnClass = 'pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background'/>
                    </div>
                </div>
            </form>
            <div id="adminUsers">
                <div className="userHeader">
                    <h1>Users</h1>
                    <div className="usersSearch">
                        <label htmlFor="searchProducts">Search Users:</label>
                        <input id="searchProducts" type="text" value={searchTermFilter} onChange={(event) => setSearchTermFilter(event.target.value)} />
                        <br></br>
                        <p>Search 'true' to filter by Admin</p>
                    </div>
                </div>
                <div className="quotesTable">
                    <table id="usersTable">
                        <thead>
                            <tr>
                                <th className="text-center pad-none" colSpan="2">Email</th>
                                <th className="text-center" colSpan="2">Name</th>
                                <th className="text-center" colSpan="2">Phone Extension</th>
                                <th className="text-center">Operations</th>
                                <th className="text-center">Admin</th>
                                <th className="text-center pad-none">Sales</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="usersBody">
                            {searchTermFilter
                                ? filteredUsers.map((user) => (
                                      <tr id={user.id} key={user.email} className="users_row">
                                          <td className="email text-center" colSpan="2">
                                              <button
                                                  onClick={(e) => confirmDeleteUser(user)}
                                                  className="userDeleteBtn col-buttons">
                                                  x
                                              </button>
                                              {user.email}
                                          </td>
                                          <td className="text-center" colSpan="2">
                                                <p>{user.name}</p>
                                          </td>
                                          <td className="text-center" colSpan="2">
                                              <input className="telephone" type="text" defaultValue={user.telephone} />
                                          </td>
                                          <td className="text-center">
                                              {user.ops ? (
                                                  <input className="ops" type="checkbox" defaultChecked={true} />
                                              ) : (
                                                  <input className="ops" type="checkbox" defaultChecked={false} />
                                              )}
                                          </td>
                                          <td className="text-center">
                                              {user.admin ? (
                                                  <input className="admin" type="checkbox" defaultChecked={true} />
                                              ) : (
                                                  <input className="admin" type="checkbox" defaultChecked={false} />
                                              )}
                                          </td>
                                          <td className="text-center">
                                              {user.sales ? (
                                                  <input className="sales" type="checkbox" defaultChecked={true} />
                                              ) : (
                                                  <input className="sales" type="checkbox" defaultChecked={false} />
                                              )}
                                          </td>
                                          <td>
                                              <Btn title="Update" onClick={() => userUpdate(user)} />
                                          </td>
                                      </tr>
                                  ))
                                : users.map((user) => (
                                      <tr id={user.id} key={user.email} className="users_row">
                                          <td className="email" colSpan="2">
                                              <button
                                                  onClick={(e) => confirmDeleteUser(user)}
                                                  className="userDeleteBtn col-buttons">
                                                  x
                                              </button>
                                              {user.email}
                                          </td>
                                          <td colSpan="2">
                                                <p>{user.name}</p>
                                          </td>
                                          <td colSpan="2">
                                              <input type="text" className="telephone" defaultValue={user.telephone} />
                                          </td>
                                          <td>
                                              {user.ops ? (
                                                  <input className="ops" type="checkbox" defaultChecked={true} />
                                              ) : (
                                                  <input className="ops" type="checkbox" defaultChecked={false} />
                                              )}
                                          </td>
                                          <td>
                                              {user.admin ? (
                                                  <input className="admin" type="checkbox" defaultChecked={true} />
                                              ) : (
                                                  <input className="admin" type="checkbox" />
                                              )}
                                          </td>
                                          <td>
                                              {user.sales ? (
                                                  <input className="sales" type="checkbox" defaultChecked={true} />
                                              ) : (
                                                  <input className="sales" type="checkbox" defaultChecked={false} />
                                              )}
                                          </td>
                        
                                          <td>
                                              <Btn title="Update" onClick={() => userUpdate(user)} 
                                              btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm' />
                                          </td>
                                      </tr>
                                  ))}
                        </tbody>
                    </table>
                </div>
                {showUserDeleteModal && (
                    <UserDeleteModal
                        deleteUser={deleteUser}
                        user={userToDelete}
                        setShowUserDeleteModal={setShowUserDeleteModal}
                    />
                )}
            </div>
        </div>
    );
};

export default UsersSection;
