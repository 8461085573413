import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Btn from '../../Btn';
import config from '../../../config';
import authHeaders from '../../../utilities/authHeaders';
import Products from '../../../views/Products';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';
import convertJSON from '../../../utilities/convertJSON';

const MyTCoat = ({freightRules, markup}) => {
    const { state, dispatch } = useStore();
    const [mtc_price_per_partial_palette, set_mtc_price_per_partial_palette] = useState(
        freightRules.myTCoat_price_per_partial_palette
    );
    const [mtc_price_per_palette, set_mtc_price_per_palette] = useState(freightRules.myTCoat_price_per_palette);

    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const myTCoat = {
        title: 'MyTCoat',
        names: ['Manufacturer', 'Price Per Partial Palette', 'Price Per Palette', 'Freight Markup'],
    };
    const colSpanLength = myTCoat.names.length;

    let idArray = ['myTCoat_price_per_partial_palette', 'myTCoat_price_per_palette'];

    let putBody = {};

    // API call for posting quote into our database
    const putInput = async () => {
        for (let e = 0; e < idArray.length; e++) {
            var inputID = idArray[e];
            var inputValue = document.getElementById(inputID).value;

            putBody[inputID] = inputValue;
        }

        try {
            const res = await fetch(`${config.base_api}/freightRules`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(putBody),
            });

            await convertJSON(res);

            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Action Play Systems Updated'}});
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
        }
    };

    return (
        <form className="freightGroup">
            <div className="quotesTable">
                <table>
                    <thead>
                        <tr>
                            {myTCoat.names.map((name) => (
                                <th>{name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{myTCoat.title}</td>
                            <td>
                                <input
                                    id="myTCoat_price_per_partial_palette"
                                    defaultValue={mtc_price_per_partial_palette}
                                    onChange={(e) => set_mtc_price_per_partial_palette(e.target.value)}></input>
                            </td>
                            <td>
                                <input
                                    id="myTCoat_price_per_palette"
                                    defaultValue={mtc_price_per_palette}
                                    onChange={(e) => set_mtc_price_per_palette(e.target.value)}></input>
                            </td>
                            <td>
                                <input value={markup} disabled="true"></input>
                            </td>
                        </tr>
                        <tr className="equation">
                            <td colspan={colSpanLength}>
                                Calculation: <br />
                                ((FLOOR[Products / 2] x ${mtc_price_per_partial_palette}) + ([Products % 2] x $
                                {mtc_price_per_palette})) / {markup}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Btn title="Update" onClick={putInput} 
                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
            </div>
        </form>
    );
};

export default MyTCoat;
