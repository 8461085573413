import React, { useEffect, useState, useForm } from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import Btn from '../components/Btn';
import LoadingInline from '../components/LoadingInline'
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';

const ProductVariants = ({
    productVariants,
    setProductVariants,
    getProductVariants,
    manufacturers
}) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const deleteVariant = async (id) => {
        try {
            const response = await fetch(`${config.base_api}/productVariants/delete/${id}`, {
                method: 'DELETE',
                headers
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: { message: `Variant Deleted` }});
            //Get Updated Product Variants
            setProductVariants('loading');
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: `Could Not Delete Variant`, isError: true }});
        }
    }

    const createVariant = async () => {
        try {
            let body = {
                manufacturer: document.getElementById('variant_manufacturer').value,
                attribute: document.getElementById('variant_attribute').value,
                options: document.getElementById('variant_options').value
            };

            console.log(body);


            const response = await fetch(`${config.base_api}/productVariants`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            });


            await convertJSON(response);

            dispatch({ type: ACTIONS.TOAST, payload: { message: `Variant Created` }});
            //Get Updated Product Variants
            setProductVariants('loading');

        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: `Could Not Create Variant`, isError: true }});
        }
    }

    const variantUpdate = async (id) => {
        try {
            let body = {
                manufacturer: document.getElementById(`variant${id}_manufacturer`).value,
                attribute: document.getElementById(`variant${id}_attribute`).value,
                options: document.getElementById(`variant${id}_options`).value
            };

            const res = await fetch(`${config.base_api}/productVariants/${id}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body)
            });

            await convertJSON(res);

            //Get Updated Product Variants
            setProductVariants('loading');
        }
        catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: `Could Not Create Variant`, isError: true }});
        }
    }

    const compareVariant = (a, b) => {
        if (a.manufacturer > b.manufacturer) {
            return 1;
        } else if (a.manufacturer < b.manufacturer) {
            return -1;
        } else if (a.attribute > b.attribute) {
            return 1;
        } else if (a.attribute < b.attribute) {
            return -1;
        } else if (a.options > b.options) {
            return 1;
        } else if (a.options < b.options) {
            return -1;
        } else {
            return 0;
        }
    }

    useEffect(() => {
		if (!Array.isArray(productVariants)) {
			getProductVariants()
		}
	}, [productVariants])

    return (
        <>       
            {!Array.isArray(productVariants) &&
                <LoadingInline />
            }

            {Array.isArray(productVariants) &&
                <div>
                    {/*CREATE VARIANT*/}
                    <div id='addVariant'>
                            <h2 className='centerText'>Add Variant</h2>

                            <div>
                                <label className="block">
                                    Manufacturer
                                </label>

                                <select id={'variant_manufacturer'}>
                                    <option value={null}></option>
                                    {manufacturers.map((item, i) => item ? <option value={item.vendor} key={i}>{item.vendor}</option> : null)}
                                </select>
                            </div>
                            <div>
                                <label className="block">
                                    Attribute
                                </label>

                                <input id='variant_attribute' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    Options
                                </label>

                                <input id='variant_options' type='text'></input>
                            </div>

                            <div className='centerButton'>
                                <Btn title="Create Variant" onClick={createVariant}
                                btnClass = 'pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background' />

                            </div>

                    </div>
                    {/*UPDATE OR DELETE VARIANT*/}
                    <h1 className='centerText'>Product Variants</h1>
                    <div className='quotesTable'>
                        <table id='variantsTable'>
                            <thead>
                                <tr>
                                    {/*Columns Total = 9*/}
                                    <th colSpan="2">Manufacturer</th>
                                    <th colSpan="2">Attribute</th>
                                    <th colSpan="4">Options</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id='variantsBody'>
                                {Array.isArray(productVariants) &&
                                    (productVariants.sort(compareVariant).map((variant) => (
                                        <tr key={variant.id} className='variant_row'>
                                            <td className='variant_manufacturer' colSpan="2">
                                                <span onClick={() => deleteVariant(variant.id)} className="table_deleteBtn col-buttons">
                                                    x
                                                </span>
                                                <select
                                                    id={`variant${variant.id}_manufacturer`}
                                                    defaultValue={variant.manufacturer}
                                                >
                                                    <option value={null}></option>
                                                    {manufacturers.map((item, i) => item ? <option value={item.vendor} key={i}>{item.vendor}</option> : null)}
                                                </select>
                                            </td>
                                            <td className='variant_attribute'  colSpan="2">
                                                <input id={`variant${variant.id}_attribute`} type="text" defaultValue={variant.attribute} />
                                            </td>
                                            <td className='variant_options'  colSpan="4">
                                                <input id={`variant${variant.id}_options`} type="text" defaultValue={variant.options} />
                                            </td>
                                            <td>
                                                <Btn title="Update" onClick={() => variantUpdate(variant.id)} 
                                                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
                                            </td>
                                        </tr>
                                    )))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default ProductVariants