import React from 'react';

const CellName = ({ name }) => {

    return (
        <td key="name" className="col-name">
            <p className="wrap-text">{name}</p>
        </td>
    );
};

export default CellName;
