import React, {useEffect, useState} from 'react';
import CostSheetView from '../components/quote/CostSheetView';
import authHeaders from '../utilities/authHeaders';
import config from '../config';
import LoadingInline from '../components/LoadingInline';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import {quoteToObject} from '../utilities/formatQuote';

const CostSheet = () => {
    // empty Cost Sheet Object
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const quoteNumber = window.location.pathname?.split('/costsheet/')[1];
    const quoteID = quoteNumber?.substring(7);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (quoteID) {
            dispatch({type: ACTIONS.QUOTE, payload: emptyQuoteObj});

            (async () => {
                try {
                    let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, {headers});
                    if (!quoteData.ok) throw 'Could not get quote';
                    quoteData = await convertJSON(quoteData);
                    const formattedQuote = quoteToObject(quoteData);

                    dispatch({ type: ACTIONS.QUOTE, payload: {...state?.quote, ...formattedQuote}});
                    setLoading(false);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, []);

    if (loading) {
        return (
            <div className="mainContainer">
                <LoadingInline />
            </div>
        );
    }

    return (
        <div className="mainContainer">
            {/* Quote information here */}
            <CostSheetView
            setLoading={setLoading}
            />
        </div>
    );
};
export default CostSheet;