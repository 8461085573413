export function costSheetToObject(costSheet) {
    return {
        quote_number: costSheet.quote_number,
        installation: {
            cost: costSheet.installation_cost,
            price: costSheet.installation_price,
        },
        discount: {
            totalDiscount: costSheet.discount_totalDiscount,
            additionalDiscount: costSheet.discount_additionalDiscount,
            addDiscount1: costSheet.addDiscount1,
            addDiscount1_note: costSheet.addDiscount1_note,
            addDiscount2: costSheet.addDiscount2,
            addDiscount2_note: costSheet.addDiscount2_note,
        },
        freight: {
            price: costSheet.freight_price,
            cost: costSheet.freight_cost,
            additional_freight: costSheet.freight_additional_freight,
            real_freight: costSheet.real_freight,
            real_handling: costSheet.real_handling,
        },
        products: JSON.parse(costSheet.products),
    };
}
