// Format date used in quote tool

// Returns format "08-25-2021"
export default function formatDate(isoDate, type = 'dash') {
    let date = new Date(isoDate);

    let month = date.getMonth() + 1,
        day = date.getDate(),
        year = date.getFullYear();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    // For 2 digit year use the following
    // year = year.toString().slice(-2);

    if (type === 'dash') return `${month}-${day}-${year}`;
    if (type === 'slash') return `${month}/${day}/${year}`;
    return `${month}-${day}-${year}`;
}
