import React from 'react';
import Btn from '../Btn';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

// Save quote Function
// Pass in auth state and quote id to update
export const saveQuote = async (auth, quote, quoteId, ACTIONS, dispatch) => {
    const headers = authHeaders(auth.accessToken, auth.refreshToken);

    try {
        const id = quote?.id || quoteId;
        let res = await fetch(`${config.base_api}/quote/updateQuote/${id}`, {
            method: 'PATCH',
            headers,
            body: JSON.stringify(quote),
        });

        // why are we doing this? Later we can test saving the quote after removing this
        await convertJSON(res);

        if (!res.ok) {
            dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: 'Quote failed to auto save, please try saving again.' });
            throw 'Response from server not ok';
        } 
        else {
            if (dispatch && ACTIONS) dispatch({ type: ACTIONS.GLOBAL_ERROR_MESSAGE, payload: '' });
        }

        return 1;
    } catch (error) {
        console.log(error);
        console.log(JSON.stringify(quote));
        throw 'Quote failed to save'; 
    }
};

// Save Quote button
const SaveQuote = () => {
    const { state, dispatch } = useStore();
    const onClick = async (e) => {
        // Add productTableItems to quote - does not affect save quote call
        dispatch({ type: ACTIONS.QUOTE, payload: {...state?.quote, products: [...state?.productTableItems]}});
        try {
            await saveQuote(state?.auth, {...state?.quote, products: state?.productTableItems});

            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Quote saved succesfully'}});
        } catch (error) {
            dispatch({type: ACTIONS.TOAST, payload: {message: 'Quote failed to save', isError: true}});
        }
    };

    return <Btn title="Save" onClick={(e) => onClick(e)} 
    btnClass="pad-t-md pad-b-md width-100 justify-center box-shadow text-bold text-white bg-light-green green-button b-none" />;
};

export default SaveQuote;
