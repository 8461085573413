import React, {useEffect, useState} from 'react';
import {useNavigate, withRouter} from 'react-router';
import Btn from '../components/Btn';
import LoadingInline from '../components/LoadingInline';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import { useCacheBuster } from 'react-cache-buster';
import { useStore } from '../StoreProvider';
import convertJSON from '../utilities/convertJSON';

const ProjectAccessLog = () => {
  const { state } = useStore();
  const { checkCacheStatus } = useCacheBuster();
  const projectID = window.location.pathname?.split('/operations/projectAccessLog/')[1];
  const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
  const navigate = useNavigate();
  const [accessLog, setAccessLog] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkCacheStatus();
    (async () => {
      try {
        console.log('projectID', projectID);
        let logs = await fetch(`${config.base_api}/projects/accesslog/${projectID}`, {headers})
        logs = await convertJSON(logs);
        console.log('logs', logs)
        connectHubSpotUsers(logs);
        setLoading(false);

        let project = await fetch(`${config.base_api}/projects/${projectID}`, {headers})
        project = await convertJSON(project);
        console.log('project', project)
        setProjectName(project.project_name);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    })();
  }, []);

  const connectHubSpotUsers = async (logs) => {
    let draftAccessLogArray = [];
    
      try {
        let usersData = await fetch(`${config.base_api}/users/db`, {headers})
        usersData = await convertJSON(usersData);

        for (let i = 0; i < logs.length; i++) {
          let logEmail = logs[i].user;
          let foundUser = false;

          for (let j = 0; j < usersData.length; j++) {
            let userEmail = usersData[j].email;

            if (logEmail?.toLowerCase() == userEmail?.toLowerCase()) {
              foundUser = true;
              let userName = usersData[j].name;
              draftAccessLogArray.push({...logs[i], userName: userName});
            }
          }
          if (!foundUser) {
            draftAccessLogArray.push({...logs[i], userName: logEmail});
          }
        }
        setAccessLog(draftAccessLogArray);
      } catch (err) {
        console.log(err);
      }
  }

  const convertTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const eventDate = new Date(timestamp);
    let date = (eventDate.getMonth() + 1) + '/' + eventDate.getDate() + '/' + eventDate.getFullYear();
    let hours = eventDate.getHours();
    // the old timestamp was in UTC, it still has the right seconds with two digits
    let minutes = timestamp?.slice(14,16)
    let am_pm = 'am'
    if (parseInt(hours) === 0) am_pm = 'pm';
    if (parseInt(hours) > 12) {
      hours = hours - 12;
      am_pm = 'pm'
    }
    if (hours[0]==0) {
      hours = hours?.slice(1)
    }
    return `${date} ${hours}:${minutes} ${am_pm}`;
  };

  const backToProject = () => {
    const newPath = `/operations/projects/${projectID}`;
    navigate(newPath);
  }

  const accessLogTableView = () => {
    if (loading) return (<><td></td><div className='loading-table'><LoadingInline /></div></>);
    return (accessLog || []).map((logEvent, index, array) => 
      <tr className={index === array.length - 1 ? '' : 'underline-light-gray'}>
        <td key={index} className='text-bold text-xs pad-xlg text-med-gray no-wrap'>{convertTimestamp(logEvent.createdAt)}</td>
        <td key={index} className='text-bold text-xs pad-xlg text-med-gray '>{logEvent.userName}</td>
        <td key={index} className='text-bold text-xs pad-xlg text-med-gray '>{logEvent.update_message}</td>

      </tr>
    )
  }

  return (
    <div className='width-100 pad-lg'>
      <div className="">
        <Btn onClick={backToProject}
          btnClass='bg-white text-blue b2-blue text-bold darken-background box-shadow pad-md pad-l-xxlg pad-r-xxlg margin-b-xlg' 
          title='Back to Project'/>
      </div>

      <div className='margin-t-xlg'>
        <span className='text-bold text-lrg'>Access Log </span>
        <span className='text-bold text-med-gray text-small'>for {projectName}</span>
      </div>
      <div className='width-100 margin-t-xlg'>
        <table className='width-100 border-collapse access-table'>
            <tr className='underline-orange'>
              <td className='text-med text-bold pad-md pad-l-lg'>Time</td>

              <td className='text-med text-bold pad-l-lg'>User</td>
              <td className='text-med text-bold pad-l-lg'>Updates</td>
            </tr>
            {accessLogTableView()}
        </table>
      </div>
    </div >
  )
}
export default ProjectAccessLog;
