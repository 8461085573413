import React from 'react';
import {isNote} from './productTypeCheck';
import { roundUp } from '../../../utilities/rounding';
import {useStore} from '../../../StoreProvider';
import {ACTIONS} from '../../../Actions';

const CellPrice = ({product, calculateRow}) => {
    const {state, dispatch} = useStore();
    /**
   * Product list price -
   * user can edit value if no value in database
   * 
   
   * If product is 'note' return blank td - else
     If web listed price has a value from database display value
     else if no price let user input value
   */

    if (isNote(product)) {
        return <td key="list_price" className="col-list_price"></td>;
    }

    if (product.web_listed_price && !product.web_listed_price_altered) {
        return (
            <td key="list_price" className="center col-list_price">
                {`${roundUp(product.web_listed_price)}`}
            </td>
        );
    }

    return (
        <td key="list_price" className="center col-list_price">
            <input
                onWheel={(e) => e.target.blur()}
                type="number"
                className={'center col-list_price' + product.web_listed_price_altered ? 'altered pad-t-xs pad-b-xs text-center' : ''}
                name="web_listed_price"
                value={product.web_listed_price}
                onChange={(e) => {
                    // Update product description in state productTableItems
                    product.web_listed_price = roundUp(e.target.value);
                    product.web_listed_price_altered = true;

                    // Update state with user input changes
                    // Map through previous state, replace product with matching sku, return updated state
                    dispatch({
                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                        payload: state?.productTableItems.map((prod) =>
                            prod.playground_boss_sku === product.playground_boss_sku ? product : prod
                        )
                    });
                    calculateRow(product.playground_boss_sku);
                }}
                // When user leaves table data cell (and stops entering input) calculate the values in the row
                onBlur={(e) => calculateRow(product.playground_boss_sku)}
                disabled={state?.quote.readOnly}
            />
        </td>
    );
};

export default CellPrice;
