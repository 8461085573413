import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StoreProvider } from './StoreProvider';
import CacheBuster from 'react-cache-buster';
import packageInfo from "../package.json";
import LoadingInline from './components/LoadingInline';
import App from './App';
import ErrorBoundary from './utilities/errorBoundary';
import * as Sentry from "@sentry/react";

const version = packageInfo.version;

function onCacheClear(refreshCacheAndReload) {
    if (!window.location.hash.includes('updated')) window.location.hash = 'updated'; // UI will be added later to notify user of update if the hash is present
    refreshCacheAndReload();
};

function formatName(email) {
    const username = email.split('@')[0];
    if (username.includes('.')) {
        return username.split('.').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
    } else {
        return username.charAt(0).toUpperCase() + username.slice(1);
    }
}

const username = localStorage.getItem("username");
console.log("Username from localStorage:", username);
const email = username || '';
const name = username ? formatName(username) : '';

let environment;
const apiBase = process.env.REACT_APP_API_BASE;

if (apiBase.includes("localhost")) {
    environment = "development";
} else if (apiBase.includes("staging-api.pgbquote.com")) {
    environment = "staging";
} else if (apiBase.includes("api.pgbquote.com")) {
    environment = "production";
}

Sentry.setUser({
    email: email,
    fullName: name,
  });

Sentry.init({
    dsn: "https://8d00425ad323519397e187e9d81f84ef@o4506536560951296.ingest.sentry.io/4506587522596864",
    environment: environment,

    integrations:
        [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [apiBase]
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
            new Sentry.Feedback({
                autoInject: true,
                showBranding: false,
                colorScheme: "light",
                showName: false,
                showEmail: false,
                useSentryUser: {
                    email: "email",
                    name: "fullName",
                },
                formTitle: "Send Feedback",
                buttonLabel: "Send Feedback",
                submitButtonLabel: "Send",
                messagePlaceholder: "What message would you like to send?",
                successMessageText: "Thanks for your feedback!",
            }),
        ],

    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,
});

// Find the root element in your HTML
const container = document.getElementById('root');

// Create a root
const root = createRoot(container);


root.render(
    <React.StrictMode>
        <StoreProvider>
            <ErrorBoundary>
                <CacheBuster
                    currentVersion={version}
                    isEnabled={true}
                    isVerboseMode={false}
                    loadingComponent={<LoadingInline iconSize='large-icon' cacheBusting={true} title='' />}
                    metaFileDirectory={'.'}
                    onCacheClear={onCacheClear}
                >
                    <BrowserRouter> {/* Wrap App component with BrowserRouter */}
                        <App />
                    </BrowserRouter>
                </CacheBuster>
            </ErrorBoundary>
        </StoreProvider>
    </React.StrictMode>
);
