import React from 'react';
import formatNumber from '../../utilities/formatNumber';
import IncludeTaxes from './IncludeTaxes';
import addDisabledClass from '../../utilities/addDisabledClass';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const QuoteSummary = ({setRecalculateTotals, setAutoSave}) => {
    const { state, dispatch } = useStore();

    return (
        <div className="quoteSummary component-card">
            <h4 className="quoteSummary_title pad-l-lg pad-t-lg">Quote Summary</h4>
            {/* Taxes toggle button */}
            <IncludeTaxes setRecalculateTotals={setRecalculateTotals} />
            <div className="quoteSummary_grid pad-l-lg pad-r-lg pad-b-lg">
                {/* Price / subtotal */}
                <p className="quoteSummary_label text-bold">Price:</p>
                <p className="quoteSummary_value">
                    $ {state?.quote.customer_subtotal && `${formatNumber(state?.quote.customer_subtotal)}`}
                </p>

                {/* Taxes */}
                <p className={addDisabledClass('quoteSummary_label', !state?.quote.includeTaxes)}>Taxes:</p>
                <p className={addDisabledClass('quoteSummary_value', !state?.quote.includeTaxes)}>
                    {state?.quote.includeTaxes ? `+ $ ${state?.quote.total_taxes && `${formatNumber(state?.quote.total_taxes)}`}` : '+ $ 0'}
                </p>

                {/* Freight */}
                <p className="quoteSummary_label">Freight:</p>
                <p className="quoteSummary_value">
                    + $ {/* Display freight price if it has a value, if no value display 0 */}
                    {state?.quote?.freight?.customerPrice ? `${formatNumber(state?.quote?.freight?.customerPrice)}` : '0'}
                </p>

                {/* Installation */}
                <p className={addDisabledClass('quoteSummary_label', !state?.quote?.installation?.includeInstallation)}>
                    Installation:
                </p>
                <p className={addDisabledClass('quoteSummary_value', !state?.quote?.installation?.includeInstallation)}>
                    {/* If include installation isn't checked, display value 0. If no value is available display 0 */}+
                    ${' '}
                    {state?.quote?.installation?.includeInstallation
                        ? `${
                              state?.quote?.installation?.customerPrice
                                  ? `${formatNumber(state?.quote?.installation?.customerPrice)}`
                                  : '0'
                          }`
                        : '0'}
                </p>

                {/* Discount - line item total*/}
                <p className="quoteSummary_label">Discount:</p>
                <p className="quoteSummary_value">
                    $ {state?.quote?.discount?.lineItemsDiscount ? `${formatNumber(state?.quote?.discount?.lineItemsDiscount)}` : '0'}
                </p>

                {/* Discount - additional discount */}
                <p className="quoteSummary_label">Add. Discount:</p>
                <p className="quoteSummary_value">
                    - $ {state?.quote?.discount?.additionalDiscount ? `${formatNumber(state?.quote?.discount?.additionalDiscount)}` : '0'}
                </p>

                {/* Total price to customer */}
                <p className="quoteSummary_label totalPrice text-bold">Total Price:</p>
                <p className="quoteSummary_value totalPrice">
                    $ {state?.quote?.customer_totalPrice && `${formatNumber(state?.quote?.customer_totalPrice)}`}
                </p>

                {/*Quote Summary Internal Notes*/}
                <p className={(addDisabledClass('quoteSummary_label', state?.quote.readOnly), ' quoteSummary internalNotes text-bold')}>
                    Internal Notes:
                </p>
                <textarea
                    value={state?.quote?.internal_notes}
                    className='pad-xs'
                    onChange={(e) =>
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {...state?.quote, internal_notes: e.target.value},
                        })
                    }
                    onBlur={async (e) => {
                        setAutoSave(true);
                    }}
                    disabled={state?.quote.readOnly}
                />
            </div>
        </div>
    );
};

export default QuoteSummary;
