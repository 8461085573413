import React from 'react';
import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';
import PGLogo from '../assets/PGLogo.png'
import TotalProfit from './quote/TotalProfit';
import LogoutLink from './auth/LogoutLink';
import connectQuoteData from '../utilities/calculateNavbar';
import formatNumber from '../utilities/formatNumber';
import LoadingInline from '../components/LoadingInline';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';

const Navbar = ({pathname}) => {
    const { state, dispatch } = useStore();
    // If user isn't logged in show this navbar
    const [costSheetInfo, setCostSheetInfo] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        retrieveCostSheetInfo();
    }, [state?.navbarQuoteID]);

    if (!state?.auth.loggedIn) {
        return null;
    }

    async function retrieveCostSheetInfo() {
        if (!state?.navbarQuoteID) return;

        try {
            let costSheetInfoRetrieved = await connectQuoteData(state?.auth, state?.navbarQuoteID);

            setCostSheetInfo(costSheetInfoRetrieved)
            setLoading(false)
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    // Home route / Browsing all saved quotes
    const NoQuote = () => (
        <div className="flex width-100 justify-between">
            <div className="flex align-middle">              
                <li
                    className="flex align-middle"
                    onClick={() => {
                        dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: "" });
                        dispatch({ type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: [] });
                    }}>
                    <Link to="/contactDetails" className={`no-underline margin-l-md text-bold text-med ${pathname === '/contactDetails' ? 'text-orange' : 'text-blue'}`}>New Quote</Link>
                </li>
            </div>

            <div className="flex">
                {/* When logged in username and logout button */}
                {state?.navbarQuoteID.length > 0 ? (
                    <>
                    {loading ? (
                        <div className="productsTotal">
                            <p className="text-med text-bold">Profit Margin</p>
                            <p className="margin-r-md"><LoadingInline iconSize="small-icon" bold={true} title='' /></p>
                            <p className="text-med text-bold">Total Profit</p>
                            <p className="margin-r-md"><LoadingInline iconSize="small-icon" bold={true} title='' /></p>
                        </div>
                    ) : (
                        <div className="productsTotal">
                            <p className="text-med text-bold margin-r-sm">Profit Margin</p>
                            <p className="text-profit-margin text-bold text-green margin-r-sm"> {costSheetInfo?.totalMargin ? formatNumber(costSheetInfo?.totalMargin) + '%' : '0%'}</p>
                            <p className="text-med text-bold margin-r-sm">Total Profit</p>
                            <p className="text-profit-margin text-bold text-green margin-r-sm">{costSheetInfo?.totalProfit ? '$' + formatNumber(costSheetInfo?.totalProfit) : '$0'}</p>
                        </div>
                        )}
                    </>
                ) : (
                    <>
                    {state?.auth.loggedIn ? (
                        <>
                            <li className='text-med text-bold text-black margin-r-xxxlg'>{state?.auth.username}</li>
                            <li className = 'margin-l-xxxlg'>
                                <LogoutLink />
                            </li>
                        </>
                    ) : (
                        <li>
                            <Link to="/login">Login</Link>
                        </li>
                    )}
                </>
                )}  
            </div>
        </div>
    );

    // When in quote
    const WithQuote = () => (
        <div className="flex justify-between width-100 ">
            <div className="flex ">
                <li className="flex align-middle">
                    <li className="text-med">
                        <Link to={`/contactDetails/${state?.quote?.quote_number}`} className={`no-underline ${pathname === '/contactDetails' ? "text-orange" : "text-blue"}`}>
                            Contact Details
                        </Link>
                    </li>                      
                </li>
                <li className="flex align-middle margin-l-xxxlg">
                    <li className= "text-med">
                        <Link to={`/viewQuote/${state?.quote.quote_number}`} className={`${pathname.includes("/viewQuote") ? "text-orange" : 'text-blue'} no-underline`}>
                            Quote#{state?.quote.quote_number}
                        </Link>
                    </li> 
                </li>
                {state?.quote.readOnly && (!pathname.includes("/viewQuote") && !pathname.includes("/costsheet")) ? (
                        <li className="flex align-middle margin-l-xxxlg">
                            <li className = 'text-med'>
                                <Link to={`/operations/costsheet/${state?.quote.quote_number}`} className={`${pathname.includes("/costsheet") ? "text-orange" : "text-blue"} no-underline`}>
                                    Cost Sheet
                                </Link>
                            </li>
                        </li>
                    ) : (
                        <div></div>
                    )
                }
            </div>
            <li className="margin-t-sm">
                <TotalProfit />
            </li>
        </div>
    );

    return (
        <nav className="pad-l-none pad-r-none">
            {state?.globalErrorMessage?.length > 0 &&
                <div class="glb-error-message">
                    <p>Error: {state?.globalErrorMessage}</p>
                </div> 
            }
            <ul className='flex-row align-center gap-lg list-none pad-l-60 pad-r-60'>
                {/* Link to home page */}
                <li className='flex align-middle' ><img src={PGLogo} alt='PGBLogo'></img></li>
                    <li id="viewQuotesBtn" className="text-med margin-l-md"
                    onClick={() => {
                        dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: "" });
                        }}>
                        <Link to="/" className={`no-underline ${pathname === '/' ? "text-orange" : "text-blue"}`}>     
                            Quotes
                        </Link>
                    </li>
                {!state?.quote.id ? <NoQuote /> : <WithQuote />}
            </ul>
        </nav>
    );
};

export default Navbar;
