import React from 'react';
import formatNumber from '../../utilities/formatNumber';
import roundDollar from '../../utilities/roundDollar';
import addDisabledClass from '../../utilities/addDisabledClass';
import formatInput from '../../utilities/formatInput';
import {useStore} from '../../StoreProvider';
import {ACTIONS} from '../../Actions';


const Installation = ({setRecalculateTotals, setProductChange, setAutoSave}) => {
    const {state, dispatch} = useStore();

    return (
        <div className="installation component-card">
            <h4 className="installation_title pad-l-lg pad-t-lg">Installation</h4>
          
                {/* Include installation */}
            <div className = 'pad-l-lg '>
                <div className = 'flex column-gap-sm'>
                    <input
                        id="includeInstallation"
                        type="checkbox"
                        className = "sm-box pointer"
                        checked={state?.quote?.installation?.includeInstallation}
                        onChange={(e) => {
                            dispatch({
                                type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                    installation: {
                                        ...state?.quote?.installation,
                                        suggestedCost: e.target.checked ? state?.quote['installation']['suggestedCost'] : 0,
                                        includeInstallation: e.target.checked,
                                        cost: e.target.checked ? state?.quote['installation']['cost'] : 0,
                                        customerPrice: e.target.checked
                                            ? roundDollar(state?.quote['installation']['customerPrice'])
                                            : 0,
                                        suggestedPrice: e.target.checked
                                            ? roundDollar(state?.quote['installation']['suggestedPrice'])
                                            : 0,
                                        description: e.target.checked ? state?.quote['installation']['description'] : '',
                                    },
                            },
                            });
    
                        setProductChange(true);
                        }}
                        disabled={state?.quote.readOnly}
                    />
                    <label htmlFor="includeInstallation" className = "pointer" >
                        Include Installation?
                    </label>
                </div>
            </div>
            <div className="installation_grid pad-l-lg pad-r-lg pad-b-lg">
                {/* Suggested installation Cost */}
                <p className={`text-bold ${addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}`}>
                    Sugg Install Cost:
                </p>
                <p className={addDisabledClass('installation_value', !state?.quote?.installation?.includeInstallation)}>
                    $ {state?.quote?.installation?.includeInstallation ? roundDollar(state?.quote?.installation?.suggestedCost) : ''}
                </p>

                {/* Suggested installation Price */}
                <p className={`text-bold ${addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}`}>
                    Sugg Install Price:
                </p>
                <p className={addDisabledClass('installation_value', !state?.quote?.installation?.includeInstallation)}>
                    $ {state?.quote?.installation?.includeInstallation ? roundDollar(state?.quote?.installation?.suggestedPrice) : ''}
                </p>

                {/* Display install margin */}
                <p className={`text-bold ${addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}`}>
                    Install Margin:
                </p>

                <p className={addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}>
                    {state?.quote?.installation?.cost == state?.quote?.installation?.customerPrice
                        ? `0%`
                        : state?.quote?.installation?.cost && !state?.quote?.installation?.customerPrice
                        ? <span className = 'text-smaller'>Confirm with Manager</span>
                        : state?.quote?.installation?.cost && state?.quote?.installation?.customerPrice == 0
                        ? <span className = 'text-smaller'>Confirm with Manager</span>
                        : state?.quote?.installation?.cost &&
                          state?.quote?.installation?.customerPrice &&
                          `${formatNumber(
                              ((state?.quote?.installation?.customerPrice - state?.quote?.installation?.cost) /
                                  state?.quote?.installation?.customerPrice) *
                                  100
                          )}%`
                    }
                </p>
    
                {/* Installation cost */}
                <p className={`text-bold margin-b-xs ${addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}`}>
                    Installation Cost:{' '}
                </p>

                {/* Form input - installation cost */}
                <input
                    onWheel={(e) => e.target.blur()}
                    className="installation_value margin-b-xs pad-l-xs pad-r-xs"
                    type="number"
                    value={formatInput(state?.quote?.installation?.cost)}
                    onChange={(e) => {
                        {
                            dispatch({
                                type: ACTIONS.QUOTE,
                                payload: {
                                    ...state?.quote,
                                    installation: {
                                        ...state?.quote?.installation,
                                        cost: e.target.value || null,
                                    },
                                },
                            });
                        }
                    }}
                    disabled={!state?.quote?.installation?.includeInstallation || state?.quote?.readOnly}
                />

                {/* Installation customer price */}
                <p className={`text-bold ${addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}`}>
                    Customer Price:{' '}
                </p>
                <input
                    onWheel={(e) => e.target.blur()}
                    className="installation_value pad-l-xs pad-r-xs"
                    type="number"
                    value={formatInput(state?.quote?.installation?.customerPrice)}
                    onChange={(e) => {
                        {
                            dispatch({
                                type: ACTIONS.QUOTE,
                                payload: {
                                    ...state?.quote,
                                    installation: {
                                        ...state?.quote?.installation,
                                        customerPrice: e.target.value || null,
                                    },
                                },
                            });
                        }
                    }}
                    disabled={!state?.quote?.installation?.includeInstallation || state?.quote.readOnly}
                />

                {/* Installation description */}
                <p className={`text-bold ${addDisabledClass('installation_label', !state?.quote?.installation?.includeInstallation)}`}>
                    Installation Description:{' '}
                </p>
                <textarea
                    className="installation_value pad-xs"
                    type="number"
                    value={state?.quote?.installation?.description}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                installation: {
                                    ...state?.quote?.installation,
                                    description: e.target.value,
                                },
                            },
                        });
                    }}
                    onBlur={async (e) => {
                        setAutoSave(true);
                        setTimeout(() => setRecalculateTotals(true), 10);
                    }}
                    disabled={!state?.quote?.installation?.includeInstallation || state?.quote.readOnly}
                />
            </div>
        </div>
    );
};

export default Installation;
