import React from 'react';

const SubmitBtn = ({title = 'Submit', disabled = false}) => {
    return (
        <button className={(disabled ? 'btn-disabled pad-tb-md min-width-150px text-center justify-center ' 
        : 'bg-light-green green-button text-white b-none box-shadow pointer border-radius-xs ') 
        + `flex align-center pad-tb-md text-bold min-width-150px justify-center btn`} 
        type="submit" disabled={disabled}>
            {title}
        </button>
    );
};

export default SubmitBtn;
