import React from 'react';
import {isNote} from './productTypeCheck';
import formatNumber from '../../../utilities/formatNumber';

const CellMargin = ({product}) => {
    // Return blank td if product is a note or has no margin value
    if (isNote(product) || !product.margin) {
        return <td key="margin" className="center col-margin"></td>;
    }

    return (
        <td key="margin" className="center col-margin">
            {`${formatNumber(product.margin)}%`}
        </td>
    );
};

export default CellMargin;
