const convertJSON = (response) => {
    if (response.status === 403) {

        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('isOps');

        window.location.replace(window.location.origin + '/auth/login/timedOut')
    return;
    }
    return response.json()
    }

    export default convertJSON;