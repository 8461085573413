import React from 'react';
import Btn from '../Btn';
import {useNavigate} from 'react-router-dom';

const SendEmailBtn = ({quoteNumber}) => {
    const navigate = useNavigate();

    const onClick = async (e) => {
        navigate(`/sendEmail/${quoteNumber}`);
    };

    return <div className="saveQuote">
        <Btn title="Send Email" onClick={onClick} 
        btnClass="pad-tb-md bg-blue text-white text-bold b-none blue-button box-shadow min-width-150px justify-center" />
    </div>;
};

export default SendEmailBtn;
