import React, {useEffect, useState} from 'react';

const IncomeAcc = ({put, setPut, product, populateAccounts}) => {
    //Filter Income Accounts
    const [incomeAccounts, setIncomeAccounts] = useState();

    //Loading States
    const [loadingIncome, setLoadingIncome] = useState(true);

    //Display for account input
    const [incomeDisplay, setIncomeDisplay] = useState();

    //Set Income and Expense Change Default
    //Populate Accounts On Mount
    useEffect(() => {
        const filteredAccounts = populateAccounts.filter((account) => account.income === true);
        setIncomeAccounts(filteredAccounts);

        if (product.qb_income_acc) {
            let incomeAcc = filteredAccounts.filter((account) => account.id === product.qb_income_acc);

            if (incomeAcc.length > 0) {
                setIncomeDisplay(incomeAcc[0].reference);
                setLoadingIncome(false);
            }
        } else {
            setIncomeDisplay('');
            setLoadingIncome(false);
        }
    }, []);

    if (loadingIncome == true) {
        return <td colSpan="6">Loading...</td>;
    }

    if (loadingIncome == false) {
        return (
            <>
                <td colSpan="1">Income Account</td>
                <td colSpan="2">
                    <input
                        className="inputWidth"
                        list="income-results-list"
                        name="income-results-input"
                        placeholder="Select Income Account"
                        defaultValue={incomeDisplay}
                        onChange={(e) => {
                            let incomeAcc = incomeAccounts.filter((account) => account.reference === e.target.value);

                            if (incomeAcc.length > 0) {
                                setIncomeDisplay(incomeAcc[0].reference);
                                setPut({...put, qb_income_acc: incomeAcc[0].id});
                            }
                        }}
                    />
                    <datalist id="income-results-list">
                        {incomeAccounts.map((account, i) => (
                            <option value={account.reference} key={i} />
                        ))}
                    </datalist>
                </td>
            </>
        );
    }
};

export default IncomeAcc;
