import React, {useEffect, useState} from 'react';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

/**
 * When new toast is added to 'toast - Push to component level state array
 * Create array of all toasts that auto delete after x amount of time
 *
 */

const Toast = () => {
    // Toast schema
    // const { message, isError } = toast;
    const { state, dispatch } = useStore();

    const [toasts, setToasts] = useState([]);

    useEffect(() => {
        // When new toast is set to state - add it onto end of local state toasts array
        setToasts((prevState) => [...prevState, state?.toast]);

        // Clear toast after certain amount of time
        const interval = setInterval(() => {
            setToasts([]);
            dispatch({ type: ACTIONS.TOAST, payload: {} });
        }, 3500);

        return () => {
            clearInterval(interval);
        };
    }, [state?.toast]);

    return (
        <div className="toast">
            <ul className="toast_list">
                {toasts.map((t) => (
                    // If toast is for an error, apply toast-error class
                    <li className={`toast-${t.isWarning ? 'warning' : !t.isError ? 'success' : 'error'}`}>{t.message}</li>
                ))}
            </ul>
        </div>
    );
};

export default Toast;
