import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import Btn from '../components/Btn';
import checkmark from '../../src/assets/checkmark.svg';
import xmark from '../../src/assets/xmark.svg';
import xIcon from '../../src/assets/xicon.svg';
import warning from '../../src/assets/warning.svg';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import LoadingInline from '../components/LoadingInline';
import loadingGif from '../../src/assets/loading.gif';
import { useCacheBuster } from 'react-cache-buster';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import formatNumber from '../utilities/formatNumber'
import formatDate from '../utilities/formatDate';
import formatTimestamp from '../utilities/formatTimestamp';

const Projects = () => {
  const { state, dispatch } = useStore();
  const { checkCacheStatus } = useCacheBuster();
  const navigate = useNavigate();
  const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
  const dropdownMenuRef = useRef(null);
  const tagDropdownMenuRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openTagMenu, setOpenTagMenu] = useState(false);
  const [issuesOption, setIssuesOption] = useState(false);
  const [lateOption, setLateOption] = useState(false);
  const [requiredResponseOption, setRequiredResponseOption] = useState(false);
  const [projectsListed, setProjectsListed] = useState();
  const [filteredProjects, setFilteredProjects] = useState();
  const [projectInstaller, setProjectInstaller] = useState({});
  const [searchTermFilter, setSearchTermFilter] = useState('');
  const [boughtDateFilter, setBoughtDateFilter] = useState('');
  const [activityDateFilter, setActivityDateFilter] = useState('');
  const [installerNameFilter, setInstallerNameFilter] = useState('');
  const [projectOwnerFilter, setProjectOwnerFilter] = useState('');
  const [signedPerforma, setSignedPerforma] = useState(false);
  const [customerInvoiced, setCustomerInvoiced] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [poSubmitted, setPoSubmitted] = useState(false);
  const [complete, setComplete] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [installers, setInstallers] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusPOs, setStatusPOs] = useState({});
  const [syncingProjects, setSyncingProjects] = useState(false);
  const [createProjectModal, setCreateProjectModal] = useState(false);
  const [createProjectLoading, setCreateProjectLoading] = useState(false);
  const [quoteNumber, setQuoteNumber] = useState('');
  const [qbProjectNumber, setQbProjectNumber] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [lastProjectSync, setLastProjectSync] = useState('');
  const [activityCheckboxChecked, setActivityCheckboxChecked] = useState(false);
  const [projectOwnerCheckbox, setProjectOwnerCheckbox] = useState(false);

  const closeOpenMenu = (e) => {
    if (dropdownMenuRef.current && openMenu && !dropdownMenuRef.current.contains(e.target) && !e.target?.className?.includes('dropdown-menu')) {
      setOpenMenu(false);
    }
  }

  document.addEventListener('mouseup', closeOpenMenu)

  const closeOpenTagMenu = (e) => {
    if (tagDropdownMenuRef.current && openTagMenu && !tagDropdownMenuRef.current.contains(e.target) && !e.target?.className?.includes('tag-dropdown-menu')) {
      setOpenTagMenu(false);
    }
  }

  document.addEventListener('mouseup', closeOpenTagMenu)

  useEffect(() => {
    checkCacheStatus();
    (async () => {
      await loadProjects();
    })();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${config.base_api}/projects/projectSync`, { headers });
        const syncInfo = await convertJSON(response);

        const latestTimestamp = syncInfo[0]?.syncTimestamp;
        
        const formattedDateAndTime = formatTimestamp(latestTimestamp);
        setLastProjectSync(formattedDateAndTime);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [projectsListed])

  const loadProjects = async () => {
    let projects = [];
    setLoading(true);
    try {
      let response;
      response = await fetch(`${config.base_api}/projects/`, { headers });
      projects = await convertJSON(response);
    } catch (err) {
      console.log(err)
      projects = [];
    }

    let updatedProjects = [];
    try {
      updatedProjects = await Promise.all(projects.map(async (project, i) => {
        project['tags'] = { issues: false, late: false, responseRequired: false, complete: false, cancelled: false };
        project['poSubmitted'] = { 'NA': false, 'CHECK': false, 'X': false };
        project['signedPerformaStatus'] = { 'NA': false, 'CHECK': false, 'X': false };

        if (project.last_activity) {
          let lastActivity = new Date(project.last_activity);
          let nextDay = lastActivity.setDate(lastActivity.getDate() + 1);
          project.last_activity = formatDate(nextDay).replace(/-/g, "/")
        }

        project = await issuesTag(project);
        project = await lateTag(project);
        project = await responseRequiredTag(project);
        project = await completeTag(project);
        project = await cancelledTag(project);

        return project
      }));

      const noCompletedProjects = updatedProjects.filter(project => !project.complete);
      const noCancelledProjects = noCompletedProjects.filter(project => !project.cancelled);
      setFilteredProjects(noCancelledProjects);
      setLoading(false);
    } catch (err) {
      console.log(err)
      setLoading(false);
      dispatch({ type: ACTIONS.TOAST, payload: { isError: true, message: 'Error loading projects' } });
    }

    let installerDropdown = [];
    try {
      let allPOs = await fetch(`${config.base_api}/projects/POs`, { headers });
      allPOs = await convertJSON(allPOs);
      let installerData = {};
      let statusData = {};
      allPOs?.forEach(po => {
        let newPoValue = {};
        if (po?.project_id) {
          if (po?.po_submitted) newPoValue = { [po?.project_id]: [{ 'poSubmitted': true, 'name': po?.project_name, id: po?.qb_po_id }] };
          else newPoValue = { [po?.project_id]: [{ 'poSubmitted': false, 'name': po?.project_name, id: po?.qb_po_id }] };

          if (statusData[po?.project_id]) {
            newPoValue = newPoValue[po?.project_id][0];
            statusData = { ...statusData, ...statusData[po?.project_id].push(newPoValue) };
          } else {
            statusData = { ...statusData, ...newPoValue };
          }
        }

        if (po?.project_id && po?.type === 'installation') {
          if (!installerDropdown.includes(po?.install_name) && po?.install_name?.trim()) installerDropdown?.push(po?.install_name);
          let installerObject = {};
          if (!installerData[po?.project_id]) installerObject = { [po?.project_id]: [{ name: po?.install_name, date: po?.install_date }] };
          else installerObject = { ...installerData[po?.project_id].push({ name: po?.install_name, date: po?.install_date }) };
          installerData = {
            ...installerData,
            ...installerObject,
          };
        }
      });
      updatedProjects?.forEach(project => {
        project.installation_company = [];
        if (installerData[project?.project_id]) {
          installerData[project?.project_id]?.forEach((installer, i) => {
            if (i === 0) project.installation_company = [installer?.name];
            else project.installation_company.push(installer?.name);
          });
        }
      });

      installerDropdown.sort();
      setStatusPOs(statusData);
      setInstallers(installerDropdown);
      setProjectsListed(updatedProjects);

      setProjectInstaller(installerData);
    } catch (err) {
      console.log(err);
      dispatch({ type: ACTIONS.TOAST, payload: { isError: true, message: 'Error loading installers' } });
    }
    connectDBUsers();
  }

  useEffect(() => {
    async function fetchData() {
      if (syncingProjects) {
        await syncProjectNames();

        let projects = [];
        try {
          let response;
          response = await fetch(`${config.base_api}/projects/`, { headers });
          projects = await convertJSON(response);
        } catch (err) {
          console.log(err)
          projects = [];
        }

        let updatedProjects = [];
        try {
          updatedProjects = await Promise.all(projects.map(async (project, i) => {
            project['tags'] = { issues: false, late: false, responseRequired: false, complete: false, cancelled: false };
            project['poSubmitted'] = { 'NA': false, 'CHECK': false, 'X': false };
            project['signedPerformaStatus'] = { 'NA': false, 'CHECK': false, 'X': false };

            if (project.last_activity) {
              let lastActivity = new Date(project.last_activity);
              let nextDay = lastActivity.setDate(lastActivity.getDate() + 1);
              project.last_activity = formatDate(nextDay).replace(/-/g, "/")
            }

            project = await issuesTag(project);
            project = await lateTag(project);
            project = await responseRequiredTag(project);
            project = await completeTag(project);
            project = await cancelledTag(project);

            return project
          }));
          setProjectsListed(updatedProjects);
          const noCompletedProjects = updatedProjects.filter(project => !project.complete);
          const noCancelledProjects = noCompletedProjects.filter(project => !project.cancelled);
          setFilteredProjects(noCancelledProjects);
          setLoading(false);
        } catch (err) {
          console.log(err)
        }
      }
    }

    fetchData();
  }, [syncingProjects])

  const connectDBUsers = async () => {
    let dropdownArray = []
    try {
      const res = await fetch(`${config.base_api}/users/db`, { headers });
      const data = await convertJSON(res);

      let awsUsers = data?.filter((user) => user?.aws_id !== null)

      for (let i = 0; i < awsUsers.length; i++) {
        let userName = awsUsers[i]?.name || awsUsers[i]?.email.toLowerCase();
        let userEmail = awsUsers[i]?.email.toLowerCase();
        let isOperations = awsUsers[i]?.operations;

        if (isOperations == true) {
          dropdownArray.push({ userName, userEmail })
        }
      }
    } catch (error) {
      console.log(error)
    }

    setUserDropdown(dropdownArray)
  }

  const matchesSearch = (field) => {
    if (searchTermFilter?.trim()) {
      return field?.toLowerCase()?.includes(searchTermFilter?.toLowerCase()?.trim());
    }
    return false;
  }

  useEffect(() => {
    if (!projectsListed) return;

    const projects = projectsListed.filter(project => {
      if (searchTermFilter?.trim() &&
        !(matchesSearch(project.project_name) || matchesSearch(project.contact) || matchesSearch(project.project_id))) {
        return false;
      }

      if (boughtDateFilter) {
        let dateBought = new Date(project.date_bought);
        let boughtDate = new Date(boughtDateFilter + 'T08:00:00.000Z');
        let boughtDateStr = boughtDate.getFullYear() + '-' + (boughtDate.getMonth() + 1) + '-' + boughtDate.getDate();
        let dateBoughtStr = dateBought.getFullYear() + '-' + (dateBought.getMonth() + 1) + '-' + dateBought.getDate();
        if (boughtDateStr !== dateBoughtStr) {
          return false;
        };
      }

      if (activityDateFilter) {
        let lastActivity = new Date(project.last_activity);
        let activityDate = new Date(activityDateFilter + 'T08:00:00.000Z');
        activityDate = activityDate.getFullYear() + '-' + (activityDate.getMonth() + 1) + '-' + activityDate.getDate();
        lastActivity = lastActivity.getFullYear() + '-' + (lastActivity.getMonth() + 1) + '-' + lastActivity.getDate();
        if (activityDate !== lastActivity) {
          return false;
        }
      }

      if (activityCheckboxChecked) {
        let lastActivity = new Date(project.last_activity);
        let activityDate = new Date();

        activityDate.setDate(activityDate.getDate() - 7);
        activityDate = activityDate.toISOString().split('T')[0];
        lastActivity = lastActivity.toISOString().split('T')[0];

        if ((lastActivity > activityDate) || (project.last_activity === null)) {
          return false;
        }
      }

      if (installerNameFilter && !project?.installation_company?.includes(installerNameFilter)) {
        return false;
      }

      if (projectOwnerFilter) {
        if (projectOwnerFilter === "Unassigned") {
          if (project?.owner) {
            return false;
          }
        } else if (!project?.owner?.includes(projectOwnerFilter)) {
          return false;
        }
      }

      if (signedPerforma && project.all_signed_performa !== signedPerforma) {
        return false;
      }

      if (customerInvoiced && project.customer_invoiced !== customerInvoiced) {
        return false;
      }

      if (poSubmitted && project.all_pos_submitted !== poSubmitted) {
        return false;
      }

      if (invoicePaid && project.all_invoices_paid !== invoicePaid) {
        return false;
      }

      if (issuesOption && !project?.tags?.issues) {
        return false;
      }

      if (lateOption && !project?.tags?.late) {
        return false;
      }

      if (requiredResponseOption && !project?.tags?.responseRequired) {
        return false;
      }

      if (complete !== undefined) {
        if (project.complete !== complete) {
          return false;
        }
      }

      if (cancelled) {
        if (!project.cancelled) {
          return false;
        }
      } else if (cancelled === false) {
        if (project.cancelled) {
          return false;
        }
      }

      return true;
    });

    if (projectOwnerCheckbox) {
      projects.sort((a, b) => {
        if (a.owner === null || a.owner === undefined) {
          return 1; 
        }
        if (b.owner === null || b.owner === undefined) {
          return -1; 
        }

        return a.owner.localeCompare(b.owner);
      });
    }

    setFilteredProjects(projects);
  }, [searchTermFilter, boughtDateFilter, activityDateFilter, activityCheckboxChecked, installerNameFilter, projectOwnerFilter, signedPerforma, 
    customerInvoiced, poSubmitted, invoicePaid, complete, cancelled, issuesOption, lateOption, requiredResponseOption, projectOwnerCheckbox]);

  const completeTag = (project) => {
    if (project.complete) {
      project['tags'] = { ...project.tags, complete: true };
    }
    return project;
  }

  const responseRequiredTag = (project) => {
    if ((project.has_po_submitted && !project.all_pos_submitted) || (project.has_signed_performa && !project.all_signed_performa)) {
      project['tags'] = { ...project.tags, responseRequired: true };
    }
    return project;
  }

  const lateTag = (project) => {
    if (lateCompletion(project.preferred_completion, project.complete)) {
      project['tags'] = { ...project.tags, late: true };
    }
    return project;
  }

  const issuesTag = async (project) => {
    if (project.issues_tag) {
      project['tags'] = { ...project.tags, issues: true };
    }
    return project;
  }

  const cancelledTag = async (project) => {
    if (project.cancelled) {
      project['tags'] = { ...project.tags, cancelled: true };
    }
    return project;
  }

  const lateCompletion = (preferredCompletion, projectCompletion = false, index) => {
    if (projectCompletion) return false;

    let today = new Date();
    today = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
    today = new Date(today);
    return (today.getTime() > new Date(convertDate(preferredCompletion)).getTime());
  }

  const syncProjectNames = async () => {
    try {
      const response = await fetch(`${config.base_api}/projects/projectNames/update`, {
        method: 'PATCH',
        headers
      })
      const data = await convertJSON(response);

      setSyncingProjects(false);

      if (data === 'success') {
        dispatch({
          type: ACTIONS.TOAST, payload: {
            message: 'Project names have been updated',
            isError: false,
          }
        })
      } else {
        console.log(data)
        dispatch({
          type: ACTIONS.TOAST, payload: {
            message: 'Project names could not be updated',
            isError: true,
          }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: ACTIONS.TOAST, payload: {
          message: 'Project names could not be updated',
          isError: true,
        }
      })
      setSyncingProjects(false);
    }
  }

  const onClick = (projectID) => {
    navigate(`/operations/projects/${projectID}`)
  }

  const convertDateObject = (date) => {
    if (!date) return '';
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  const convertDate = (timestamp) => {
    if (!timestamp) return '';
    return timestamp.slice(5, 7) + '/' + timestamp.slice(8, 10) + '/' + timestamp.slice(0, 4);
  };

  const handleCheckboxChange = (e) => {
    setActivityCheckboxChecked(e.target.checked);
  }

  const handleProjectOwnerCheckbox = (e) => {
    setProjectOwnerCheckbox(e.target.checked);
  }

  const issues = (tags, index) => {
    let tag = [];
    if (tags.issues) {
      tag.push(<span key={`issues-tag-${index}`} className="bg-red text-white width-fit-content pad-xs pad-l-md pad-r-md border-radius-md text-normal">Issues</span>);
    }
    if (tags.late) {
      tag.push(<span key={`late-tag-${index}`} className="bg-yellow text-black width-fit-content pad-xs pad-l-lg pad-r-lg border-radius-md text-normal">Late</span>);
    }
    if (tags.responseRequired) {
      tag.push(<span key={`response-required-tag-${index}`} className="bg-orange text-white width-fit-content pad-xs pad-l-sm pad-r-sm border-radius-md text-normal">Response Required</span>);
    }
    if (tags.complete) {
      tag.push(<span key={`complete-tag-${index}`} className="bg-green text-white width-fit-content pad-xs pad-l-sm pad-r-sm border-radius-md text-normal">Complete</span>);
    }
    if (tags.cancelled) {
      tag.push(<span key={`cancelled-tag-${index}`} className="bg-dark-gray text-white width-fit-content pad-xs pad-l-sm pad-r-sm border-radius-md text-normal">Cancelled</span>);
    }
    return (<div key={`div-tag-${index}`} className="flex-column justify-center align-center row-gap-sm">{tag}</div>);
  }

  const preferredCompletion = (preferredDate, projectCompleted, index) => {
    const displayResults = [];
    displayResults.push(convertDate(preferredDate));
    if (lateCompletion(preferredDate) && !projectCompleted) {
      displayResults.push(<p key={`late-${index}`} className='pad-l-xs black'>Late</p>)
      displayResults.unshift(<span key={`warning-${index}`} className='pad-r-xs'><img src={warning} className='bold height-1dot5em' /></span>);
    } else {
      displayResults.unshift(<span key={`warnin-${index}`} className='pad-r-xs'><img src={warning} className='bold height-1dot5em hidden' /></span>);
    }
    return <div key={`preferred-completion-div-${index}`} className='flex-row align-center justify-center'>{displayResults}</div>;
  }

  const statusMessage = (type, message, index) => {
    let html;
    const randomNumber = Math.random();

    if (type === 'CHECK') {
      html = <div key={`checkmark-div-${index}-${randomNumber}`} className='table-row'>
        <span key={`checkmark-${index}-${randomNumber}`} className='table-cell text-end pad-r-xs pad-b-xs'><img src={checkmark} className=' text-xs height-1dot5em' /></span>
        {message.length > 17 ?
          <span key={`shortened-text-${index}-${randomNumber}`} className='table-cell'>{message.substring(0, 15) + '...'}</span>
          :
          <span key={`text-${index}-${randomNumber}`} className='table-cell'>{message}</span>
        }
      </div>;
    } else if (type === 'X') {
      html = <div key={`xmark-div-${index}-${randomNumber}`} className='table-row'>
        <span key={`xmark-${index}-${randomNumber}`} className='table-cell text-end pad-r-xs pad-b-xs'><img src={xmark} className=' text-xs height-1dot5em' /></span>
        {message.length > 17 ?
          <span key={`xmark-shortened-text-${index}-${randomNumber}`} className='table-cell'>{message.substring(0, 15) + '...'}</span>
          :
          <span key={`xmark-text-${index}-${randomNumber}`} className='table-cell'>{message}</span>
        }
      </div>;
    } else if (type === 'NA') {
      html = <div key={`na-div-${index}-${randomNumber}`} className='table-row'>
        <span key={`na-${index}`} className='table-cell height-1dot5em bold pad-r-xs letter-spacing-1px pad-b-xs'>N/A</span>
        {message.length > 17 ?
          <span key={`na-shortened-text-${index}-${randomNumber}`} className='table-cell'>{message.substring(0, 15) + '...'}</span>
          :
          <span key={`na-text-${index}-${randomNumber}`} className='table-cell'>{message}</span>
        }
      </div>
    }
    return html;
  }

  const setStatus = (statusHas, statusIs, message, index) => {
    if (statusHas === true) {
      if (statusIs === true) {
        return statusMessage('CHECK', message, index);
      } else {
        return statusMessage('X', message, index);
      }
    } else {
      return statusMessage('NA', message, index);
    }
  }

  const status = (project, index) => {
    let statusList = [];
    const randomNumber = Math.floor(Math.random() * 1000);
    if (project?.has_po_submitted !== undefined) {
      if (project?.all_pos_submitted || !project?.has_po_submitted) {
        statusList.push(setStatus(project?.has_po_submitted, project?.all_pos_submitted, 'All POs Submitted'));
      } else {
        statusPOs[project?.project_id]?.forEach((po) => {
          if (!po?.poSubmitted) {
            statusList.push(statusMessage('X', po?.name, index));
          }
        });
      }
    } else {
      statusList.push(<div className='table-row'>
        <span key={`loading-signed-performa-${randomNumber}`} className='table-cell text-end pad-r-xs'><LoadingInline size='text-sm' title='' /></span>
        <span key={`pos-submitted-${randomNumber}`} className='table-cell vertical-align-middle'>{'All POs Submitted'}</span>
      </div>);
    }

    if (project?.has_signed_performa !== undefined) {
      statusList.push(setStatus(project?.has_signed_performa, project?.all_signed_performa, 'Signed Performa', index));
    } else {
      statusList.push(<div className='table-row'>
        <span key={`loading-pos-submitted-${randomNumber}`} className='table-cell text-end pad-r-xs'><LoadingInline size='text-sm' title='' /></span>
        <span key={`signed-performa-${randomNumber}`} className='table-cell vertical-align-middle'>{'Signed Performa'}</span>
      </div>);
    }

    return <div key={`status-div-${randomNumber}`} className='table horizontal-middle'>{statusList}</div>
  }

  const invoiceStatus = (project) => {
    if (project?.has_invoice !== true) {
      return <div className='table horizontal-middle'>N/A</div>
    } else if (project?.all_invoices_paid === true) {
      return <div className='table horizontal-middle'>
        <span className='table-cell text-bold text-green text-smedium'>PAID</span>
      </div>
    } else {
      return <div>Due: <span className="text-red">${formatNumber(project?.invoice_balance)}</span></div>
    }
  }

  const dropdownClicked = () => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  }

  const tagDropdownClicked = () => {
    if (openTagMenu) {
      setOpenTagMenu(false);
    } else {
      setOpenTagMenu(true);
    }
  }

  const searchProjectsDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg">
        <p className='text-bold no-wrap'>Search Projects:</p>
        <input id="search" type="text" value={searchTermFilter} onChange={(e) => setSearchTermFilter(e.target.value)}
          className="icon-rtl width-fit-content pad-xs border-radius-sm serch-projects-width"
          placeholder="Search by project name, contact name, and job number" />
      </div>
    )
  }

  const displayInstallation = (installersData, index) => {
    let htmlOutput = [];
    const randomNumber = Math.floor(Math.random() * 1000);
    installersData?.map((inst,idx) => {
      if (!inst?.name) {
        if (htmlOutput.length === 0) htmlOutput.push(<p key={`installation-na-${index}-${idx}-${randomNumber}`} className='text-small text-bold text-med-gray'>N/A</p>);
      } else if (!inst?.date) {
        htmlOutput.push(<p key={`installation-name-only-${index}-${idx}-${randomNumber}`} className='line-height-120  overflow-wrap'>{
          inst?.name?.length > 25
            ? inst?.name.substring(0, 22) + '...'
            : inst?.name
        }</p>);
      } else {
        htmlOutput.push(<p key={`installation-name-date-${index}-${idx}-${randomNumber}`} className='line-height-120  overflow-wrap'>{
          inst?.name?.length > 30
            ? inst?.name.substring(0, 27) + '...'
            : inst?.name
        }, {convertDate(inst?.date)}</p>);
      }
    });
    if (htmlOutput.length === 0) {
      htmlOutput.push(<p key={`display-installation-${index}-${randomNumber}`} className='text-small text-bold text-med-gray'>N/A</p>);
    }
    return <div key={`display-output-${index}-${randomNumber}`} className='flex-col align-center justify-center'>{htmlOutput}</div>;
  }

  const quoteBoughtDateDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg">
        <p className='text-bold no-wrap'>Quote Bought Date:</p>
        <input id="bought_date" type="date" value={boughtDateFilter} onChange={(e) => setBoughtDateFilter(e.target.value)}
          className="pad-xs border-radius-sm" />
      </div>
    );
  }

  const lastActivityDateDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg flex-column">
        <p className='text-bold no-wrap'>Last Activity Date:</p>
        <input id="activity_date" type="date" value={activityDateFilter} onChange={(e) => setActivityDateFilter(e.target.value)}
          className="pad-xs border-radius-sm" />
        <div className="flex align-center">
          <label htmlFor="activityCheckbox" className="pointer pad-r-xs">Customer Contact Past 7 Days</label>
          <input type="checkbox" id="activityCheckbox" className="sm-box pointer"
            onChange={handleCheckboxChange} />
        </div>
      </div>
    );
  }

  const projectOwnerDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg">
        <p className='text-bold no-wrap'>Project Owner:</p>
        <select id="project_owner" value={projectOwnerFilter} onChange={(e) => setProjectOwnerFilter(e.target.value)}
          className="pad-xs border-radius-sm fixed-width-project-owner">
          <option value=''>Select Project Owner</option>
          <option value='Unassigned'>Unassigned</option>
          {(userDropdown || [])?.map((user, index) => (
            <option value={user.userName} key={`project-owner-${index}`} className="option-any-length">{user.userName}</option>
          ))}
        </select>
        <div className="flex align-center">
          <label htmlFor="projectOwnerCheckbox" className="pointer pad-r-xs">Sort By First Name:</label>
          <input type="checkbox" id="projectOwnerCheckbox" className="sm-box pointer"
            onChange={handleProjectOwnerCheckbox} />
        </div>
      </div>
    )
  }

  const tagDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg">
        <p className="text-bold no-wrap">Tags:</p>
        <div className="project-dropdowns tag-dropdown-menu width-fit-content" onClick={() => tagDropdownClicked()}>
          <select ref={tagDropdownMenuRef} id="tag" className="pad-xs border-radius-sm" disabled>
            <option>Select Tag</option>
          </select>
        </div>
        <div ref={tagDropdownMenuRef} className={(openTagMenu ? 'tag-dropdown-menu' : 'hide') + ' position-absolute border-radius-xs margin-t-xxs pad-sm flex-col box-shadow bg-white z-index-1'}>
          <label htmlFor="issuesOption" className='checkbox-container margin-b-xs'>Issues
            <input type="checkbox" id="issuesOption" className="custom-checkbox"
              onClick={() => setIssuesOption(!issuesOption)} value="issuesOption" />
            <span className='checkmark'><img src={checkmark} className={issuesOption ? null : 'hide'} /></span>
          </label>
          <label htmlFor="lateOption" className='checkbox-container margin-b-xs'>Late
            <input type="checkbox" id="lateOption" className='custom-checkbox'
              onClick={() => setLateOption(!lateOption)} value="lateOption" />
            <span className='checkmark'><img src={checkmark} className={lateOption ? null : 'hide'} /></span>
          </label>
          <label htmlFor="requiredResponseOption" className='checkbox-container margin-b-xs'>Required Response
            <input type="checkbox" id="requiredResponseOption" className='custom-checkbox'
              onClick={() => setRequiredResponseOption(!requiredResponseOption)} value="requiredResponseOption" />
            <span className='checkmark'><img src={checkmark} className={requiredResponseOption ? null : 'hide'} /></span>
          </label>
        </div>
      </div>
    )
  }

  const installationDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg">
        <p className='text-bold no-wrap'>Installer Name:</p>
        <select id="installer_name" value={installerNameFilter} onChange={(e) => setInstallerNameFilter(e.target.value)}
          className="pad-xs border-radius-sm fixed-width-installer">
          <option value=''>Select Installer</option>
          {(installers || [])?.map((installer, index) => (
            <option value={installer} key={`installers-${index}`} className="option-any-length">{installer}</option>
          ))}
        </select>
      </div>
    )
  }

  const statusDropdown = () => {
    return (
      <div className="pad-r-lg pad-l-lg">
        <p className='text-bold no-wrap'>Status:</p>
        <div className='project-dropdowns dropdown-menu width-fit-content' onClick={() => dropdownClicked()}>
          <select ref={dropdownMenuRef} id="status" className="pad-xs border-radius-sm" disabled>
            <option>Select Status</option>
          </select>
        </div>
        <div ref={dropdownMenuRef} className={(openMenu ? 'dropdown-menu' : 'hide') + ' position-absolute border-radius-xs margin-t-xxs pad-sm flex-col box-shadow bg-white z-index-1'}>
          <label htmlFor="signedPerforma" className='checkbox-container margin-b-xs'>Signed Performa
            <input type="checkbox" id="signedPerforma" className="custom-checkbox"
              onClick={() => setSignedPerforma(!signedPerforma)} value="signedPerforma" />
            <span className='checkmark'><img src={checkmark} className={signedPerforma ? null : 'hide'} /></span>
          </label>

          <label htmlFor="posSubmitted" className='checkbox-container margin-b-xs'>All POs Submitted
            <input type="checkbox" id="posSubmitted" className="custom-checkbox"
              onClick={() => setPoSubmitted(!poSubmitted)} value="posSubmitted" />
            <span className='checkmark'><img src={checkmark} className={poSubmitted ? null : 'hide'} /></span>
          </label>

          <label htmlFor="invoicePaid" className='checkbox-container margin-b-xs'> Invoices Paid
            <input type="checkbox" id="invoicePaid" className='custom-checkbox'
              onClick={() => setInvoicePaid(!invoicePaid)} value="invoicePaid" />
            <span className='checkmark'><img src={checkmark} className={invoicePaid ? null : 'hide'}></img></span>
          </label>

          <label htmlFor="complete" className='checkbox-container margin-b-xs'>Complete
            <input type="checkbox" id="complete" className="custom-checkbox"
              onClick={() => setComplete(!complete)} value="complete" />
            <span className='checkmark'><img src={checkmark} className={complete ? null : 'hide'} /></span>
          </label>
          <label htmlFor="cancelled" className='checkbox-container'>Cancelled
            <input type="checkbox" id="cancelled" className="custom-checkbox"
              onClick={() => setCancelled(!cancelled)} value="cancelled" />
            <span className='checkmark'><img src={checkmark} className={cancelled ? null : 'hide'} /></span>
          </label>
        </div>
      </div>
    );
  }

  const closeCreateModal = async () => {
    setCreateProjectModal(false);
    setQuoteNumber("");
    setQbProjectNumber("");
    setInvoiceNumber("");
  }

  const createProject = () => {
    setQuoteNumber("");
    setQbProjectNumber("");
    setInvoiceNumber("");
    setCreateProjectModal(true);
  }

  const handleCreateProject = async () => {
    setCreateProjectLoading(true);
    let response;
    try {
      const res = await fetch(`${config.base_api}/quickbooks/syncProducts/createProject`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          quote_number: quoteNumber,
          qb_project_id: qbProjectNumber || "",
          invoice_number: invoiceNumber || "",
        }),
      });
      response = await convertJSON(res);

      if (res.ok) {
        setQuoteNumber("");
        setQbProjectNumber("");
        setInvoiceNumber("");
        dispatch({ type: ACTIONS.TOAST, payload: { message: "Project created." } });
        setCreateProjectModal(false);
        setCreateProjectLoading(false);
        await loadProjects();
      } else {
        setCreateProjectLoading(false);
        if (response?.message?.length > 0 & typeof response?.message === 'string') dispatch({ type: ACTIONS.TOAST, payload: { isError: true, message: response.message } });
        else dispatch({ type: ACTIONS.TOAST, payload: { isError: true, message: "Could not create project." } });
      }
    } catch (error) {
      console.log(error);
      setCreateProjectLoading(false);
      dispatch({ type: ACTIONS.TOAST, payload: { isError: true, message: "Could not create project." } });
    }
  }

  const projectsTable = () => {
    if (loading) return (<><td></td><td></td><td></td><td></td><div className='loading-table'><LoadingInline /></div></>);

    return (
      filteredProjects?.length > 0 || loading
        ? filteredProjects?.map((project, index, array) =>
          <tr key={`tr-${index}`} className={index === array.length - 1 ? '' : 'underline-light-gray'}>
            <td key={`date-bought-${index}`} className='no-wrap text-small pad-xlg text-med-gray text-center no-wrap'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{convertDateObject(new Date(project?.date_bought))}</Link></td>
            <td key={`lad-${index}`} className='no-wrap text-small pad-xlg text-med-gray text-center no-wrap'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{project.last_activity}</Link></td>
            <td key={`pid-${index}`} className='no-wrap text-small pad-xlg text-med-gray text-center'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{project.project_id}</Link></td>
            <td key={`name-${index}`} className='text-small pad-xlg text-med-gray text-center overflow-wrap'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline word-wrap line-height-150'>{project.project_name}</Link></td>
            <td key={`owners-${index}`} className='text-small pad-xlg text-med-gray text-center overflow-wrap'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{project.owner || 'Unassigned'}</Link></td>
            <td key={`tag-${index}`} className='no-wrap text-small pad-xlg text-med-gray text-center'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{issues(project.tags, index)}</Link></td>
            <td key={`contact-${index}`} className='text-small pad-xlg text-med-gray text-center overflow-wrap'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline line-height-120'>{project.contact}</Link></td>
            <td key={`invoice-${index}`} className='text-bold no-wrap text-small pad-xlg text-med-gray text-center'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{invoiceStatus(project)}</Link></td>
            <td key={`installation-${index}`} className='text-small pad-xlg text-med-gray text-center overflow-wrap'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline line-height-150'>{displayInstallation(projectInstaller[project?.project_id], index)}</Link></td>
            <td key={`status-${index}`} className='no-wrap text-small pad-xlg text-med-gray text-start'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{status(project, index)}</Link></td>
            <td key={`completion-${index}`} className='no-wrap text-small pad-xlg text-med-gray text-center'><Link to={`/operations/projects/${project.project_id}`} className='no-visited no-underline'>{preferredCompletion(project.preferred_completion, project.complete, index)}</Link></td>
          </tr>
        )
        : <><td></td><td></td><td></td><td></td><div className='flex justify-center align-center font-med pad-md '>
          No Records Found
        </div></>
    )
  }

  return (
    <div id="projectTablePage" className='pad-lg'>
      <span className='text-bold text-lrg pad-l-xs'>Projects</span>

      <div id="projectFilterBar" className="flex align-center justify-between">
        <div className='margin-t-xlg component-card flex-row width-fit-content pad-xlg project_filter_responsive'>
          {searchProjectsDropdown()}
          {quoteBoughtDateDropdown()}
          {lastActivityDateDropdown()}
          {projectOwnerDropdown()}
          {tagDropdown()}
          {installationDropdown()}
          {statusDropdown()}
        </div>

        <div className="flex">
          <Btn title={syncingProjects === true ? <img className='icon-width' src={loadingGif} /> : `Sync Project Names`} onClick={() => setSyncingProjects(true)}
            btnClass='bg-blue blue-button text-white b-none bolder box-shadow btn-pm margin-t-xxlg' />

          <Btn btnClass='bg-blue blue-button text-white b-none bolder box-shadow btn-pm margin-t-xxlg margin-l-xxlg' title='Create Project' onClick={() => createProject()} />
        </div>
      </div>
      <p className="margin-t-md"><i>Last Updated: {lastProjectSync}</i></p>

      <div key={`table-headers`} className='width-100 margin-t-xlg overflow-scroll overflow-horizontal-scroll height-70vh'>
        <table key={`table-headers-card`} className='width-100 border-collapse component-card'>
          <tr key={`table-headers-tr`} className='bg-white position-sticky sticky-underline-orange'>
            <td key={`date-bought-td`} className='pad-l-xlg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Date Bought</td>
            <td key={`lad-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Last Activity Date</td>
            <td key={`job-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Job #</td>
            <td key={`project-name-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Project Name</td>
            <td key={`project-owner-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap  pad-b-xs pad-t-lg'>Project Owner</td>
            <td key={`tags-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap  pad-b-xs pad-t-lg'>Tags</td>
            <td key={`contact-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Contact</td>
            <td key={`invoices-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Invoices</td>
            <td key={`installation-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Installation</td>
            <td key={`status-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Status</td>
            <td key={`preferred-completion-td`} className='pad-l-lg pad-r-lg text-center text-med text-bold no-wrap pad-b-xs pad-t-lg'>Preferred Completion</td>
          </tr>
          {/* in useEffect run query for all projects and add issue tag array of bools (issues: [true, false, true] represents [issues, late, response required])
        then add that array to each project so that it can just pass the array to a function that will determine which tags are shown */}
          {projectsTable()}
        </table>
      </div>
      <div className={`${createProjectModal ? "invoice-modal border-radius-xs b-none height-30" : "hide"}`}>
        <button onClick={closeCreateModal} className='bg-white b-none close-button'>
          <img src={xIcon} alt="close button" className='pointer' />
        </button>
        <div className="pad-xlg margin-t-lg margin-r-md margin-l-md width-100">
          <p className="text-lrg text-bold margin-b-lg">Create Project: </p>
          {createProjectLoading
            ? <LoadingInline bold={true} title='' id='name-modal-loader' />
            : <div className='flex-col'>
              <input type="text" placeholder="Enter quote number" className="pad-xs border-radius-xs gray-border width-100"
                value={quoteNumber} onChange={(e) => setQuoteNumber(e.target.value)}>
              </input>
              <p className='text-light-gray line-height-sm pad-xs margin-b-sm'>
                Required
              </p>

              <input type="text" placeholder="Enter QuickBooks project number" className="pad-xs border-radius-xs gray-border width-100"
                value={qbProjectNumber} onChange={(e) => setQbProjectNumber(e.target.value)}>
              </input>
              <p className='text-light-gray line-height-sm pad-xs margin-b-sm'>
                Optional
              </p>


              <input type="text" placeholder="Enter invoice number" className="pad-xs border-radius-xs gray-border width-100"
                value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)}>
              </input>
              <p className='text-light-gray line-height-sm pad-xs margin-b-sm'>
                Optional
              </p>
            </div>
          }

          <Btn btnClass={`text-xs bolder pad-md margin-t-xxlg absolute sync-button-location ${(quoteNumber?.length < 5 || createProjectLoading) ? "btn-disabled" : "text-white b-none btn-light-green box-shadow"}`}
            title='Create Project' disabled={quoteNumber?.length > 5 ? false : true} onClick={() => handleCreateProject()} />
        </div>
      </div>
      <div id="page-mask" className={`${(createProjectModal) ? "" : "hidden"}`}></div>
    </div >
  )
}
export default Projects;
