import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import Btn from '../Btn';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';

export default function MakePrimaryQuote({setShowCutSheetsModal}) {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const navigate = useNavigate();

    const onClick = async (e) => {
        let body = {
            id: state?.quote?.id,
            hubspot_contact_id: state?.quote?.hubspot_contact_id,
            deal_name: state?.quote?.deal_name,
        };

        try {
            const response = await fetch(`${config.base_api}/quote/makePrimaryQuote/`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(body),
            });

            await convertJSON(response);

            dispatch({ type: ACTIONS.QUOTE, payload: {...state?.quote, is_primary_quote: true}});

            //If includeSheets Create deal in Hubspot with cutSheets
            if (state?.includeSheets) {
                try {
                    const res = await fetch(`${config.base_api}/quote/createDeal`, {
                        headers,
                        method: 'POST',
                        body: JSON.stringify({
                            cutSheets: state?.includeSheets,
                            quote: state?.quote,
                        }),
                    });
                    // if (!res.ok) throw data.error;
                    const data = await convertJSON(res);
                    // Succesfully created deal
                    console.log('Created Hubspot deal');
                    dispatch({ type: ACTIONS.TOAST, payload: {
                        message: 'Created deal in hubspot',
                        isError: false,
                    }});
                    navigate('/');
                } catch (error) {
                    console.log(error);
                }

                //Else Show Modal
            } else {
                setShowCutSheetsModal(true);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Quote not marked as primary', isError: true}});
        }
    };

    return (
        <div>
            <Btn title="Make Primary" 
            btnClass="blue-button b2-blue text-white bg-blue text-bold justify-center box-shadow pad-t-md pad-b-md width-100 no-wrap"
            onClick={onClick} />
        </div>
    );
}
