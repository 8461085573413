import React, {useEffect, useState} from 'react';

import formatNumber from '../../utilities/formatNumber';
import roundDollar from '../../utilities/roundDollar';
import formatInput from '../../utilities/formatInput';
import {useStore} from '../../StoreProvider';
import {ACTIONS} from '../../Actions';

const Freight = ({setRecalculateTotals, setAutoSave}) => {
    const {state, dispatch} = useStore();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            setRecalculateTotals(true);
        }
    }, [loading]);

    const updateFreight = (e) => {
        dispatch({
            type: ACTIONS.QUOTE,
            payload: {
                ...state?.quote,
                freight: {
                    ...state?.quote?.freight,
                    description: e.target.value,
                },
            },
        });
    }

    return (
        <div className="freight component-card">
            <h4 className="freight_title pad-l-lg pad-t-lg">Freight</h4>
            {/* Freight calculation total figures from quote and mileage frome backend*/}
            <div className="freight_grid pad-l-lg pad-r-lg pad-b-lg">
                {/* Suggested freight Cost */}
                <p className="freight_label text-bold">Sugg Freight Cost:</p>
                <p className="freight_value">
                    ${' '}
                    {state?.quote?.freight?.suggestedCost || state?.quote?.freight?.suggestedCost === 0
                        ? formatNumber(roundDollar(state?.quote?.freight?.suggestedCost)) || ''
                        : 'Calculating...'}
                </p>

                {/* Suggested freight Price */}
                <p className="freight_label text-bold">Sugg Freight Price:</p>
                <p className="freight_value">
                    ${' '}
                    {state?.quote?.freight?.suggestedPrice || state?.quote?.freight?.suggestedPrice === 0
                        ? formatNumber(roundDollar(state?.quote?.freight?.suggestedPrice)) || ''
                        : 'Calculating...'}
                </p>

                {/* Freight cost */}
                <p className="freight_label text-bold margin-b-xs">Freight Cost: </p>
                <input
                    onWheel={(e) => e.target.blur()}
                    className="freight_value margin-b-xs pad-l-xs pad-r-xs"
                    type="number"
                    value={formatInput(state?.quote?.freight?.cost)}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                freight: {
                                    ...state?.quote?.freight,
                                    cost: e.target.value || null,
                                },
                            },
                        });
                    }}
                    disabled={state?.quote.readOnly}
                />
                {/* Freight customer price */}
                <p className="freight_label text-bold">Customer Price: </p>
                <input
                    onWheel={(e) => e.target.blur()}
                    className="freight_value pad-l-xs pad-r-xs"
                    type="number"
                    value={formatInput(state?.quote?.freight?.customerPrice)}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                freight: {
                                    ...state?.quote?.freight,
                                    customerPrice: e.target.value || null,
                                },
                            },
                        });
                    }}
                    disabled={state?.quote.readOnly}
                />

                {/*Freight Margin*/}
                <p className="freight_label text-bold margin-t-sm"> Freight Margin: </p>
                <p className="freight_label line-height-120 margin-t-sm">
                    {state?.quote?.freight?.cost == state?.quote?.freight?.customerPrice
                        ? `0%`
                        : state?.quote?.freight?.cost && !state?.quote?.freight?.customerPrice
                        ? <span className = 'text-smaller'>Confirm with Manager</span>
                        : state?.quote?.freight?.cost && state?.quote?.freight?.customerPrice == 0
                        ? <span className = 'text-smaller'>Confirm with Manager</span>
                        : state?.quote?.freight?.cost &&
                          state?.quote?.freight?.customerPrice &&
                          `${formatNumber(
                              ((state?.quote?.freight?.customerPrice - state?.quote?.freight.cost) / state?.quote?.freight?.customerPrice) * 100
                          )}%`}
                    </p>
                    <p className="freight_label text-bold margin-t-sm">Freight Description:</p>
                    <textarea
                        className="freight_value freight_value-description pad-xs"
                        value={state?.quote?.freight?.description}
                        onChange={(e) => {
                            updateFreight(e);
                        }}
                        onBlur={(e) => {
                            setAutoSave(true);
                        }}
                        disabled={state?.quote.readOnly}
                    />
            </div>
        </div>
    );
};

export default Freight;
