import React from 'react';
import TrashIcon from '../assets/trashIcon.svg'
import uploadIcon from '../assets/upload.svg';

// Regular old button with title for button name and string of classes to add
// Executes pass in function if exists
const Btn = ({title = 'Submit', id = '', dataTestId = '', btnClass = '', onClick, icon = '', disabled = false}) => {
    if (icon === 'trash') return <img src={TrashIcon} alt = "trash icon" className={`pointer ${btnClass}`} onClick={onClick} disabled={disabled} />
    if (icon === 'upload') return <img src = {uploadIcon} alt = "upload icon" className={`pointer ${btnClass}`} onClick={onClick} disabled={disabled} />
    return (
        <button id={id} data-testid={dataTestId || id} aria-label={dataTestId || id} className={`btn ` + (disabled ? 'btn-disabled pad-tb-md min-width-150px text-center justify-center' : btnClass)} type="button" onClick={onClick} disabled={disabled}>

            {title}
        </button>
    );
};

export const SmallBtn = ({title = 'btn', id = '', btnClass = '', onClick}) => {
    return (
        <button id={id} className={`btn ${btnClass}`} type="button" onClick={onClick}>
            {title}
        </button>
    );
};

export default Btn;
