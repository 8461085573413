import authHeaders from './authHeaders';
import config from '../config';
import {costSheetToObject} from './formatCostSheet';
import {roundUp} from './rounding'
import convertJSON from './convertJSON';

let quote = {}

let newCostSheet = {
    installation: {
        price: 0,
        cost: 0,
    },
    discount: {
        totalDiscount: 0,
        additionalDiscount: 0,
        addDiscount1: 0,
        addDiscount1_note: '',
        addDiscount2: 0,
        addDiscount2_note: '',
    },

    freight: {
        additional_freight: 0,
        price: 0,
        cost: 0,
        real_freight: 0,
        real_handling: 0,
    },
    products: [],
};

let installInfo = {};
let freight_info = {};
let summary_info = {};
let discount_info = {};

const checkForCostSheet = async (auth, quote_number) => {
    const headers = authHeaders(auth.accessToken, auth.refreshToken);
    try {
        let res = await fetch(`${config.base_api}/quote/cost_sheet/get/${quote_number}`, {
            method: 'GET',
            headers,
        });
        let existingCostSheet = await convertJSON(res);
        
        if (!existingCostSheet) {
            // if no existing cost sheet is found create one based on the quote
            let costSheetCalculations = createCostSheet();
            return costSheetCalculations;
        } else {
            let formattedSheet = costSheetToObject(existingCostSheet);
            // set the saved cost sheet to the one returned from the db

            let costSheetCalculations = calculateTotalProfit(formattedSheet);

            return costSheetCalculations;
        }
    } catch (error) {
        console.log(error);
    }
};

const connectQuoteData = async (auth, navbarQuoteID) => {
    const headers = authHeaders(auth.accessToken);
    try {
        // this may not be the proper id required
        let quoteData = await fetch(`${config.base_api}/quote/id/${navbarQuoteID}`, {headers});
        quoteData = await convertJSON(quoteData);

        let quote_number = quoteData.quote_number
        quoteData.products = JSON.parse(quoteData.products);
        quote = quoteData;

        let costSheetCalculations = await checkForCostSheet(auth, quote_number);

        return costSheetCalculations

    } catch (err) {
      console.log(err)
      return {};
    }
  }

const createCostSheet = async () => {
    newCostSheet.quote_number = quote.quote_number;
    newCostSheet.installation.price = quote.installation_customerPrice;
    newCostSheet.installation.cost = quote.installation_cost;
    newCostSheet.discount.totalDiscount = quote.discount_totalDiscount;
    newCostSheet.discount.additionalDiscount = quote.discount_additionalDiscount;
    newCostSheet.discount.addDiscount1 = 0;
    newCostSheet.discount.addDiscount2 = 0;
    newCostSheet.freight.additional_freight = addAdditionalFreight();
    newCostSheet.freight.price = quote.freight_customerPrice;
    newCostSheet.freight.cost = quote.freight_cost;
    newCostSheet.freight.real_freight = quote.freight_cost + newCostSheet.freight.additional_freight;
    newCostSheet.freight.real_handling = quote.real_handling;
    newCostSheet.products = [];
    for (var i = 0; i < quote.products.length; i++) {
        quote.products[i].web_listed_price = roundUp(+quote.products[i].web_listed_price);
        quote.products[i].cost = +quote.products[i].cost;
        newCostSheet.products.push(quote.products[i]);
    }
    let costSheetCalculations = calculateTotalProfit(newCostSheet);
    return(costSheetCalculations)
}

function addAdditionalFreight() {
    let add_freight = 0;
    for (var i = 0; i < quote.products.length; i++) {
        if (quote.products[i].has_free_freight) {
            add_freight += quote.products[i].freight_cost;
        }
    }
    return add_freight;
}

function calculateTotalProfit(savedCostSheet) {
   // calculate summary info for initial load

   installInfo.profit = parseInt(savedCostSheet.installation.price) - parseInt(savedCostSheet.installation.cost);
   installInfo.margin = Math.round((installInfo.profit / savedCostSheet.installation.price) * 100);

   // calculate freight profit and margin

   freight_info.profit = Math.round(savedCostSheet.freight.price - savedCostSheet.freight.cost);
   freight_info.margin = Math.round((freight_info.profit / savedCostSheet.freight.price) * 100);

   // calculate discount
   discount_info.discount = savedCostSheet.discount.totalDiscount;
   discount_info.additionalDiscount = savedCostSheet.discount.additionalDiscount;

   // setting summary Values for autoupdate
   summary_info.price = recalculatePrice(savedCostSheet);
   summary_info.cost = recalculateCost(savedCostSheet);
   summary_info.discount = 0;
   summary_info.discount +=
       +savedCostSheet.discount.additionalDiscount +
       +savedCostSheet.discount.totalDiscount +
       +savedCostSheet.discount.addDiscount1 +
       +savedCostSheet.discount.addDiscount2;
   summary_info.price_after_discount = summary_info.price - summary_info.discount;
   summary_info.total_profit = summary_info.price_after_discount - summary_info.cost;
   summary_info.profit_margin = (summary_info.total_profit / summary_info.price_after_discount) * 100;

    // setTotalProfit(summary_info.total_profit)
    let totalMargin = calculateTotalMargin(savedCostSheet);
    let calculationObject = {
        totalProfit: summary_info.total_profit,
        totalMargin: totalMargin
    }
    return (calculationObject);
}

function recalculatePrice(savedCostSheet) {
    let totalPrice = savedCostSheet.installation.price + savedCostSheet.freight.price;
    for (var i = 0; i < savedCostSheet.products.length; i++) {
        totalPrice += savedCostSheet.products[i].total_price;
    }
    return totalPrice;
}

function recalculateCost(savedCostSheet) {
    let totalCost = savedCostSheet.installation.cost + +savedCostSheet.freight.real_freight + +savedCostSheet.freight.real_handling;
    for (var i = 0; i < savedCostSheet.products.length; i++) {
        if (savedCostSheet.products[i].ops_cost != null) {
            totalCost += savedCostSheet.products[i].ops_cost * savedCostSheet.products[i].qty;
            continue;
        }
        if (typeof savedCostSheet.products[i]['cost'] != 'number') {
            continue;
        } else {
            totalCost += savedCostSheet.products[i]['cost'] * savedCostSheet.products[i].qty;
        }
    }
    return totalCost;
}

function calculateTotalMargin(savedCostSheet) {
    let totalPrice = 0;
    totalPrice += savedCostSheet.freight.price;
    totalPrice += savedCostSheet.installation.price;
    for (let i = 0; i < savedCostSheet.products.length; i++) {
        totalPrice += savedCostSheet.products[i].total_price;
    }
    totalPrice -= savedCostSheet.discount.totalDiscount;
    totalPrice -= savedCostSheet.discount.additionalDiscount;
    totalPrice -= savedCostSheet.discount.addDiscount1;
    totalPrice -= savedCostSheet.discount.addDiscount2;
    let totalCost = recalculateCost(savedCostSheet);
    if (totalPrice == 0) {
        return 0;
    }
    let totalMargin = (((totalPrice - totalCost) / totalPrice) * 100).toFixed(2);
    return totalMargin;
}

export default connectQuoteData;    