export default function formatTimestamp(timestamp) {
    const [datePart, timePart] = timestamp.split('T');

    // Further splitting to get individual components
    const [year, month, day] = datePart.split('-');
    let [hour, minute] = timePart.split(':');

    // Adjusting the hour for 12-hour format and setting AM/PM
    hour = parseInt(hour, 10);
    const amPm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour === 0 ? 12 : hour; // Adjust for 12 AM/PM

    // Formatting the date and time
    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hour}:${minute} ${amPm}`;

    return `${formattedDate}, ${formattedTime}`;
}