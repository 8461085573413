import React, { useEffect, useState, useForm } from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import Btn from '../components/Btn';
import LoadingInline from '../components/LoadingInline'
import StatesList from '../components/StatesList';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';

const Manufacturers = ({
    manufacturers,
    setManufacturers,
    getManufacturers
}) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const deleteManufacturer = async (id) => {
        try {
            const response = await fetch(`${config.base_api}/manufacturers/delete/${id}`, {
                method: 'DELETE',
                headers
            });

            await convertJSON(response)

            dispatch({ type: ACTIONS.TOAST, payload: { message: `Manufacturer Deleted` }});
            //Get Updated Product Variants
            setManufacturers('loading');
        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: `Could Not Delete Manufacturer`, isError: true }});
        }
    }

    const createManufacturer = async () => {
        try {
            let body = {
                vendor: document.getElementById('manufacturer_vendor').value || '',
                company: document.getElementById('manufacturer_company').value || '',
                address: document.getElementById('manufacturer_address').value || '',
                city: document.getElementById('manufacturer_city').value || '',
                state: document.getElementById('manufacturer_state').value || '',
                zip: document.getElementById('manufacturer_zip').value || '',
                phone: document.getElementById('manufacturer_phone').value || '',
                email: document.getElementById('manufacturer_email').value || ''
            };

            console.log(body);


            const response = await fetch(`${config.base_api}/manufacturers`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            });


            await convertJSON(response)

            dispatch({ type: ACTIONS.TOAST, payload: { message: `Manufacturer Created` }});
            //Get Updated Product Variants
            setManufacturers('loading');

        } catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: `Could Not Create Manufacturer`, isError: true }});
        }
    }

    const manufacturerUpdate = async (id) => {
        try {
            let body = {
                vendor: document.getElementById(`manufacturer${id}_vendor`).value || '',
                company: document.getElementById(`manufacturer${id}_company`).value || '',
                address: document.getElementById(`manufacturer${id}_address`).value || '',
                city: document.getElementById(`manufacturer${id}_city`).value || '',
                state: document.getElementById(`manufacturer${id}_state`).value || '',
                zip: document.getElementById(`manufacturer${id}_zip`).value || '',
                phone: document.getElementById(`manufacturer${id}_phone`).value || '',
                email: document.getElementById(`manufacturer${id}_email`).value || ''
            };

            const res = await fetch(`${config.base_api}/manufacturers/${id}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body)
            });

            await convertJSON(res)

            //Get Updated Product Variants
            setManufacturers('loading');
        }
        catch (error) {
            console.error(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: `Could Not Create Manufacturer`, isError: true }});
        }
    }

    const compareManufacturer = (a, b) => {
        if (a.vendor > b.vendor) {
            return 1;
        } else if (a.vendor < b.vendor) {
            return -1;
        } else {
            return 0;
        }
    }

    useEffect(() => {
		if (!Array.isArray(manufacturers)) {
			getManufacturers()
		}
	}, [manufacturers])

    return (
        <>       
            {!Array.isArray(manufacturers) &&
                <LoadingInline />
            }

            {Array.isArray(manufacturers) &&
                <div>
                    {/*CREATE MANUFACTURER*/}
                    <div id='addManufacturer'>
                            <h2 className='centerText'>Add Manufacturer</h2>

                            <div>
                                <label className="block">
                                    Vendor
                                </label>

                                <input id='manufacturer_vendor' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    Company
                                </label>

                                <input id='manufacturer_company' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    Street Address
                                </label>

                                <input id='manufacturer_address' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    City
                                </label>

                                <input id='manufacturer_city' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    State
                                </label>

                                <input id='manufacturer_state' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    Zip
                                </label>

                                <input id='manufacturer_zip' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    Phone
                                </label>

                                <input id='manufacturer_phone' type='text'></input>
                            </div>
                            <div>
                                <label className="block">
                                    Email
                                </label>

                                <input id='manufacturer_email' type='text'></input>
                            </div>

                            <div className='centerButton'>
                                <Btn title="Create Manufacturer" onClick={createManufacturer} 
                                btnClass = 'pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background'/>
                            </div>

                    </div>
                    {/*UPDATE OR DELETE MANUFACTURER*/}
                    <h1 className='centerText'>Manufacturers</h1>
                    <div className='quotesTable'>
                        <table id='manufacturersTable'>
                            <thead>
                                <tr>
                                    {/*Columns Total = 8*/}
                                    <th colSpan="1">Vendor</th>
                                    <th colSpan="1">Company</th>
                                    <th colSpan="1">Address</th>
                                    <th colSpan="1">City</th>
                                    <th colSpan="1">State</th>
                                    <th colSpan="1">Zip</th>
                                    <th colSpan="1">Phone</th>
                                    <th colSpan="1">Email</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id='manufacturersBody'>
                                {Array.isArray(manufacturers) &&
                                    (manufacturers.sort(compareManufacturer).map((manufacturer) => (
                                        <tr key={manufacturer.id} className='manufacturer_row'>
                                            <td className='manufacturer_vendor' colSpan="1">
                                                <span onClick={() => deleteManufacturer(manufacturer.id)} className="table_deleteBtn col-buttons">
                                                    x
                                                </span>
                                                <input id={`manufacturer${manufacturer.id}_vendor`} type="text" defaultValue={manufacturer.vendor} />
                                            </td>
                                            <td className='manufacturer_company' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_company`} type="text" defaultValue={manufacturer.company} />
                                            </td>
                                            <td className='manufacturer_address' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_address`} type="text" defaultValue={manufacturer.address} />
                                            </td>
                                            <td className='manufacturer_city' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_city`} type="text" defaultValue={manufacturer.city} />
                                            </td>
                                            <td className='manufacturer_state' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_state`} type="text" defaultValue={manufacturer.state} />
                                            </td>
                                            <td className='manufacturer_zip' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_zip`} type="text" defaultValue={manufacturer.zip} />
                                            </td>
                                            <td className='manufacturer_phone' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_phone`} type="text" defaultValue={manufacturer.phone} />
                                            </td>
                                            <td className='manufacturer_email' colSpan="1">
                                                <input id={`manufacturer${manufacturer.id}_email`} type="text" defaultValue={manufacturer.email} />
                                            </td>
                                            <td>
                                                <Btn title="Update" onClick={() => manufacturerUpdate(manufacturer.id)} 
                                                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
                                            </td>
                                        </tr>
                                    )))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default Manufacturers