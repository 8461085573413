import React, {useEffect, useState} from 'react';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';

const CellVariants = ({product, productVariants}) => {
    const { state, dispatch } = useStore();
    const [productVariantsOrder, setProductVariantsOrder] = useState([]);
    //Four variant choices
    const [attribute1, setAttribute1] = useState();
    const [attribute2, setAttribute2] = useState();
    const [attribute3, setAttribute3] = useState();
    const [attribute4, setAttribute4] = useState();

    //Four Option States
    const [option1, setOption1] = useState(product.attribute1 ? product.attribute1.option : 'TBD');
    const [option2, setOption2] = useState(product.attribute2 ? product.attribute2.option : 'TBD');
    const [option3, setOption3] = useState(product.attribute3 ? product.attribute3.option : 'TBD');
    const [option4, setOption4] = useState(product.attribute4 ? product.attribute4.option : 'TBD');

    useEffect(() => {
        if (product?.variants_order) {
            var variants = product?.variants_order?.split(',');

            for (let i = 0; i < variants?.length; i++) {
                const attr = productVariants?.filter((variant) => variant?.id === parseInt(variants[i]));

                switch (i) {
                    case 0:
                        setAttribute1(attr);
                        product.attribute1 = {
                            attribute: attr[0]?.attribute,
                            option: option1 || 'TBD',
                        };
                        dispatch({
                            type: ACTIONS.PRODUCT_TABLE_ITEMS,
                            payload: state?.productTableItems?.map((prod) =>
                                prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                            )
                        });
                        break;
                    case 1:
                        setAttribute2(attr);
                        product.attribute2 = {
                            attribute: attr[0]?.attribute,
                            option: option2 || 'TBD',
                        };
                        dispatch({
                            type: ACTIONS.PRODUCT_TABLE_ITEMS,
                            payload: state?.productTableItems?.map((prod) =>
                                prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                            )
                        });       
                        break;
                    case 2:
                        setAttribute3(attr);
                        product.attribute3 = {
                            attribute: attr[0]?.attribute,
                            option: option3 || 'TBD',
                        };
                        dispatch({
                            type: ACTIONS.PRODUCT_TABLE_ITEMS,
                            payload: state?.productTableItems?.map((prod) =>
                                prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                            )
                        });
                        break;
                    case 3:
                        setAttribute4(attr);
                        product.attribute4 = {
                            attribute: attr[0]?.attribute,
                            option: option4 || 'TBD',
                        };
                        dispatch({
                            type: ACTIONS.PRODUCT_TABLE_ITEMS,
                            payload: state?.productTableItems.map((prod) =>
                                prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                            )
                        });
                        break;
                }
            }
        }
    }, [product]);

    useEffect(() => {
        console.log('attr', attribute1);
    }, [attribute1]);

    if (!product) {
        <td key="variant" className="col-variant">
            Loading...
        </td>;
    }

    return (
        <td key="variant" className="col-variant">
            <div className="colVariantScroll">
                {/*VARIANT1*/}
                {attribute1 && attribute1.length > 0 ? (
                    <>
                        <p>{attribute1[0]?.attribute}</p>
                        <div className="variant-choice">
                            <select
                                name="variant"
                                id="attribute1"
                                placeholder="Select Variant"
                                value={option1}
                                onChange={(e) => {
                                    product.attribute1 = {
                                        attribute: attribute1[0]?.attribute,
                                        option: e.target.value,
                                    };
                                    dispatch({
                                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                                        payload: state?.productTableItems?.map((prod) =>
                                            prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                                        )
                                    });
                                    setOption1(e.target.value);
                                }}
                                className="inputWidth pad-xs"
                                disabled={state?.quote?.readOnly}>
                                <option value="TBD">TBD</option>
                                {attribute1[0]?.options?.split(',')?.map((item, i) => (
                                    <option value={item} key={i}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                    </>
                ) : (
                    <div className="variant-choice">
                        <b className="wrap-text">No Variants Exist </b>
                    </div>
                )}

                {/*VARIANT2*/}
                {attribute2 && attribute2.length > 0 ? (
                    <>
                        <p>{attribute2[0].attribute}</p>
                        <div className="variant-choice">
                            <select
                                name="variant"
                                id="attribute2"
                                placeholder="Select Variant"
                                value={option2}
                                onChange={(e) => {
                                    product.attribute2 = {
                                        attribute: attribute2[0].attribute,
                                        option: e.target.value,
                                    };
                                    dispatch({
                                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                                        payload: state?.productTableItems?.map((prod) =>
                                            prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                                        )
                                    });
                                    setOption2(e.target.value);
                                }}
                                className="inputWidth pad-xs"
                                disabled={state?.quote?.readOnly}>
                                <option value="TBD">TBD</option>
                                {attribute2[0]?.options?.split(',')?.map((item, i) => (
                                    <option value={item} key={i}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                    </>
                ) : (
                    <></>
                )}

                {/*VARIANT3*/}
                {attribute3 && attribute3.length > 0 ? (
                    <>
                        <p>{attribute3[0]?.attribute}</p>
                        <div className="variant-choice">
                            <select
                                name="variant"
                                id="attribute3"
                                placeholder="Select Variant"
                                value={option3}
                                onChange={(e) => {
                                    product.attribute3 = {
                                        attribute: attribute3[0]?.attribute,
                                        option: e.target.value,
                                    };
                                    dispatch({
                                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                                        payload: state?.productTableItems?.map((prod) =>
                                            prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                                        )
                                    });
                                    setOption3(e.target.value);
                                }}
                                className="inputWidth pad-xs"
                                disabled={state?.quote?.readOnly}>
                                <option value="TBD">TBD</option>
                                {attribute3[0]?.options?.split(',')?.map((item, i) => (
                                    <option value={item} key={i}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                    </>
                ) : (
                    <></>
                )}

                {/*VARIANT4*/}

                {attribute4 && attribute4.length > 0 ? (
                    <>
                        <p>{attribute4[0].attribute}</p>
                        <div className="variant-choice">
                            <select
                                name="variant"
                                id="attribute4"
                                placeholder="Select Variant"
                                value={option4}
                                onChange={(e) => {
                                    product.attribute4 = {
                                        attribute: attribute4[0]?.attribute,
                                        option: e.target.value,
                                    };
                                    dispatch({
                                        type: ACTIONS.PRODUCT_TABLE_ITEMS,
                                        payload: state?.productTableItems?.map((prod) =>
                                            prod?.playground_boss_sku === product?.playground_boss_sku ? product : prod
                                        )
                                    });
                                    setOption4(e.target.value);
                                }}
                                className="inputWidth pad-xs"
                                disabled={state?.quote?.readOnly}>
                                <option value="TBD">TBD</option>
                                {attribute4[0]?.options?.split(',')?.map((item, i) => (
                                    <option value={item} key={i}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                    </>
                ) : (
                    <></>
                )}
            </div>
        </td>
    );
};

export default CellVariants;
