import React from 'react';
import { useStore } from '../../StoreProvider';

const HubspotDeal = () => {
    const { state } = useStore();

    const PrimaryQuote = () => (
        <p>
            This is a <strong>primary</strong> quote and will create a deal in HubSpot
        </p>
    );

    const NotPrimaryQuote = () => (
        <p>
            This is not a primary quote and will <strong>not</strong> create a deal in HubSpot
        </p>
    );

    return (
        <div>
            <h3>HubSpot Deal</h3>
            {state?.quote.is_primary_quote ? <PrimaryQuote /> : <NotPrimaryQuote />}
        </div>
    );
};

export default HubspotDeal;
