// Sample 'rules' object, to pass in to all freight calculations
/*
const sampleObj = {
  distance: {
    description: 'Shipping distances (in miles) from manufacturer address to customers shipping address - 1502 old ranch rd camarillo ca',
    address_kidsTale_playgrounds: 1597,
    address_kidsTale_inc: 384,
    address_barks_and_rec: 2229,
    address_sports_play: 1871
  },
  freight_markup: 0.65,
  heavy_group_price_per_crate: 300,
  heavy_group_price_per_palette: 100,
  heavy_group_price_per_mile: 1,
  myTCoat_price_per_palette: 400,
  myTCoat_price_per_partial_palette: 315,
  zaeger_ewf_price_per_cubic_yard: 55,
  zaeger_filter_price_per_cubic_yard: 150,
  libertyTire_price_per_tons: 375,
  libertyTire_fixed_cost: 1950,
  athleticConnection_markup: 0.2,
  actionPlaySystems_price_per_pallete: 700,
  installer_cost: 0.3,
  installer_trip_charge: 500,
  installer_markup: 0.65
}
*/

// Manufacturer names we are calculating:
// KidsTale Playgrounds, KidsTale Inc, Barks and Rec, Sports Play,
// Zaeger, MyTCoat, Athletic Connection, USA Shade,
// Liberty Tire, Turf, PIP (poured in place rubber)

// Calculate freight for each single product
export function calculateFreightCost(rules, product) {
    if (product.has_free_freight) return 0;

    // If product is a "timber" - 4 different products - do not calculate freight
    // Filter by sku
    if (
        product.playground_boss_sku === 'PGBTB-5208-KT' ||
        product.playground_boss_sku === 'PGBTB-5212-KT' ||
        product.playground_boss_sku === 'PGBTB-5208-APS' ||
        product.playground_boss_sku === 'PGBTB-5212-APS'
    )
        return 0;

    let freightCost = 0;

    switch (product.manufacturer) {
        case 'KidsTale Playgrounds':
        case 'KidsTale Inc':
        case 'Barks and Rec':
        case 'Sports Play':
            freightCost = heavyGroup(rules, product);
            break;
        case 'Athletic Connection':
            freightCost = athleticConnection(rules, product);
            break;
        case 'USA Shade':
            // Do nothing / omit
            break;
        case 'Zaeger':
            break;
        case 'MyTCoat':
        case 'mytcoat':
        case 'myTcoat':
            // Omit
            break;
        case 'Action Play Systems': // timbers
            // Omit - calculate freight total for all quantity of timbers- not for individual product
            break;
        case 'Liberty Tire':
            freightCost = libertyTire(rules, product);
            break;
        case 'Turf':
            // Omit - NO FREIGHT CALCULATION FOR TURF. IT"S BUILT INTO THE COST.
            break;
        case 'PIP':
            // Omit - NO FREIGHT CALCULATION FOR Poured In Place Rubber. IT"S BUILT INTO THE COST.
            break;
        default:
            freightCost = 0;
            break;
    }

    // Make sure freightCost is valid calculation after running functions
    if (freightCost === undefined || freightCost === null || isNaN(freightCost)) {
        return 0;
    }

    return freightCost;
}

export function calculateFreightPrice(rules, cost) {
    if (!rules || cost === undefined) {
        return 0;
    }
    return cost / rules.freight_markup;
}

// Heavy group calculates
// (crates * price per crate + price per palette + miles * price per mile)
// For products with following manufactures in switch statement
function heavyGroup(rules, product) {

    let miles = 0;
    switch (product.manufacturer) {
        case 'KidsTale Playgrounds':
            miles = rules.distance?.address_kidsTale_playgrounds;
            break;
        case 'KidsTale Inc':
            miles = rules.distance?.address_kidsTale_inc;
            break;
        case 'Barks and Rec':
            miles = rules.distance?.address_barks_and_rec;
            break;
        case 'Sports Play':
            miles = rules.distance?.address_sports_play;
            break;
        default:
            break;
    }

    if (miles === undefined || miles === 0) {
        return 0;
    }

    const ppMile = rules.heavy_group_price_per_mile;

   const freightCost = (miles * ppMile) * product?.qty;
    return freightCost;
}

function athleticConnection(rules, product) {
    // Calculation - MSRP / athleticConnection_markup
    if (!product.msrp) {
        return 0;
    }

    let freightCost = product.msrp * rules.athleticConnection_markup;
    freightCost *= +product.qty;

    return freightCost;
}

function zaeger(rules, product) {
    let freightCost = 0;

    return freightCost;
}

function libertyTire(rules, product) {
    // calculated as 375 x # of Tons + 1800(freight). — Up to 20 tons.  Anything over 20 tons requires another truck (+1700)
    // libertyTire_price_per_tons: 375,
    // libertyTire_fixed_cost: 1950,
    let freightCost = 0;
    if (!product || !rules) {
        return 0;
    }

    const qty = +product.qty;

    freightCost = qty * rules.libertyTire_price_per_tons + rules.libertyTire_fixed_cost;

    if (qty > 20) {
        freightCost += rules.libertyTire_fixed_cost;
    }

    return freightCost;
}

// Takes in products array of all myTCoat products
// Deprecated
export function myTCoatFreight(rules, products) {
    // Calculation Math.floor(totalQty / 2 ) * rules.myTCoat_price_per_palette + (totalQty % 2) * rules.myTCoat_price_per_partial_palette;
    let freightCost = 0;
    if (!products || !Array.isArray(products) || !rules || products.length === 0) {
        return 0;
    }

    let totalQty = 0;

    products.forEach((product) => {
        totalQty += product.qty;
    });

    freightCost =
        Math.floor(totalQty / 2) * rules.myTCoat_price_per_palette +
        (totalQty % 2) * rules.myTCoat_price_per_partial_palette;

    return freightCost;
}
