import React, { useState, useEffect } from 'react';
import authHeaders from './../utilities/authHeaders';
import config from './../config';
import convertJSON from './../utilities/convertJSON';
import { useStore } from './../StoreProvider';
import { ACTIONS } from './../Actions';
import GenericTable from '../components/table/GenericTable';
import Btn from '../components/Btn';
import Modal from '../components/Modal';
import DashboardNavBar from '../components/navBar/DashboardNavBar';

const dashboards = [
    { name: "Email Errors", type: 'emails', path: "", localPath: "email", selected: true, onClick: () => {
        dashboards[0].selected = true;
        dashboards[1].selected = false;
    }},
    { name: "HubSpot Errors", type: 'deals', path: "", localPath: "hubspot", selected: false, onClick: () => {
        dashboards[0].selected = false;
        dashboards[1].selected = true;
    }},
];

const ApiErrorRetry = () => {
    const { state, dispatch } = useStore();

    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const [loading, setLoading] = useState(false);
    const [apiErros, setApiErrors] = useState([]);
    const [deleteId, setDeleteId] = useState(0);

    const options = { 
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, 
        hour12: true, 
        hour: 'numeric', 
        minute: 'numeric', 
        month: 'numeric', 
        day: 'numeric', 
        year: 'numeric'
    };

    useEffect(() => {
        const fetchApiErrors = async () => {
            setLoading(true);
            try {
                const res = await fetch(`${config.base_api}/apiErrorRetry/`, {
                    headers,
                });
                const data = await convertJSON(res);

                const transformData = data.map((error, index) => {
                    return {
                        id: error.id,
                        index: index + 1,
                        cutSheetsAmount: error?.cut_sheet_data ? JSON.parse(error?.cut_sheet_data).length : 0,
                        quoteNumber: error?.quote_data ? JSON.parse(error?.quote_data).quote_number : '',
                        thirdParty: error.third_party_api,
                        type: error.type,
                        emailValues: error?.email_values ? JSON.parse(error?.email_values) : {},
                        quote: error?.quote_data ? JSON.parse(error?.quote_data) : {},
                        cutSheets: error?.cut_sheet_data ? JSON.parse(error?.cut_sheet_data) : [],
                        path: error.path,
                        createdAt: (() => {
                            const date = new Date(error.createdAt);
                            const localTimeString = date?.toLocaleString('en-US', options);
                            return localTimeString || '';
                        })(),
                    }
                });

                setLoading(false);

                setApiErrors(transformData);
            } catch (error) {
                setLoading(false);
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'Failed to fetch api errors', isError: true } });
            } 
        };
        fetchApiErrors();
    }, []);

    const retryApiError = async (id) => {
        try {
            const apiError = apiErros.find(error => error.id === id);
            let body;
            if (apiError.path == '/quote/sendQuote') {
                body = JSON.stringify({
                    emailValues: apiError?.emailValues,
                    quote: apiError?.quote,
                    cutSheets: apiError?.cutSheets,
                    errorId: apiError?.id,
                });
            } else if (apiError.path == '/quote/createDeal') {
                body = JSON.stringify({
                    quote: apiError?.quote,
                    cutSheets: apiError?.cutSheets,
                    errorId: apiError?.id,
                });
            } else if (apiError.path == '/projects/sendEmail') {
                body = apiError?.emailValues;
                if (apiError.type == 'AWS') {
                    body = JSON.stringify({ ...body, errorId: apiError.id, sendEmailOnly: true });
                } else {
                    body = JSON.stringify({ ...body, errorId: apiError.id, googleInviteOnly: true });
                }
            }

            const res = await fetch(`${config.base_api}${apiError?.path}`, {
                method: 'POST',
                headers,
                body,
            });

            if (!res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'Failed to retry api error', isError: true } });
            } else {
                let response = await convertJSON(res);
                
                if (response?.includes('failed')) {
                    dispatch({ type: ACTIONS.TOAST, payload: { message: 'Failed to retry api error', isError: true } });
                } else {
                    dispatch({ type: ACTIONS.TOAST, payload: { message: 'Api error retried successfully' } });
                    // remove the error from the list
                    setApiErrors(apiErros.filter(error => error.id !== id));
                }
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Failed to retry api error', isError: true } });
        }
    }

    const deleteApiError = async (id) => {
        try {
            const res = await fetch(`${config.base_api}/apiErrorRetry/delete/${id}`, {
                method: 'DELETE',
                headers,
            });

            if (!res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'Failed to delete api error', isError: true } });
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: { message: 'Api error deleted successfully' } });
                // remove the error from the list
                setApiErrors(apiErros.filter(error => error.id !== id));
                setDeleteId(0);
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Failed to delete api error', isError: true } });
        }
    }

    const deleteModalDisplay = () => {
        if (!deleteId) return null;
        return (
            <Modal>
                <div className="modal_deleteQuote">
                    <h4>Remove API Error Retry</h4>
                    <p style={{fontWeight: 'bold'}}>
                        Are you sure you'd like to remove this API Error from being retried?
                    </p>
                    <div className="modal_deleteQuote_buttonContainer">
                        <Btn title="Remove" onClick={() => deleteApiError(deleteId)} btnClass="btn-red" />
                        <Btn title="Cancel" onClick={() => setDeleteId(0)} />
                    </div>
                </div>
            </Modal>
        );
    }

    const actions = (row) => {
        return [
            {
                label: 'Retry',
                handler: async () => await retryApiError(row.id),
            },
            {
                label: 'Delete',
                handler: () => setDeleteId(row.id),
            }
        ]
    };

    const columns = [
        { key: 'index', label: 'ID' },
        { key: 'quoteNumber', label: 'Quote/Project Number' },
        { key: 'type', label: 'Event' },
        { key: 'createdAt', label: 'Event Date' },
    ]

    return (
        <div>
            <h3 className='text-center text-bold text-lrg pad-l-xs'>{dashboards?.filter(dashboard => dashboard.selected)[0].name}</h3>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <div className="dashboardNavbarContainer" style={{ display: 'inline-block', width: 'auto' }}>
                    <DashboardNavBar dashboards={dashboards} />
                </div>
            </div>

            <GenericTable data={dashboards?.filter(dashboard => dashboard.selected)[0]?.type !== 'deals' ? apiErros?.filter(apiError => apiError?.thirdParty !== 'HubSpot') : apiErros?.filter(apiError => apiError?.thirdParty === 'HubSpot')} columns={columns} actions={actions} />
            {deleteModalDisplay()}
            {/* {!loading && apiErros.map((error, index) => (
                <div key={index}>
                    <p>{error}</p>
                </div>
            ))} */}
        </div>
    );
};  

export default ApiErrorRetry;