import React, { useEffect, useState } from 'react';
import person from '../../../src/assets/person.svg';
import phone from '../../../src/assets/phone.svg';
import email from '../../../src/assets/email.svg';
import formatPhoneNumber from '../../utilities/formatPhoneNumber';
import { useStore } from '../../StoreProvider';
import Btn from '../Btn';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import convertJSON from '../../utilities/convertJSON';
import authHeaders from '../../utilities/authHeaders';

const BillingContact = ({ billingContactMissing, setBillingContactMissing }) => {
    const { state } = useStore();
    const navigate = useNavigate();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const billingContactID = state?.quote?.hubspot_contact_id;
                
                if (billingContactID) {
                    const res = await fetch(`${config.base_api}/hubspot/contacts/id/${billingContactID}`, { headers });
                    const data = await convertJSON(res);

                    if (data.error && billingContactID != undefined) {
                        setBillingContactMissing(true);
                    } else if (data.firstname === "" || data.lastname === "" || data.email === "" || data.phone === ""
                        || data.address === "" || data.city === "" || data.state === "" || data.zip === "") {
                        setBillingContactMissing(true);
                    } else if (state?.quote?.firstname?.trim() === "" || state?.quote?.lastname?.trim() === ""
                        || state?.quote?.email?.trim() === "" || state?.quote?.phone?.trim() === "" || state?.quote?.address?.trim() === ""
                        || state?.quote?.city?.trim() === "" || state?.quote?.state?.trim() === "" || state?.quote?.zip?.trim() === "") {
                        setBillingContactMissing(true);
                    } else {
                        setBillingContactMissing(false)
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }

        fetchData();
    }, [state?.quote])

    const updateContact = async () => {
        navigate(`/contactDetails/${state?.quote?.quote_number}`)
    }

    return (
        <div className='component-card'>
            <h4 className='pad-l-lg pad-t-lg'>
                Billing Contact
            </h4>
            {
                billingContactMissing ? (
                    <div className='flex-column pad-l-lg pad-t-xs align-center pad-lr-sm'>
                        <p className='margin-l-sm text-red line-height-150 margin-b-sm'>
                            Information for this contact is missing.
                            Update by clicking the button below.
                        </p>
                        <Btn
                            title='Update Contact'
                            btnClass='darken-background box-shadow text-bold margin-b-xlg pad-t-md pad-b-md bg-white text-blue b2-blue width-150px justify-center'
                            onClick={updateContact}
                        />
                    </div>
                ) : (
                    <>
                        <div className='flex pad-l-lg pad-t-xs'>
                            <img src={person} className='width-5 ' alt="person icon" />
                            <p className='margin-l-sm'>
                                {state?.quote?.firstname} {state?.quote?.lastname}
                            </p>
                        </div>
                        <div className='flex pad-l-lg margin-t-xxs'>
                            <img src={phone} className='width-5' alt="phone icon" />
                            <p className='margin-l-sm'>
                                {formatPhoneNumber(state?.quote?.phone)}
                            </p>
                        </div>
                        <div className='flex pad-l-lg pad-b-lg margin-t-xxs'>
                            <img src={email} className='width-5 img-black' alt="email icon" />
                            <p className='margin-l-sm'>
                                {state?.quote?.email}
                            </p>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default BillingContact;