import React, {useState} from 'react';
import config from '../../config';
import formatNumber from '../../utilities/formatNumber';
import xmark from '../../assets/xmark.svg';
import checkmark from '../../assets/checkmark.svg';
import trashIcon from '../../assets/trashIcon.svg';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import Btn from '../Btn';
import LoadingInline from '../../components/LoadingInline';

const InvoiceTable = ( {headers, invoiceArray, setInvoiceArray, createAccessLogEvent, todayTimeAndDate, loadingQB, leftCol} ) => {
    const { state, dispatch } = useStore();
    const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('');

    const handleDeleteInvoice = async(event) => {
        try {
            const res = await fetch(`${config.base_api}/projects/deleteInvoice/${event.target.id}`, {
                method: 'PATCH',
                headers: headers,
            });

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: {
                    message: 'Invoice deleted successfully',
                    isError: false,
                }});
                setInvoiceArray(invoiceArray?.filter((invoice) => invoice.id != event.target.id))
                await createAccessLogEvent(`Deleted invoice ${event.target.name}, ${todayTimeAndDate}.`)
            }
    
        } catch (error) {
            console.log(error)
            dispatch({ type: ACTIONS.TOAST, payload: {
                message: 'Error deleting invoice',
                isError: true,
            }});
        }
    }

    const handleAddInvoice = async() => {
        const body = {
            project_id: state?.project.project_id,
            searchInvoiceNumber: searchInvoiceNumber,
        }

        try {
            const res = await fetch(`${config.base_api}/quickbooks/syncProducts/addInvoice`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body),
            });

            if (res.ok) {
                const data = await res.json();
                if (data.message === "Invoice already exists") {
                    dispatch({ type: ACTIONS.TOAST, payload: {
                        message: 'Invoice already exists',
                        isError: true,
                    }});
                    return;
                } else {
                    const invoiceName = data?.addedInvoice?.invoice_number;
                    dispatch({ type: ACTIONS.TOAST, payload: {
                        message: 'Invoice added successfully',
                        isError: false,
                    }});
                    setInvoiceArray([...invoiceArray, data.addedInvoice])
                    await createAccessLogEvent(`Added invoice ${invoiceName}, ${todayTimeAndDate}.`)
                }
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: {
                    message: 'Invoice cannot be found',
                    isError: true,
                }});
            }
        } catch (error) {
            console.log(error)
            dispatch({ type: ACTIONS.TOAST, payload: {
                message: 'Error adding invoice',
                isError: true,
            }});
        }
    }

    return (
        <>
            <div className = {`component-card margin-t-md min-height-265px ${leftCol ? 'max-width-550px' : 'max-width-500px'}`}>
                <div className = "flex justify-between">                
                    <p className = "text-lrg text-bold pad-l-xlg pad-t-lg margin-b-sm margin-r-md">
                        5. Invoices
                    </p>
                    <div className="flex">
                        <input className='pad-xs pad-r-lg margin-r-sm margin-t-lg margin-b-sm border-radius-xs' type="text" 
                        placeholder="Search Invoice Number" value={searchInvoiceNumber} onChange={(e) => setSearchInvoiceNumber(e.target.value)}/>
                        <Btn btnClass={(searchInvoiceNumber?.length > 7 ? 'text-green bg-white b2-green text-bold darken-background box-shadow' : 'btn-disabled') + ' pad-sm pad-l-xlg pad-r-xlg margin-r-md margin-t-lg margin-b-sm'}
                       // disabled={searchInvoiceNumber?.length > 7 ? false : true}
                        title='Add' 
                        onClick = {async () => {
                            await handleAddInvoice(searchInvoiceNumber);
                            setSearchInvoiceNumber('');
                        }}/>
                    </div>
                </div>

                <table className = 'width-100 border-collapse'>
                    <thead className = "top-border underline-black text-bold">
                        <tr>
                            <td></td>
                            <td className = "pad-l-xxlg pad-t-lg pad-b-lg">
                                Invoice #
                            </td>
                            <td>
                                Amount 
                            </td>
                            <td>
                                Balance
                            </td>
                            <td>
                                Sent 
                            </td>
                            <td>
                                Status
                            </td>
                        </tr>
                    </thead>

                    <tbody>
                    {loadingQB ? (
                        <tr>
                            <td></td>
                            <td></td>
                            <td className = 'pad-t-xlg pad-b-xlg'>
                                <LoadingInline />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                     ) :
                     <>
                    {invoiceArray?.length > 0 ? invoiceArray?.map((invoice, index, array) => (
                        <tr className = {index === array.length - 1 ? '' : 'underline-black'} key = {invoice?.id}>
                            <td className = "pad-l-lg">
                                <img src={trashIcon} className = 'sm-box pointer' 
                                id = {invoice?.id} name = {invoice?.invoice_number} alt = "trash" 
                                onClick = {handleDeleteInvoice}/>
                            </td>
                            <td className = "pad-l-lg pad-t-lg pad-b-lg">
                               <a target="_blank"
                               href={`${config.qb_base_api}/app/invoice?txnId=${invoice?.invoice_id}`}
                               >
                                {invoice?.invoice_number}
                               </a> 
                            </td>
                            <td>
                                ${formatNumber(invoice?.amount)}
                            </td>
                            <td>
                                ${formatNumber(invoice?.balance)}
                            </td>
                            <td>
                                {(invoice?.email_sent === "EmailSent")
                                ? <img src={checkmark} alt = "checkmark" />
                                : <img src={xmark} alt = "X" />}
                            </td>
                            <td className = {`${(invoice?.status === "Paid") ? "text-bold text-green" : ""}`}>
                                {(invoice?.status === "Paid") ? "PAID" : ""}
                            </td>
                        </tr>
                        ))
                    :
                        <tr>
                            <td></td>
                            <td className = 'pad-lg'>No invoices</td>
                        </tr>
                    }
                    </>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default InvoiceTable;