import React from 'react';
import {Link} from 'react-router-dom';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';

const NavbarOps = ({pathname, setPathname}) => {
    const { dispatch } = useStore();
    const resetNav = () => {
        dispatch({ type: ACTIONS.QUOTE, payload: emptyQuoteObj });
        dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: "" });
    };
    return (
        <div className="width-100">
            <ul className='flex-row list-none pad-md bg-orange gap-xlg justify-start'>
                <li className='text-white text-smedium no-underline text-bold first-link-nav' 
                onClick={()=>{resetNav();
                setPathname ('/operations/productVariants')}}>
                    <Link to="/operations/productVariants" className={`no-underline ${pathname === '/operations/productVariants' ? 'text-black' : 'text-white'}`}>
                        Product Variants
                    </Link>
                </li>
                <li className='text-white text-smedium no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/projects')}}>
                    <Link to="/operations/projects" className={`no-underline ${pathname.includes('/operations/projects') ? 'text-black' : 'text-white'}`}>
                        Projects
                    </Link>
                </li>
                <li className='text-white text-smedium no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/manufacturers')}}>
                    <Link to="/operations/manufacturers" className={`no-underline ${pathname === '/operations/manufacturers' ? 'text-black' : 'text-white'}`}>
                        Manufacturers
                    </Link>
                </li>
                <li className='text-white text-smedium no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/zoho')}}>
                    <Link to="/operations/zoho" className={`no-underline ${pathname === '/operations/zoho' ? 'text-black' : 'text-white'}`}>
                        Stock Sheet
                    </Link>
                </li>
                <li className='text-white text-smedium no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/sync')}}>
                    <Link to="/operations/sync" className={`no-underline ${pathname === '/operations/sync' ? 'text-black' : 'text-white'}`}>
                        Sync Section
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default NavbarOps;
