import React from 'react';
import StatesList from '../StatesList';
import { useStore } from '../../StoreProvider';

const ContactDetailsBilling = ({handleContactDetailsChange, billingContact, quote}) => {
    const { state } = useStore();

    return (
        <div className="hubspotContact">
            <h4>Billing Address</h4>

            <div className="hubspotContact_details hubspotContact_details-billing">
                <label htmlFor="hubspot_street">
                    Street Address: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_street"
                    value={billingContact.address}
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="address"
                    disabled={quote.readOnly}
                    required
                />

                <label htmlFor="hubspot_city">
                    City: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_city"
                    value={billingContact.city}
                    onChange={handleContactDetailsChange}
                    type="text"
                    name="city"
                    disabled={quote.readOnly}
                    required
                />

                <label htmlFor="hubspot_state">
                    State: <span className="hubspotContact_details_required">*</span>
                </label>

                <input
                    list="hubspot_state"
                    value={billingContact.state?.toUpperCase()}
                    onChange={handleContactDetailsChange}
                    type="text"
                    disabled={quote.readOnly}
                    name="state"
                    required
                />
                <StatesList datalist_id="hubspot_state" disabled={quote.readOnly} />

                <label htmlFor="hubspot_zip">
                    Zip Code: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_zip"
                    value={billingContact.zip}
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="zip"
                    disabled={quote.readOnly}
                    required
                />
            </div>
        </div>
    );
};

export default ContactDetailsBilling;
