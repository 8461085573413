import React, {useEffect, useState} from 'react';
import formatNumber from '../../utilities/formatNumber';
import roundDollar from '../../utilities/roundDollar';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import convertJSON from '../../utilities/convertJSON';

const FindProducts = ({addProduct}) => {
    const { state } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const [searchBar, setSearchBar] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    //  Search for products on text input change
    const searchProducts = async (e) => {
        const query = e.target.value;
        setSearchBar(query);
        try {
            const response = await fetch(`${config.base_api}/products/quoteTool/search/${query}`, {headers});

            const data = await convertJSON(response);

            setSearchResults(data);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className="findProducts component-card">
            <div className="findProducts_searchBar pad-l-lg pad-t-lg">
                <input
                    type="text"
                    value={searchBar}
                    onChange={searchProducts}
                    className = "icon-rtl-md pad-xs border-radius-sm border-thin"
                    placeholder="Search by product name or SKU"
                    disabled={state?.quote.readOnly}
                />
            </div>
            <div className="findProducts_productResults margin-l-lg margin-r-lg margin-b-lg">
                <table>
                    {/* Set up colgroup columns and alter column width in css */}
                    <colgroup>
                        <col span="1" />
                        <col span="1" />
                        <col span="1" />
                    </colgroup>

                    <tbody>
                        {searchResults.map((product, index, array) => (
                            <tr key={product.playground_boss_sku} className = "pointer no-visited" onClick={(e) => addProduct(product.playground_boss_sku)}>
                                <td key="name" className = "pad-l-smd pad-t-lg pad-b-lg pad-r-md width-50">{product.product_name}</td>
                                <td key="sku" className= "width-30">{product.playground_boss_sku}</td>
                                <td key="price" className = "width-20">
                                    {product.web_listed_price
                                        ? `$${formatNumber(roundDollar(product.web_listed_price))}`
                                        : ''}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FindProducts;
