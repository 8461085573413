import React from 'react';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const Attachments = () => {
    const { state, dispatch } = useStore();

    return (
        <div className="sendEmail_attachments">
            <h3>Attachments</h3>
            <ul className="sendEmail_list">
                <li>Quote {state?.quote?.quote_number}</li>
                {state?.includeSheets.map((sheet) => (
                    <li id={sheet.id}>{sheet.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default Attachments;
