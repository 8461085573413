import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from '../StoreProvider';

const PrivateRoute = ({ children }) => {
    const { state } = useStore();
    // Check to see if waiting for response of authentication
    if (state?.auth.loading) return <div>Loading...</div>;

    if (!state?.auth.loggedIn && !window.location.pathname?.includes('timedOut')) {
        return <Navigate to="/auth/login" replace />;
    } else if (!state?.auth.loggedIn && window.location.pathname?.includes('timedOut')) {
        return <Navigate to="/auth/login/timedOut" replace />;
    }

    return children; // Render children if authenticated
};

export default PrivateRoute;
