import React from 'react';
import {useNavigate} from 'react-router';
import authHeaders from '../../utilities/authHeaders';
import config from '../../config';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import Modal from '../Modal';
import Btn from '../Btn';
import convertJSON from '../../utilities/convertJSON';

const DeleteModal = ({setShowDeleteModal}) => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const deleteQuote = async (e) => {
        try {
            const res = await fetch(`${config.base_api}/quote/id/${state?.quote?.id}`, {method: 'DELETE', headers});

            const data = await convertJSON(res);

            if (!res.ok) throw data.error;

            dispatch({type: ACTIONS.TOAST, payload: {message: `Succesfully Deleted quote ${state?.quote?.quote_number}`}});
            // This pushes back to home page, which will then clear out the quote state
            navigate('/');
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: {message: error, isError: true}});
        }
    };

    return (
        <Modal>
            <div className="modal_deleteQuote">
                <h4>Delete quote</h4>
                <p style={{fontWeight: 'bold'}}>Are you sure you want to delete Quote: {state?.quote?.quote_number}?</p>
                <div className="modal_deleteQuote_buttonContainer">
                    <Btn title="Delete Quote" onClick={deleteQuote} 
                    btnClass="pad-t-md pad-b-md width-100 justify-center box-shadow text-bold text-white bg-light-red delete-button b-none" />
                    <Btn title="Cancel" 
                    btnClass="pad-t-md pad-b-md width-100 justify-center box-shadow text-bold bg-white text-blue b2-blue darken-background"
                    onClick={(e) => setShowDeleteModal(false)} />
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
