import React from 'react';
import { useNavigate } from 'react-router-dom';
import {quoteToObject} from '../../utilities/formatQuote';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import Btn from '../Btn';
import convertJSON from '../../utilities/convertJSON';

const DuplicateQuote = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const navigate = useNavigate();

    const onClick = async (e) => {
        if (state.quote.firstname.trim() === '' || state.quote.lastname.trim() === '' || state.quote.email.trim() === '' || state.quote.phone.trim() === '' 
        || state.quote.address.trim() === '' || state.quote.city.trim() === '' || state.quote.state.trim() === '' || state.quote.zip.trim() === '' 
        || state.quote.shipping_firstname.trim() === '' || state.quote.shipping_lastname.trim() === '' || state.quote.shipping_email.trim() === '' || state.quote.shipping_phone.trim() === '' 
        || state.quote.shipping_address.trim() === '' || state.quote.shipping_city.trim() === '' || state.quote.shipping_state.trim() === '' || state.quote.shipping_zip.trim() === '') {
            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Please ensure that all required contact fields are properly filled in.', isError: true } });
            return;
        }
        try {
            const res = await fetch(`${config.base_api}/quote`, {
                method: 'POST',
                headers,
                body: JSON.stringify({ ...state?.quote, duplicate_quote: true }),
            });
            const data = await convertJSON(res);

            let quoteObj = quoteToObject(data);

            dispatch({type: ACTIONS.QUOTE, payload: quoteObj});
            dispatch({type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: quoteObj.products ? quoteObj.products : []});
            navigate(`/viewQuote/${quoteObj.quote_number}`);
            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Quote duplicated'}});
        } catch (error) {
            console.error(error);
            dispatch({type: ACTIONS.TOAST, payload: {
                message: 'May or may not have duplicated quote',
                isError: true,
            }});
        }
    };

    return <Btn title="Duplicate" onClick={onClick} 
    btnClass="pad-t-md pad-b-md width-100 justify-center box-shadow bg-white text-green text-bold b2-green darken-background" />;
};

export default DuplicateQuote;
