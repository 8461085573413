import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Btn from '../../Btn';
import config from '../../../config';
import authHeaders from '../../../utilities/authHeaders';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';
import convertJSON from '../../../utilities/convertJSON';

const ActionPlaySystems = ({freightRules, markup}) => {
    const { state, dispatch } = useStore();
    const [aps_price_per_pallete, set_aps_price_per_pallete] = useState(
        freightRules.actionPlaySystems_price_per_pallete
    );
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const actionPlaySystems = {
        title: 'Action Play Systems',
        names: ['Manufacturer', 'Price Per Palete', 'Freight Markup'],
    };
    const colSpanLength = actionPlaySystems.names.length;

    let idArray = ['actionPlaySystems_price_per_pallete'];

    let putBody = {};

    const putInput = async () => {
        for (let e = 0; e < idArray.length; e++) {
            var inputID = idArray[e];
            var inputValue = document.getElementById(inputID).value;

            putBody[inputID] = inputValue;
        }

        try {
            const res = await fetch(`${config.base_api}/freightRules`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(putBody),
            });

            await convertJSON(res);

            dispatch({type: ACTIONS.TOAST, payload: {message: 'Action Play Systems Updated'}});
        } catch (error) {
            console.log(error);
            dispatch({type: ACTIONS.TOAST, payload: {message: error, isError: true}});
        }
    };

    return (
        <form className="freightGroup">
            <div className="quotesTable">
                <table>
                    <thead>
                        <tr>
                            {actionPlaySystems.names.map((name) => (
                                <th>{name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{actionPlaySystems.title}</td>
                            <td>
                                <input
                                    id="actionPlaySystems_price_per_pallete"
                                    defaultValue={aps_price_per_pallete}
                                    onChange={(e) => set_aps_price_per_pallete(e.target.value)}></input>
                            </td>
                            <td>
                                <input value={markup} disabled="true"></input>
                            </td>
                        </tr>
                        <tr className="equation">
                            <td colspan={colSpanLength}>
                                Calculation: <br />
                                ([CEILING(#Timbers / 42) x ${aps_price_per_pallete}]) / {markup}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Btn title="Update" onClick={putInput} 
                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm'/>
            </div>
        </form>
    );
};

export default ActionPlaySystems;
