import React, { useState, useEffect } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import convertJSON from '../../utilities/convertJSON';


const SearchQuotes = ({ setSavedQuotesList, quoteSearchTerm, setQuoteSearchTerm, setQuotesLoading }) => {
    const { state, dispatch } = useStore();
    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();
    const [searchPrimaryQuote, setSearchPrimaryQuote] = useState(false);
    const [searchDealWon, setSearchDealWon] = useState(false);

    // On page load set dates for date picker

    useEffect(() => {
        // Set up state for date picker
        const d = new Date();
        // Todays Date
        let [month, day, year] = d.toLocaleDateString('en-US').split('/');
        if (month.length === 1) month = '0' + month;
        if (day.length === 1) day = '0' + day;
        // Past Date
        const daysInPast = 45;
        d.setDate(d.getDate() - daysInPast);
        let [pastMonth, pastDay, pastYear] = d.toLocaleDateString('en-US').split('/');
        if (pastMonth.length === 1) pastMonth = '0' + pastMonth;
        if (pastDay.length === 1) pastDay = '0' + pastDay;

        setDateEnd(`${year}-${month}-${day}`);
        setDateStart(`${pastYear}-${pastMonth}-${pastDay}`);
    }, []);

    // Search for quotes when search term or a date is changed
    useEffect(() => {
        setQuotesLoading(true);
        let cancelled = false;
        (async () => {
            // Only search of date times have been generated to prevent errors
            if (dateStart && dateEnd) {
                let query = encodeURI(`?searchTerm=${quoteSearchTerm}&dateStart=${dateStart}&dateEnd=${dateEnd}&searchPrimaryQuote=${searchPrimaryQuote}&searchDealWon=${searchDealWon}`);
        
                const getQuotes = async () => {
                    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
        
                    try {
                        let res = await fetch(`${config.base_api}/quote/searchSaved/${query}`, { headers });

                        let quotes = await convertJSON(res);
                       
                        if (!quotes) quotes = [];

                        if (!cancelled) {
                            setSavedQuotesList(quotes);
                            setQuotesLoading(false);
                        }
                    } catch (error) {
                        console.log(error);
                        setQuotesLoading(false);
                    }
                };
        
                getQuotes();
            }
        })();
        return () => {
            cancelled = true;
            setQuotesLoading(false);
          }
}, [quoteSearchTerm, dateStart, dateEnd, searchPrimaryQuote, searchDealWon]);

    return (
        <div className="searchQuotes component-card width-fit-content pad-lg">
            <form className = 'pad-l-md pad-t-xs flex'>
                <div className = 'flex-column'>
                    <label htmlFor="quote-search" className = 'text-bold'>Search Quotes:</label>
                    <input type="text" id='quote-search' className = 'border-radius-sm pad-sm b1-black width-300px icon-rtl2' 
                    placeholder = 'Search by name, number, prepared by.'
                    value={quoteSearchTerm || ''} onChange={(e) => setQuoteSearchTerm(e.target.value)} />
                </div>
                <div className = 'flex-column'>
                    <label htmlFor="StartDate" className = 'text-bold'>Quote Start Date:</label>
                    <input name="StartDate" className = 'border-radius-sm pad-sm b1-black' type="date" value={dateStart || ''} onChange={(e) => setDateStart(e.target.value)} />
                </div>
                <div className = 'flex-column'>
                    <label htmlFor="EndDate" className = 'text-bold'>Quote End Date:</label>
                    <input name="EndDate" className = 'border-radius-sm pad-sm b1-black' type="date" value={dateEnd || ''} onChange={(e) => setDateEnd(e.target.value)} />
                </div>
                <div className = 'flex align-center pad-t-xlg'>
                    <label htmlFor="primaryQuote" className = 'text-bold'>Primary Only</label>
                    <input id="primaryQuote" className = 'sm-box pointer' type="checkbox" checked={searchPrimaryQuote} onChange={(e) => setSearchPrimaryQuote(e.target.checked)} />
                </div>
                <div className = 'flex align-center pad-t-xlg'>
                    <label htmlFor="dealWon" className = 'text-bold'>Won Only</label>
                    <input id="dealWon" className = 'sm-box pointer' type="checkbox" checked={searchDealWon} onChange={(e) => setSearchDealWon(e.target.checked)} />
                </div>
            </form>
        </div>
    );
};

export default SearchQuotes;
