import React from 'react';
import formatDate from '../../utilities/formatDate';
import LoadingInline from '../../components/LoadingInline';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const ProjectInformation = ({projectID, lastAccess, projectOwner, setProjectOwner, userDropdown, projectComplete, setProjectComplete,
preferredCompletionDate, setPreferredCompletionDate, pastDateStatus, loading, loadingQuote, loadingAccessLog, leftCol}) => {
    const { state, dispatch } = useStore();

    let salesPerson = state?.quote?.prepared_by_name || "N/A"
    const dateBought = formatDate(state?.project?.date_bought || '').replace(/-/g, "/")

    function changeDateHandler(e) {
        setPreferredCompletionDate(e.target.value)
        dispatch({ type: ACTIONS.UPDATE_PREFERED_COMPLETION_DATE, payload: true });
    }

    function handleProjectComplete() {
        setProjectComplete(!projectComplete)
    }

    const handleViewAccessLog = (projectID) => {
        window.open(`/operations/projectAccessLog/${projectID}`)
    }

    const handleProjectOwnerDropdown = (e) => {
        setProjectOwner(e);
    }

    if (loading || loadingQuote || loadingAccessLog) {
        return (
        <div className = 'project-box pad-xlg height-fit-content'>
            <p className = 'text-lrg text-bold pad-b-md'>Project Information</p>
            <LoadingInline id="project-information-loader" />
        </div>
        );
    }

    return (
        <>
            <div className = 'project-box pad-xlg height-fit-content max-width-550px'>
                <p className = 'text-lrg text-bold pad-b-xs'>Project Information</p>
                <p className = 'pad-b-xs'><b>Project Name:</b> {state?.project?.project_name || ''}</p>
                <p className = 'flex column-gap-md pad-b-xs'><b>Date Bought:</b> {dateBought}</p>
                <div className = 'flex align-center pad-b-sm'>
                    <p className = 'text-bold margin-r-xs'>Project Owner: </p>
                    <select id='project_owner' value = {projectOwner} 
                    className = 'width-30 margin-r-sm height-fit-content' onChange = {(e) => handleProjectOwnerDropdown(e.target.value)}>
                        <option value = ''>Select</option>
                        {userDropdown?.map((user) => (
                            <option value = {user.userName} key={user.userEmail}>{user.userName}</option>
                        ))}
                    </select>
                </div>
                <p className='pad-b-xs'><b>Sales Person:</b> {salesPerson}</p>
                <div className = 'flex pad-b-xs'>
                    <p className = 'text-bold margin-r-xs'>Preferred Project Completion: </p>
                    <input type='date' id='preferred-date'
                    className = {`${pastDateStatus ? "border-orange" : ""} form-input text-center`}
                     value={preferredCompletionDate} onChange={changeDateHandler}></input>
                </div>
                <p className = 'pad-b-xs'>
                    <b>Last Access:  </b> 
                    <i>{lastAccess}</i>
                </p>
                <div className = 'flex column-gap-md justify-end'>
                    <span className={`${projectComplete ? 
                    "bg-green text-white width-fit-content pad-xs pad-l-sm pad-r-sm border-radius-md text-normal height-25px align-self-center"
                    : "hide"}`}>
                        Complete
                    </span>
                    <div 
                    className = "flex align-center justify-center pad-l-md pad-r-md pad-t-xs pad-b-xs b1-black border-radius-xs column-gap-sm pointer"  
                    id="checkboxes"  
                    onClick = {handleProjectComplete}>
                        <input type = "checkbox" id="complete" name="complete"
                        className = "sm-box pointer" checked = {projectComplete} onChange = {handleProjectComplete}/>
                        <label className = 'text-bold pointer'>Project Complete?</label>
                    </div>
                    <button 
                    className = "pad-l-md pad-r-md pad-t-xs pad-b-xs bg-white border-radius-xs text-blue b2-blue text-bold pointer darken-background box-shadow"
                    onClick={() => handleViewAccessLog(projectID)}>View Access Log</button>
                </div>               
            </div>
        </>
    )
}

export default ProjectInformation