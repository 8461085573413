import React from 'react';
import {isNote} from './productTypeCheck';
import formatNumber from '../../../utilities/formatNumber';
import {useStore} from '../../../StoreProvider';
import {ACTIONS} from '../../../Actions';

const CellCost = ({product, calculateRow}) => {
    const {state, dispatch} = useStore();

    // Return blank td if product is a note or has no margin value
    if (isNote(product)) {
        return <td key="cost" className="center col-cost"></td>;
    }

    if (product.cost && !product.cost_altered) {
        return (
            <td key="cost" className="center col-cost">
                {`$${formatNumber(product.cost)}`}
            </td>
        );
    }

    return (
        <td key="list_price" className="center col-list_price">
            <input
                onWheel={(e) => e.target.blur()}
                type="number"
                className={'center col-cost' + product.cost_altered ? 'pad-t-xs pad-b-xs text-center altered' : ''}
                name="cost"
                value={product.cost}
                onChange={(e) => {
                    // Update product description in state productTableItems
                    product.cost = e.target.value;
                    product.cost_altered = true;

                    // Update state with user input changes
                    // Map through previous state, replace product with matching id, return updated state
                    dispatch({
                        type: ACTIONS.PRODUCT_TABLE_ITEMS, 
                        payload: state?.productTableItems.map((prod) =>
                            prod.playground_boss_sku === product.playground_boss_sku ? product : prod
                        )
                    });
                    calculateRow(product.playground_boss_sku);
                }}
                // When user leaves table data cell (and stops entering input) calculate the values in the row
                onBlur={(e) => calculateRow(product.id)}
                disabled={state?.quote.readOnly}
            />
        </td>
    );
};

export default CellCost;
