import React, { useEffect, useState } from 'react';
import authHeaders from '../../utilities/authHeaders';
import convertJSON from '../../utilities/convertJSON';
import config from '../../config';
import Btn from '../Btn';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import formatDate from '../../utilities/formatDate';
import trashIcon from '../../assets/trashIcon.svg';

const LastActivityDate = ({ saveClicked, activityDate, setActivityDate, selectedActivity, setSelectedActivity, leftCol }) => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [options, setOptions] = useState([]);
    const [addActivity, setAddActivity] = useState(false);
    const [activityDescription, setActivityDescription] = useState('');
    const [activities, setActivities] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await getOptions();
            await getActivities();
        }

        fetchData();
    }, [state?.project?.project_id])

    useEffect(() => {
        async function fetchData() {
            if (saveClicked && (activityDate !== '' && selectedActivity !== '')) {
                submitActivity();
            }
        }

        fetchData();
    }, [saveClicked])

    const getOptions = async () => {
        try {
            const res = await fetch(`${config.base_api}/configuration/options`, { headers });
            const data = await convertJSON(res);

            setOptions(data);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error loading options.' } });
        }
    };

    const getActivities = async () => {
        try {
            const res = await fetch(`${config.base_api}/projects/activities/${state?.project?.project_id}`, { headers });
            const data = await convertJSON(res);

            for (let i = 0; i < data.length; i++) {
                let tempDate = new Date(data[i].date);
                let nextDay = tempDate.setDate(tempDate.getDate() + 1);
                let newDate = formatDate(nextDay).replace(/-/g, "/")

                data[i].date = newDate;
            }

            setActivities(data);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error loading activities.' } });
        }
    }

    const handleAddActivity = () => {
        setAddActivity(!addActivity);
    }

    const updateProject = async () => {
        let latestActivityDate = null

        if (activities.length > 0) {
            latestActivityDate = activities[0].date;
            latestActivityDate = latestActivityDate.split("/")
            latestActivityDate = latestActivityDate[2] + "-" + latestActivityDate[0] + "-" + latestActivityDate[1]
        }

        if (latestActivityDate === null || activityDate > latestActivityDate) {
            try {
                const res = await fetch(`${config.base_api}/projects/updateProject/${state?.project?.id}`, {
                    method: 'PATCH',
                    headers,
                    body: JSON.stringify({ last_activity: activityDate })
                });
                const data = await convertJSON(res);

                if (!res.ok) {
                    console.log(data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        return;
    }

    const submitActivity = async () => {
        if (activityDate === '' || selectedActivity === '') {
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Cannot submit without required fields',
                    isError: true,
                }
            });
        } else {
            const newActivity = {
                project_id: state?.project?.project_id,
                activity: selectedActivity,
                date: activityDate,
                description: activityDescription,
            };
            try {
                const res = await fetch(`${config.base_api}/projects/activities`,
                    {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(newActivity)
                    });
                const data = await convertJSON(res);

                if (res.ok) {
                    dispatch({ type: ACTIONS.TOAST, payload: { type: 'success', message: 'Activity submitted.' } });
                    await getActivities();
                    await updateProject();
                    setActivityDate('');
                    setActivityDescription('');
                    setAddActivity(false);
                } else {
                    console.log(data);
                    dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error submitting activity.' } });
                }
            } catch (error) {
                console.log(error);
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error submitting activity.' } });
            }
        }
    }

    const handleDateChange = (event) => {
        setActivityDate(event.target.value);
    }

    const handleActivityDescription = (event) => {
        setActivityDescription(event.target.value);
    }

    const handleSelectActivity = (value) => {
        setSelectedActivity(value);
    }

    const checkProjectColumn = async (deletedDate) => {
        try {
            const resActivities = await fetch(`${config.base_api}/projects/activities/${state?.project?.project_id}`, { headers });
            const data = await convertJSON(resActivities);

            let lastActivity = null;

            if (data.length > 0) {
                lastActivity = data[0].date
            }

            const res = await fetch(`${config.base_api}/projects/updateProject/${state?.project?.id}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify({ last_activity: lastActivity })
            });

            if (!res.ok) {
                console.log(res);
            }
        } catch (error) {
            console.log(error);
        }
        // }
    }

    const handleDeleteActivity = async (event) => {
        let eventID = event.target.id;

        try {
            const res = await fetch(`${config.base_api}/projects/activities/delete/${eventID}`, {
                method: 'DELETE',
                headers
            })

            if (res.ok) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Activity deleted successfully',
                        isError: false,
                    }
                });
                await checkProjectColumn(event.target.name);
                getActivities();
            }
        } catch (error) {
            console.log(error)
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not delete activity',
                    isError: true,
                }
            });
        }
    }

    const toggleRow = (activityId) => {
        setExpandedRows(prev => {
            if (prev.includes(activityId)) {
                return prev.filter(id => id !== activityId);
            } else {
                return [...prev, activityId];
            }
        });
    };

    const isTextOverflowing = (element) => {
        return element.textContent.length > 70;
    }

    return (
        <>
            <div className={`component-card pad-lg min-height-265px ${leftCol ? 'max-width-550px' : 'width-500px'}`}>
                <div className="flex justify-between align-center">
                    <p className="text-lrg text-bold pad-l-xlg">
                        Last Activity Date
                    </p>
                    <div className="">
                        {addActivity ? (
                            <Btn
                                title="Back"
                                btnClass='bg-white text-blue b2-blue text-bold darken-background box-shadow pad-md'
                                onClick={handleAddActivity} />
                        ) : (
                            <Btn
                                title="Add"
                                btnClass="text-green bg-white b2-green text-bold darken-background box-shadow pad-sm pad-l-xlg pad-r-xlg"
                                onClick={handleAddActivity} />
                        )
                        }

                    </div>
                </div>
                <div>
                    {addActivity ? (
                        <>
                            <div className="flex margin-t-md">
                                <div className="flex margin-l-xlg margin-t-md">
                                    <p>Activity Type:</p>
                                    <select id='activity_type' className="margin-l-xs"
                                        onChange={(e) => handleSelectActivity(e.target.value)}>
                                        <option value=''>Select</option>
                                        {options?.map((option) => (
                                            <option value={option.name} key={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex margin-l-xlg margin-t-sm">
                                    <p>Date of Contact:</p>
                                    <input type="date"
                                        className='form-input margin-l-xs'
                                        onChange={handleDateChange}></input>
                                </div>
                            </div>
                            <div className="flex margin-l-xlg margin-t-sm">
                                <p>Description:</p>
                                <textarea className='form-input margin-l-xs height-50px width-175px'
                                    onChange={handleActivityDescription}></textarea>
                            </div>
                            <Btn
                                title="Save"
                                btnClass={`${(!selectedActivity || !activityDate) ? 'btn-disabled' : 'text-white bg-light-green box-shadow green-button b-none'} margin-l-auto margin-r-lg margin-t-lg text-bold min-width-85px justify-center pad-tb-md`}
                                onClick={submitActivity}
                                disabled={!selectedActivity || !activityDate}
                            />
                        </>
                    ) : (
                        <div className='overflow-scroll height-200px margin-t-md margin-b-sm'>
                            <table className="width-100 border-collapse">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-bold width-25">Date</th>
                                        <th className="text-bold text-left width-25">Activity</th>
                                        <th className="text-bold text-left">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activities.length > 0 ? (
                                        activities.map((activity, index, array) => (
                                            <tr className={index === array.length - 1 ? '' : 'underline-black'} key={activity.id}>
                                                <td><img src={trashIcon} className='sm-box pointer margin-l-md'
                                                    id={activity.id} alt="trash icon" name={activity.date} onClick={handleDeleteActivity} /></td>
                                                <td className="text-center height-50px">{activity.date}</td>
                                                <td>{activity.activity}</td>
                                                <td
                                                    className={`${expandedRows.includes(activity.id) ? '' : 'webkit-box-properties'} activity-details`}
                                                    ref={(element) => {
                                                        if (element && isTextOverflowing(element)) {
                                                            element.classList.add('pointer', 'no-visited', 'webkit-display');
                                                        }
                                                    }}
                                                    onClick={() => toggleRow(activity.id)}>
                                                    {activity.description}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td className='pad-sm'>No activities</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default LastActivityDate;