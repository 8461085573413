import React from 'react';
import formatDate from '../../utilities/formatDate';
import formatNumber from '../../utilities/formatNumber';
import Btn from '../Btn';
import { Link } from 'react-router-dom';
import LoadingInline from '../LoadingInline';

const QuotesTable = ({ savedQuotesList, quotesLoading }) => {
    return (
        <div className="quotesTable component-card">
            <table>
                <thead>
                    <tr>
                        <th className="quotesTable_quoteNum text-bold text-med text-left ">Quote #</th>
                        <th className="quotesTable_name text-bold text-med text-left ">Contact</th>
                        <th className="quotesTable_tagName text-bold text-med text-center">Tag Name</th>
                        <th className="quotesTable_date text-bold text-med text-end">Date</th>
                        <th className="quotesTable_company text-bold text-med text-center">Company</th>
                        <th className="quotesTable_price text-bold text-med text-left">Price</th>
                        <th className="quotesTable_preparedBy text-bold text-med text-center">Project Owner</th>
                        <th className="quotesTable_salesPerson text-bold text-med text-left ">Sales Person</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Filtered list quote attributes are that from database not front end */}

                    {quotesLoading ?
                        <tr>
                            <td colSpan="8" className="text-center pad-tb-xlg">
                                <LoadingInline />
                            </td>
                        </tr>
                        :
                        savedQuotesList?.length > 0 ?
                            savedQuotesList.map((quote, index, array) => (
                                <tr key={quote.quote_number} className={index === array.length - 1 ? '' : 'quotesTable_row'}>
                                    <td className="quotesTable_quoteNum pad-t-lg">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.quote_number}</Link>
                                        <div className='flex pad-t-sm pad-b-md column-gap-sm quote_tags_responsive'>
                                            {quote.is_primary_quote &&
                                                <span className="flex justify-center bg-orange text-white text-smedium pad-lr-xxxlg border-radius-md text-normal pad-t-xs pad-b-xs">
                                                    Primary
                                                </span>}
                                            {quote.deal_closed && (
                                                <span className="flex justify-center bg-blue text-white text-smedium pad-lr-xxxlg border-radius-md text-normal pad-t-xs pad-b-xs">
                                                    WON
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                    <td className="quotesTable_name pad-t-lg pad-b-xxlg">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.firstname + ' ' + quote.lastname}</Link></td>
                                    <td className="quotesTable_tagName pad-t-lg pad-b-xxlg text-center">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.tag_name}</Link></td>
                                    <td className="quotesTable_date pad-t-lg pad-b-xxlg text-end">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{formatDate(quote.createdAt, 'slash')}</Link></td>
                                    <td className="quotesTable_company pad-t-lg pad-b-xxlg text-center">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.company}</Link></td>
                                    <td className="quotesTable_price pad-t-lg pad-b-xxlg">
                                        {/* If totalPrice display it with dollar sign */}
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.customer_totalPrice && '$ ' + formatNumber(quote.customer_totalPrice)}</Link>
                                    </td>
                                    <td className="quotesTable_preparedBy pad-t-lg pad-b-xxlg text-center">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.prepared_by_name}</Link></td>
                                    <td className="quotesTable_salesPerson pad-t-lg pad-b-xxlg">
                                        <Link to={`/viewQuote/${quote.quote_number}`} className='no-visited no-underline'>{quote.prepared_by_name}</Link></td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan="8" className="text-center pad-tb-xlg">No quotes found.</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    );
};

export default QuotesTable;
