// Format number to have commas every thousand
// Display up to two decimal points
// If number ends in .00 truncate to integer

export default function formatNumber(num) {
    if (!num) return '';
    // If num is a string, convert to number()
    num = +num;
    // Add commas into number at thousands
    num = num.toLocaleString();

    // Find out if there is decimals
    const index = num.indexOf('.');
    if (index !== -1) {
        num = num + '0000';
        // If decimals truncate at thousandths - eg 123.451
        num = num.slice(0, index + 4);

        //Remove commas
        const withoutCommas = num.replace(/,/g, '');
        //Round to nearest tenth 123.45
        num = Math.round(100 * +withoutCommas) / 100;

        //revert back to Locale String
        num = num.toLocaleString();

        // If decimal is .00 truncate to int
        const decimal = num.slice(-2);
        if (decimal === '00') {
            num = num.slice(0, -3);
        }
    }

    return num;
}
