import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import Btn from '../Btn';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import formatNumber from '../../utilities/formatNumber';
import {roundUp} from '../../utilities/rounding';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';
import {costSheetToObject} from '../../utilities/formatCostSheet';
import LoadingInline from '../../components/LoadingInline';

const CostSheetView = ({
    setLoading
}) => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();

    const [savedCostSheet, setSavedCostSheet] = useState({});
    const [costSheetExists, setCostSheetExists] = useState(false);
    const [overwriteCostSheet, setOverwriteCostSheet] = useState(false);

    const [summaryInfo, setSummaryInfo] = useState({});
    const [shouldUpdate, setShouldUpdate] = useState();
    const [editedCostSheet, setEditedCostSheet] = useState({});

    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const quoteNumber = window.location.pathname?.split('/costsheet/')[1];

    let newCostSheet = {
        installation: {
            price: 0,
            cost: 0,
        },
        discount: {
            totalDiscount: 0,
            additionalDiscount: 0,
            addDiscount1: 0,
            addDiscount1_note: '',
            addDiscount2: 0,
            addDiscount2_note: '',
        },

        freight: {
            additional_freight: 0,
            price: 0,
            cost: 0,
            real_freight: 0,
            real_handling: 0,
        },
        products: [],
    };
    
    let installInfo = {};
    let freight_info = {};
    let summary_info = {};
    let discount_info = {};

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        const fetchData = async () => {
            await checkForCostSheet(quoteNumber);
        }
        
        fetchData();
    }, [shouldUpdate]);

    useEffect(() => {
        calculateInfo();
      }, [editedCostSheet]); 

    const calculateInfo = async () => {
        if (overwriteCostSheet) {
            setEditedCostSheet(savedCostSheet);
            saveCostSheet();
            setOverwriteCostSheet(false);
        }
        // calculate summary info for initial load
        installInfo.profit = parseInt(editedCostSheet?.installation?.price) - parseInt(editedCostSheet?.installation?.cost);
        installInfo.margin = Math.round((installInfo.profit / editedCostSheet?.installation?.price) * 100);
        // calculate freight profit and margin

        freight_info.profit = Math.round(editedCostSheet?.freight?.price - editedCostSheet?.freight?.cost);
        freight_info.margin = Math.round((freight_info.profit / editedCostSheet?.freight?.price) * 100);

        // calculate discount
        discount_info.discount = editedCostSheet?.discount?.totalDiscount;
        discount_info.additionalDiscount = editedCostSheet?.discount?.additionalDiscount;

        // setting summary Values for autoupdate
        summary_info.price = recalculatePrice();
        summary_info.cost = recalculateCost();
        summary_info.discount = 0;
        summary_info.discount +=
            +editedCostSheet?.discount?.additionalDiscount +
            +editedCostSheet?.discount?.totalDiscount +
            +editedCostSheet?.discount?.addDiscount1 +
            +editedCostSheet?.discount?.addDiscount2;
        summary_info.price_after_discount = summary_info.price - summary_info.discount;
        summary_info.total_profit = summary_info.price_after_discount - summary_info.cost;
        summary_info.profit_margin = (summary_info.total_profit / summary_info.price_after_discount) * 100;
        // set all summary info into state
        setSummaryInfo(summary_info);
        setShouldUpdate(false);
    }

    function createCostSheet() {
        newCostSheet.quote_number = state?.quote?.quote_number;
        newCostSheet.installation.price = state?.quote?.installation.customerPrice;
        newCostSheet.installation.cost = state?.quote?.installation.cost;
        newCostSheet.discount.totalDiscount = state?.quote?.discount.totalDiscount;
        newCostSheet.discount.additionalDiscount = state?.quote?.discount.additionalDiscount;
        newCostSheet.discount.addDiscount1 = 0;
        newCostSheet.discount.addDiscount2 = 0;
        newCostSheet.freight.additional_freight = addAdditionalFreight();
        newCostSheet.freight.price = state?.quote?.freight.customerPrice;
        newCostSheet.freight.cost = state?.quote?.freight.cost;
        newCostSheet.freight.real_freight = +state?.quote?.freight.cost + +newCostSheet.freight.additional_freight;
        newCostSheet.freight.real_handling = state?.quote?.freight.real_handling || null;
        for (var i = 0; i < state?.quote?.products.length; i++) {
            state.quote.products[i].web_listed_price = roundUp(+state?.quote?.products[i].web_listed_price);
            state.quote.products[i].cost = +state?.quote?.products[i].cost;
            newCostSheet.products.push(state?.quote?.products[i]);
        }
        setSavedCostSheet(newCostSheet);
        setEditedCostSheet(newCostSheet);
        setLoading(false);
    }

    // add additional freight base on included freight in products
    function addAdditionalFreight() {
        let add_freight = 0;
        for (var i = 0; i < state?.quote?.products.length; i++) {
            if (state?.quote?.products[i].has_free_freight) {
                add_freight += +state?.quote?.products[i].freight_cost;
            }
        }
        return add_freight;
    }

    // // Function to check database for existing cost sheet
    const checkForCostSheet = async (quote_number) => {
       // const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
        try {
            let res = await fetch(`${config.base_api}/quote/cost_sheet/get/${quote_number}`, {
                method: 'GET',
                headers,
            });
            let existingCostSheet = await convertJSON(res);
            if (!existingCostSheet) {
                // if no existing cost sheet is found create one based on the quote
                createCostSheet();
            }
            else {
                let formattedSheet = costSheetToObject(existingCostSheet);
                // set the saved cost sheet to the one returned from the db
                setSavedCostSheet(formattedSheet);
                setEditedCostSheet(formattedSheet)
                setCostSheetExists(true);
                setLoading(false);
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Error retreiving cost sheet.'}});
        }
    };

    function resetCostSheet() {
        console.log('reset pressed');
        setLoading(true);
        createCostSheet();
        setOverwriteCostSheet(true);
    }

    function resetValues() {
        resetCostSheet();
        // reset values in fields
        // resetCostSheetValues();
        setShouldUpdate(true);
    }

    // calculate new total price based on each items updated field
    function recalculatePrice() {
        let totalPrice = savedCostSheet?.installation?.price + savedCostSheet?.freight?.price;
        for (var i = 0; i < savedCostSheet?.products?.length; i++) {
            totalPrice += savedCostSheet?.products[i]?.total_price;
        }
        return totalPrice;
    }

    // calculate new total cost based on each field
    function recalculateCost() {
        let totalCost = editedCostSheet?.installation?.cost + +editedCostSheet?.freight?.real_freight + +editedCostSheet?.freight?.real_handling;
        for (var i = 0; i < editedCostSheet?.products?.length; i++) {
            if (editedCostSheet?.products[i]?.ops_cost != null) {
                totalCost += editedCostSheet?.products[i]?.ops_cost * editedCostSheet?.products[i]?.qty;
                continue;
            }
            if (typeof editedCostSheet?.products[i]['cost'] != 'number') {
                continue;
            } else {
                totalCost += editedCostSheet?.products[i]['cost'] * editedCostSheet?.products[i]?.qty;
            }
        }
        return totalCost;
    }

    // calculate profit margin on products in the product array being mapped
    function calculateProductMargin(product) {
        if (product.ops_cost != null) {
            let profit = product.total_price - product.ops_cost * product.qty;
            let margin = (profit / product.total_price) * 100;
            if (product.total_price === 0) return 0
            return Math.round(margin);
        } else {
            let profit = product.total_price - product.cost * product.qty;
            let margin = (profit / product.total_price) * 100;
            if (product.total_price === 0) return 0
            return Math.round(margin);
        }
    }

    // calculate profit on items in product array
    function calculateProductProfit(product) {
        let profit = 0;
        if (product.ops_cost != null) {
            product.ops_cost = +product.ops_cost.toFixed(2);
            profit = product.total_price - product.ops_cost * product.qty;
        } else {
            product.cost = +product.cost.toFixed(2);
            profit = product.total_price - product.cost * product.qty;
        }

        return profit;
    }

    // calculate total profit margin
    function calculateTotalMargin() {
        console.log(editedCostSheet);
        let totalPrice = 0;
        totalPrice += editedCostSheet?.freight?.price;
        totalPrice += editedCostSheet?.installation?.price;
        for (let i = 0; i < editedCostSheet?.products?.length; i++) {
            totalPrice += editedCostSheet?.products[i]?.total_price;
        }
        totalPrice -= editedCostSheet?.discount?.totalDiscount;
        totalPrice -= editedCostSheet?.discount?.additionalDiscount;
        totalPrice -= editedCostSheet?.discount?.addDiscount1;
        totalPrice -= editedCostSheet?.discount?.addDiscount2;
        let totalCost = recalculateCost();
        if (totalPrice == 0) {
            return 0;
        }
        let totalMargin = (((totalPrice - totalCost) / totalPrice) * 100).toFixed(2);
        return totalMargin;
    }

    // Reset values in the all cost fields on reset values of cost sheet
    function resetCostSheetValues() {
        document.getElementById('installation_cost').value = formatNumber(savedCostSheet.installation.cost);
        document.getElementById('addDiscount').value = formatNumber(savedCostSheet.discount.additionalDiscount);
        document.getElementById('freight_cost').value = formatNumber(savedCostSheet.freight.cost);
        for (let i = 0; i < savedCostSheet.products.length; i++) {
            let product = document.getElementById(savedCostSheet.products[i].playground_boss_sku);
            if (savedCostSheet.products[i].ops_cost) {
                product.value = formatNumber(savedCostSheet.products[i].ops_cost);
            } else {
                product.value = formatNumber(savedCostSheet.products[i].cost);
            }
        }
    }

    // change the total cost based on inputs from cost sheet bottom table
    const refreshProductCost = async (idx, newVal, hasOpsCost) => {
        let newProductArray = [];
        for (var i = 0; i < editedCostSheet.products.length; i++) {
            if (idx === i) {
                let updatedProduct = {...editedCostSheet.products[i]};
                if (hasOpsCost) {
                    updatedProduct.ops_cost = newVal || 0;
                } else {
                    updatedProduct.cost = newVal || 0;
                }
                newProductArray.push(updatedProduct);
            } else {
                newProductArray.push({...editedCostSheet.products[i]});
            }
        }
        // set the new product array into the edited quote object
        setEditedCostSheet((prevState) => ({
            ...prevState,
            products: newProductArray,
        }));
    }

    const saveCostSheet = async () => {
        let costSheet = editedCostSheet;
        if (overwriteCostSheet) {
            costSheet = savedCostSheet;
        }
        console.log('Save button pressed', costSheet, costSheetExists);
        if (costSheetExists) {
            console.log('updating existing cost sheet');
            try {
                await updateCostSheet(costSheet);
                dispatch({ type: ACTIONS.TOAST, payload: {message: 'Saved Cost Sheet'}});
            } catch (error) {
                dispatch({ type: ACTIONS.TOAST, payload: {mesage: 'Error saving Cost Sheet', isError: true}});
            }
        } else {
            console.log('saving new cost sheet', costSheetExists);
            try {
                await saveNewCostSheet(costSheet);
                dispatch({ type: ACTIONS.TOAST, payload: {message: 'Cost sheet created and saved'}});
            } catch (error) {
                dispatch({ type: ACTIONS.TOAST, payload: {messagE: 'Error Creating/Saving Cost Sheet', isError: true}});
            }
        }
    };

    // functions to save and update quote
    // create new cost sheet
    const saveNewCostSheet = async (costSheet) => {
        const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
        try {
            const res = await fetch(`${config.base_api}/quote/cost_sheet/create`, {
                method: 'POST',
                headers,
                body: JSON.stringify(costSheet),
            });
            
            await convertJSON(res);

            if (res.ok) {
                setCostSheetExists(true);
                console.log('Cost Sheet created successfully');
                dispatch({ type: ACTIONS.TOAST, payload: {message: 'Cost Sheet Created and Saved to Database'}});
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {message: 'Something went wrong creating cost sheet'}});
        }
    };

    // Update Existing Cost Sheet
    const updateCostSheet = async (costSheet) => {
        const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
        try {
            let res = await fetch(`${config.base_api}/quote/cost_sheet/update`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(costSheet),
            });
            await convertJSON(res);
            
            if (!res.ok) throw new Error('Something went wrong');
            return;
        } catch (error) {
            console.log(error);
            throw 'Unable to save Cost Sheet';
        }
    };

    function getInstallationProfit() {
        return editedCostSheet?.installation?.price - editedCostSheet?.installation?.cost;
    }

    function addAdditionalFreight() {
        let add_freight = 0;
        for (var i = 0; i < state?.quote?.products.length; i++) {
            if (state?.quote?.products[i].has_free_freight) {
                add_freight += +state?.quote?.products[i].freight_cost;
            }
        }
        return add_freight;
    }

    // Get profit on freight
    function getFreightProfit() {
        let totalProfit =
            editedCostSheet?.freight?.additional_freight +
            editedCostSheet?.freight?.price -
            editedCostSheet?.freight?.real_freight - 
            editedCostSheet?.freight?.real_handling;

        return totalProfit;
    }

    // Get margin on freight
    function getFrieghtMargin() {
        let profit = getFreightProfit();
        let margin = profit / (editedCostSheet?.freight?.additional_freight + editedCostSheet?.freight?.price);
        margin *= 100;
        return Math.round(margin);
    }

    const returnToQuote = async () => {
        saveCostSheet();
        setSavedCostSheet(editedCostSheet);
        setCostSheetExists(true);
        navigate("/viewQuote/" + quoteNumber);
    };

    return (
        <>
            <div className="mainContainer">
                <div className="headerDiv">
                    <p className="header">Cost Sheet Operations</p>
                    <div className="buttonContainer">
                        <Btn title="Return to Quote" onClick={returnToQuote} 
                        btnClass="bg-white text-blue b2-blue darken-background box-shadow text-bold justify-center min-width-150px pad-tb-md" />
                        <Btn title="Reset Values" onClick={resetValues} 
                        btnClass="b2-blue text-white bg-blue text-bold box-shadow min-width-150px justify-center blue-button" />
                        <Btn title="Save" onClick={saveCostSheet} btnClass="justify-center min-width-150px pad-tb-md box-shadow text-bold text-white bg-light-green green-button border-green" />
                    </div>
                </div>
                <div>
                    <p className="quoteNumber">Quote: #{state?.quote.quote_number}</p>
                </div>
                {/* Contact info Here */}
                <div className="contactInfo">
                    <div>
                        <p>
                            Name: {state?.quote.firstname} {state?.quote.lastname}
                        </p>
                        <p>Phone: {state?.quote.phone}</p>
                        <p>Email: {state?.quote.email}</p>
                    </div>
                    <div>
                        <p>Shipping Address:</p>
                        <p>{state?.quote.shipping_address}</p>
                        <p>{state?.quote.shipping_city}</p>
                        <p>
                            {state?.quote.shipping_state}, {state?.quote.shipping_zip}
                        </p>
                    </div>
                </div>
                {/* Top Container for Installation, freight, Discount and Breakdowns */}
                <div className="infoBox_container">
                    {/* Installation and Discount Column */}
                    <div className="install_discount_column mainBox">
                        <p className="infoBox_header">Installation</p>
                        <div className="installation_info">
                            <p>Total Price: ${formatNumber(state?.quote.installation.customerPrice)}</p>
                            <p>
                                Total Cost: ${' '}
                                <input
                                    id="installation_cost"
                                    className="info_input"
                                    type={'number'}
                                    value={editedCostSheet?.installation?.cost}
                                    onChange={(e) =>
                                        setEditedCostSheet(
                                            (prevState) => ({
                                                ...prevState,
                                                installation: {
                                                    ...prevState['installation'],
                                                    cost: +e.target.value || null,
                                                },
                                            }),
                                            calculateInfo(),
                                        )
                                    }
                                />
                            </p>
                            <p>Profit: ${formatNumber(getInstallationProfit())}</p>
                            <p>
                                Profit Margin:{' '}
                                {isNaN(getInstallationProfit() / editedCostSheet?.installation?.price)? 'N/A' : Math.round((getInstallationProfit() / editedCostSheet.installation.price)* 100) + '%'}
                            </p>
                        </div>
                        <p className="infoBox_header">Discount</p>
                        <div className="installation_info">
                            <p>Additional Discount: ${state?.quote.discount.additionalDiscount}</p>
                            <p>Description:{state?.quote.discount.description}</p>
                            <p className="discount_field">
                                Additional Discount #2: ${' '}
                                <input
                                    className="info_input"
                                    id="addDiscount1"
                                    type={'number'}
                                    value={
                                        editedCostSheet?.discount?.addDiscount1 == 0
                                            ? ''
                                            : editedCostSheet?.discount?.addDiscount1
                                    }
                                    onChange={(e) =>
                                        setEditedCostSheet(
                                            (prevState) => ({
                                                ...prevState,
                                                discount: {
                                                    ...prevState['discount'],
                                                    addDiscount1: +e.target.value || null,
                                                },
                                            }),
                                            calculateInfo()
                                        )
                                    }
                                />
                            </p>
                            <p className="discount_field">
                                Note:
                                <input
                                    className="description_input"
                                    id="addDiscount1_note"
                                    type={'text'}
                                    value={editedCostSheet?.discount?.addDiscount1_note}
                                    onChange={(e) => {
                                        setEditedCostSheet((prevState) => ({
                                            ...prevState,
                                            discount: {
                                                ...prevState['discount'],
                                                addDiscount1_note: e.target.value || null,
                                            },
                                        }));
                                    }}
                                />
                            </p>
                            <p className="discount_field">
                                Additional Discount #3: ${' '}
                                <input
                                    className="info_input"
                                    id="addDiscount2"
                                    type={'number'}
                                    value={
                                        editedCostSheet?.discount?.addDiscount2 == 0
                                            ? ''
                                            : editedCostSheet?.discount?.addDiscount2
                                    }
                                    onChange={(e) =>
                                        setEditedCostSheet(
                                            (prevState) => ({
                                                ...prevState,
                                                discount: {
                                                    ...prevState['discount'],
                                                    addDiscount2: +e.target.value || null,
                                                },
                                            }),
                                            calculateInfo()
                                        )
                                    }
                                />
                            </p>
                            <p className="discount_field">
                                Note:
                                <input
                                    className="description_input"
                                    id="addDiscount2_note"
                                    type={'text'}
                                    value={editedCostSheet?.discount?.addDiscount2_note}
                                    onChange={(e) => {
                                        setEditedCostSheet((prevState) => ({
                                            ...prevState,
                                            discount: {
                                                ...prevState['discount'],
                                                addDiscount2_note: e.target.value || null,
                                            },
                                        }));
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                    {/* Freight Column */}
                    <div className="freight_column_main mainBox">
                        <p className="infoBox_header">Freight</p>
                        <div className="freight_column">
                            <div className="freight_col2">
                                <p className="info_highlight">Total Freight:</p>
                                <p>Total Price: {USDollar.format(state?.quote.freight.customerPrice)}</p>
                                <p>Total Cost: {USDollar.format(state?.quote.freight.cost)}</p>
                                <p>Allocated Freight:{USDollar.format(editedCostSheet?.freight?.additional_freight)}</p>
                                <p>
                                    Real Freight Cost: $
                                    <input
                                        className="info_input"
                                        id="real_freight"
                                        value={editedCostSheet?.freight?.real_freight}
                                        onChange={(e) => {
                                            setEditedCostSheet((prevState) => ({
                                                ...prevState,
                                                freight: {
                                                    ...prevState['freight'],
                                                    real_freight: e.target.value || null,
                                                },
                                            }));
                                            calculateInfo()
                                        }}
                                    />
                                </p>
                                <p>
                                    Real Handling Cost: $
                                    <input
                                        className="info_input"
                                        id="real_handling"
                                        value={editedCostSheet?.freight?.real_handling}
                                        onChange={(e) => {
                                            setEditedCostSheet((prevState) => ({
                                                ...prevState,
                                                freight: {
                                                    ...prevState['freight'],
                                                    real_handling: e.target.value || null,
                                                },
                                            }));
                                            calculateInfo()
                                        }}
                                    />
                                </p>
                                <p>Profit: {USDollar.format(getFreightProfit())}</p>
                                <p>Profit Margin:{isNaN(getFrieghtMargin()) ? 'N/A' : getFrieghtMargin() + '%'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="summary_column">
                        <div className="summary_Row">
                            <div className="summary_item large_item">
                                <p className="summary_header">Total Price Before Discount</p>
                                {summaryInfo.price ? (
                                    <p className="summary_total">{USDollar.format(summaryInfo?.price)}</p>
                                ) : (
                                    <div className = 'summary_total'><LoadingInline /></div>
                                )}
                            </div>
                            <div className="summary_item small_item">
                                <p className="summary_header">Total Discount</p>
                                {summaryInfo.discount >= 0 ? (
                                    <p className="summary_total">{USDollar.format(summaryInfo.discount)}</p>
                                ) : (
                                    <div className = 'summary_total'><LoadingInline /></div>
                                )}
                            </div>
                            <div className="summary_item small_item">
                                <p className="summary_header">Profit Margin</p>
                                {
                                    (() => {
                                        const margin = calculateTotalMargin();
                                        return margin !== 'NaN' ? (
                                            <p className="summary_total">{margin}%</p>
                                        ) : (
                                            <div className = 'summary_total'><LoadingInline /></div>
                                        )
                                    })()
                                }
                            </div>
                        </div>
                        <div className="summary_Row">
                            <div className="summary_item large_item">
                                <p className="summary_header">Total Price After Discount</p>
                                {summaryInfo.price_after_discount ? (
                                    <p className="summary_total">{USDollar.format(summaryInfo.price_after_discount)}</p>
                                ) : (
                                    <div className = 'summary_total'><LoadingInline /></div>
                                )}
                            </div>
                            <div className="summary_item small_item">
                                <p className="summary_header">Total Profit</p>
                                {summaryInfo.total_profit ? (
                                     <p className="summary_total">{USDollar.format(summaryInfo.total_profit)}</p>
                                ) : (
                                    <div className = 'summary_total'><LoadingInline /></div>
                                )}
                            </div>
                            <div className="summary_item small_item">
                                <p className="summary_header">Total Cost</p>
                                {
                                    (() => {
                                        const cost = USDollar.format(recalculateCost());
                                        return cost !== '$NaN' ? (
                                            <p className="summary_total">{cost}</p>
                                        ) : (
                                            <div className = 'summary_total'><LoadingInline /></div>
                                        )
                                    })()
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* Map items on quote */}
                <div className="quote_items">
                    <table className="cost_table">
                        <thead className="cost_table_head">
                            <th className="table_header">Product Name</th>
                            <th className="table_header">SKU</th>
                            <th className="table_header">Qty.</th>
                            <th className="table_header">List Price</th>
                            <th className="table_header">Sales Price</th>
                            <th className="table_header">Item Cost</th>
                            <th className="table_header">Total Price</th>
                            <th className="table_header">Profit</th>
                            <th className="table_header">Profit Margin</th>
                        </thead>
                        {/* Map the Products array here to line up with the headers */}

                        {editedCostSheet?.products?.map((product, index) => (
                            <tr className="product_row">
                                <td key={index} className="product_cell">
                                    {product.product_name}
                                </td>
                                <td key={index} className="product_cell">
                                    {product.playground_boss_sku}
                                </td>
                                <td key={index} className="product_cell">
                                    {product.qty}
                                </td>
                                <td key={index} className="product_cell">
                                    ${roundUp(product.web_listed_price)}
                                </td>
                                <td key={index} className="product_cell">
                                    {product.sale_price ? '$' + roundUp(product.sale_price) : ''}
                                </td>
                                <td key={index} className="product_cell">
                                    $
                                    {product.ops_cost != null ? (
                                        <input
                                            id={product.playground_boss_sku}
                                            className="info_input"
                                            type={'number'}
                                            value={product.ops_cost == 0 ? '' : product.ops_cost}
                                            onChange={(e) => refreshProductCost(index, +e.target.value, true)}
                                        />
                                    ) : (
                                        <input
                                            id={product.playground_boss_sku}
                                            className="info_input"
                                            type={'number'}
                                            value={product.cost == 0 ? '' : product.cost}
                                            onChange={(e) => refreshProductCost(index, +e.target.value, false)}
                                        />
                                    )}
                                </td>
                                <td key={index} className="product_cell">
                                    {USDollar.format(product.total_price.toFixed(2))}
                                </td>
                                <td key={index} className="product_cell">
                                    {USDollar.format(calculateProductProfit(product).toFixed(2))}
                                </td>
                                <td key={index} className="product_cell">
                                    {calculateProductMargin(product)}%
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </>
    );
};
export default CostSheetView;
