import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ apiData, barOrientation, xLabel="", yLabel="", seriesLabel = "", seriesQuantifier = 0, seriesColorSet={} }) => {
  const labels = apiData.length > 0 ? apiData[0].map(item => item[0]) : [];

  const datasets = apiData.map((series, index) => {
    // Use predefined colors or generate random ones
    const color = seriesColorSet[index] || `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`;

    return {
        label: `${seriesLabel}${index + seriesQuantifier}`,
        data: series.map(item => item[1]),
        borderColor: color,
        backgroundColor: color,
    };
  });

  const data = { labels, datasets };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel, // Customize this value
        }
      },
      y: {
        title: {
          display: true,
          text: yLabel, // Customize this value
        }
      }
    },
    indexAxis: barOrientation, // Use 'y' for horizontal bar charts, 'x' for vertical bars
  };

  return (
    <div style={{ width: 'auto', height: 'auto' }}>
        <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
