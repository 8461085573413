import React, {  useState, useEffect  } from 'react';
import {  DragDropContext, Draggable, Droppable  } from 'react-beautiful-dnd';
import trashIcon from '../../../assets/trashIcon.svg';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';
import CellCost from './CellCost';
import CellMargin from './CellMargin';
import CellName from './CellName';
import CellPrice from './CellPrice';
import CellProfit from './CellProfit';
import CellQty from './CellQty';
import CellSalePrice from './CellSalePrice';
import CellSku from './CellSku';
import CellTotalPrice from './CellTotalPrice';
import CellDescription from './CellDescription';
import CellVariants from './CellVariants';
import CellTotalCost from './CellTotalCost';
import CellExcludeInstallation from './CellExcludeInstallation';

const ProductsTable = ({
    calculateRow,
    setRecalculateTotals,
    productVariants,
    productChange,
    setProductChange,
    setAutoSave,
}) => {
    const { state, dispatch } = useStore();
    // Set state for debug toggle button
    const [debugToggle, setDebugToggle] = useState();

    // When delete button is clicked in product row
    // Remove product from state
    const removeProduct = (e, playground_boss_sku) => {
        dispatch({
            type: ACTIONS.PRODUCT_TABLE_ITEMS,
            payload: state?.productTableItems.filter(
                (product) => product.playground_boss_sku !== playground_boss_sku
            ),
        });
        dispatch({
            type: ACTIONS.QUOTE,
            payload: {
                ...state?.quote,
                products: state?.productTableItems,
            },
        });
        setRecalculateTotals(true);
    };

    /**
     * Functions for drag and drop
     */

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reordered = reorder(state?.productTableItems, result.source.index, result.destination.index);
        dispatch({ type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: reordered });
    };

    // End functions for drag and drop

    /**
     * productsTable has two 'views'
     * Table shows some properties when 'debugToggle' in state is true, others when it's false
     */
    return (
        <div className="component-card productsTable">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <table {...provided.droppableProps} ref={provided.innerRef}>
                            <colgroup>
                                {/* Always show these fields, regardless of debug mode */}
                                <col span="1" className="col-buttons" />
                                <col span="1" className="col-sku" />
                                <col span="1" className="col-name" />
                                {/* When debug is not selected, show following fields */}
                                <col span="1" className="col-description" />
                                <col span="1" className="col-variants" />
                                <col span="1" className="col-qty" />
                                <col span="1" className="col-list_price" />
                                <col span="1" className="col-sale_price" />
                                <col span="1" className="col-cost" />
                                <col span="1" className="col-total_price" />
                                <col span="1" className="col-profit" />
                                <col span="1" className="col-margin" />
                                <col span="1" className="col-exclusion" />
                            </colgroup>
                            <thead>
                                <tr className='sticky-underline-orange'>
                                    <th className="col-buttons no-bottom-border" />
                                    <th className="col-sku text-bold text-med pad-t-lg pad-b-lg no-bottom-border">SKU</th>
                                    <th className="col-name text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Product</th>
                                    <th className="col-description text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Description</th>
                                    <th className="col-variants text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Variants</th>
                                    <th className="col-qty text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Qty.</th>
                                    <th className="col-list_price text-bold text-med pad-t-lg pad-b-lg no-bottom-border">List Price</th>
                                    <th className="col-sale_price text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Sale Price </th>
                                    <th className="col-cost text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Item Cost</th>
                                    <th className="col-total_price text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Total</th>
                                    <th className="col-profit text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Profit</th>
                                    <th className="col-margin text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Margin %</th>
                                    <th className="col-exclusion text-bold text-med pad-t-lg pad-b-lg no-bottom-border">Exclude Installation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* 
                Map through all items in state state?.productTableItems
                For each product in array give own table row
                */}
                                {state?.productTableItems?.map((product, index, array) => (
                                    <Draggable
                                        key={product.playground_boss_sku}
                                        draggableId={String(product.playground_boss_sku)}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <tr
                                                className={index === array.length - 1 ? '' : "productsTable_row"}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                {/* 
                        Delete Row
                        Display icon (or x) for deleting row 
                        */}
                                                {/* ternary to only display 'x' if the state?.quote is not read only */}
                                                {state?.quote.readOnly ? (
                                                    <td></td>
                                                ) : (
                                                    <td
                                                        key="buttons"
                                                        className="productsTable_deleteBtn col-buttons"
                                                        onClick={(e) => {
                                                            if (!state?.quote.readOnly) {
                                                                removeProduct(e, product.playground_boss_sku);
                                                            }
                                                        }}>
                                                        {<img src={trashIcon} className='sm-box' alt="trash icon" />}
                                                    </td>
                                                )}

                                                <CellSku
                                                    sku={product.playground_boss_sku}
                                                    product={product} />

                                                <CellName
                                                    name={product.product_name}
                                                />

                                                <CellDescription
                                                    product={product}
                                                    setAutoSave={setAutoSave}
                                                    calculateRow={calculateRow}
                                                />

                                                <CellVariants
                                                    product={product}
                                                    productVariants={productVariants}
                                                />

                                                {/* Quantity of product */}
                                                <CellQty
                                                    product={product}
                                                    calculateRow={calculateRow}
                                                />

                                                {/* Product list price - 
                        user can edit value if no value in database 
                        */}
                                                <CellPrice
                                                    product={product}
                                                    calculateRow={calculateRow}
                                                />

                                                {/* Product sale price - user can edit value if no value in database */}
                                                <CellSalePrice
                                                    productChange={productChange}
                                                    setProductChange={setProductChange}
                                                    product={product}
                                                    calculateRow={calculateRow}
                                                />

                                                <CellCost
                                                    product={product}
                                                    calculateRow={calculateRow}
                                                />
                                                <CellTotalPrice product={product} />

                                                <CellProfit product={product} />

                                                <CellMargin product={product} />

                                                <CellExcludeInstallation
                                                    product={product}
                                                    calculateRow={calculateRow} />
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default ProductsTable;
