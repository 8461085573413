import React from 'react';
import Btn from '../Btn';

const UpdateContact = ({contactType, setShowUpdateModal, setUpdateModalType, billingFieldInvalid, shippingFieldInvalid}) => {
    function setConfirmationModal() {
        setUpdateModalType(contactType);
        setShowUpdateModal(true);
    }

    const onClick = (params) => setConfirmationModal();
    const title = 'Update ' + contactType;

    return <Btn title={title} onClick={onClick} 
    btnClass = {`${(billingFieldInvalid || shippingFieldInvalid) ? "btn-disabled" : "box-shadow blue-button b2-blue text-white bg-blue"} text-bold`}
    disabled = {(billingFieldInvalid || shippingFieldInvalid)}/>;
};

export default UpdateContact;
