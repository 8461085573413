import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addAccessLogEvent } from '../utilities/accessLog';
import PurchaseOrders from '../components/Project/PurchaseOrders';
import Issues from '../components/Project/Issues';
import ProjectInformation from '../components/Project/ProjectInformation';
import ExtraNotes from '../components/Project/ExtraNotes';
import PointofContact from '../components/Project/PointofContact';
import InvoiceTable from '../components/Project/InvoiceTable';
import Images from '../components/Project/Images';
import InstallationStatus from '../components/Project/InstallationStatus';
import LastActivityDate from '../components/Project/LastActivityDate';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import Btn from '../components/Btn';
import blackWarning from '../../src/assets/warning-black.svg';
import whiteWarning from '../../src/assets/warning-white.svg';
import xIcon from '../../src/assets/xicon.svg';
import pgLogo from '../../src/assets/PGLogo.png';
import notFound from '../../src/assets/not-found.svg';
import formatDate from '../utilities/formatDate';
import LoadingInline from '../components/LoadingInline';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';

const ViewProject = () => {
  const { state, dispatch } = useStore();

  const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
  const navigate = useNavigate();

  const [userDropdown, setUserDropdown] = useState();
  const [projectOwner, setProjectOwner] = useState('');
  const [lastAccess, setLastAccess] = useState('')
  const [preferredCompletionDate, setPreferredCompletionDate] = useState("");
  const [pastDateStatus, setPastDateStatus] = useState(false);
  const [projectComplete, setProjectComplete] = useState(false);
  const [extraNotes, setExtraNotes] = useState('')
  const [unitInStock, setUnitInStock] = useState({});
  const [factoryOrder, setFactoryOrder] = useState({});
  const [poSubmitted, setPoSubmitted] = useState({});
  const [signedPerforma, setSignedPerforma] = useState({});
  const [freightPoSubmitted, setFreightPoSubmitted] = useState({});
  const [arrivalDate, setArrivalDate] = useState({});
  const [kidstaleManufacturers, setKidstaleManufacturers] = useState({});
  const [customerContacted, setCustomerContacted] = useState(false)
  const [showImage, setShowImage] = useState({ status: false, url: "" })
  const [imageArray, setImageArray] = useState();
  const [taxExemptionArray, setTaxExemptionArray] = useState();
  const [issueOutstanding, setIssueOutstanding] = useState(false)
  const [fetchData, setFetchData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingQuote, setLoadingQuote] = useState(true);
  const [loadingImage, setLoadingImage] = useState(true);
  const [loadingAccessLog, setLoadingAccessLog] = useState(true);
  const [loadingQB, setLoadingQB] = useState(true);
  const [awsData, setAwsData] = useState([]);
  const [phoneExt, setPhoneExt] = useState('');
  const [projectName, setProjectName] = useState('');
  const [showUnknownsModal, setShowUnknownsModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('');
  const [unknownPOs, setUnknownPOs] = useState({});
  const [projectCancelled, setProjectCancelled] = useState(false);
  const [invalidProject, setInvalidProject] = useState(false);
  const [invoiceArray, setInvoiceArray] = useState([]);
  const [nameChangingLoading, setNameChangingLoading] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [installationDateChanged, setInstallationDateChanged] = useState(false)
  const [issueDetails, setIssueDetails] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [issuesFormChanged, setIssuesFormChanged] = useState(false);
  const [activityDate, setActivityDate] = useState('');
  const [selectedActivity, setSelectedActivity] = useState('');
  const [widgets, setWidgets] = useState([]);
  const [customerContactDate, setCustomerContactDate] = useState('');

  const projectID = window.location.pathname.split('/operations/projects/')[1];
  const params = new URLSearchParams(window.location.search);
  const column1Items = params.get('column1') ? atob(params.get('column1')).split(',') : [];
  const column2Items = params.get('column2') ? atob(params.get('column2')).split(',') : [];

  const extraWideWidgets = ['project_issues', 'pos_status'];
  let quoteID = projectID.substring(7)?.split('-')[0];

  const continueButton = async () => {
    let unknownPOsSet = true;
    state?.qbPurchaseOrders?.unknowns?.map((po) => {
      if (!unknownPOs[po.refID]) {
        unknownPOsSet = false;
      }
    });

    try {
      if (unknownPOsSet) {
        setShowUnknownsModal(false);
        await saveUnknownPOs();
      } else {
        dispatch({ type: ACTIONS.TOAST, payload: { message: "Please select a PO type for each unknown PO.", isError: true } });
      }
    } catch (err) {
      console.log(err);
      setShowUnknownsModal(false);
      setFetchData(true);
      dispatch({ type: ACTIONS.TOAST, payload: { message: "Issue saving POs.", isError: true } });
      setUnknownPOs({});
    }
  }

  const saveUnknownPOs = async () => {
    try {
      const res = await fetch(`${config.base_api}/projects/unknownPOs/${projectID}`, {
        headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
        method: 'POST',
        body: JSON.stringify(unknownPOs),
      });
      await convertJSON(res);
      if (res.ok) {
        dispatch({ type: ACTIONS.TOAST, payload: { message: "POs saved." } });
        setFetchData(true);
        setUnknownPOs({});
      } else {
        dispatch({ type: ACTIONS.TOAST, payload: { message: "Issue saving POs.", isError: true } });
        setFetchData(true);
        setUnknownPOs({});
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: ACTIONS.TOAST, payload: { message: "Issue saving POs.", isError: true } });
      setUnknownPOs({});
    }
  }

  const connectProjectData = async () => {
    try {
      let projectData = await fetch(`${config.base_api}/projects/${projectID}`, { headers });
      projectData = await convertJSON(projectData);

      let installBadgeDate = projectData?.install_date || ""
      if (installBadgeDate?.length > 0) {
        let day = new Date(installBadgeDate)
        let nextDay = day.setDate(day.getDate() + 1)
        let newDate = formatDate(nextDay)

        projectData = { ...projectData, installBadgeDate: newDate }
        dispatch({ type: ACTIONS.PROJECT, payload: projectData });
      } else {
        projectData = { ...projectData, installBadgeDate: installBadgeDate }
        dispatch({ type: ACTIONS.PROJECT, payload: projectData });
      }

      if (!projectData?.id) {
        setInvalidProject(true)
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const connectPurchaseOrderData = async () => {
    try {
      let purchaseOrders = await fetch(`${config.base_api}/projects/purchaseOrders/${projectID}`, { headers });
      purchaseOrders = await convertJSON(purchaseOrders);
      const products = {};
      purchaseOrders?.forEach((po) => {
        products[po.id] = po;
        setUnitInStock((prevState) => ({ ...prevState, [po?.id]: po?.unit_in_stock }));
        setFactoryOrder((prevState) => ({ ...prevState, [po?.id]: po?.factory_order })); 
        setPoSubmitted((prevState) => ({ ...prevState, [po?.id]: po?.po_submitted })); 
        setSignedPerforma((prevState) => ({ ...prevState, [po?.id]: po?.signed_performa }));
        setFreightPoSubmitted((prevState) => ({ ...prevState, [po?.id]: po?.freight_po_submitted }));
        setArrivalDate((prevState) => ({ ...prevState, [po?.id]: po?.arrival_date }));
      });
      dispatch({ type: ACTIONS.PURCHASE_ORDER, payload: products });
    } catch (err) {
      dispatch({ type: ACTIONS.LOADING_PO, payload: false });
      console.log(err);
    }
  }

  const connectPOData = async () => {
    try {
      let poData = await fetch(`${config.base_api}/quickbooks/syncProducts/authURI/${projectID}`, { headers });
      poData = await convertJSON(poData);

      if (poData?.unknowns?.length > 0) setShowUnknownsModal(true);
      if (poData?.invoiceName) setProjectName(prevState => ({ ...prevState, [projectID]: poData?.invoiceName }));
      await getInvoices();
      dispatch({ type: ACTIONS.QB_PURCHASE_ORDER, payload: poData });
      setLoadingQB(false);
      poData?.pos?.forEach((po) => {
        if (po?.manufacturer_name?.toLowerCase()?.includes('kidstale')) {
          setKidstaleManufacturers((prevState) => ({ ...prevState, [po?.refID]: true }))
        }
      });
      await connectPurchaseOrderData();
    } catch (err) {
      console.log(err);
      setLoadingQB(false);
    }
  }

  const connectQuoteData = async () => {
    try {
      let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, { headers });
      quoteData = await convertJSON(quoteData);
      dispatch({ type: ACTIONS.QUOTE, payload: quoteData });
      dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: quoteID });
      setLoadingQuote(false);
    } catch (err) {
      setLoadingQuote(false);
      console.log(err)
    }
  }

  const connectImageData = async () => {
    let draftImageArray = [];
    let draftTaxExemptionArray = [];

    try {
      let imageData = await fetch(`${config.base_api}/images/${projectID}`, { headers });
      imageData = await convertJSON(imageData);
      for (let i = 0; i < imageData?.length; i++) {
        let imageLink = imageData[i]?.image_link || '';
        let imageType = ((imageLink).substring(imageLink?.length - 3))
        if (imageType === "pdf") {
          if (imageData[i]?.tax_exemption) {
            draftTaxExemptionArray.push({ ...imageData[i], type: false });
          } else {
            draftImageArray.push({ ...imageData[i], type: false });
          }
        } else {
          if (imageData[i]?.tax_exemption) {
            draftTaxExemptionArray.push({ ...imageData[i], type: true });
          } else {
            draftImageArray.push({ ...imageData[i], type: true });
          }
        }
      }
      setImageArray(draftImageArray);
      setTaxExemptionArray(draftTaxExemptionArray);
      setLoadingImage(false);
    } catch (err) {
      setLoadingImage(false);
      console.log(err)
    }
  }

  const connectAccessLog = async () => {
    try {
      let res = await fetch(`${config.base_api}/projects/accesslog/${projectID}`, { headers });
      let accessLogData = await convertJSON(res)
      if (accessLogData[0]?.update_message) setLastAccess(accessLogData[0]?.update_message);
      setLoadingAccessLog(false);
    } catch (err) {
      setLoadingAccessLog(false);
      console.log(err);
    }
  }

  const connectDBUsers = async () => {
    let dropdownArray = []
    try {
      const res = await fetch(`${config.base_api}/users/db`, { headers });
      const data = await convertJSON(res);

      let awsUsers = data?.filter((user) => user?.aws_id !== null)
      setAwsData(awsUsers)

      for (let i = 0; i < awsUsers?.length; i++) {
        if (awsUsers[i]?.name.toLowerCase().trim() === state?.project?.owner?.toLowerCase()?.trim()) setPhoneExt(awsUsers[i]?.phone_ext)

        let userName = awsUsers[i]?.name || awsUsers[i]?.email.toLowerCase();
        let userEmail = awsUsers[i]?.email.toLowerCase();
        let isOperations = awsUsers[i]?.operations;

        if (isOperations == true) {
          dropdownArray.push({ userName, userEmail })
        }
      }
    } catch (error) {
      console.log(error)
    }

    setUserDropdown(dropdownArray)
  }

  const getInvoices = async () => {
    try {
      let invoiceData = await fetch(`${config.base_api}/projects/invoices/${projectID}`, { headers });
      invoiceData = await invoiceData.json();

      setInvoiceArray(invoiceData);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    async function fetchData() {
      setLoadingQB(true);
      setLoadingQuote(true);
      setLoadingAccessLog(true);
      setLoadingImage(true);
      dispatch({ type: ACTIONS.LOADING_PO, payload: true });
      setLoading(true);

      await widgetOrganizer();
      await connectProjectData();
      await connectPurchaseOrderData();
      await connectQuoteData();
      await connectAccessLog();
      await connectDBUsers();
      await connectPOData();
      await connectImageData();
    }

    fetchData();

    return () => {
      dispatch({ type: ACTIONS.PROJECT, payload: {} });
      dispatch({ type: ACTIONS.PURCHASE_ORDER, payload: [] });
      dispatch({ type: ACTIONS.QUOTE, payload: {} });
      dispatch({ type: ACTIONS.LOADING_PO, payload: false });
      dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: '' });
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      setCustomerContacted(state?.project?.customer_contacted || false)
      setExtraNotes(state?.project?.extra_notes || '')
      setProjectComplete(state?.project?.complete || false)
      setProjectOwner(state?.project?.owner || '')
      setProjectCancelled(state?.project?.cancelled || false)
      setCustomerContacted(state?.project?.customer_contacted || false)
      setCustomerContactDate(state?.project?.contact_date || '')
    }

    fetchData();
  }, [state?.project])

  const widgetOrganizer = async () => {
    try {
      const res = await fetch(`${config.base_api}/configuration/organizer`, { headers });
      const data = await convertJSON(res);
      setWidgets(data);
    } catch (error) {
      dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error loading widget organization' } });
    }
  }

  async function createAccessLogEvent(eventValue) {
    const accessLogEvent = {
      project_id: projectID,
      update_message: eventValue,
      user: localStorage.getItem('username') || '',
    }

    try {
      const res = await addAccessLogEvent(accessLogEvent, headers);
      if (res) {
        dispatch({ type: ACTIONS.TOAST, payload: { message: "Project has been saved and Access Log updated" } })
        setLastAccess(accessLogEvent.update_message)
      }
    } catch (e) {
      console.log(e)
      dispatch({ type: ACTIONS.TOAST, payload: { message: e, isError: true } })
    }
  }

  const setPoSync = async (searchPoNumber) => {
    try {
      const synced = await fetch(`${config.base_api}/quickbooks/syncProducts/authURI/PoProjectNumber_|_${projectID}_|_${searchPoNumber}`, { headers });
      await convertJSON(synced);

      if (synced.ok) {
        setFetchData(true);
        dispatch({ type: ACTIONS.TOAST, payload: { message: "PO has been synced" } });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const currentDate = formatDate(new Date).slice(0, 5)
  let today = new Date()
  let todayDate = today.getFullYear() + '-' + currentDate
  let todayTime = today.toLocaleTimeString('en-US').slice(0, -6) + today.toLocaleTimeString('en-US').toLowerCase().slice(-2)
  let todayTimeAndDate = todayTime + " " + (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getFullYear();

  useEffect(() => {

    async function fetchData() {
      const res = state?.project?.preferred_completion || null
      const preferredDate = res?.slice(0, 10) || null
      if (preferredDate) {
        setPreferredCompletionDate(preferredDate)
      }
    }
    fetchData();
  }, [state?.project, todayDate])

  useEffect(() => {
    async function fetchData() {
      let projectLate = false;
      if (state?.purchaseOrders && preferredCompletionDate?.length > 0) {
        Object.keys(state?.purchaseOrders).forEach((id) => {
          if (!state?.purchaseOrders[id]?.arrival_date && state?.purchaseOrders[id]?.type !== 'installation') return null;
          else if (state?.purchaseOrders[id]?.arrival_date?.length < 1 && state?.purchaseOrders[id]?.type !== 'installation') return null;
          else if (!state?.purchaseOrders[id]?.install_date && state?.purchaseOrders[id]?.type === 'installation') return null;
          else if (state?.purchaseOrders[id]?.install_date?.length < 1 && state?.purchaseOrders[id]?.type === 'installation') return null;
          else if (state?.purchaseOrders[id]?.type === 'installation' && preferredCompletionDate < state?.purchaseOrders[id]?.install_date?.split('T')[0]) projectLate = true;
          else if (state?.purchaseOrders[id]?.arrival_date && preferredCompletionDate < state?.purchaseOrders[id]?.arrival_date?.split('T')[0]) projectLate = true;
        });
      }
      setPastDateStatus(projectLate)
      dispatch({ type: ACTIONS.UPDATE_PREFERED_COMPLETION_DATE, payload: false });
    }
    fetchData();
  }, [preferredCompletionDate, state?.loadedPO, state?.updatedPreferredCompletionDate])

  useEffect(() => {
    async function fetchData() {
      setLoadingQuote(true);
      try {
        let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, { headers });
        quoteData = await quoteData.json();
        dispatch({ type: ACTIONS.QUOTE, payload: quoteData });
        setLoadingQuote(false);
      } catch (err) {
        setLoadingQuote(false);
        console.log(err)
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      setIssueOutstanding(false);
      if (fetchData) {
        setLoadingQB(true);
        dispatch({ type: ACTIONS.LOADING_PO, payload: true });
        setFetchData(false);
        await connectPurchaseOrderData();
        await connectProjectData();
        try {
          let poData = await fetch(`${config.base_api}/quickbooks/syncProducts/authURI/${projectID}`, { headers });
          poData = await convertJSON(poData);

          if (poData?.unknowns?.length > 0) setShowUnknownsModal(true);
          // setPOs(poData);
          dispatch({ type: ACTIONS.QB_PURCHASE_ORDER, payload: poData });
          setLoadingQB(false);
        } catch (err) {
          setLoadingQB(false);
          console.log(err);
        }
      }
    }

    fetchData();
  }, [fetchData]);

  useEffect(() => {
    async function fetchData() {
      if (saveClicked) {
        handleSaveButton();
      }
    }
    fetchData();
  }, [saveClicked])

  let savedProject = {
    complete: projectComplete,
    customer_contacted: customerContacted,
    extra_notes: extraNotes,
    cancelled: projectCancelled,
    contact_date: customerContactDate,
  };

  if (preferredCompletionDate?.length > 0) {
    savedProject = { ...savedProject, preferred_completion: preferredCompletionDate }
  }

  if (projectOwner?.length > 0) {
    savedProject = { ...savedProject, owner: projectOwner }
  }

  const compareValues = (originalValue, newValue) => {
    if ((originalValue || '') !== (newValue || '')) {
      return true;
    } else {
      return false;
    }
  }

  const getBool = (value) => {
    if (typeof value == 'boolean') return value;
    if (value == 'true') {
      return true;
    } else if (value == 'false') {
      return false;
    }
    return null;
  }

  const savePurchaseOrders = (changedComponents) => {
    const ids = Object.keys(state?.purchaseOrders);
    const savedPOs = [];
    let poSubmittedCount = 0;
    let signedPerformaCount = 0;
    let poCount = 0;
    let kidstaleCount = 0;
    ids.forEach((id) => {
      const po = state?.purchaseOrders[id];
      // if (po?.type !== 'product') return null;
      poCount++;
      if (kidstaleManufacturers[id]) kidstaleCount++;
      if (kidstaleManufacturers[id] && getBool(signedPerforma[id])) signedPerformaCount++;
      if (getBool(poSubmitted[id])) poSubmittedCount++;
      if (compareValues(po.unit_in_stock, unitInStock[id]) || compareValues(po.factory_order, factoryOrder[id]) || compareValues(po.po_submitted, poSubmitted[id])
        || compareValues(po.signed_performa, getBool(signedPerforma[id])) || compareValues(po.freight_po_submitted, freightPoSubmitted[id]) || compareValues(po.arrival_date, arrivalDate[id])) {
        savedPOs.push({ unit_in_stock: unitInStock[id], factory_order: factoryOrder[id], po_submitted: poSubmitted[id], signed_performa: signedPerforma[id], freight_po_submitted: freightPoSubmitted[id], arrival_date: arrivalDate[id], id: po.id, kids_tales: kidstaleManufacturers[id] ? true : false });
        if (!changedComponents.includes("Purchase Orders")) changedComponents.push("Purchase Orders");
      }
    });
    if (poSubmittedCount === poCount && poCount) savedProject.all_pos_submitted = true;
    else savedProject.all_pos_submitted = false;
    if (signedPerformaCount === kidstaleCount && kidstaleCount) savedProject.all_signed_performa = true;
    else savedProject.all_signed_performa = false;

    return savedPOs;
  }

  const handleSaveButton = async () => {
    const changedComponents = [];
    savedProject.purchaseOrders = savePurchaseOrders(changedComponents);
    if (savedProject.complete !== state?.project.complete || (savedProject.preferred_completion !== state?.project.preferred_completion?.substring(0, 10) && preferredCompletionDate?.length > 0)
      || (savedProject.owner !== state?.project.owner && projectOwner?.length > 0)) {
      changedComponents.push("Project Information");
    } else {
      console.log("project info same")
    }
    if (savedProject.customer_contacted === false) {
      savedProject.contact_date = null;
    }
    
    if (savedProject.customer_contacted !== state?.project.customer_contacted) {
      changedComponents.push("Point of Contact");
    } else if (savedProject.contact_date !== state?.project.contact_date) {
      changedComponents.push("Point of Contact");
     } else {
      console.log("contacts same")
    }
    if (savedProject.extra_notes !== state?.project.extra_notes) {
      changedComponents.push("Extra Notes");
    } else {
      console.log("notes same")
    }
    if (savedProject.cancelled !== state?.project.cancelled) {
      changedComponents.push("Project Status");
    } else {
      console.log("status same")
    }
    if (changedComponents?.length > 0) {
      try {
        const res = await fetch(`${config.base_api}/projects/updateProject/${state?.project.id}`, {
          method: 'PATCH',
          headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
          body: JSON.stringify(savedProject),
        });

        if (res.ok) {
          let messageString = changedComponents.join(', ')
          createAccessLogEvent(`Edited ${messageString}.`);
          setFetchData(true);
        }
      } catch (error) {
        console.log(error)
      }
    } else if (installationDateChanged || (issuesFormChanged && (issueDetails.length > 0 && issueDate.length > 0)) || (activityDate.length > 0 && selectedActivity.length > 0)) {
      console.log("installation date, issue, or activity changed")
    } else {
      console.log("no change")
      dispatch({ type: ACTIONS.TOAST, payload: { message: "No changes have been made." } })
    }
    setSaveClicked(false);
  };

  const handleInvoiceButton = async () => {
    setInvoiceModal(true);
  }

  const handleSyncInvoice = async () => {
    setNameChangingLoading(true);
    let projectID = state?.project.project_id;

    try {
      const res = await fetch(`${config.base_api}/quickbooks/syncProducts/updateProjectInvoice/${projectID}`, {
        method: 'PATCH',
        headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
        body: JSON.stringify({ searchInvoiceNumber })
      });
      await convertJSON(res);

      if (res.ok) {
        if (res.message === "Invoice already exists") {
          dispatch({
            type: ACTIONS.TOAST, payload: {
              message: 'Invoice already exists',
              isError: true,
            }
          });
          return;
        }
        await connectPOData();
        setFetchData(true);
        setInvoiceModal(false);
        setNameChangingLoading(false);
        setSearchInvoiceNumber('');
        await createAccessLogEvent(`Invoice ${searchInvoiceNumber} synced, ${todayTimeAndDate}.`)
        dispatch({ type: ACTIONS.TOAST, payload: { message: "Invoice synced successfully." } })

      } else {
        setNameChangingLoading(false);
        dispatch({ type: ACTIONS.TOAST, payload: { message: "Could not sync invoice. Please try again.", isError: true } });
      }
    } catch (error) {
      setNameChangingLoading(false);
      dispatch({ type: ACTIONS.TOAST, payload: { message: "Could not sync invoice. Please try again.", isError: true } })
      console.log(error)
    }
  }

  const closeInvoiceModal = async () => {
    setInvoiceModal(false);
  }

  const handleEmailSent = async () => {
    createAccessLogEvent(`Project Owner, ${todayTimeAndDate}. Sent Installation Status email.`);
  }

  const handleSaveInstallationDate = async () => {
    createAccessLogEvent(`Project Owner, ${todayTimeAndDate}. Saved Installation Date.`);
  }

  const logImagesUpdate = async () => {
    createAccessLogEvent(`Project Owner, ${todayTimeAndDate}. Updated Images.`);
  }

  const logTaxExemptionsUpdate = async () => {
    createAccessLogEvent(`Project Owner, ${todayTimeAndDate}. Updated Tax Exemptions.`);
  }

  const backToProject = () => {
    const newPath = "/operations/projects";
    dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: '' });
    navigate(newPath);
  }

  const handleCancelProject = async () => {
    setProjectCancelled(!projectCancelled);
  }

  const displayIssueOutstanding = () => {
    if (preferredCompletionDate === state?.project?.preferred_completion?.split('T')[0] && issueOutstanding) {
      return (
        <div className="flex align-center bg-red text-white b-none border-radius-sm box-shadow pad-lg pad-t-lg pad-b-lg margin-r-md">
          <span className='pad-r-xs'><img src={whiteWarning} className='bold height-1dot5em' /></span>
          <p className="line-height-sm text-xs"><em><span className='newline-after'>Issues Outstanding.</span>Time to Complete Items in Red: 3 Days</em></p>
        </div>
      )
    }
    return null;
  }

  const displayPastPreferredDate = () => {
    if (pastDateStatus) {
      return (
        <div className="flex align-center bg-yellow text-black b-none border-radius-sm box-shadow pad-lg pad-t-lg pad-b-lg margin-r-md">
          <span className='pad-r-xs'><img src={blackWarning} className='bold height-1dot5em' /></span>
          <p className="text-xs"><em>Past Preferred Completion Date</em></p>
        </div>
      )
    }
    return null;
  }

  
  const displayProjectName = (proj) => {
    if (projectName[proj?.project_id]) {
      return <p>{projectName[proj?.project_id]}</p>
    }
    return <LoadingInline size='h3' bold={true} title={proj?.project_name} id='project-name-loader' />;
  }

  const renderComponent = (componentId, leftCol = false) => {
    if (componentId === 'project_information') {
      return <ProjectInformation
            headers={headers}
            projectID={projectID}
            projectOwner={projectOwner}
            setProjectOwner={setProjectOwner}
            userDropdown={userDropdown}
            lastAccess={lastAccess}
            projectComplete={projectComplete}
            setProjectComplete={setProjectComplete}
            preferredCompletionDate={preferredCompletionDate}
            setPreferredCompletionDate={setPreferredCompletionDate}
            pastDateStatus={pastDateStatus}
            todayDate={todayDate}
            setIssueOutstanding={setIssueOutstanding}
            loading={loading}
            loadingQuote={loadingQuote}
            loadingAccessLog={loadingAccessLog}
          />
    }
    else if (componentId === 'point_of_contact') {
      return <PointofContact
            customerContacted={customerContacted}
            setCustomerContacted={setCustomerContacted}
            loading={loading}
            loadingQuote={loadingQuote}
            customerContactDate={customerContactDate}
            setCustomerContactDate={setCustomerContactDate}
            leftCol={leftCol}
          />
    }
    else if (componentId === 'pos_status') {
      return <PurchaseOrders
            setIssueOutstanding={setIssueOutstanding}
            issueOutstanding={issueOutstanding}
            unitInStock={unitInStock}
            setUnitInStock={setUnitInStock}
            factoryOrder={factoryOrder}
            setFactoryOrder={setFactoryOrder}
            poSubmitted={poSubmitted}
            signedPerforma={signedPerforma}
            setSignedPerforma={setSignedPerforma}
            arrivalDate={arrivalDate}
            setArrivalDate={setArrivalDate}
            setPoSync={setPoSync}
            loadingPO={state?.loadingPO}
            loadingQB={loadingQB}
            setFetchData={setFetchData}
          />
    }
    else if (componentId === 'installation_status') {
      return <InstallationStatus
          projectID={projectID || ""}
          userDropdown={userDropdown}
          handleEmailSent={handleEmailSent}
          handleSaveInstallationDate={handleSaveInstallationDate}
          loading={loading}
          loadingQuote={loadingQuote}
          loadingPO={state?.loadingPO}
          loadingQB={loadingQB}
          phoneExt={phoneExt}
          saveClicked={saveClicked}
          installationDateChanged={installationDateChanged}
          setInstallationDateChanged={setInstallationDateChanged}
          leftCol={leftCol}
        />
    }
    else if (componentId === 'project_issues') {
      return <Issues
          loading={loading}
          setLastAccess={setLastAccess}
          saveClicked={saveClicked}
          issueDate={issueDate}
          setIssueDate={setIssueDate}
          issueDetails={issueDetails}
          setIssueDetails={setIssueDetails}
          issuesFormChanged={issuesFormChanged}
          setIssuesFormChanged={setIssuesFormChanged}
        />
    }
    else if (componentId === 'last_activity_date') {
      return <LastActivityDate
          activityDate={activityDate}
          setActivityDate={setActivityDate}
          selectedActivity={selectedActivity}
          setSelectedActivity={setSelectedActivity}
          saveClicked={saveClicked} 
          leftCol={leftCol}
          />
    }
    else if (componentId === 'images') {
      return <Images key={'images'}
          taxExemption={false}
          projectID={projectID}
          headers={headers}
          showImage={showImage}
          setShowImage={setShowImage}
          imageArray={imageArray}
          logImagesUpdate={logImagesUpdate}
          connectImageData={connectImageData}
          loading={loading}
          loadingImage={loadingImage}
          leftCol={leftCol}
        />
    }
    else if (componentId === 'tax_exemption') {
      return <Images key={'tax_exemption'}
        taxExemption={true}
        projectID={projectID}
        headers={headers}
        showImage={showImage}
        setShowImage={setShowImage}
        imageArray={taxExemptionArray}
        logImagesUpdate={logTaxExemptionsUpdate}
        connectImageData={connectImageData}
        loading={loading}
        loadingImage={loadingImage}
        leftCol={leftCol}
        />
    }
    else if (componentId === 'extra_notes') {
      return <ExtraNotes
          extraNotes={extraNotes}
          setExtraNotes={setExtraNotes}
          loading={loading}
          leftCol={leftCol}
        />
    }
    else if (componentId === 'invoices') {
      return <InvoiceTable
          headers={headers}
          invoiceArray={invoiceArray}
          setInvoiceArray={setInvoiceArray}
          createAccessLogEvent={createAccessLogEvent}
          todayTimeAndDate={todayTimeAndDate}
          loadingQB={loadingQB}
          leftCol={leftCol}
        />
    }
    return null;
  }


  return (
    <div id="pmTool" className='width-100 pad-lg'>
      <Btn onClick={backToProject} id='back-to-project'
        btnClass='bg-white text-blue b2-blue text-bold darken-background box-shadow pad-md pad-l-xxlg pad-r-xxlg margin-b-xlg'
        title='Back to Project' />
      <div className='flex  justify-between margin-b-lg'>
        <div className="flex align-center">
          <h3 className="margin-r-xlg vertical-middle no-wrap">{displayProjectName(state?.project)}</h3>
          <Btn btnClass='bg-light-green text-white b-none bolder box-shadow pad-md pad-l-60 pad-r-60 margin-l-xxxlg green-button'
            title='Save'
            onClick={() => setSaveClicked(true)} />
          <Btn btnClass='bg-blue blue-button text-white b2-blue bolder no-wrap box-shadow pad-md pad-lr-xxxlg margin-l-xxxlg'
            title='Change Name' onClick={handleInvoiceButton} />
        </div>

        <div className="flex  align-center justify-end width-100">
          {displayIssueOutstanding()}
          {displayPastPreferredDate()}
        </div>
        <div
          className={`flex  align-center justify-center pad-l-md pad-r-md pad-t-xs pad-b-xs b1-black border-radius-xs column-gap-sm pointer ${projectCancelled ? 'b1-red' : ''}`}
          id="cancel-checkbox"
          onClick={handleCancelProject}>
          <input type="checkbox" id="cancel" name="cancel"
            className="cancel-box pointer" checked={projectCancelled} onChange={handleCancelProject} />
          <label className='no-wrap text-bold text-smaller pointer'>Cancel Project</label>
        </div>
      </div>

      <div className={`${invalidProject ? "hide" : "project-widgets flex width-100 column-gap-md project_widgets_responsive"}`}>
        { column1Items?.length > 0 || column2Items?.length > 0
          ? <>
            <div className="flex-column min-width-500px row-gap-md project_info_responsive">
              { column1Items?.map((widget, index) => (renderComponent(widget, true))) }
            </div>
            <div className="right-column">
              { column2Items?.map((widget, index) => (
                 <div key={index} className={`${extraWideWidgets.includes(widget) ? "large-widget" : ""}`}>{renderComponent(widget)}</div>)) }
           </div>
          </>
          : <>
            <div className="flex-column min-width-500px row-gap-md project_info_responsive">
              { widgets[0]?.length > 0
                ? widgets[0]?.map((widget, index) => (renderComponent(widget?.name_id, true)))
                : null
              }
            </div>
            <div className='right-column'>
              { widgets[1]?.length > 0
                ? widgets[1]?.map((widget, index) => (
                  <div key={index} className={`${extraWideWidgets.includes(widget?.name_id) ? "large-widget" : ""}`}>{renderComponent(widget?.name_id)}</div>
                ))
                : null
              }
            </div>
          </>
        }
      </div>
      <div className={`${showUnknownsModal ? "po-modal border-radius-xs b-none" : "hide"}`}>
        <div className='flex-column align-start justify-start width-100 margin-t-xxlg margin-b-xxlg pad-l-xxlg'>
          <h3>Update POs</h3>
          <div className='overflow-scroll width-100 height-200px'>
            <table className='width-fit-content border-collapse margin-t-xlg overflow-scroll'>
              <thead>
                <tr className='underline-light-gray'>
                  <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>PO Type</td>
                  <td className='text-bold text-left pad-l-lg pad-r-xlg pad-t-sm pad-b-sm'>Manufacturer Name (PO #)</td>
                </tr>
              </thead>
              {state?.qbPurchaseOrders?.unknowns?.map((po, index, array) => (
                <tr key={index} className={index === array?.length - 1 ? 'issue_row' : 'issue_row underline-light-gray'} >
                  <td className='text-small pad-t-lg pad-b-lg pad-l-md '>
                    <select className='pad-xs pad-r-lg border-radius-xs' onChange={(e) => setUnknownPOs(prevState => ({ ...prevState, [po.refID]: e.target.value }))}>
                      <option selected value=''>Select</option>
                      <option value='product'>Product</option>
                      <option value='installation'>Installation</option>
                      <option value='freight'>Freight</option>
                    </select>
                  </td>
                  <td className='text-small pad-t-lg pad-b-lg pad-l-md '>{po.manufacturer_name}<br /><a target="_blank" href={`${config.qb_base_api}/app/purchaseorder?txnId=${po.qb_po_id}`}>{`PO #${po.qb_po_id}`}</a></td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <button className={`bg-light-green text-white b-none bolder box-shadow pad-md pad-l-60 pad-r-60 margin-l-xxxlg green-button absolute bottom-right pointer`}
          onClick={() => continueButton()}>{"Continue"}
        </button>
      </div>
      <div className={`${invoiceModal ? "invoice-modal border-radius-xs b-none" : "hide"}`}>
        <button onClick={closeInvoiceModal} className='bg-white b-none close-button pointer'>
          <img src={xIcon} alt="close button" />
        </button>
        <div className="pad-xlg margin-t-lg margin-r-md margin-l-md width-100">
          <p className="text-lrg text-bold margin-b-lg">Sync to Invoice: </p>
          {nameChangingLoading
            ? <LoadingInline bold={true} title='' id='name-modal-loader' />
            : <div>
              <input type="text" placeholder="Enter invoice number" className="pad-xs border-radius-xs gray-border width-100"
                value={searchInvoiceNumber} onChange={(e) => setSearchInvoiceNumber(e.target.value)}>
              </input>
            </div>
          }
          <p className='text-light-gray line-height-sm pad-xs'>
            Project name will be updated with the customer name on the invoice provided.
          </p>
          <Btn btnClass={`text-xs bolder pad-md margin-t-xxlg absolute sync-button-location ${(searchInvoiceNumber?.length < 5 || nameChangingLoading) ? "btn-disabled" : "text-white b2-blue bg-blue blue-button box-shadow"}`}
            title='Sync Invoice to Project' disabled={searchInvoiceNumber?.length > 5 ? false : true} onClick={handleSyncInvoice} />
        </div>
      </div>
      <div id="page-mask" className={`${(showUnknownsModal || invoiceModal) ? "" : "hidden"}`}></div>
      <div className={`${invalidProject ? "full-orange-background" : "hidden"}`}></div>
      {invalidProject ?
        <div className={"error-modal border-radius-xs b-none justify-center"}>
          <img src={pgLogo} alt="Playground Boss Logo" className="modal-logo"></img>
          <div className="flex-column height-100 width-60 justify-center">
            <div className='flex justify-center align-center width-100 column-gap-lg'>
              <img src={notFound} alt="Not Found" className="width-20"></img>
              <div className="text-green text-bolder row-gap-lg">
                <p className="text-xxl margin-b-md">Invalid Quote</p>
                <p className="text-xxxl">Number</p>
              </div>
            </div>
            <p className="margin-t-lg width-75 align-self-center text-smedium line-height-150">
              This project does not exist, please verify the project number in the URL is a valid project number.
            </p>
            <div className="margin-t-xxlg flex justify-center">
              <Btn onClick={backToProject}
                btnClass='bg-green2 text-white text-lrg b-none box-shadow pad-md pad-l-xxlg pad-r-xxlg'
                title='Back to Projects' />
            </div>
          </div>
        </div>
        : null}
    </div>
  )
}

export default ViewProject
