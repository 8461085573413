import React from 'react';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const TagName = () => {
    const { state, dispatch } = useStore();

    return (
        <> 
            <div className='width-100'>
                <label className = 'text-bold text-smedium'>Tag Name: </label>
                <br />
                <input
                    type="text"
                    className='width-100 pad-xs border-radius-xs border-thin'
                    value={state?.quote?.tagName}
                    onChange={(e) => dispatch({ type: ACTIONS.QUOTE, payload: {...state?.quote, tagName: e.target.value}})}
                    disabled={state?.quote?.readOnly}
                />
            </div>
        </>
    );
};

export default TagName;
