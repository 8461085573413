import React from 'react';
import Btn from '../Btn';

const DeleteBtn = ({onClick}) => {
    return <Btn title="Delete Quote" 
    btnClass="pad-t-md pad-b-md width-100 justify-center box-shadow text-bold text-white bg-light-red delete-button b-none" 
    onClick={onClick} />;
};

export default DeleteBtn;
