import React, { useEffect } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import convertJSON from '../../utilities/convertJSON';

const HubspotContactSelect = ({
    billingContact,
    setBillingContact,
    billingContactID,
    setBillingContactID,
    billingContactsSelectedArr,
    setBillingContactsSelectedArr,
    setIsNewBillingContact,
    getContactDetails,
    quote,
    billingContactMissing
}) => {
    // Populate state with search contact query
    // Populates data list "contact-results-list"
    const { state } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const populateContactsSelect = async (e) => {
        try {
            // Send query to our API which gets data from hubspot
            const res = await fetch(`${config.base_api}/hubspot/contacts/selectOptionValues/${e.target.value}`, {
                headers,
            });

            if (!res.ok) {
                return;
            }

            let data = await convertJSON(res);

            // Build object with id and name field
            data = data.map((contact) => ({
                id: contact.id,
                name: `${contact.firstname} ${contact.lastname}`,
            }));

            // data list "contact-results-list" maps through this state value
            setBillingContactsSelectedArr(data);

            // Need to set state contactSelected with id
            // However datalist only has data.name in option value
            // Get id by matching name
            let contactId = data.filter((contact) => contact.name === e.target.value);

            // If contactId is a match set as state - which will get contact information and fill in updateContactInfo with values
            if (contactId.length !== 0) {
                contactId = contactId[0].id;
                setBillingContactID(contactId);
            } else {
                // If blank or no match create new contact
                setBillingContactID('create');
            }
        } catch (error) {
            console.log(error);
        }
    };

    // When contact select is changed
    // Populate updateContactInfo with selected contact values
    useEffect(() => {
        try {
            if (billingContactID === 'create' && quote.quote_number) {
                setBillingContactID(quote.hubspot_contact_id);
            }

            if (billingContactID === 'create') {
                setIsNewBillingContact(true);

                // Clear drop down state for better UI
                setBillingContact({
                    ...billingContact,
                    id: '',
                    sameShippingAddress: false,
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    mobile: '',
                    company: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                });
            } else {
                setIsNewBillingContact(false);

                // Sets state with contact details
                getContactDetails('billing');
            }
        } catch (error) {
            console.log(error);
        }
    }, [billingContactID]);

    return (
        <div className="hubspotContact_select flex-column">
            <div className="flex-row align-center margin-b-xs" >
                <h3 className="margin-b-none">Select Billing Contact</h3>
                <div>
                    {/* Drop down hubspot contact select */}
                    <input
                        list="contact-results-list"
                        id="contact-results-input"
                        name="contact-results-input"
                        className="icon-rtl width-fit-content pad-xs border-radius-sm"
                        placeholder="Search for a contact"
                        onChange={populateContactsSelect}
                        disabled={quote.readOnly}
                    />
                    <datalist id="contact-results-list">
                        {billingContactsSelectedArr.map((contact, i) => (
                            <option value={contact.name} key={i} />
                        ))}
                    </datalist>
                </div>
            </div>
            {billingContactMissing && (
                <div className="contact-missing">
                    <p  className="text-red">
                        <strong>Warning:</strong> This contact is missing required fields. Please update the contact
                        before proceeding.
                    </p>
                </div>
            )}
        </div>
    );
};

export default HubspotContactSelect;
