export const ACTIONS = {
    APP_CONTAINER_STYLE: "APP_CONTAINER_STYLE",
    AUTH: "AUTH",
    BACKGROUND_COLOR: "BACKGROUND_COLOR",
    CONTACT_INFO: "CONTACT_INFO",
    CONTACT_SELECTED: "CONTACT_SELECTED",
    CONTACT_SELECTED_ARR: "CONTACT_SELECTED_ARR",
    FILTERED_LIST: "FILTERED_LIST",
    GLOBAL_ERROR_MESSAGE: "GLOBAL_ERROR_MESSAGE",
    IS_NEW_CONTACT: "IS_NEW_CONTACT",
    INCLUDE_SHEETS: "INCLUDE_SHEETS",
    MANUFACTURERS: "MANUFACTURERS",
    NAVBAR_QUOTE_ID: "NAVBAR_QUOTE_ID",
    IS_NEW_CONTACT: "IS_NEW_CONTACT",
    LOADING_PO: "LOADING_PO",
    PREPARED_BY: "PREPARED_BY",
    PRODUCT_TABLE_ITEMS: "PRODUCT_TABLE_ITEMS",
    PRODUCT_VARIANTS: "PRODUCT_VARIANTS",
    PROJECT: "PROJECT",
    PURCHASE_ORDER: "PURCHASE_ORDER",
    QB_PURCHASE_ORDER: "QB_PURCHASE_ORDER",
    QUOTE: "QUOTE",
    SAVED_QUOTE_LIST: "SAVED_QUOTE_LIST",
    TOAST: "TOAST",
    UPDATE_CONTACT_INFO: "UPDATE_CONTACT_INFO",
    UPDATE_PREFERED_COMPLETION_DATE: "UPDATE_PREFERED_COMPLETION_DATE",
    VERSION_NUMBER: "VERSION_NUMBER",
}
