import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../StoreProvider';

// Adjusted for React Router v6
const PrivateRouteAdmin = ({ children }) => {
    const { state } = useStore();
    const location = useLocation();

    const adminRoute = /admin/.test(location.pathname);
    const loading = state?.auth.loading;
    const isAdmin = +state?.auth.isAdmin;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAdmin && adminRoute) {
        // Redirect non-admin users trying to access admin routes
        return <Navigate to="/auth/login" replace />;
    }

    // If admin, render children directly
    return children;
};

export default PrivateRouteAdmin;
