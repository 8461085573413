import React, { Component } from 'react';
import Btn from '../components/Btn';
import pgLogo from '../../src/assets/PGLogo.png'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
      }
    
      static getDerivedStateFromError(error) {
        if (error) return { hasError: true };
      }
    
      handleGoHome = () => {
        window.location.replace(window.location.origin)
      }
   
      render() {
        if (this.state.hasError) {
        return (
        <>
        <div className = "full-orange-background"></div>
        <div className = "error-modal border-radius-xs b-none justify-center">
          <img src = {pgLogo} alt = "Playground Boss Logo" className = "modal-logo"></img>
          <div className = "flex-column height-100 width-60 justify-center">
            <div className = "text-green text-bolder row-gap-lg flex justify-center">
              <p className = "text-xxl width-75 text-center line-height-120">Opps, something went wrong.</p>
            </div>
            <p className = "margin-t-lg width-75 align-self-center text-smedium line-height-150">
              The page you are looking for is currently unavailable. 
              Please click the button below to return to the homepage.
            </p>
            <div className = "margin-t-xxlg flex justify-center">
            <Btn title = "Go Home" 
            btnClass='bg-green2 text-white text-lrg b-none box-shadow pad-md pad-l-xxlg pad-r-xxlg' 
            onClick={this.handleGoHome} />
            </div>
          </div>
        </div>
        </>
        )
        }
    
        return this.props.children;
      }
}

export default ErrorBoundary;