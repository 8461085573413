import React from 'react';
import {useNavigate} from 'react-router';
import authHeaders from '../../utilities/authHeaders';
import config from '../../config';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import Modal from '../Modal';
import Btn from '../Btn';
import convertJSON from '../../utilities/convertJSON';

const CutSheetsModal = ({setShowCutSheetsModal}) => {
    const [state, dispatch] = useStore();
    const navigate = useNavigate();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const createDeal = async (e) => {
        try {
            const res = await fetch(`${config.base_api}/quote/createDeal`, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    quote: state?.quote,
                }),
            });
            // if (!res.ok) throw data.error;
            const data = await convertJSON(res);
            // Succesfully created deal
            console.log('Created Hubspot deal');
            dispatch({type: ACTIONS.TOAST, payload: {
                message: 'Created deal in hubspot',
                isError: false,
            }});
            navigate('/');
        } catch (error) {
            dispatch({type: ACTIONS.TOAST, payload: {
                message: 'Could not create hubspot deal',
                isError: true,
            }});
            console.log(error);
        }
    };

    return (
        <Modal>
            <div className="modal_deleteQuote">
                <h4>Missing PDF</h4>
                <p style={{fontWeight: 'bold'}}>
                    Are you sure you would like to make this quote a primary deal in Hubspot without including a PDF?
                </p>
                <div className="modal_deleteQuote_buttonContainer">
                    <Btn title="Create Quote" onClick={createDeal} btnClass="btn-red" />
                    <Btn title="Cancel" onClick={(e) => setShowCutSheetsModal(false)} />
                </div>
            </div>
        </Modal>
    );
};

export default CutSheetsModal;
