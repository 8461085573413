import React from 'react';
import authHeaders from '../../utilities/authHeaders';
import powerOff from '../../assets/powerOff.svg'
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const Logout = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const onClick = (e) => {
        dispatch({type: ACTIONS.AUTH, payload: {
            loggedIn: false,
            loading: false,
            isAdmin: false,
            accessToken: '',
            refreshToken: '',
            username: '',
            tempPass: '',
            rememberMe: false,
            isOps: false,
        }});

        localStorage.removeItem('idToken');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
        localStorage.removeItem('isAdmin');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('isOps');
    };

    return (
        <a className="text-red text-med text-bold flex align-middle justify-center align-center" onClick={onClick}>
          <img src={powerOff} alt='Power Off' className='powerOff margin-r-sm'></img>
          Log out
        </a>
    );
};

export default Logout;
