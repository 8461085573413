import React, {useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import MakePrimaryQuote from './MakePrimaryQuote';
import SaveQuote from './SaveQuote';
import DuplicateQuote from './DuplicateQuote';
import Btn from '../Btn';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import DeleteBtn from './DeleteBtn';
import DeleteModal from './DeleteModal';
import NoDataModal from './NoDataModal';
import CutSheetsModal from './CutSheetsModal';
import { useStore } from '../../StoreProvider';
import arrow from '../../../src/assets/arrow.svg';
import convertJSON from '../../utilities/convertJSON';

const QuoteButtons = ({
uniqueName, dealNameRequired, shippingContactMissing, billingContactMissing}) => {
    const { state } = useStore();
    const navigate = useNavigate();
    const [showCutSheetsModal, setShowCutSheetsModal] = useState(false);
    const [showNoDataModal, setShowNoDataModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const quoteNumber = window.location.pathname?.split('/viewQuote/')[1];
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    let costSheetAccess = false;
    if (+state?.auth.isAdmin) {
        costSheetAccess = true;
    } else if (+state?.auth.isOps) {
        costSheetAccess = true;
    } else {
        costSheetAccess = false;
    }

    const productsExistInDB = async () => {
        const res = await fetch(`${config.base_api}/quote/productsExistInDB/${state?.quote?.id}`, { headers });
        const data = await convertJSON(res);
        return data.result;
    }

    return (
        <div className = 'flex-column row-gap-md min-width-150px'>
            {!state?.quote.is_primary_quote && (
                <MakePrimaryQuote
                    setShowCutSheetsModal={setShowCutSheetsModal}
                />
            )}
            <SaveQuote/>
            <DuplicateQuote/>
            {dealNameRequired || uniqueName || shippingContactMissing || billingContactMissing ? (
                <div></div>
            ) : (
                <Btn
                    title="Create PDF"
                    onClick={async (e) => {
                        if (
                            !state?.quote.installation.includeInstallation &&
                            !state?.quote.freight.customerPrice &&
                            state?.quote.products.length == 0
                        ) {
                            setShowNoDataModal(true);
                        } else {
                            const productsInDB = await productsExistInDB();
                            if (productsInDB) {    
                                setShowNoDataModal(false);
                                navigate(`/cutSheets/${quoteNumber}`);
                            } else {
                                setShowNoDataModal(true);
                            }
                        }
                    }}
                    btnClass="pad-t-md pad-b-md width-100 justify-center box-shadow bg-white text-bold text-blue b2-blue darken-background"
                />
            )}
            <DeleteBtn onClick={(e) => setShowDeleteModal(true)} />
            {state?.quote.readOnly && costSheetAccess ? (
                <Link to={`/operations/costsheet/${quoteNumber}`} className="cost_sheet_link no-underline">
                    <button
                    className = 'darken-background text-bold bg-white text-blue pad-t-md pad-b-md width-100 no-wrap justify-center b2-blue box-shadow border-radius-xs pointer'>
                        View Cost Sheet
                        <img src = {arrow}
                        className = 'pad-l-sm width-10' alt = "arrow" />
                    </button>
                </Link>
            ) : (
                <></>
            )}
            {showNoDataModal && <NoDataModal setShowNoDataModal={setShowNoDataModal} />}
            {showDeleteModal && (
                <DeleteModal
                    setShowDeleteModal={setShowDeleteModal}
                />
            )}
            {showCutSheetsModal && (
                <CutSheetsModal
                    setShowCutSheetsModal={setShowCutSheetsModal}
                />
            )}
        </div>
    )
}

export default QuoteButtons;