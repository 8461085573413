import React, {useEffect, useState} from 'react';
import {SmallBtn} from '../Btn';
import {useStore} from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const AvailableSheets = ({cutSheets, loading}) => {
    const {state, dispatch} = useStore();
    // If cut sheets is undefined being passed in, set to blank array.
    cutSheets = cutSheets ? cutSheets : [];

    const [filterSheets, setFilterSheets] = useState('');

    useEffect(() => {}, []);

    return (
        <div className="cutSheets_availableSheets">
            <h3>Available Cut Sheets</h3>
            {/* Filter sheets input field */}
            <label htmlFor="">Search </label>
            <input
                name="filterSheets"
                type="text"
                value={filterSheets}
                onChange={(e) => setFilterSheets(e.target.value)}
            />
            <ul>
                {loading && <li>Sheets loading...</li>}
                {/* Filter sheets based on user search */}
                {cutSheets
                    .filter((sheet) => {
                        const regEx = new RegExp(filterSheets, 'i');
                        return regEx.test(sheet.name);
                    })
                    // Sort cut sheets by alphabetical order
                    .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1))
                    // Return li element and button
                    .map((sheet, i) => (
                        <li key={sheet.id}>
                            <SmallBtn
                                btnClass="inline-block margin-r-xlg bg-blue text-white b2-blue box-shadow text-bold blue-button"
                                title="Add"
                                onClick={(e) => {
                                    console.log('sheet clicked', sheet)
                                    dispatch({type: ACTIONS.INCLUDE_SHEETS, payload: [...state.includeSheets, sheet]})
                                }}
                            />
                            <SmallBtn
                                btnClass="inline-block margin-r-xlg bg-white text-blue b2-blue box-shadow text-bold darken-background"
                                title="View"
                                onClick={(e) => window.open(sheet.url)}
                            />
                            {sheet.name}
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default AvailableSheets;
