import React from 'react';
import {isNote} from './productTypeCheck';
import formatNumber from '../../../utilities/formatNumber';

const CellProfit = ({product}) => {
    // Return blank td if product is a note or has no profit value
    if (isNote(product) || !product.profit) {
        return <td key="profit" className="center col-profit"></td>;
    }

    return (
        <td key="profit" className="center col-profit">
            {`$${formatNumber(product.profit)}`}
        </td>
    );
};

export default CellProfit;
