import React from 'react';
import LoadingInline from '../../components/LoadingInline';

const ExtraNotes = ({extraNotes, setExtraNotes, loading, leftCol}) => {
    if (loading) {
        return (
        <div className = 'flex-column project-box pad-lg width-40'>
            <p className = 'text-lrg text-bold pad-b-sm'>Extra Notes</p>
            <LoadingInline />
        </div>
        )
    }

    return (
        <>
            <div className = {`flex-column project-box pad-lg ${leftCol ? 'max-width-550px' : 'width-500px'}`}>
                <p className = 'text-lrg text-bold pad-b-sm'>Extra Notes</p>
                <textarea 
                    className = {`form-input text-input-xl text-med no-resize`}
                    value = {extraNotes}
                    onChange = {(e) => 
                    setExtraNotes(e.target.value)}
                />
            </div>
        </>
    )
}

export default ExtraNotes;