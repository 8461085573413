import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import authHeaders from '../utilities/authHeaders';

import HubspotContactSelect from '../components/contactDetails/HubspotContactSelect';
import ContactDetailsGeneral from '../components/contactDetails/ContactDetailsGeneral';
import ContactDetailsBilling from '../components/contactDetails/ContactDetailsBilling';
import ContactDetailsShipping from '../components/contactDetails/ContactDetailsShipping';
import PreparedBy from '../components/contactDetails/PreparedBy';
import UpdateContact from '../components/contactDetails/UpdateContact';
import UpdateModal from '../components/contactDetails/UpdateModal';
import { quoteToObject } from '../utilities/formatQuote';
import SubmitBtn from '../components/SubmitBtn';
import Btn from '../components/Btn';
import ShippingContact from '../components/contactDetails/ShippingContact';
import ShippingContactSelect from '../components/contactDetails/ShippingContactSelect';
import UpdatePreparedBy from '../components/contactDetails/UpdatePreparedBy';
import { saveQuote } from '../components/quote/SaveQuote';
import LoadingInline from '../components/LoadingInline';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import { phoneFormat } from '../utilities/formatInput';

// Destructure props
const ContactDetails = ({
    preparedBy,
    setPreparedBy,
}) => {
    const { state, dispatch } = useStore();
    const navigate = useNavigate();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const quoteNumber = window.location.pathname?.split('/contactDetails/')[1];
    const quoteID = quoteNumber?.substring(7);

    /**
     * LOCAL State for shipping and billing contact
     */
    const [billingContact, setBillingContact] = useState({
        sameShippingAddress: false,
        sameBillingContact: false,
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        mobile: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
    });
    const [shippingContact, setShippingContact] = useState({
        shipping_contactid: '',
        shipping_firstname: '',
        shipping_lastname: '',
        shipping_email: '',
        shipping_phone: '',
        shipping_mobile: '',
        shipping_company: '',
        shipping_address: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
    });

    const [isNewBillingContact, setIsNewBillingContact] = useState(false);
    const [isNewShippingContact, setIsNewShippingContact] = useState(false);
    const [billingContactID, setBillingContactID] = useState('create');
    const [shippingContactID, setShippingContactID] = useState('create');
    const [billingContactsSelectedArr, setBillingContactsSelectedArr] = useState([]);
    const [shippingContactsSelectedArr, setShippingContactsSelectedArr] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadedQuote, setLoadedQuote] = useState({});
    // local state for update Modal
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    // used to determine if it's a shipping or billing contact update
    const [updateModalType, setUpdateModalType] = useState('');
    const [billingFieldInvalid, setBillingFieldInvalid] = useState(false);
    const [shippingFieldInvalid, setShippingFieldInvalid] = useState(false);
    const [billingContactMissing, setBillingContactMissing] = useState(false);
    const [shippingContactMissing, setShippingContactMissing] = useState(false);

    /**
     * API actions
     */

    useEffect(() => {
        dispatch({ type: ACTIONS.QUOTE, payload: emptyQuoteObj });

        if (quoteID) {
            (async () => {
                try {
                    let quoteData = await fetch(`${config.base_api}/quote/id/${quoteID}`, { headers });
                    if (!quoteData.ok) throw 'Could not get quote';
                    quoteData = await convertJSON(quoteData);
                    const formattedQuote = quoteToObject(quoteData);
                    formattedQuote['loaded'] = true
                    dispatch({ type: ACTIONS.QUOTE, payload: { ...state?.quote, ...formattedQuote } });
                    loadContactInfo({ ...state?.quote, ...formattedQuote });
                    setLoadedQuote({ ...state?.quote, ...formattedQuote })
                } catch (error) {
                    console.log(error);
                    dispatch({ type: ACTIONS.TOAST, payload: { message: "Error loading the quote", isError: true } });
                    setLoadedQuote({});
                    loadContactInfo({});
                    setLoading(false);
                }
            })();
        } else {
            setLoadedQuote({});
            loadContactInfo({});
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (billingContact.firstname?.trim() === "" || billingContact.lastname?.trim() === '' || billingContact.email?.trim().length < 4 || billingContact.phone?.trim().length < 4 || billingContact.address?.trim().length < 3 || billingContact.city?.trim().length < 2 || billingContact.state?.trim().length < 2 || billingContact.zip?.trim().length < 5) {
            setBillingFieldInvalid(true);
        } else {
            setBillingFieldInvalid(false);
        }
    }, [billingContact.firstname, billingContact.lastname, billingContact.email, billingContact.phone, billingContact.address, billingContact.city, billingContact.state, billingContact.zip]);

    useEffect(() => {
        if (shippingContact.shipping_firstname?.trim() === '' || shippingContact.shipping_lastname?.trim() === '' || shippingContact.shipping_email?.trim().length < 4 || shippingContact.shipping_phone?.trim().length < 4 || shippingContact.shipping_address?.trim().length < 3 || shippingContact.shipping_city?.trim().length < 2 || shippingContact.shipping_state?.trim().length < 2 || shippingContact.shipping_zip?.trim().length < 5) {
            setShippingFieldInvalid(true);
        } else {
            setShippingFieldInvalid(false);
        }
    }, [shippingContact.shipping_firstname, shippingContact.shipping_lastname, shippingContact.shipping_email, shippingContact.shipping_phone, shippingContact.shipping_address, shippingContact.shipping_city, shippingContact.shipping_state, shippingContact.shipping_zip]);

    // Get billing contact details by hubspot id
    const getContactDetails = async (contact_type) => {
        if (contact_type == 'billing') {
            if (billingContactID == null || billingContactID == 'create') {
                return;
            }
            try {
                const res = await fetch(`${config.base_api}/hubspot/contacts/id/${billingContactID}`, { headers });
                const data = await convertJSON(res);
                if (data.error) {
                    setBillingContactMissing(true);
                } else if (data.firstname?.trim() === "" || data.lastname?.trim() === "" || data.email?.trim() === "" || data.phone?.trim() === "" || data.address?.trim() === "" || data.city?.trim() === "" || data.state?.trim() === "" || data.zip?.trim() === "") {
                    setBillingContactMissing(true);
                }
                setBillingContact({
                    ...billingContact,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    email: data.email,
                    phone: phoneFormat(data.phone),
                    mobile: phoneFormat(data.mobilephone) || '',
                    company: data.company,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                });
                // check if same as billing contact is checked and if it's from hubspot
                if (billingContact.sameBillingContact && !isNewBillingContact) {
                    setShippingContact({
                        ...shippingContact,
                        shipping_address: data.shipping_address || '',
                        shipping_city: data.shipping_city || '',
                        shipping_state: data.shipping_state || '',
                        shipping_zip: data.shipping_zip || '',
                    });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
        if (contact_type == 'shipping') {
            if (shippingContactID == null || shippingContactID == 'create') {
                return;
            }
            try {
                const res = await fetch(`${config.base_api}/hubspot/contacts/id/${shippingContactID}`, { headers });
                const data = await convertJSON(res);
                if (data.error) {
                    setShippingContactMissing(true);
                } else if (data.firstname?.trim() === "" || data.lastname?.trim() === "" || data.email?.trim() === "" || data.phone?.trim() === "" || data.shipping_address?.trim() === "" || data.shipping_city?.trim() === "" || data.shipping_state?.trim() === "" || data.shipping_zip?.trim() === "") {
                    setShippingContactMissing(true);
                }
                setShippingContact({
                    ...shippingContact,
                    shipping_firstname: data.firstname,
                    shipping_lastname: data.lastname,
                    shipping_email: data.email,
                    shipping_phone: phoneFormat(data.phone),
                    shipping_mobile: phoneFormat(data.mobilephone) || '',
                    shipping_company: data.company,
                    shipping_address: data.shipping_address,
                    shipping_city: data.shipping_city,
                    shipping_state: data.shipping_state,
                    shipping_zip: data.shipping_zip,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    // Create contact on hubspot
    // Set state contactSelected to id created by hubspot
    const postContact = async (type) => {
        let postBody;
        //check type to see which contact state to build the body out of
        if (type == 'billing') {
            postBody = billingContact;
            postBody = {
                firstname: billingContact.firstname,
                lastname: billingContact.lastname,
                email: billingContact.email,
                phone: billingContact.phone,
                mobilephone: billingContact.mobile,
                company: billingContact.company,
                address: billingContact.address,
                city: billingContact.city,
                state: billingContact.state,
                zip: billingContact.zip,
            };
            // if same as billing contact is checked, append the shipping address to the contact creation
            if (billingContact.sameBillingContact) {
                postBody = {
                    ...postBody,
                    shipping_address: shippingContact.shipping_address,
                    shipping_city: shippingContact.shipping_city,
                    shipping_state: shippingContact.shipping_state,
                    shipping_zip: shippingContact.shipping_zip,
                };
            }
        } else {
            postBody = {
                firstname: shippingContact.shipping_firstname,
                lastname: shippingContact.shipping_lastname,
                email: shippingContact.shipping_email,
                phone: shippingContact.shipping_phone,
                mobilephone: shippingContact.shipping_mobile,
                company: shippingContact.shipping_company,
                shipping_address: shippingContact.shipping_address,
                shipping_city: shippingContact.shipping_city,
                shipping_state: shippingContact.shipping_state,
                shipping_zip: shippingContact.shipping_zip,
            };
        }
        try {
            const res = await fetch(`${config.base_api}/hubspot/contacts`, {
                method: 'POST',
                headers,
                body: JSON.stringify(postBody),
            });
            let data = await convertJSON(res);
            if (!res.ok) {
                throw 'ERROR, CONTACT EXISTS IN HUBSPOT';
            }
            const { id } = data;

            // Set newly created hubspot id in state and return it
            if (type == 'shipping' && !state?.quote.quote_number) {
                setShippingContactID(id);
            }
            if (type == 'shipping' && state?.quote.quote_number) {
                dispatch({ type: ACTIONS.QUOTE, payload: { ...state?.quote, shipping_contactid: id } });
            }
            if (type == 'billing') {
                setBillingContactID(id);
            }
            return id;
        } catch (error) {
            console.log(error);
            throw 'Could not create new contact on Hubspot';
        }
    };

    // API call for posting quote into our database
    const postQuote = async (hubspot_contact_id, shipping_contactid) => {
        // Post quote from state - whether new contact or existing contact
        // if same as billing contact is checked, use the billing contact hubspot id for shipping contact
        if (billingContact.sameBillingContact) {
            shipping_contactid = hubspot_contact_id;
        }
        let body = {
            firstname: billingContact.firstname,
            lastname: billingContact.lastname,
            email: billingContact.email,
            phone: billingContact.phone,
            mobile: billingContact.mobile,
            company: billingContact.company,
            address: billingContact.address,
            city: billingContact.city,
            state: billingContact.state,
            zip: billingContact.zip,
            shipping_firstname: shippingContact.shipping_firstname,
            shipping_lastname: shippingContact.shipping_lastname,
            shipping_email: shippingContact.shipping_email,
            shipping_phone: shippingContact.shipping_phone,
            shipping_mobile: shippingContact.shipping_mobile,
            shipping_company: shippingContact.shipping_company,
            shipping_address: shippingContact.shipping_address,
            shipping_city: shippingContact.shipping_city,
            shipping_state: shippingContact.shipping_state,
            shipping_zip: shippingContact.shipping_zip,
        };
        body = JSON.stringify({
            ...body,
            hubspot_contact_id,
            shipping_contactid,
            ...preparedBy,
        });
        try {
            const res = await fetch(`${config.base_api}/quote`, {
                method: 'POST',
                headers,
                body,
            });
            const data = await convertJSON(res)
            return data;
        } catch (error) {
            console.log(error);
            throw 'Could not create new quote';
        }
    };

    /**
     * Input - handlers
     *
     */

    // Handlers for Contact information state

    // User input for filling in contact details
    // Setting of state changes depending if new or previously created contact
    const handleContactDetailsChange = (e) => {
        const name = e.target.name;
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        if (name.includes("phone") || name.includes("mobile")) value = phoneFormat(value);
        // check if it is shipping info or billing info
        if (name.includes('shipping')) {
            // set shipping contact info into local shippingContact state
            setShippingContact((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            // set Billing contact info into local Billing contact state
            setBillingContact((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            if (billingContact.sameBillingContact || billingContact.sameShippingAddress) {
                copyTextToShipping(name, value);
            }
        }
    };

    // function to autopopulate as you type if box is checked
    function copyTextToShipping(name, value) {
        let infoType = 'contact';
        // returns true if address info
        if (checkInfoType(name)) {
            infoType = 'address';
        }
        name = 'shipping_' + name;
        if (billingContact.sameBillingContact && infoType == 'contact') {
            setShippingContact((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        if (billingContact.sameShippingAddress && infoType == 'address') {
            setShippingContact((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    function checkInfoType(name) {
        const nameList = ['address', 'city', 'state', 'zip'];
        for (var i = 0; i < nameList.length; i++) {
            if (name == nameList[i]) {
                return true;
            }
        }
        return false;
    }

    // create new shipping contact from manual entry on duplicated quotes that did not have a shipping contact
    const createNewShipping = async () => {
        const quoteForm = document.getElementById('createQuoteForm');
        if (!quoteForm.checkValidity()) {
            quoteForm.reportValidity();
            return;
        }
        setShippingContactID('create')
        try {
            const newContact = await postContact('shipping');
            await dispatch({
                type: ACTIONS.QUOTE,
                payload: {
                    ...state?.quote,
                    shipping_firstname: shippingContact.shipping_firstname,
                    shipping_lastname: shippingContact.shipping_lastname,
                    shipping_email: shippingContact.shipping_email,
                    shipping_mobile: shippingContact.shipping_mobile,
                    shipping_address: shippingContact.shipping_address,
                    shipping_city: shippingContact.shipping_city,
                    shipping_state: shippingContact.shipping_state,
                    shipping_zip: shippingContact.shipping_zip,
                }
            });

            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Shipping Contact created and attached to quote.' } })
            setShippingContactID(newContact)
            await saveQuote(state?.auth, state?.quote, null, ACTIONS, dispatch);
        } catch (error) {
            console.log(error)
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } })
        }
    }

    // creates Contacts in hubspot, handles errors and triggers createQuote to stop and hit the catch block if email exists in hubspot
    const createContacts = async () => {
        let createdContacts = {};
        let hubspot_contact_id;
        let shipping_contactid;
        // contactID set to null or create if not selected from hubspot as existing contact
        if (billingContactID == 'create' || null) {
            try {
                hubspot_contact_id = await postContact('billing');
                setBillingContactID(hubspot_contact_id);
            } catch (error) {
                hubspot_contact_id = 'create';
                setBillingContactID('create');
            }
        } else {
            hubspot_contact_id = billingContactID;
        }

        if (!billingContact.sameBillingContact) {
            if (shippingContactID == 'create' || null) {
                try {
                    shipping_contactid = await postContact('shipping');
                    setShippingContactID(shipping_contactid);
                } catch (error) {
                    shipping_contactid = 'create';
                    setShippingContactID('create');
                }
            } else {
                shipping_contactid = shippingContactID;
            }
        } else {
            shipping_contactid = hubspot_contact_id;
        }

        createdContacts.billingContact = hubspot_contact_id;
        createdContacts.shippingContact = shipping_contactid;
        return createdContacts;
    };

    //  Submit Form
    const createQuote = async (e) => {
        e.preventDefault();
        const quoteForm = document.getElementById('createQuoteForm');
        if (!quoteForm.checkValidity()) {
            quoteForm.reportValidity();
            return;
        }
        try {
            let hubspot_contact_id;
            let shipping_contactid;
            let contactError;

            const newContacts = await createContacts();
            hubspot_contact_id = newContacts.billingContact;
            shipping_contactid = newContacts.shippingContact;

            // the id is set to create if there is a duplicate email, this will throw an error for the toast to display
            if (hubspot_contact_id == 'create' || shipping_contactid == 'create') {
                if (hubspot_contact_id == 'create') {
                    contactError = 'Billing Contact';
                } else {
                    contactError = 'Shipping Contact';
                }
                throw `${contactError} email already exists in HubSpot`;
            }

            // Create quote in our database
            let newQuote = await postQuote(hubspot_contact_id, shipping_contactid);
            // Set quote state to post request response
            // Get key values;
            const {
                id,
                quote_number,
                firstname,
                lastname,
                phone,
                email,
                mobile,
                company,
                address,
                city,
                state,
                zip,
                shipping_firstname,
                shipping_lastname,
                shipping_email,
                shipping_phone,
                shipping_mobile,
                shipping_company,
                shipping_address,
                shipping_city,
                shipping_state,
                shipping_zip,
                prepared_by_hubspot_id,
                prepared_by_name,
                prepared_by_email,
                prepared_by_phone,
                is_primary_quote,
                include_tax: includeTaxes,
                createdAt,
            } = newQuote;

            if (!hubspot_contact_id) hubspot_contact_id = billingContactID;
            if (!shipping_contactid) shipping_contactid = shippingContactID;

            // Update our state to track quote creation
            dispatch({
                type: ACTIONS.QUOTE,
                payload: {
                    ...state?.quote,
                    id,
                    hubspot_contact_id,
                    quote_number,
                    deal_name: quote_number,
                    firstname,
                    lastname,
                    phone,
                    mobile,
                    email,
                    company,
                    address,
                    city,
                    state,
                    zip,
                    shipping_firstname,
                    shipping_lastname,
                    shipping_email,
                    shipping_phone,
                    shipping_mobile,
                    shipping_company,
                    shipping_contactid,
                    shipping_address,
                    shipping_city,
                    shipping_state,
                    shipping_zip,
                    prepared_by_hubspot_id,
                    prepared_by_name,
                    prepared_by_email,
                    prepared_by_phone,
                    is_primary_quote,
                    includeTaxes,
                    createdAt,
                },
            });

            // Change to quote view
            dispatch({ type: ACTIONS.TOAST, payload: { message: 'New quote started' } });
            navigate(`/viewQuote/${quote_number}`);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
        }
    };

    const loadContactInfo = (quote) => {
        // dispatch({ type: ACTIONS.QUOTE, payload: emptyQuoteObj });
        if (quote.quote_number) {
            console.log(
                'useEffect on Page load, contactDetailsView:',
                quote.hubspot_contact_id,
                quote.shipping_contactid
            );
            setPreparedBy({
                prepared_by_hubspot_id: quote.prepared_by_hubspot_id,
                prepared_by_name: quote.prepared_by_name,
                prepared_by_phone: quote.prepared_by_phone,
                prepared_by_email: quote.prepared_by_email,
            });
            setBillingContactID(quote.hubspot_contact_id);
            setShippingContactID(quote.shipping_contactid);
        }
    }

    if (loading) {
        <LoadingInline title=""></LoadingInline>
    }

    return (
        // Contact Details View
        <div className="contactDetails">
            <h1>Contact Information - Hubspot</h1>

            {/* Form - Submit to hubspot and our quote API */}
            <form onSubmit={createQuote} id="createQuoteForm">
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1' }}>
                        <HubspotContactSelect
                            billingContact={billingContact}
                            setBillingContact={setBillingContact}
                            billingContactID={billingContactID}
                            setBillingContactID={setBillingContactID}
                            billingContactsSelectedArr={billingContactsSelectedArr}
                            setBillingContactsSelectedArr={setBillingContactsSelectedArr}
                            isNewBillingContact={isNewBillingContact}
                            setIsNewBillingContact={setIsNewBillingContact}
                            getContactDetails={getContactDetails}
                            quote={loadedQuote}
                            billingContactMissing={billingContactMissing}
                        />
                    </div>

                    <div style={{ flex: '1' }}>
                        {/* Second Hubspot contact search for Shipping Contact */}
                        <ShippingContactSelect
                            billingContact={billingContact}
                            setBillingContact={setBillingContact}
                            shippingContact={shippingContact}
                            setShippingContact={setShippingContact}
                            shippingContactID={shippingContactID}
                            setShippingContactID={setShippingContactID}
                            shippingContactsSelectedArr={shippingContactsSelectedArr}
                            setShippingContactsSelectedArr={setShippingContactsSelectedArr}
                            isNewShippingContact={isNewShippingContact}
                            setIsNewShippingContact={setIsNewShippingContact}
                            getContactDetails={getContactDetails}
                            quote={loadedQuote}
                            shippingContactMissing={shippingContactMissing}
                        />
                    </div>
                </div>

                <div className="contactDetails_grid">
                    {/* General contact details */}
                    <ContactDetailsGeneral
                        handleContactDetailsChange={handleContactDetailsChange}
                        billingContact={billingContact}
                        quote={loadedQuote}
                    />

                    <ShippingContact
                        handleContactDetailsChange={handleContactDetailsChange}
                        billingContact={billingContact}
                        shippingContact={shippingContact}
                        setShippingContact={setShippingContact}
                        setShippingContactID={setShippingContactID}
                        isNewShippingContact={isNewShippingContact}
                        quote={loadedQuote}
                    />

                    {/* Contact billing address */}
                    <ContactDetailsBilling
                        handleContactDetailsChange={handleContactDetailsChange}
                        billingContact={billingContact}
                        quote={loadedQuote}
                    />

                    {/* Contact Shipping address */}
                    <ContactDetailsShipping
                        setShippingContactID={setShippingContactID}
                        isNewBillingContact={isNewBillingContact}
                        billingContactID={billingContactID}
                        handleContactDetailsChange={handleContactDetailsChange}
                        billingContact={billingContact}
                        isNewShippingContact={isNewShippingContact}
                        shippingContact={shippingContact}
                        setShippingContact={setShippingContact}
                        quote={loadedQuote}
                    />

                    {/* Prepared by to our API and database */}
                    <PreparedBy preparedBy={preparedBy} setPreparedBy={setPreparedBy} />
                </div>

                {/* Dynamic form buttons - Start quote / view quote -  Update contact*/}
                <div className="contactDetails_btn">
                    {/* If quote has not been created, show create quote button */}
                    {!state?.quote.id && <SubmitBtn title="Start Quote"
                        btnClass='pad-tb-md pad-lr-xlg justify-center box-shadow bg-white text-green text-bold b2-green darken-background' 
                        disabled={billingFieldInvalid || shippingFieldInvalid}/>}
                    {/* If quote has already been created, show view quote button */}
                    {state?.quote.id && (
                        <div onClick={() => navigate(`/viewQuote/${state?.quote.quote_number}`)}>
                            <Btn title="View Quote"
                                btnClass='pad-tb-md pad-lr-xlg justify-center box-shadow bg-white text-blue text-bold b2-blue darken-background' />
                        </div>
                    )}
                    <UpdatePreparedBy preparedBy={preparedBy} quote={loadedQuote} />
                    {/* Btn for update contact if contact is already in hubspot */}
                    {billingContactID !== 'create' && !state?.quote.readOnly && (
                        <UpdateContact
                            contactType={'Billing Contact'}
                            billingContact={billingContact}
                            billingContactID={billingContactID}
                            shippingContact={shippingContact}
                            shippingContactID={shippingContactID}
                            setUpdateModalType={setUpdateModalType}
                            setShowUpdateModal={setShowUpdateModal}
                            preparedBy={preparedBy}
                            setPreparedBy={setPreparedBy}
                            billingFieldInvalid={billingFieldInvalid}
                        />
                    )}
                    {/* btn for update shipping contact */}
                    {shippingContactID != 'create' &&


                        (shippingContactID || (billingContact.sameBillingContact && billingContactID)) ? (

                        <UpdateContact
                            contactType={'Shipping Contact'}
                            billingContact={billingContact}
                            shippingContact={shippingContact}
                            setUpdateModalType={setUpdateModalType}
                            setShowUpdateModal={setShowUpdateModal}
                            preparedBy={preparedBy}
                            setPreparedBy={setPreparedBy}
                            shippingFieldInvalid={shippingFieldInvalid}
                        />
                    ) : (
                        <></>
                    )}
                    {state?.quote.quote_number && !shippingContactID && shippingContact.shipping_firstname ?
                        <Btn title='Create Shipping Contact' onClick={createNewShipping} />
                        :
                        <></>

                    }
                    {showUpdateModal ? (
                        <UpdateModal
                            preparedBy={preparedBy}
                            showUpdateModal={showUpdateModal}
                            setShowUpdateModal={setShowUpdateModal}
                            updateModalType={updateModalType}
                            billingContact={billingContact}
                            shippingContact={shippingContact}
                            billingContactID={billingContactID}
                            shippingContactID={shippingContactID}
                            quote={loadedQuote}
                            setShippingContactMissing={setShippingContactMissing}
                            setBillingContactMissing={setBillingContactMissing}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ContactDetails;
