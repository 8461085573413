import React from 'react';
import StatesList from '../StatesList';
import SameBillingAddress from './SameBillingAddress';
import { useStore } from '../../StoreProvider';

const ContactDetailsShipping = ({
    handleContactDetailsChange,
    isNewShippingContact,
    shippingContact,
    setShippingContact,
    billingContact,
    billingContactID,
    isNewBillingContact,
    setShippingContactID,
    quote
}) => {
    const { state } = useStore();

    return (
        <div className="hubspotContact">
            <h4>Shipping Address</h4>
            <SameBillingAddress
                handleContactDetailsChange={handleContactDetailsChange}
                isNewShippingContact={isNewShippingContact}
                billingContact={billingContact}
                billingContactID={billingContactID}
                isNewBillingContact={isNewBillingContact}
                setShippingContactID={setShippingContactID}
                shippingContact={shippingContact}
                setShippingContact={setShippingContact}
            />
            <div className="hubspotContact_details hubspotContact_details-shipping">
                {/* Checkbox for same shipping address as billing */}
                {/* Disabled in update contact view */}

                <label htmlFor="hubspot_street">
                    Street Address: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_shipping_street"
                    value={shippingContact.shipping_address}
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="shipping_address"
                    disabled={billingContact.sameShippingAddress || quote.readOnly}
                    required
                />

                <label htmlFor="hubspot_city">
                    City: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_shipping_city"
                    value={shippingContact.shipping_city}
                    name="shipping_city"
                    onChange={handleContactDetailsChange}
                    type="text"
                    disabled={billingContact.sameShippingAddress || quote.readOnly}
                    required
                />

                <label htmlFor="hubspot_state">
                    State: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    list="hubspot_shipping_state"
                    onChange={handleContactDetailsChange}
                    type="select"
                    value={shippingContact.shipping_state?.toUpperCase()}
                    name="shipping_state"
                    disabled={billingContact.sameShippingAddress || quote.readOnly}
                    required
                />

                <StatesList
                    datalist_id="hubspot_shipping_state"
                    disabled={billingContact.sameShippingAddress || quote.readOnly}
                />

                <label htmlFor="hubspot_zip">
                    Zip Code: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_shipping_zip"
                    value={shippingContact.shipping_zip}
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="shipping_zip"
                    disabled={billingContact.sameShippingAddress || quote.readOnly}
                    required
                />
            </div>
        </div>
    );
};

export default ContactDetailsShipping;
