import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../StoreProvider';

const PrivateRouteOps = ({ isSalesOverride, allUsersOverride, children }) => {
    const { state } = useStore();
    const location = useLocation();
    const opsRoute = /operations/.test(location.pathname);
    let allowedAccess = false;

    // Check to see if waiting for response of authentication
    if (state?.auth.loading) return <div>Loading...</div>;

    if (+state?.auth.isOps || +state?.auth.isAdmin) {
        allowedAccess = true;
    }

    if (isSalesOverride && +state?.auth.isSales) {
        allowedAccess = true;
    }

    if (allUsersOverride && state?.auth?.loggedIn) {
        allowedAccess = true;
    }

    // If not logged in with the required role, display message or redirect
    if (!allowedAccess && opsRoute) {
        // Instead of returning a message, you might want to redirect or display a specific component
        return <Navigate to="/auth/login" state={{ from: location }} replace />;
    }

    // If logged in and authorized, render the children components
    return children;
};

export default PrivateRouteOps;
