import React, { useEffect, useState } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import convertJSON from '../../utilities/convertJSON';
import Btn from '../../components/Btn';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const LastActivityDateOptions = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [addOption, setAddOption] = useState(false);
    const [optionName, setOptionName] = useState('');
    const [options, setOptions] = useState([]);
    const [editOption, setEditOption] = useState(0);
    const [editedOptionName, setEditedOptionName] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const res = await fetch(`${config.base_api}/configuration/options`, { headers });
                const data = await convertJSON(res);

                setOptions(data);
            } catch (error) {
                console.log(error);
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error loading options.' } });
            }
        }
        
        fetchData();
    }, [])

    const handleOptionName = (event) => {
        setOptionName(event.target.value);
    }

    const saveOption = async () => {
        if (optionName === '') {
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Cannot save blank option',
                    isError: true,
                }
            });
        } else {
            const newOption = {
                type: 'option',
                name: optionName
            };

            try {
                const res = await fetch(`${config.base_api}/configuration/options/create`,
                    {
                        method: 'POST',
                        headers,
                        body: JSON.stringify(newOption)
                    });
                const data = await convertJSON(res);
                const newOptions = [...options, data];
                setOptions(newOptions);
                setOptionName('');
                setAddOption(false);
            } catch (error) {
                console.log(error);
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Error saving option',
                        isError: true,
                    }
                });
            }
        }
    }

    const deleteOption = (id) => async () => {
        console.log("DELETE OPTION", id);
        try {
            const res = await fetch(`${config.base_api}/configuration/options/delete/${id}`,
                {
                    method: 'DELETE',
                    headers,
                    body: JSON.stringify({}),
                });

            const newOptions = options.filter((option) => {
                if (option.id !== id) {
                    return option;
                }
            });

            setOptions(newOptions);
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error deleting option' } });
        }
    }

    const invisible = (id) => {
        if (editOption !== id && editOption > 0) return ' invisible ';
    }

    const changeOption = async (id) => {
        const updatedOption = {
            name: editedOptionName
        }

        try {
            const res = await fetch(`${config.base_api}/configuration/options/update/${id}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(updatedOption)
            });

            if (res.ok) {
                setEditOption(0);
                setEditedOptionName('');
                const newOptions = options.map((option) => {
                    if (option.id === id) {
                        option.name = editedOptionName;
                    }
                    return option;
                });
                setOptions(newOptions);
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'success', message: 'Option updated' } });
            } else {
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error updating option' } });
            }
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error updating option' } });
        }
    }

    const editClicked = (id, name) => {
        setEditOption(id);
        setEditedOptionName(name);
    }

    const hideCancel = (id) => {
        if (editOption === 0) return ' hide ';
        if (editOption !== id && editOption > 0) return ' hide ';
    }

    return (
        <>
            <div className="project-box margin-t-sm thin-widget-width margin-t-xxlg">
                <div className="flex justify-between pad-tb-sm pad-r-sm pad-l-lg">
                    <p className='text-lrg text-bold'>Last Activity Options</p>
                    {addOption
                        ? null
                        : <Btn title='Add' icon='add' btnClass='text-bold bg-white text-green b2-green darken-background box-shadow pad-lr-xlg text-small' onClick={() => { setAddOption(true) }}
                        />
                    }
                </div>
                {addOption ?
                    <div className='pad-sm project-box border-radius-md pad-lg margin-b-sm'>
                        <div className='flex align-center margin-b-lg'>
                            <label className='margin-r-md'>Name:</label>
                            <input className='pad-xs width-100' type='text' placeholder='Option' value={optionName}
                                onChange={handleOptionName} />
                        </div>

                        <div className='flex justify-end'>
                            <Btn title='Cancel'
                                btnClass='bg-light-red delete-button text-white pad-lr-xlg text-small margin-r-sm b-none'
                                onClick={() => {
                                    setOptionName('');
                                    setAddOption(false)
                                }} />
                            <Btn title='Save'
                                btnClass='bg-light-green green-button text-white pad-lr-xlg text-small b-none'
                                onClick={() => {
                                    saveOption();
                                }} />
                        </div>
                    </div>
                    : null
                }
                <div className='underline-light-gray'></div>
                <div className="width-100">
                    {options?.length > 0
                        ? <table className='width-100 border-collapse'>
                            <thead>
                                <tr className='underline-light-gray'>
                                    <td></td>
                                    <td className='text-bold pad-tb-sm'>Name</td>
                                </tr>
                            </thead>
                            <tbody className='height-100'>
                                {options.map((option, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="width-15">
                                                <Btn icon='trash' btnClass=' margin-l-lg sm-box'
                                                    onClick={deleteOption(option?.id)} />
                                            </td>
                                            <td className=''>
                                                {editOption === option?.id ?
                                                    <input
                                                        type="text"
                                                        value={editedOptionName}
                                                        onChange={(e) => setEditedOptionName(e.target.value)} />
                                                    :
                                                    option?.name
                                                }
                                            </td>
                                            <td className={`${hideCancel(option?.id)} pad-r-md pad-t-sm pad-b-sm text-center`}>
                                                <Btn title='Cancel'
                                                    btnClass='bg-light-red delete-button text-white pad-lr-lg text-small b-none'
                                                    onClick={() => {
                                                        if (editOption === option?.id) {
                                                            setEditOption(0);
                                                        }
                                                    }} />
                                            </td>
                                            <td className=' pad-t-sm pad-b-sm text-center'>
                                                <Btn title={editOption !== option?.id ? 'Edit' : 'Save'}
                                                    btnClass={`${editOption > 0 ? '' : 'margin-r-sm margin-l-auto '} ${editOption !== option?.id ? 'bg-blue small-blue-button' : 'bg-light-green green-button margin-none'} ' text-white pad-lr-lg text-small b-none ' + ${invisible(option?.id)}`}
                                                    onClick={() => {
                                                        if (editOption > 0) changeOption(option?.id)
                                                        else editClicked(option?.id, option?.name)
                                                    }} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        : <div className='flex justify-center font-med pad-md margin-t-xlg bold'>
                            No Options Recorded Yet
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default LastActivityDateOptions;