import React from 'react';
import {isNote} from './productTypeCheck';
import formatNumber from '../../../utilities/formatNumber';

const CellTotalPrice = ({product}) => {
    // Return blank td if product is a note or has no total_price value
    if (isNote(product) || !product.total_price) {
        return <td key="total_price" className="center col-total_price"></td>;
    }

    return (
        <td key="total_price" className="center col-total_price">
            {`$${formatNumber(product.total_price)}`}
        </td>
    );
};

export default CellTotalPrice;
