import React, { useEffect, useState} from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import Btn from '../components/Btn';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';

const EditEmail = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    const [emailBody, setEmailBody] = useState();
    const [emailSubject, setEmailSubject] = useState();
    const [emailSignature, setEmailSignature] = useState();

    const [loading, setLoading] = useState(true);

    const getEmail = async () => {
        try {
            const res = await fetch(`${config.base_api}/email`, { headers });
            const data = await convertJSON(res);

            if (data) {

                setEmailSubject(data.subject);
                setEmailBody(data.body);
                setEmailSignature(data.signature);

                setLoading(false);
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true }});
            console.log(error);
        }
    }

    async function updateEmail() {
        try {
            const res = await fetch(`${config.base_api}/email`,
                {
                    headers,
                    method: 'PUT',
                    body: JSON.stringify({
                        subject: emailSubject,
                        body: emailBody,
                        signature: emailSignature
                    })
                }
            );
            const data = await convertJSON(res);
            
            if (!res.ok) throw data.error;

            dispatch({ type: ACTIONS.TOAST, payload: {
                message: 'Updated Email',
                isError: false
            }})
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: {
                message: 'Could not update email',
                isError: true
            }})
            console.log(error);
        }
    }

    useEffect(() => {
        getEmail();
    }, []);

    if (loading) {
        return (
            <div>
                <h1 className='centerText'>Edit Email</h1>
                <div className='quotesTable'>
                </div>
                <table className='editEmailTable'>
                    <thead>
                        <tr>
                            <th colSpan="2">
                                Section
                            </th>
                            <th colSpan="2">
                                Variable
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                Body
                            </td>
                            <td colSpan="2">
                                FIRST_NAME
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                Signature
                            </td>
                            <td colSpan="2">
                                SALES_REP_NAME
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                Signature
                            </td>
                            <td colSpan="2">
                                SALES_REP_EXTENSION
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div>
            <h1 className='centerText'>Edit Email</h1>
            <div className='quotesTable'>
            </div>
            <table className='editEmailTable'>
                <thead>
                    <tr>
                        <th colSpan="2">
                            Section
                        </th>
                        <th colSpan="2">
                            Variable
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="2">
                            Body
                        </td>
                        <td colSpan="2">
                            FIRST_NAME
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            Signature
                        </td>
                        <td colSpan="2">
                            SALES_REP_NAME
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            Signature
                        </td>
                        <td colSpan="2">
                            SALES_REP_EXTENSION
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id='editEmail'>
                <h6>Subject</h6>
                <textarea
                    id='emailSubject'
                    defaultValue={emailSubject}
                    rows={2}
                    onChange={e => setEmailSubject(e.target.value)}
                />
                <br />
                <br />
                <h6>Body</h6>
                <textarea
                    id='emailBody'
                    defaultValue={emailBody}
                    rows={10}
                    onChange={e => setEmailBody(e.target.value)}
                />
                <br />
                <br />
                <h6>Signature</h6>
                <textarea
                    id='emailSignature'
                    defaultValue={emailSignature}
                    rows={5}
                    onChange={e => setEmailSignature(e.target.value)}
                />
                <Btn title="Update Email" onClick={e => updateEmail()} 
                btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-md'/>
            </div>
        </div>
    )
}

export default EditEmail
