import React from 'react';
import formatNumber from '../../utilities/formatNumber';
import {saveQuote} from './SaveQuote';
import formatInput from '../../utilities/formatInput';
import {useStore} from '../../StoreProvider';
import {ACTIONS} from '../../Actions';

/*
quote: {
  discount: {
    lineItemsDiscount: 0,
    additionalDiscount: 0,
    totalDiscount: 0,
  }
}
*/

const Discount = ({setAutoSave}) => {
    const {state, dispatch} = useStore();

    return (
        <div className="discount component-card">
            <h4 className="discount_title pad-l-lg pad-t-lg">Discount</h4>
            <div className="discount_grid pad-l-lg pad-r-lg pad-b-lg">
                <p className="discount_label text-bold margin-r-sm">Sale Structure Discount:</p>
                <p className="discount_value">
                    $ {state?.quote?.discount?.lineItemsDiscount && `${formatNumber(state?.quote?.discount?.lineItemsDiscount)}`}
                </p>

                <p className="discount_label text-bold">Additional Discount:</p>
                <input
                    onWheel={(e) => e.target.blur()}
                    className="discount_value pad-l-xs pad-r-xs"
                    type="number"
                    value={formatInput(state?.quote?.discount?.additionalDiscount)}
                    onChange={(e) =>
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                discount: {
                                    ...state?.quote?.discount,
                                    additionalDiscount: e.target.value || null,
                                },
                            },
                        })
                    }
                    disabled={state?.quote.readOnly}
                />

                <p className="discount_label text-bold margin-t-sm">Discount Description:</p>
                <textarea
                    className="discount_value discount_value-description pad-xs"
                    value={state?.quote?.discount?.description}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                discount: {
                                    ...state?.quote?.discount,
                                    description: e.target.value,
                                },
                            },
                        });
                    }}
                    onBlur={(e) => {
                        setAutoSave(true);
                    }}
                    disabled={state?.quote.readOnly}
                />
            </div>
        </div>
    );
};

export default Discount;
