import React, { useEffect, useState } from 'react';
import GenericTable from '../components/table/GenericTable'; // Adjust the import path as necessary
import config from '../config'; // Ensure you have your configuration with the API's base URL
import authHeaders from '../utilities/authHeaders'; // Function to get auth headers
import { useStore } from '../StoreProvider';

const SwingSetsProducts = ({ access }) => {
  const { state } = useStore();
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState(null); // Track the row being edited
  const [weightOfUnit, setWeightOfUnit] = useState(null); // New state to track the edited value
  const [notes, setNotes] = useState(null); // New state to track the edited value
  const [onHand, setOnHand] = useState(null);
  const [savedCells, setSavedCells] = useState({}); // Tracks saved cells, e.g., { 'rowIndex-columnKey': true }
  const [cancel, setCancel] = useState(false);

  let allowed = false;
  if (access?.includes('admin') && +state?.auth?.isAdmin) {
      allowed = true;
  } else if (access?.includes('ops') && +state?.auth?.isOps) {
      allowed = true;
  } else if (access?.includes('sales') && +state?.auth?.isSales) {
      allowed = true;
  }

  const handleBlur = (sku, columnKey, value) => {
    console.log('cancel:', cancel)
    if (!cancel) {
      handleSave(sku, columnKey, value);
    } 
    else {
      setCancel(false);
    }
  };
  const handleSave = async (sku, columnKey, newValue) => {
    // Implement your save logic here
    if (columnKey === 'weightOfUnit') {
        setEditingRow(null); // Exit editing mode
        await updateWeightOfUnitDB(sku, newValue);

        // Optionally, update the data state with the new value
        const updatedData = data.map(item => item.sku === sku ? {...item, weightOfUnit: newValue} : item);
        setData(updatedData);
    }
    else if (columnKey === 'notes') {
        setEditingRow(null); // Exit editing mode
        await updateNotesDB(sku, newValue);
        const updatedData = data.map(item => item.sku === sku ? {...item, notes: newValue} : item);
        setData(updatedData);
    }
    else if (columnKey === 'onHand') {
      setEditingRow(null); // Exit editing mode
      const oldValue = data.find(item => item.sku === sku)?.onHand; // Get the old value
      await updateOnHandDB(sku, newValue, oldValue);
      const updatedData = data.map(item => item.sku === sku ? {...item, onHand: newValue} : item);
      setData(updatedData);
    }
    // For demonstration, we assume the save is immediate and successful

    // Update the savedCells state to mark this cell as saved
    const cellId = `${sku}-${columnKey}`;
    setSavedCells({ ...savedCells, [cellId]: true });

    // Optionally, clear the saved indicator after a delay
    setTimeout(() => setSavedCells((prev) => ({ ...prev, [cellId]: false })), 2000); // Clear after 2 seconds
  };

  // Updated columns with correct key accessors
  const columns = [
    { key: 'productName', label: 'Product Name', headerClass: 'pad-l-xxlg text-left pad-sm pad-t-xlg', dataClass: '200px pad-l-xxlg text-left' },
    { key: 'sku', label: 'Product \nCode/SKU', headerClass: 'text-center pad-sm pad-t-xlg', dataClass: '200px' }, // Using `sku` as key
    { key: 'onHand', label: 'On Hand', headerClass: 'text-center pad-sm  pad-t-xlg', dataClass: '200px',
      renderCell: (row) => {
        if (editingRow === row.sku) {
          return (
            <input
              type="number"
              className="number-input"
              value={onHand}
              onChange={(e) => setOnHand(e.target.value)} // Keep the input controlled
              onBlur={(e) => handleBlur(row.sku, 'onHand', Math.round(e.target.value))} // Save the value on blur
            />
          );
        } else {
          return row.onHand;
        }
      }
    },
    { key: 'empty', label: '', headerClass: 'text-center pad-sm  pad-t-xlg', dataClass: '200px' },
    { key: 'ages', label: 'Ages', headerClass: 'text-center pad-sm  pad-t-xlg', dataClass: '200px' },
    {
        key: 'weightOfUnit', 
        label: 'Weight \nof Unit', 
        headerClass: 'text-center lh-normal pad-sm  pad-t-xlg',
        renderCell: (row) => {
          if (editingRow === row.sku) {
            return (
              <input
                type="number"
                className="number-input"
                value={weightOfUnit}
                onChange={(e) => setWeightOfUnit(e.target.value)} // Keep the input controlled
                onBlur={(e) => handleBlur(row.sku, 'weightOfUnit', Math.round(e.target.value))} // Save the value on blur
              />
            );
          } else {
            return row.weightOfUnit;
          }
        }
      },
      {
        key: 'notes',
        label: 'Notes',
        headerClass: 'text-center pad-sm  pad-t-xlg',
        renderCell: (row) => {
          if (editingRow === row.sku) {
            return (
              <input
                type="text"
                value={notes}
                onChange={(e) => setNotes(e.target.value)} // Keep the input controlled
                onBlur={(e) => handleBlur(row.sku, 'notes', e.target.value)} // Save the value on blur
              />
            );
          } else {
            return row.notes;
          }
        }
      }
  ];

const editSaveActions = (row) => {
  // Actions when a row is being edited
  if (editingRow === row.sku) {
    return [
      {
        label: 'Cancel',
        onMouseDown: () => setCancel(true), // Set cancel state to true on mouse down
        handler: () => {
          setEditingRow(null); // Exit editing mode without saving
          setWeightOfUnit(null); // Reset edited value (optional, based on your state management)
          setOnHand(null); // Reset edited value (optional, based on your state management)
          setNotes(null); // Reset edited value (optional, based on your state management)
        },
        buttonClass: 'btn-cancel',
      }
    ];
  } 
  // Default action when not editing
  else {
    return [
      {
        label: 'Edit',
        handler: () => {
          setEditingRow(row.sku); // Set this row to be in editing mode
          setWeightOfUnit(row.weightOfUnit); // Initialize editValue with this row's value
          setOnHand(row.onHand); // Initialize editValue with this row's value
          setNotes(row.notes); // Initialize editValue with this row's value
          setCancel(false); // Reset cancel state
        },
        buttonClass: 'btn-edit',
      }
    ];
  }
};

  // Adjusted to be a function that accepts a row and returns actions for that row
  const actions = (row) => {
    return [
      ...editSaveActions(row),
    ];
  };

  const updateOnHandDB = async (sku, newValue, oldValue = null) => {
    try {
        const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
        const response = await fetch(`${config.base_api}/zoho/stockSheet/swingSetsProducts/onHand/${sku}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ onHand: newValue, ...(oldValue !== null ? { previousOnHand: oldValue } : {}) }),
        });

        if (!response.ok) throw new Error('Failed to update on hand');

        const updatedData = await response.json();
        console.log('Updated on hand:', updatedData);
    } catch (error) {
        console.error('Error updating on hand:', error);
        // Handle error, e.g., set error state, show toast notification, etc.
    }
  }

  const updateNotesDB = async (sku, newValue) => {
    try {
        const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
        const response = await fetch(`${config.base_api}/zoho/stockSheet/swingSetsProducts/notes/${sku}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ notes: newValue }),
        });

        if (!response.ok) throw new Error('Failed to update notes');

        const updatedData = await response.json();
        console.log('Updated notes:', updatedData);
    } catch (error) {
        console.error('Error updating notes:', error);
        // Handle error, e.g., set error state, show toast notification, etc.
    }
  };

  const updateWeightOfUnitDB = async (sku, newValue) => {
    try {
      const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
      const response = await fetch(`${config.base_api}/zoho/stockSheet/swingSetsProducts/weightOfUnit/${sku}`, { 
        method: 'PUT', 
        headers,
        body: JSON.stringify({ weightOfUnit: newValue }),
      });

      if (!response.ok) throw new Error('Failed to update weight of unit');

      const updatedData = await response.json();
      console.log('Updated weight of unit:', updatedData);
    } catch (error) {
      console.error('Error updating weight of unit:', error);
      // Handle error, e.g., set error state, show toast notification, etc.
    }
  }

  useEffect(() => {
    const fetchSwingSetsProductsData = async () => {
      try {
        const headers = authHeaders(); // Assuming authHeaders function returns necessary authentication headers
        const response = await fetch(`${config.base_api}/zoho/stockSheet/swingSetsProducts`, { method: 'GET', headers });

        if (!response.ok) throw new Error('Failed to fetch stock sheet data');

        const fetchedData = await response.json();

        // Transform data to match the columns format and adjust according to the available data keys
        const transformedData = fetchedData.map(item => ({
          productName: item.productName, // Assuming this key is correct
          sku: item.sku, // Using `sku` as received from the data
          onHand: item.stock, // Assuming this key is correct
          ages: item.ages, // Removed transformation for age and weightOfUnit
          weightOfUnit: item.weight_of_unit,
          notes: item.notes,
          // Removed transformation for age and weightOfUnit
        }));

        setData(transformedData);
      } catch (error) {
        console.error('Error fetching stock sheet data:', error);
        // Handle error, e.g., set error state, show toast notification, etc.
      }
    };

    fetchSwingSetsProductsData();
  }, []); // Empty array means this effect runs once on component mount

  const getCellClassName = (value, column) => {
    if (column !== 'onHand') return '';
    if (+value === 0) return 'cell-zero';
    else if (+value < 0) return 'cell-negative';
    else return '';
  };

  const style = { continuedTable: true};

  const actionHeader = {
    headerClass: "text-center pad-sm  pad-t-xlg",
  }

  return (
    //<div className="stockSheetDashboard">
      <GenericTable columns={columns} data={data} actions={allowed ? actions : null} getCellClassName={getCellClassName} savedCells={savedCells} style={style} actionHeader={actionHeader}/>
    //</div>
  );
};

export default SwingSetsProducts;
