import React, {useEffect, useState} from 'react';
import QuotesTable from '../components/savedQuotes/QuotesTable';
import SearchQuotes from '../components/savedQuotes/SearchQuotes';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import { useCacheBuster } from 'react-cache-buster';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';

const SavedQuotes = ({
    filteredList,
    setDbQuote,
    quoteSearchTerm,
    setQuoteSearchTerm
}) => {
    const { state, dispatch } = useStore();
    const { checkCacheStatus } = useCacheBuster();
    // When SavedQuotes loads - clear out any quote in state.
    // quote attributes taken from app.js
    const [savedQuotesList, setSavedQuotesList] = useState([]);
    const [quotesLoading, setQuotesLoading] = useState(false);
    const [contactInfo, setContactInfo] = useState({
        sameShippingAddress: true,
        sameBillingContact: true,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        shipping_firstname: '',
        shipping_lastname: '',
        shipping_email: '',
        shipping_phone: '',
        shipping_mobile: '',
        shipping_company: '',
        shipping_address: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
    });
    const [updateContactInfo, setUpdateContactInfo] = useState({
        // id is hubspot_contact_id
        id: '',
        // sameShippingAddress: false,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        // shipping contact hubspot id
        shipping_contactid: '',
        shipping_firstname: '',
        shipping_lastname: '',
        shipping_email: '',
        shipping_phone: '',
        shipping_mobile: '',
        shipping_company: '',
        shipping_address: '',
        shipping_city: '',
        shipping_state: '',
        shipping_zip: '',
    });
    const [contactSelected, setContactSelected] = useState('create');

    useEffect(() => {
        checkCacheStatus();
        dispatch({type: ACTIONS.QUOTE, payload: emptyQuoteObj});
        dispatch({type: ACTIONS.PRODUCT_TABLE_ITEMS, payload: []});
  
        setContactInfo({
            sameShippingAddress: true,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            company: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_zip: '',
        });
        setUpdateContactInfo({
            // id is hubspot_contact_id
            id: '',
            // sameShippingAddress: false,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            company: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            shipping_address: '',
            shipping_city: '',
            shipping_state: '',
            shipping_zip: '',
        });
        setContactSelected('create');
        dispatch({type: ACTIONS.INCLUDE_SHEETS, payload: []});
    }, []);

    return (
        <div className="savedQuotes">
            <h1 className = 'margin-t-xlg'>Quotes</h1>
            <div style={{margin: '2rem 0'}}>
                <SearchQuotes
                    savedQuotesList={savedQuotesList}
                    setSavedQuotesList={setSavedQuotesList}
                    quoteSearchTerm={quoteSearchTerm}
                    setQuoteSearchTerm={setQuoteSearchTerm}
                    setQuotesLoading={setQuotesLoading}
                />
            </div>
            <QuotesTable
                savedQuotesList={savedQuotesList}
                filteredList={filteredList}
                setDbQuote={setDbQuote}
                quotesLoading={quotesLoading}
            />
        </div>
    );
};

export default SavedQuotes;
