import React from 'react';
import {Link} from 'react-router-dom';
import emptyQuoteObj from '../utilities/emptyQuoteObj';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
const NavbarAdmin = ({pathname, setPathname}) => {
    const { dispatch } = useStore();
    const resetNav = () => {
        dispatch({ type: ACTIONS.QUOTE, payload: emptyQuoteObj });
        dispatch({ type: ACTIONS.NAVBAR_QUOTE_ID, payload: "" });
    };
    return (
        <div className="width-100">
            
            <ul className='flex-row list-none pad-md bg-orange gap-xlg justify-start'>
                <li className= "text-small no-underline text-bold first-link-nav"
                onClick={()=>{resetNav();
                setPathname ('/admin/freightRules')}}>
                    <Link to="/admin/freightRules" className={`no-underline ${pathname === '/admin/freightRules' ? 'text-black' : 'text-white'}`}>
                        Freight Rules
                    </Link>
                </li>              
                <li className='text-white text-small no-underline text-bold'
                 onClick={()=>{resetNav();
                 setPathname ('/operations/projects')}}>
                    <Link to="/operations/projects" className={`no-underline ${pathname.includes('/operations/projects') ? 'text-black' : 'text-white'}`}>
                        Projects
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/products')}}>
                    <Link to="/admin/products" className={`no-underline ${pathname === '/admin/products' ? 'text-black' : 'text-white'}`}>
                        Products
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/productVariants')}}>
                    <Link to="/operations/productVariants" className={`no-underline ${pathname === '/operations/productVariants' ? 'text-black' : 'text-white'}`}>
                        Product Variants
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/manufacturers')}}>
                    <Link to="/operations/manufacturers" className={`no-underline ${pathname === '/operations/manufacturers' ? 'text-black' : 'text-white'}`}>
                        Manufacturers
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/zoho')}}>
                    <Link to="/operations/zoho" className={`no-underline ${pathname === '/operations/zoho' ? 'text-black' : 'text-white'}`}>
                        Stock Sheet
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/users')}}>
                    <Link to="/admin/users" className={`no-underline ${pathname === '/admin/users' ? 'text-black' : 'text-white'}`}>
                        Users
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/email')}}>
                    <Link to="/admin/email" className={`no-underline ${pathname === '/admin/email' ? 'text-black' : 'text-white'}`}>
                        Edit Email
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/operations/sync')}}>
                    <Link to="/operations/sync" className={`no-underline ${pathname === '/operations/sync' ? 'text-black' : 'text-white'}`}>
                        Sync Section
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/reports')}}>
                    <Link to="/admin/reports" className={`no-underline ${pathname === '/admin/reports' ? 'text-black' : 'text-white'}`}>
                        Reports
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/configuration')}}>
                    <Link to="/admin/configuration" className={`no-underline ${pathname === '/admin/configuration' ? 'text-black' : 'text-white'}`}>
                        Config
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/callAnalysis')}}>
                    <Link to="/admin/callAnalysis" className={`no-underline ${pathname === '/admin/callAnalysis' ? 'text-black' : 'text-white'}`}>
                        Call Analysis
                    </Link>
                </li>
                <li className='text-white text-small no-underline text-bold' 
                onClick={()=>{resetNav();
                setPathname ('/admin/apiErrorRetry')}}>
                    <Link to="/admin/apiErrorRetry" className={`no-underline ${pathname === '/admin/apiErrorRetry' ? 'text-black' : 'text-white'}`}>
                        API Retry
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default NavbarAdmin;
