export default function authHeaders(token, refreshToken = "", contentType = "json") {
    if (contentType === "formData") {
        return new Headers({
            // 'Content-Type': "multipart/form-data; boundary=*",
            Authorization: `Bearer ${token}`,
            RefreshToken: refreshToken,
        });
    }
    return new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        RefreshToken: refreshToken,
    });
}
