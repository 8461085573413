import {useStore} from '../../StoreProvider';

const ContactDetailsGeneral = ({handleContactDetailsChange, billingContact, quote}) => {
    const {state} = useStore();
    
    return (
        <div className="hubspotContact">
            <h4 className="billing_header">Billing Contact</h4>

            <div className="hubspotContact_details hubspotContact_details-billing">
                <label htmlFor="hubspot_fname">
                    First Name: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    value={billingContact.firstname}
                    id="hubspot_fname"
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="firstname"
                    required
                    disabled={quote.readOnly}
                />

                <label htmlFor="hubspot_lname">
                    Last Name: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_lname"
                    value={billingContact.lastname}
                    name="lastname"
                    onChange={handleContactDetailsChange}
                    type="text"
                    required
                    disabled={quote.readOnly}
                />

                <label htmlFor="hubspot_email">
                    Email: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_email"
                    value={billingContact.email}
                    type="email"
                    onChange={handleContactDetailsChange}
                    name="email"
                    required
                    disabled={quote.readOnly}
                />

                <label htmlFor="hubspot_phone">
                    Phone: <span className="hubspotContact_details_required">*</span>
                </label>
                <input
                    id="hubspot_phone"
                    value={billingContact.phone}
                    type="tel"
                    onChange={handleContactDetailsChange}
                    name="phone"
                    required
                    disabled={quote.readOnly}
                />

                <label htmlFor="hubspot_phone">Mobile (Optional):</label>
                <input
                    id="hubspot_mobile"
                    value={billingContact.mobile}
                    type="tel"
                    onChange={handleContactDetailsChange}
                    name="mobile"
                    disabled={quote.readOnly}
                />

                <label htmlFor="hubspot_company">Company:</label>
                <input
                    id="hubspot_company"
                    value={billingContact.company}
                    type="text"
                    onChange={handleContactDetailsChange}
                    name="company"
                    disabled={quote.readOnly}
                />
            </div>
        </div>
    );
};

export default ContactDetailsGeneral;
