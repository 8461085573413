import React from 'react';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';

const IncludeTaxes = ({setRecalculateTotals}) => {
    const { state, dispatch } = useStore();

    return (
        <div className = 'pad-l-lg'>
            <div className = 'flex align-center column-gap-sm'>
                <input
                    id="includeTaxes"
                    type="checkbox"
                    className = "sm-box pointer"
                    checked={state?.quote.includeTaxes}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.QUOTE,
                        payload: {
                            ...state?.quote,
                                includeTaxes: e.target.checked,
                            },
                    });
                        setRecalculateTotals(true);
                    }}
                    disabled={state?.quote.readOnly}
                />
                <label htmlFor="includeTaxes" className = 'pointer'>
                    Include Taxes?
                </label>
            </div>
            {state?.quote.includeTaxes && (
                <label htmlFor="tax_rate" style={{marginRight: '6rem'}} className = 'margin-l-lg pad-l-xs'>
                    Rate %{' '}
                </label>
            )}
            {state?.quote.includeTaxes && (
                <input
                    style={{maxWidth: '6rem'}}
                    className = 'pad-xs'
                    type="number"
                    id="tax_rate"
                    value={state?.quote.tax_rate}
                    onChange={(e) => {
                        dispatch({
                            type: ACTIONS.QUOTE,
                            payload: {
                                ...state?.quote,
                                tax_rate: e.target.value,
                            },
                        });
                    }}
                    onBlur={(e) => setRecalculateTotals(true)}
                    disabled={state?.quote.readOnly}
                />
            )}
        </div>
    );
};

export default IncludeTaxes;
