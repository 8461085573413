import React from 'react';
import {useStore} from '../../StoreProvider';

/**
 * CSS is located in sendEmail/_form.scss
 *
 */
const SalesPersonInfo = () => {
    const {state} = useStore();
    return (
        <div className="sendEmail_salesPerson">
            <h3>SalesPerson Info</h3>
            <div className="sendEmail_salesPerson_grid">
                {/* grid is two items */}
                <p>Name:</p>
                <p>{state?.quote?.prepared_by_name}</p>
                <p>Email:</p>
                <p>{state?.quote?.prepared_by_email}</p>
                <p>Phone EXT:</p>
                <p>{state?.quote?.prepared_by_phone}</p>
            </div>
        </div>
    );
};

export default SalesPersonInfo;
