import React, { useContext, useState, useReducer } from 'react'
import { storeReducer } from './storeReducer';
import { store } from './store';

const StoreContext = React.createContext();
// const StoreUpdateContext = React.createContext();

export function useStore() {
    return useContext(StoreContext);
}

// export function useStoreUpdate() {
//     return useContext(StoreUpdateContext);
// }

export function StoreProvider({ children }) {
    const [state, dispatch] = useReducer(storeReducer, store);
    return (
        <StoreContext.Provider value = {{ state, dispatch }}>
            {children}
        </StoreContext.Provider>
    );
}