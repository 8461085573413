import React from 'react';
import { useStore } from '../../../StoreProvider';
import { ACTIONS } from '../../../Actions';

const CellDescription = ({calculateRow, product, setAutoSave}) => {
    const { state, dispatch } = useStore();

    /**
  Product desciption 
  Show description from database, but update if user inputs
  Create bool state value if description is altered
  */

    return (
        <td key="desc" className="col-description">
            <textarea
                className={`pad-xs ${product.product_description_altered ? 'altered' : ''}`}
                name="product_description"
                // Style FREE SHIPPING if product has any
                value={product.product_description}
                onChange={(e) => {
                    const newDescription = e.target.value;

                    try{
                        // Update state using the callback version of setState
                        dispatch( ({
                            type: ACTIONS.PRODUCT_TABLE_ITEMS,
                            payload: state?.productTableItems.map((prod) =>
                                prod.playground_boss_sku === product.playground_boss_sku
                                    ? { ...prod, product_description: newDescription, product_description_altered: true }
                                    : prod
                            )
                        }));
                    } catch (error) {
                        console.error('Error updating product description', error);
                    }
                }}
                onBlur={(e) => {
                    setAutoSave(true);
                    setTimeout(() => calculateRow(product.playground_boss_sku), 10);
                }}
                disabled={state?.quote.readOnly}
            />
        </td>
    );
};

export default CellDescription;
