import React, { useEffect, useState } from 'react';
import config from '../config';
import authHeaders from '../utilities/authHeaders';
import Btn from '../components/Btn';
import HeavyGroup from '../components/freightRules/tables/HeavyGroup';
import Zaeger from '../components/freightRules/tables/Zaeger';
import LibertyTire from '../components/freightRules/tables/LibertyTire';
import ActionPlaySystems from '../components/freightRules/tables/ActionPlaySystems';
import Installer from '../components/freightRules/tables/Installer';
import AthleticConnection from '../components/freightRules/tables/AthleticConnection';
import MyTCoat from '../components/freightRules/tables/MyTCoat';
import { useStore } from '../StoreProvider';
import { ACTIONS } from '../Actions';
import convertJSON from '../utilities/convertJSON';

const FreightRules = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [freightRules, setFreightRules] = useState({});
    const [loading, setLoading] = useState(true);
    const [markup, setMarkup] = useState();

    const getFreightRules = async () => {
        try {
            const res = await fetch(`${config.base_api}/freightRules`, { headers });
            const data = await convertJSON(res);
            const markup = data.freight_markup; 
            setFreightRules(data);
            setMarkup(markup); 
            setLoading(false); 
        } catch (error) {
            console.log('freightRules went wrong', error);
        }
    }
    

    useEffect(() => {
        getFreightRules();
    }, []);


    const putMarkup = async () => {
        var inputValue = document.getElementById("main_freight_markup").value;
        let putBody = {
            freight_markup: inputValue
        };


        try {

            const res = await fetch(`${config.base_api}/freightRules`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(putBody)
            });
            await convertJSON(res);

            dispatch({ type: ACTIONS.TOAST, payload: { message: 'Markup Updated' }});
        } catch (error) {
            console.log(error);
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true }});
        }
    };

    if (loading) {
        return <h1>Freight and Other</h1>
    }
    return (
      <div>
          <div className='freightHeader'>
              <h1>Freight and Other</h1>
              <div>
                  <label id='markup_label' htmlFor='markup'>
                      Freight Markup: 
                  </label>
                    <input id='main_freight_markup' defaultValue={markup} onChange={e => setMarkup(e.target.value)} />
                    <Btn title="Update" onClick={putMarkup}
                     btnClass = 'blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-sm' />
              </div>
        </div>
          <HeavyGroup
                freightRules={freightRules}
                markup={markup}
            
          />
          <Zaeger
            freightRules={freightRules}
            markup={markup}
          />
          <LibertyTire
              freightRules={freightRules}
              markup={markup}
          />
          <ActionPlaySystems
              freightRules={freightRules}
              markup={markup}
          />
          <MyTCoat
            freightRules={freightRules}
            markup={markup}
            />
        <AthleticConnection
            freightRules={freightRules}
        />
        <Installer
            freightRules={freightRules}
        />
    </div>
  )
}

export default FreightRules
