import React, {useEffect} from 'react';
import {useStore} from '../../StoreProvider';

const SameBillingAddress = ({
    handleContactDetailsChange,
    billingContact,
    billingContactID,
    isNewBillingContact,
    setShippingContact,
    setShippingContactID,
    shippingContact,
}) => {
    const {state} = useStore();
    // Set the state for shipping address to address if box is checked
    useEffect(() => {
        if (billingContact.sameShippingAddress) {
            // if box is checked we should set the address to match the address in the billing contact
            setShippingContact({
                ...shippingContact,
                shipping_address: billingContact.address,
                shipping_city: billingContact.city,
                shipping_state: billingContact.state,
                shipping_zip: billingContact.zip,
            });
        } else {
            // if the box is unchecked then we set to an empty state
            setShippingContact({
                ...shippingContact,
                shipping_address: '',
                shipping_city: '',
                shipping_state: '',
                shipping_zip: '',
            });
        }
    }, [billingContact.sameShippingAddress]);

    return (
        <div className="hubspotContact_details_sameBillingAddress">
            <label htmlFor="hubspot_street">Same as Billing Address:</label>
            <input
                name="sameShippingAddress"
                type="checkbox"
                checked={billingContact.sameShippingAddress}
                onChange={handleContactDetailsChange}
                disabled={state?.quote.readOnly}
            />
        </div>
    );
};

export default SameBillingAddress;
